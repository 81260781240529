import { Link } from "react-router-dom"
import { motion } from "framer-motion";
import { AlertCircle, AlertTriangle, CheckCircle2 } from "lucide-react";

/**
* @namespace SliderCardHotel
* @memberof module:HotelHome
* @description Component responsible for rendering individual hotel service cards.
* Supports image and icon-based layouts with completion indicators and animations.
*
* @component
* @param {Object} props - Component props
* @param {Object} props.subItem - Service item data to be displayed
* @param {number} props.index - Item index for animation sequencing
*
* @returns {JSX.Element} SliderCardHotel component
*
* @example
* <SliderCardHotel
*   subItem={serviceData}
*   index={0}
* />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const SliderCardHotel = ({subItem, index}) => {
  const MotionLink = motion.create(Link);

  /**
  * @function getCompletionIndicator
  * @memberof module:HotelHome.SliderCardHotel
  * @description Determines and returns the appropriate completion indicator icon.
  * Provides visual feedback based on completion rate thresholds.
  *
  * @param {number} rate - Completion rate percentage
  * @returns {JSX.Element} Icon component based on completion rate
  *
  * @example
  * getCompletionIndicator(75)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const getCompletionIndicator = (rate) => {
    if (rate <= 40) {
      return ( <AlertCircle className="w-5 h-5 text-red-500" aria-label="Low completion rate" /> );
    } else if (rate <= 70) {
      return (
        <AlertTriangle className="w-5 h-5 text-yellow-500" aria-label="Medium completion rate" /> );
    } else {
      return (
        <CheckCircle2 className="w-5 h-5 text-green-500 dark:text-green-300" aria-label="High completion rate" />
      );
    }
  };

 /**
  * @constant {Array} typesToStrikethrough
  * @memberof module:HotelHome.SliderCardHotel
  * @description Array of service types that should be displayed with strikethrough styling.
  * Used to visually indicate unavailable or deprecated services.
  *
  * @type {Array<string>}
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 const typesToStrikethrough = [
    ];


  return (
      subItem.ImagesUrl.length === 0 ? (
      <motion.div key={index} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: index * 0.1 }} >
        <MotionLink to={subItem.LinkTo} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.98 }} className="flex flex-col bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border h-40 w-50 rounded-lg shadow p-4 w-full relative transition duration-300 ease-in-out transform hover:shadow-lg" >
          <div className="flex justify-between items-center h-full">
            <div className="flex flex-col items-center flex-grow justify-center text-center">
              <span dangerouslySetInnerHTML={{ __html: subItem.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp_violet dark:text-gxp_violet_dark  mb-2" />
              <span className={` ${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
            </div>
            {subItem.rate && (
              <div className="flex items-center gap-2 absolute right-7 top-5">
                {getCompletionIndicator(subItem.rate)}
                <span className={`text-sm  ${ subItem.rate <= 40 ? "text-red-500" : subItem.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} >
                  {subItem.rate}%
                </span>
              </div>
            )}
          </div>
        </MotionLink>
      </motion.div>
    ):(
      <motion.div key={index} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: index * 0.1 }} >
        <MotionLink to={subItem.LinkTo} whileHover={{ scale: 1.05 }} whileTap={{ scale: 0.98 }} className="flex flex-col bg-white dark:bg-gxp_dark_3 text-white dark:border-gxp_dark_1 border h-40 w-50 rounded-lg shadow p-4 w-full relative transition duration-300 ease-in-out transform hover:shadow-lg" style={{ backgroundImage: `url(${subItem.ImagesUrl[0]})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
          <div className="flex justify-between items-center h-full absolute inset-0 bg-gradient-to-t from-black/80 to-transparent rounded-md">
            <div className="flex items-end h-full w-full justify-center pb-2">
              <span className={` ${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
            </div>
            {subItem.rate && (
              <div className="flex items-center gap-2 absolute right-7 top-5">
                {getCompletionIndicator(subItem.rate)}
                <span className={`text-sm  ${ subItem.rate <= 40 ? "text-red-500" : subItem.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} >
                  {subItem.rate}%
                </span>
              </div>
            )}
          </div>
        </MotionLink>
      </motion.div>)
  )
}
