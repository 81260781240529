import { motion, AnimatePresence } from "framer-motion";
import { ChevronDown, ChevronsUp, Edit2, PlusCircle } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { DynamicValueEditor } from "../../../Components/utils/DynamicValueEditor";
import { AppContext } from "../../../../context/AppContext";
import { useTranslation } from "react-i18next";
import { HMGeneralDataCategories, iFrameCategoriesMap, MFGeneralDataCategories, MHGeneralDataCategories, OCConsentDataCategories, OCFormDataCategories, OCgeneralTermDataCategories, OCSuccessDataCategories, TRDateDataCategories, TRFormDataCategories, TRGuestDataCategories, TRSuccessDataCategories, TRTimeDataCategories } from "./iFrameCategoriesData";
import { Tooltip } from "react-tooltip";
import { hostSelectCustomStyles } from "../../../../01_HotelPanel/components/utils/hostSelectStyles";
import Select from "react-select";
import { Switch } from "antd";

export const SidebarThemeDefault = ({ filteredCategories, setFilteredCategories, setFile, themeDefaultLines, setThemeDefaultLines, themes, selectedTheme, setSelectedTheme, setIsThemeDefaultModalOpen, categories, setCategories, expandedCategories, toggleCategory, setModalType }) => {
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global");
  const [organizedCategories, setOrganizedCategories] = useState([]);
  const [expandedSections, setExpandedSections] = useState([]);
  const [iFrameCategories, setIFrameCategories] = useState([]);
  const [isEmptySelection, setIsEmptySelection] = useState(true);
  const [inspectMode, setInspectMode] = useState(() => Boolean(JSON.parse(localStorage.getItem("inspectMode"))));

  const toggleInspectMode = () => {
    const newMode = !inspectMode;
    setInspectMode(newMode);
    localStorage.setItem("inspectMode", newMode.toString());

    const iframe = document.getElementById('Iframe');
    if (iframe && iframe.contentWindow) {
      iframe.contentWindow.postMessage({
        action: "toggleInspectMode",
        value: newMode,
        debugInfo: 'Toggle Inspect Mode'
      }, "*");
    }
  };

  useEffect(() => {

    setInspectMode(false)

  }, [expandedCategories]);

  const collapseAll = () => {
    setExpandedSections([]);
  };

  const updateIFrameCategories = (styleCategoryCode, page = "default") => {
    const categoryMap = iFrameCategoriesMap[styleCategoryCode];
    if (categoryMap) {
      setIFrameCategories(categoryMap[page]);
    }
  };

  useEffect(() => {
    if (categories?.length > 0 && expandedCategories.length > 0) {
      const selectedCategory = categories.find(
        (cat) => cat.id === expandedCategories[0]
      );
      if (selectedCategory) {
        updateIFrameCategories(selectedCategory.styleCategoryCode);
        setIsEmptySelection(false);
      }
    } else {
      setIsEmptySelection(true);
      setIFrameCategories([]);
    }
  }, [expandedCategories, categories]);

  const toggleSection = (sectionId) => {
    setExpandedSections((prev) =>
      prev.includes(sectionId)
        ? prev.filter((id) => id !== sectionId)
        : [...prev, sectionId]
    );
  };

  const handleThemeChange = (event) => {
    const selectedId = parseInt(event.target.value);
    const newTheme = themes.find((theme) => theme.id === selectedId);
    setSelectedTheme(newTheme);
    localStorage.setItem("themeDefaultSelected", JSON.stringify(newTheme));
  };

  const onChangeThemeValues = (e, subCategory) => {
    const existingLine = themeDefaultLines.find(
      (line) =>
        line.themeDescriptionId === selectedTheme.id &&
        line.themeStyleId === subCategory.themeStyleId
    );

    if (existingLine) {
      const updatedLines = themeDefaultLines.map((line) => {
        if (
          line.themeDescriptionId === selectedTheme.id &&
          line.themeStyleId === subCategory.themeStyleId
        ) {
          return { ...line, value: e.target.value };
        }
        return line;
      });
      setThemeDefaultLines(updatedLines);
    } else {
      const newThemeDefaultLine = {
        themeDescriptionId: selectedTheme.id,
        themeStyleId: subCategory.themeStyleId,
        value: e.target.value,
        isActive: true,
        user: state.adminLoggedIn.username,
      };
      setThemeDefaultLines([...themeDefaultLines, newThemeDefaultLine]);
    }
  };

  const organizeSubCategories = (categories) => {
    return categories.map((category) => {
      const sections = category.subCategories.reduce((acc, subCategory) => {
        const section = subCategory.styleSubCategoryCode.includes("-")
          ? subCategory.styleSubCategoryCode.split("-")[0]
          : "General";

        if (!acc[section]) {
          acc[section] = [];
        }
        acc[section].push(subCategory);
        return acc;
      }, {});

      const organizedSections = Object.entries(sections)
        .sort((a, b) => a[0].localeCompare(b[0]))
        .map(([sectionName, items]) => ({
          id: `${category.styleCategoryCode}-${sectionName}`,
          name: sectionName,
          items: items.sort((a, b) =>
            a.description.localeCompare(b.description)
          ),
        }));

      return {
        ...category,
        sections: organizedSections,
      };
    });
  };

  useEffect(() => {
    const newCategories = [...categories]
      .sort((a, b) => a.styleCategoryCode.localeCompare(b.styleCategoryCode))
      .map((category) => ({
        ...category,
        subCategories: category.subCategories
          .sort((a, b) =>
            a.styleSubCategoryCode.localeCompare(b.styleSubCategoryCode)
          )
          .map((subCategory) => {
            const existingLine = themeDefaultLines.find(
              (line) => line.themeStyleId === subCategory.themeStyleId
            );

            if (existingLine) {
              return {
                ...subCategory,
                value: existingLine.value,
              };
            }
            return subCategory;
          }),
      }));

    if (JSON.stringify(newCategories) !== JSON.stringify(categories)) {
      setCategories(newCategories);
    }
  }, [themeDefaultLines]);

  useEffect(() => {
    const organized = organizeSubCategories(categories);
    setOrganizedCategories(organized);
  }, [categories]);

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.action === "hover") {
        const element = document.getElementById(event.data.elementId);
        if (element) {
          element.classList.add("bg-gray-200");
        }
      } else if (event.data.action === "hoverOut") {
        const element = document.getElementById(event.data.elementId);
        if (element) {
          element.classList.remove("bg-gray-200");
        }
      } else if (event.data.action === "OC-FormPage") {
        setIFrameCategories(OCFormDataCategories);
      } else if (event.data.action === "OC-ConsentPage") {
        setIFrameCategories(OCConsentDataCategories);
      } else if (event.data.action === "OC-GeneralTermPage") {
        setIFrameCategories(OCgeneralTermDataCategories);
      } else if (event.data.action === "OC-SuccessPage") {
        setIFrameCategories(OCSuccessDataCategories);
      } else if (event.data.action === "TR-GuestPage") {
        setIFrameCategories(TRGuestDataCategories);
      } else if (event.data.action === "TR-DatePage") {
        setIFrameCategories(TRDateDataCategories);
      } else if (event.data.action === "TR-TimePage") {
        setIFrameCategories(TRTimeDataCategories);
      } else if (event.data.action === "TR-FormPage") {
        setIFrameCategories(TRFormDataCategories);
      } else if (event.data.action === "TR-SuccessPage") {
        setIFrameCategories(TRSuccessDataCategories);
      } else if (event.data.action === "MH-General") {
        setIFrameCategories(MHGeneralDataCategories);
      } else if (event.data.action === "MF-General") {
        setIFrameCategories(MFGeneralDataCategories);
      } else if (event.data.action === "HM-General") {
        setIFrameCategories(HMGeneralDataCategories);
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    if (organizedCategories?.length && iFrameCategories?.length) {
      const filtered = organizedCategories
        .map((category) => ({
          ...category,
          sections: category.sections
            .filter((section) =>
              iFrameCategories.some(
                (frameCategory) => frameCategory.id === section.id
              )
            )
            .sort((a, b) => {
              const orderA =
                iFrameCategories.find((frame) => frame.id === a.id)?.order ||
                Number.MAX_VALUE;
              const orderB =
                iFrameCategories.find((frame) => frame.id === b.id)?.order ||
                Number.MAX_VALUE;
              return orderA - orderB;
            }),
        }))
        .filter((category) => category.sections.length > 0);

      setFilteredCategories(filtered);
    } else {
      setFilteredCategories([]);
    }
  }, [organizedCategories, iFrameCategories]);

  return (
    <div className="w-[250px] min-w-64 bg-white shadow-lg flex flex-col">
      <div className="p-4 border-b">
        <div className="flex items-center justify-between mb-1">
          <div className="flex items-center justify-start p-2">
            <Switch
              checked={inspectMode}
              onChange={toggleInspectMode}
              className="bg-[var(--host-r24-primary-color)]"
              checkedChildren={`Inspect`}
              unCheckedChildren={`Preview`}
            />
          </div>
          <div className="flex items-center gap-2">
            <button
              data-tooltip-id={`edit`}
              data-tooltip-content={`${t(`HotelPanel.Theme.edit`)}`}
              data-tooltip-place="bottom"
              onClick={() => {
                setModalType("edit");
                setIsThemeDefaultModalOpen(true);
              }}
              className="host-grid-button transition-all duration-300 ease-in-out relative"
            >
              <Edit2 className="w-5 h-5" />
              <Tooltip
                id="edit"
                style={{
                  padding: "4px 8px",
                  backgroundColor: "var(--host-r24-tooltip-background)",
                  borderRadius: "6px",
                  transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
                }}
                offset={5}
                className="z-[100]"
              />
            </button>
            <button
              data-tooltip-id={`add`}
              data-tooltip-content={`${t(`HotelPanel.Theme.add`)}`}
              data-tooltip-place="bottom"
              onClick={() => {
                setModalType("add");
                setIsThemeDefaultModalOpen(true);
              }}
              className="host-grid-button transition-all duration-300 ease-in-out"
            >
              <PlusCircle className="w-5 h-5" />
              <Tooltip
                id="add"
                style={{
                  padding: "4px 8px",
                  backgroundColor: "var(--host-r24-tooltip-background)",
                  borderRadius: "6px",
                  transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
                }}
                offset={5}
                className="z-[100]"
              />
            </button>
          </div>
        </div>

        <Select
          classNamePrefix="select"
          styles={hostSelectCustomStyles}
          isSearchable={false}
          name="color"
          placeholder=""
          value={themes?.find((theme) => theme.id === selectedTheme?.id)}
          options={themes}
          getOptionValue={(theme) => theme.id}
          getOptionLabel={(theme) => theme.description}
          onChange={(theme) => handleThemeChange({ target: { value: theme.id } })}
        />
      </div>

      <div className="flex-1 overflow-y-auto">
        <div className="p-2">
          <h2 className="host-title mx-2 mb-2">
            {t(`Admin.AdminPanel.sections`)}
          </h2>
          <div className="mx-2 mb-4">
            <Select
              classNamePrefix="select"
              styles={hostSelectCustomStyles}
              isSearchable={false}
              placeholder={t(`HotelPanel.Theme.selectSection`)}
              value={categories?.find(
                (category) => category.id === expandedCategories[0]
              )}
              options={categories}
              getOptionValue={(category) => category.id}
              getOptionLabel={(category) => category.description}
              onChange={(category) => toggleCategory(category.id)}
            />
          </div>

          {!isEmptySelection ? (
            <>
              <div className="flex items-center justify-between">
                <h2 className="host-title mb-2 ml-2">
                  {t(`Admin.AdminPanel.settings`)}
                </h2>
                <button
                  onClick={collapseAll}
                  className="bg-[var(--text-color)] text-white rounded-full p-1 mr-1"
                >
                  <ChevronsUp
                    data-tooltip-id="collapseAll"
                    data-tooltip-content={t(`Admin.AdminPanel.collapseAll`)}
                    data-tooltip-place="top"
                    className="w-3 h-3 focus:outline-none"
                  />
                  <Tooltip
                    id="collapseAll"
                    style={{
                      padding: "4px 8px",
                      backgroundColor: "var(--host-r24-tooltip-background)",
                      borderRadius: "6px",
                      transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
                    }}
                    offset={5}
                    className="z-[100]"
                  />
                </button>
              </div>
              {filteredCategories.map((category) => (
                <div key={category.id} className="mb-2 text-sm">
                  <AnimatePresence>
                    {expandedCategories.includes(category.id) && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: "auto", opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        className="ml-4"
                      >
                        {category.sections.map((section) => (
                          <div
                            key={section.id}
                            className="mb-2 transition-all duration-300"
                          >
                            <button
                              id={section.id}
                              onClick={() => toggleSection(section.id)}
                              className="w-full flex items-center justify-between py-2 pr-1 hover:bg-gray-50 rounded host-text"
                            >
                              <span>{section.name}</span>
                              <ChevronDown
                                className={`w-4 h-4 transform transition-transform ${
                                  expandedSections.includes(section.id)
                                    ? "rotate-180"
                                    : ""
                                }`}
                              />
                            </button>

                            <AnimatePresence>
                              {expandedSections.includes(section.id) && (
                                <motion.div
                                  initial={{ height: 0, opacity: 0 }}
                                  animate={{ height: "auto", opacity: 1 }}
                                  exit={{ height: 0, opacity: 0 }}
                                  className="ml-2"
                                >
                                  {section.items.map((subCategory) => (
                                    <div
                                      key={subCategory.id}
                                      className="flex flex-col justify-between py-1 hover:bg-gray-50 rounded group"
                                    >
                                      <p className="host-text text-start pb-1">
                                        {subCategory.description}
                                      </p>
                                      <DynamicValueEditor
                                        key={`${selectedTheme?.id}-${subCategory.id}`}
                                        type={subCategory.valueType}
                                        value={subCategory.value}
                                        onChange={(e) => onChangeThemeValues(e, subCategory)}
                                        valueList={subCategory.valueList}
                                        setFile={setFile}
                                        styleSubCategoryCode={subCategory.styleSubCategoryCode}
                                        styleCategoryCode={category.styleCategoryCode}
                                      />
                                    </div>
                                  ))}
                                </motion.div>
                              )}
                            </AnimatePresence>
                          </div>
                        ))}
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              ))}
            </>
          ) : (
            <div className="flex items-center justify-center p-4 text-gray-500">
              {t(`HotelPanel.Theme.selectSection`)}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};