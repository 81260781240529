import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import { Check, CircleAlert, Edit, Plus, Search, X } from "lucide-react";
import { UserModal } from "../Components/Modal/UserModal";
import { Loader } from "../../components/Layout/components/Loader/Loader";

export const Users = () => {
  const [isUserModalOpen, setIsUserModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [state, dispatch] = useContext(AppContext);
  const [users, setUsers] = useState([]);
  const [type, setType] = useState("");
  const [user, setUser] = useState(null);
  const [t] = useTranslation("global");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    if (state.adminInitialData?.users) {
      setUsers(state.adminInitialData.users);
      setIsLoading(false);
    }
  }, [state.adminInitialData]);

  return isLoading ? (
    <div className="flex justify-center items-center h-screen ">
      <Loader className="w-10 h-10" />
    </div>
  ) : (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        {/* <h2 className="host-title !text-xl">{`${t(`Admin.AdminPanel.users`)}`}</h2> */}
        <div className="flex space-x-4 w-full justify-end">
          <div className="relative">
            <input
              type="text"
              placeholder={`${t(`Admin.users.searchUser`)}`}
              className="!pl-8 pr-4 py-2 host-search-input"
            />
            <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1.5" />
          </div>
          {/* <button className="flex items-center host-button-primary" onClick={() => {setType("add");setModalTitle(`${t(`Admin.hotelList.newHotel`)}`); setIsHotelModalOpen(true)}} >
              <Plus className="w-5 h-5" />
            </button> */}
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 host-grid border">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left uppercase tracking-wider">
                {`${t(`Admin.users.id`)}`}
              </th>
              <th className="px-6 py-3 text-left uppercase tracking-wider whitespace-nowrap">
                {`${t(`Admin.users.username`)}`}
              </th>
              <th className="px-6 py-3 text-left uppercase tracking-wider whitespace-nowrap">
                {`${t(`Admin.users.email`)}`}
              </th>
              <th className="px-6 py-3 text-left uppercase tracking-wider">
                {`${t(`Admin.users.password`)}`}
              </th>
              <th className="px-6 py-3 text-left uppercase tracking-wider">
                {`${t(`Admin.users.firstname`)}`}
              </th>
              <th className="px-6 py-3 text-center uppercase tracking-wider">
                {`${t(`Admin.users.lastname`)}`}
              </th>
              <th className="px-6 py-3 text-center uppercase tracking-wider">
                {`${t(`Admin.users.phone`)}`}
              </th>
              <th className="px-6 py-3 text-center uppercase tracking-wider">
                {`${t(`Admin.users.status`)}`}
              </th>
              <th className="px-6 py-3 text-center uppercase tracking-wider">
                {`${t(`Admin.users.role`)}`}
              </th>
              <th className="px-6 py-3 text-center uppercase tracking-wider">
                {`${t(`Admin.users.emailverified`)}`}
              </th>
              {state?.adminLoggedIn?.role === "Admin" && (
                <th className="px-6 py-3 text-center uppercase tracking-wider">
                    {`${t(`Admin.hotelList.actions`)}`}
                </th>
              )}
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {users?.length > 0 ? (
              users.map((user, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="pl-4 py-4 whitespace-nowrap ">{user.id}</td>
                  <td className="pl-4 py-4 whitespace-normal max-w-[180px]">
                    {user.username}
                  </td>
                  <td className="pl-4 py-4 whitespace-normal ">{user.email}</td>
                  <td className="pl-4 py-4 whitespace-nowrap ">
                    {user.password}
                  </td>
                  <td className="pl-4 py-4 whitespace-nowrap ">
                    {user.firstName}
                  </td>
                  <td className="pl-4 py-4 whitespace-nowrap ">
                    {user.lastName}
                  </td>
                  <td className="pl-4 py-4 whitespace-nowrap ">{user.phone}</td>
                  <td className="pl-4 py-4 whitespace-nowrap ">
                     <span className="flex">{user.status} <span className="text-yellow-400 ml-2">{user.status === "Pending" && <CircleAlert className="w-4 h-4" />}</span> </span>
                  </td>
                  <td className="pl-4 py-4 whitespace-nowrap ">{user.role}</td>
                  <td className="pl-4 py-4 whitespace-nowrap ">
                    {user.isEmailVerified ? (
                      <div className="w-8 h-8 rounded-md flex items-center justify-center mx-auto bg-white border-2 border-gray-200">
                        <Check className="w-5 h-5 text-green-500" />
                      </div>
                    ) : (
                      <div className="w-8 h-8 rounded-md flex items-center justify-center mx-auto bg-white border-2 border-gray-200">
                        <X className="w-5 h-5 text-red-500" />
                      </div>
                    )}
                  </td>
                  {state?.adminLoggedIn?.role === "Admin" && (
                    <td className="align-middle h-full">
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() => {
                            setModalTitle(`${t(`Admin.users.editUser`)}`);
                            setIsUserModalOpen(true);
                            setType("edit");
                            setUser(user);
                          }}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          <Edit className="w-4 h-4" />
                        </button>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                >{`${t(`Admin.users.notfound`)}`}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                {state?.adminLoggedIn?.role === "Admin" && (
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500"></td>
                )}
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {type && (
        <UserModal
          isUserModalOpen={isUserModalOpen}
          onCloseUserModal={() => setIsUserModalOpen(false)}
          title={modalTitle}
          type={type}
          user={type === "add" ? null : user}
        />
      )}
    </div>
  );
};
