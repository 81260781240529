import { useContext, useEffect, useRef, useState } from "react";
import { AppContext } from "../../../context/AppContext";

/**
* @namespace NotificationsBanner
* @memberof module:HotelHome
* @description Component responsible for rendering scrolling notifications banner.
* Handles notification timing, filtering based on server time, and infinite scroll animation.
*
* @component
* @param {Object} props - Component props
* @param {Function} props.setNotificationToShow - Sets the notification to display in modal
* @param {Function} props.setIsModalOpen - Controls the visibility of notification modal
*
* @returns {JSX.Element|null} NotificationsBanner component or null if no notifications
*
* @example
* <NotificationsBanner
*   setNotificationToShow={setNotification}
*   setIsModalOpen={setModalOpen}
* />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const NotificationsBanner = ({setNotificationToShow, setIsModalOpen}) => {
  const [state] = useContext(AppContext);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [serverTime, setServerTime] = useState(null);
  const scrollerRef = useRef(null);
  const containerRef = useRef(null);

 /**
  * @function updateServerTime
  * @memberof module:HotelHome.NotificationsBanner
  * @description Updates server time by incrementing one minute.
  *
  * @param {string} initialTime - Initial server time in ISO format
  *
  * @example
  * updateServerTime(currentServerTime)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 const updateServerTime = (initialTime) => {
    const currentServerTime = new Date(initialTime);
    currentServerTime.setMinutes(currentServerTime.getMinutes() + 1);
    setServerTime(currentServerTime.toISOString());
  };

   /**
  * @function useEffectInitialData
  * @memberof module:HotelHome.NotificationsBanner
  * @description Effect that initializes notification data and server time.
  *
  * @effect Updates notification data and server time
  * @dependencies [state.hotelInitialData]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (state.hotelInitialData) {
      setData(state.hotelInitialData.GuestNotifications);
      setServerTime(state.hotelInitialData.Hotel.ServerTime);
    }
  }, [state.hotelInitialData]);

   /**
  * @function useEffectFilterNotifications
  * @memberof module:HotelHome.NotificationsBanner
  * @description Effect that filters active notifications based on time.
  * Handles special cases like notifications that span midnight.
  *
  * @effect Updates filtered notifications
  * @dependencies [data, serverTime]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (!data || !serverTime) return;

    // Converte o serverTime para apenas hora e minuto para comparação
    const currentTime = new Date(serverTime);
    const currentHourMinute = new Date();
    currentHourMinute.setHours(currentTime.getHours());
    currentHourMinute.setMinutes(currentTime.getMinutes());
    currentHourMinute.setSeconds(0);
    currentHourMinute.setMilliseconds(0);

    // Filtra as notificações ativas
    const activeNotifications = data.filter((notification) => {
      // Converte FromTime e ToTime para objetos Date
      const fromTime = new Date(notification.FromTime);
      const toTime = new Date(notification.ToTime);

      // Cria objetos Date apenas com hora e minuto para comparação
      const from = new Date();
      from.setHours(fromTime.getHours());
      from.setMinutes(fromTime.getMinutes());
      from.setSeconds(0);
      from.setMilliseconds(0);

      const to = new Date();
      to.setHours(toTime.getHours());
      to.setMinutes(toTime.getMinutes());
      to.setSeconds(0);
      to.setMilliseconds(0);

      // Verifica se o horário atual está entre FromTime e ToTime
      if (to >= from) {
        // Caso normal: FromTime <= currentTime <= ToTime
        return currentHourMinute >= from && currentHourMinute <= to;
      } else {
        // Caso especial: período que passa pela meia-noite
        // Exemplo: FromTime: 22:00, ToTime: 02:00
        return currentHourMinute >= from || currentHourMinute <= to;
      }
    });
    setFilteredData(activeNotifications);
  }, [data, serverTime]);

  /**
  * @function useEffectScrollerClones
  * @memberof module:HotelHome.NotificationsBanner
  * @description Effect that manages notification scroller clones.
  * Creates and maintains clones for infinite scroll effect.
  *
  * @effect Updates scroller clones
  * @dependencies [filteredData]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    const scrollerElement = scrollerRef.current;
    const containerElement = containerRef.current;

    if (!scrollerElement || !containerElement || filteredData.length === 0) return;

    // Limita o número máximo de clones
    const maxClones = 3;

    // Remove clones anteriores se existirem
    const existingClones = scrollerElement.querySelectorAll(".clone");
    existingClones.forEach((clone) => clone.remove());

    // Adiciona os clones
    for (let i = 0; i < maxClones; i++) {
      const clone = scrollerElement.cloneNode(true);
      clone.classList.add("clone");

      // Readiciona os event listeners nos clones
      const spans = clone.querySelectorAll('span');
      spans.forEach((span, index) => {
        span.onclick = () => {
          setIsModalOpen(true);
          setNotificationToShow(filteredData[index]);
        };
      });

      containerElement.appendChild(clone);
    }
}, [filteredData]);

 /**
  * @function useEffectTimeUpdate
  * @memberof module:HotelHome.NotificationsBanner
  * @description Effect that updates server time every minute.
  *
  * @effect Updates server time periodically
  * @dependencies [serverTime]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 useEffect(() => {
    if (!serverTime) return;
    const interval = setInterval(() => {
      updateServerTime(serverTime);
    }, 60000); // Update every minute
    return () => clearInterval(interval);
  }, [serverTime]);

  if (data.length === 0) return null;

  return (
    filteredData.length > 0 && (
      <div className="bg-indigo-600 text-white overflow-hidden relative">
        <div ref={containerRef} className="flex animate-scroll whitespace-nowrap py-2" >
          <div ref={scrollerRef} className="flex min-w-max">
          {filteredData.map((notification) => (
                <span key={notification.Id}  onClick={() => {setIsModalOpen(true); setNotificationToShow(notification)}} dangerouslySetInnerHTML={{ __html: notification.ShortDescription, }} className="mx-8 text-sm font-medium" >
                </span>
          ))}
          </div>
        </div>
      </div>
    )
  );
};
