import { AlertCircle, AlertTriangle, CheckCircle2, ChevronLeft } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect } from "react";

/**
* @namespace SubMenu
* @memberof module:HotelHome
* @description Component responsible for displaying submenu items with animations.
* Provides back navigation and renders service items in grid layout.
*
* @component
* @param {Object} props - Component props
* @param {Object} props.menu - Menu data containing submenu items
* @param {Function} props.onBack - Callback function for back navigation
* @param {string} props.posSection - Section position identifier
* @param {Function} props.setActiveSection - Function to update active section
* @param {Function} props.setIsExiting - Function to handle exit animations
*
* @returns {JSX.Element} SubMenu component
*
* @example
* <SubMenu
*   menu={menuData}
*   onBack={handleBack}
*   posSection="SUBMENU"
*   setActiveSection={setSection}
*   setIsExiting={handleExit}
* />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const SubMenu = ({ menu, onBack, posSection, setActiveSection, setIsExiting }) => {
  const MotionLink = motion.create(Link);

  /**
  * @function getCompletionIndicator
  * @memberof module:HotelHome.SubMenu
  * @description Returns appropriate icon based on completion rate.
  * Provides visual feedback for completion status.
  *
  * @param {number} rate - Completion rate percentage
  * @returns {JSX.Element} Indicator icon component
  *
  * @example
  * getCompletionIndicator(75)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
   const getCompletionIndicator = (rate) => {
     if (rate <= 40) {
       return ( <AlertCircle className="w-5 h-5 text-red-500" aria-label="Low completion rate" /> );
     } else if (rate <= 70) {
       return (
         <AlertTriangle className="w-5 h-5 text-yellow-500" aria-label="Medium completion rate" /> );
     } else {
       return (
         <CheckCircle2 className="w-5 h-5 text-green-500" aria-label="High completion rate" />
       );
     }
   };

   /**
  * @function useEffectSetActive
  * @memberof module:HotelHome.SubMenu
  * @description Effect that sets the active section on component mount.
  *
  * @effect Updates active section
  * @dependencies []
  *
  * @example
  * useEffect(() => {
  *   setActiveSection(posSection)
  * }, [])
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
   useEffect(()=>{
       setActiveSection(posSection)
   },[])

 /**
  * @constant {Array} typesToStrikethrough
  * @memberof module:HotelHome.SubMenu
  * @description Array of service types that should be displayed with strikethrough styling.
  * Used to visually indicate unavailable or deprecated services.
  *
  * @type {Array<string>}
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 const typesToStrikethrough = [
    ];

   return (
     <motion.div initial={{ opacity: 0, x: 100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: -100 }} transition={{ duration: 0.3 }} className="w-full" >
       {/* Header */}
       <div className="flex items-center gap-4 mb-6 ">
         <motion.button onClick={() => { onBack(); setIsExiting(true); setActiveSection(null) }} whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} className="p-2 rounded-lg bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border shadow" >
           <ChevronLeft />
         </motion.button>
         <h2 className="text-xl font-semibold dark:text-white">{menu.Description}</h2>
       </div>

       {/* Grid de subitems */}
       <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
         {menu.subItems.map((subItem, index) => (
            subItem.ImagesUrl.length === 0 ? (
           <motion.div key={subItem.Id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: index * 0.1 }} exit={{ opacity: 0, y: 20 }}>
             <MotionLink to={subItem.LinkTo} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} className="flex flex-col bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border min-h-24 rounded-lg shadow p-4 w-full h-full relative transition duration-300 ease-in-out transform hover:shadow-lg" >
               <div className="flex justify-between items-center h-full">
                 <div className="flex flex-col items-center flex-grow justify-center text-center">
                   <span dangerouslySetInnerHTML={{ __html: subItem.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp_violet dark:text-gxp_violet_dark mb-2" />
                   <span className={`${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
                 </div>
                 {subItem.rate && (
                   <div className="flex items-center gap-2 absolute right-7 top-5">
                     {getCompletionIndicator(subItem.rate)}
                     <span className={`text-sm text-gray-600 ${ subItem.rate <= 40 ? "text-red-500" : subItem.rate <= 70 ? "text-yellow-500" : "text-green-500" }`} >
                       {subItem.rate}%
                     </span>
                   </div>
                 )}
               </div>
             </MotionLink>
           </motion.div>)
           : (
            <motion.div key={subItem.Id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3, delay: index * 0.1 }} exit={{ opacity: 0, y: 20 }}>
             <MotionLink to={subItem.LinkTo} className="flex flex-col bg-white dark:bg-gxp_dark_3 text-white dark:border-gxp_dark_1 border min-h-40 rounded-lg shadow p-4 w-full h-full relative transition duration-300 ease-in-out transform hover:shadow-lg" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} style={{ backgroundImage: `url(${subItem.ImagesUrl[0]})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
               <div className="flex justify-between items-center h-full absolute inset-0 bg-gradient-to-t from-black/80 to-transparent rounded-md">
                 <div className="flex items-end h-full w-full justify-center pb-2" >
                   <span className={`${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
                 </div>
                 {subItem.rate && (
                   <div className="flex items-center gap-2 absolute right-7 top-5">
                     {getCompletionIndicator(subItem.rate)}
                     <span className={`text-sm text-gray-600 ${ subItem.rate <= 40 ? "text-red-500" : subItem.rate <= 70 ? "text-yellow-500" : "text-green-500" }`} >
                       {subItem.rate}%
                     </span>
                   </div>
                 )}
               </div>
             </MotionLink>
           </motion.div>
           )

         ))}
       </div>
     </motion.div>
   );
 };