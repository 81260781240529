import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { toast } from "react-toastify";
import React from "react";
import { useContext } from "react";
import { AppContext } from "../../context/AppContext";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "../../hooks/useWindowSize";
import { QRCode } from "antd";
import { getKeypassKey, getReservationByDetailId } from "../../services";
import { HeadersPage } from "../../components/Layout/HeadersPage";
import { Loader } from "../../components/Layout/components/Loader/Loader";

/**
* @module QrCode
* @description Core module for generating and displaying QR codes for hotel reservations.
* Manages QR code generation, dark mode support, and data fetching.
*/

/**
* @namespace QrCode
* @memberof module:QrCode
* @description Component responsible for displaying reservation QR codes.
* Handles keypass key fetching and QR code rendering with loading states.
*
* @component
* @returns {JSX.Element} QrCode component
*
* @example
* <QrCode />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const QrCode = () => {
  const [keypassKeyString, setKeypassKeyString] = useState("");
  const { detailId } = useParams();
  const [cookie] = useCookies(["sUid", "currentHotel"]);
  const [state, dispatch] = useContext(AppContext); // eslint-disable-line
  const [t] = useTranslation("global");
  const [reservation, setReservation] = useState(state.reservation);
  const { isMobile } = useWindowSize();
  const [isLoading, setIsLoading] = useState(true);
  const [darkMode, setDarkMode] = useState( JSON.parse(localStorage.getItem(`darkMode`)) || false );

 /**
  * @function getKeypassKeyString
  * @memberof module:QrCode.QrCode
  * @description Fetches keypass key for QR code generation.
  * Manages loading state and error handling.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await getKeypassKeyString()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const getKeypassKeyString = async () => {
    try {
      setIsLoading(true);
      const result = await getKeypassKey(detailId, state.currentHotel, cookie.sUid);
      setKeypassKeyString(result);
    } catch (error) {
      console.log(error);
      toast.error("Unable to get KeyPass Key!");
    } finally {
      setIsLoading(false);
    }
  };

 /**
  * @function fetchReservationDetails
  * @memberof module:QrCode.QrCode
  * @description Fetches and updates reservation details.
  * Updates both local and global state with fetched data.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchReservationDetails()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchReservationDetails = async () => {
    try {
      const data = await getReservationByDetailId(detailId, state.currentHotel, cookie.sUid);
      setReservation(data[0]);
      dispatch({ type: "SET_USER_RESERVATION", payload:data[0] });
    } catch (error) {
      console.error("App: Error fetching checkout info:", error);
      toast.error("App: Unable to get checkout info!");
    }
  };

 /**
  * @function useEffectFetchKeypass
  * @memberof module:QrCode.QrCode
  * @description Effect that triggers keypass key fetching on mount.
  *
  * @effect Fetches keypass key data
  * @dependencies []
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 useEffect(() => {
    if (detailId && cookie.sUid && state.currentHotel) {
      getKeypassKeyString();
    }
  }, []); // eslint-disable-line

/**
  * @function useEffectFetchReservation
  * @memberof module:QrCode.QrCode
  * @description Effect that triggers reservation fetching when needed.
  *
  * @effect Fetches reservation data if not present
  * @dependencies [detailId]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 useEffect(() => {
    if(!reservation){
      fetchReservationDetails();
    }
  }, [detailId]); // eslint-disable-line

  return (
    <>
      {reservation && (
        <div className={`antialiased h-full w-full`}>
          <section className="h-full w-full">
            <HeadersPage title={`${t(`QrCode.qrcode`)}`} room={reservation?.room} />
            <div className="flex flex-col justify-center items-center mt-5">
              {isLoading ? (
                <Loader />
              ) : (
                <QRCode key={darkMode} value={keypassKeyString || ""} status={isLoading ? "loading" : "active"} size={256} errorLevel="H" color={"#000000"} bgColor="#FFFFFF" bordered />
              )}
            </div>
          </section>
        </div>
      )}
    </>
  );
};