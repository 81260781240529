import { Check, Edit, Plus, Search, Trash2, X } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../context/AppContext";
import { GroupHotelModal } from "../Components/Modal/GroupHotelModal";
import { useTranslation } from "react-i18next";

// HotelList Component
export const GroupHotelList = () => {
  const [isGroupModalOpen, setIsGroupModalOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [groupHotels, setGroupHotels] = useState([]);
  const [type, setType] = useState("");
  const [groupHotel, setGroupHotel] = useState(null);
  const [modalTitle, setModalTitle] = useState("");
  const [t] = useTranslation("global");

  useEffect(() => {
    // Verifica se os dados existem antes de atualizar o estado
    if (state.adminInitialData?.groupHotels) {
      setGroupHotels(state.adminInitialData.groupHotels);
    }
  }, [state.adminInitialData]);

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-4">
        <h2 className="host-title !text-xl">{`${t( `Admin.AdminPanel.hotelsGroup` )}`}</h2>
        <div className="flex space-x-4">
          <div className="relative">
            <input
              type="text"
              placeholder={`${t(`Admin.groupHotelList.searchGroup`)}`}
              className="!pl-8 pr-4 py-2 host-search-input"
            />
            <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1.5" />
          </div>
          <button
            className="flex items-center host-button-primary"
            onClick={() => {
              setType("add");
              setModalTitle(`${t(`Admin.groupHotelList.newHotelGroup`)}`);
              setIsGroupModalOpen(true);
            }}
          >
            <Plus className="w-5 h-5" />
          </button>
        </div>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200 host-grid border">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-6 py-3 text-left uppercase tracking-wider">
                {`${t(`Admin.groupHotelList.groupId`)}`}
              </th>
              <th className="px-6 py-3 text-left uppercase tracking-wider">
                {`${t(`Admin.groupHotelList.description`)}`}
              </th>
              <th className="px-6 py-3 text-center uppercase tracking-wider">
                {`${t(`Admin.groupHotelList.active`)}`}
              </th>
              <th className="px-6 py-3 text-center uppercase tracking-wider">
                {`${t(`Admin.groupHotelList.actions`)}`}
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {groupHotels.length > 0 ? (
              groupHotels.map((groupHotel, index) => (
                <tr key={index} className="hover:bg-gray-50">
                  <td className="px-6 py-4 whitespace-nowrap">
                    {groupHotel.groupId}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {groupHotel.description}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    {
                      <div className="w-8 h-8 rounded-md flex items-center mx-auto justify-center bg-white border-2 border-gray-200">
                        {groupHotel.isActive ? (
                          <Check className="w-5 h-5 text-green-500" />
                        ) : (
                          <X className="w-5 h-5 text-red-500" />
                        )}
                      </div>
                    }
                  </td>
                  <td className="align-middle h-full">
                    <div className="flex items-center justify-center">
                      <button
                        type="button"
                        onClick={() => {
                          setModalTitle( `${t(`Admin.groupHotelList.editHotelGroup`)}` );
                          setIsGroupModalOpen(true);
                          setType("edit");
                          setGroupHotel(groupHotel);
                        }}
                        className="text-blue-600 hover:text-blue-900"
                      >
                        <Edit className="w-4 h-4" />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan={4}
                  className="px-6 py-4 whitespace-nowrap text-sm text-gray-900"
                >{`${t(`Admin.groupHotelList.notfound`)}`}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      {type && (
        <GroupHotelModal
          isGroupModalOpen={isGroupModalOpen}
          onCloseGroupModal={() => setIsGroupModalOpen(false)}
          title={modalTitle}
          type={type}
          groupHotel={type === "add" ? null : groupHotel}
        />
      )}
    </div>
  );
};
