
export async function getHotelPanelInitialData(hotelId){
    const requestOptions = {
        method:"GET",
        headers:{"Content-Type":"application/json"}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/hotelpanel/hotelpanelInitialData/${hotelId}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function saveParameters(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/parameter/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}