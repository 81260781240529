import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TiTick } from "react-icons/ti";

const Stepper = ({ step, totalSteps }) => {
  const [t] = useTranslation("global");
  const steps = [`${t(`Stepper.guestsdata`)}`, `${t(`Stepper.hotelconsent`)}`, `${t(`Stepper.terms`)}`];
  const currentStep = step;
  const [complete, setComplete] = useState(false);

  return (
    <div className="w-full px-8 md:px-32 pt-6">
      {/* Container principal com margens laterais aumentadas */}
      <div className="relative flex justify-center">
        {/* Linha de progresso */}
        <div className="absolute top-[15px] left-0 h-[2px] w-full border OC-Stepper-border_color dark:border-white" />

        {/* Container dos steps */}
        <div className="relative flex w-full max-w-3xl justify-between">
          {steps?.map((stepText, i) => (
            <div key={i} className="flex flex-col items-center">
              {/* Círculo e número */}
              <div className={`relative z-10 flex h-[30px] w-[30px] items-center justify-center rounded-full border-2 font-medium transition-all duration-200 ${currentStep === i + 1 ? "dark:border-gxp_violet dark:text-gxp_violet OC-Stepper-active_color bg-white" : (i + 1 < currentStep || complete) ? "border-emerald-500 bg-emerald-500" : "dark:text-gxp_dark_1 dark:border-gxp_dark_3 OC-Stepper-border_color OC-Stepper-text_color OC-Stepper-text_font_family OC-Stepper-text_variant bg-white" }`} >
                {i + 1 < currentStep || complete ? ( <TiTick className="text-white" size={20} /> ) : ( i + 1 )}
              </div>

              {/* Texto do step */}
              <span className="OC-Stepper-label_color OC-Stepper-label_font_family OC-Stepper-label_variant OC-Stepper-label_size mt-2 text-center dark:text-white max-w-[120px]">
                {stepText}
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Stepper;