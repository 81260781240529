export const OCFormDataCategories = [
  {
    order: 1,
    id: "OC-General",
  },
  {
    order: 2,
    id: "OC-Header",
  },
  {
    order: 3,
    id: "OC-Stepper",
  },
  {
    order: 4,
    id: "OC-Form",
  },
  {
    order: 5,
    id: "OC-FormField",
  },
  {
    order: 6,
    id: "OC-Button1",
  },
];

export const OCConsentDataCategories = [
  {
    order: 1,
    id: "OC-General",
  },
  {
    order: 2,
    id: "OC-Header",
  },
  {
    order: 3,
    id: "OC-Stepper",
  },
  {
    order: 4,
    id: "OC-Consent",
  },
  {
    order: 5,
    id: "OC-Button1",
  },
  {
    order: 6,
    id: "OC-Button2",
  },
  {
    order: 7,
    id: "OC-FormField",
  },
];

export const OCgeneralTermDataCategories = [
  {
    order: 1,
    id: "OC-General",
  },
  {
    order: 2,
    id: "OC-Header",
  },
  {
    order: 3,
    id: "OC-Stepper",
  },
  {
    order: 4,
    id: "OC-GeneralTerm",
  },
  {
    order: 5,
    id: "OC-Button2",
  },
  {
    order: 6,
    id: "OC-FormField",
  },
  {
    order: 7,
    id: "OC-Button1",
  },
];

export const OCSuccessDataCategories = [
  {
    order: 1,
    id: "OC-General",
  },
  {
    order: 2,
    id: "OC-Header",
  },
];

export const TRGuestDataCategories = [
  {
    order: 1,
    id: "TR-General",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Stepper",
  },
  {
    order: 5,
    id: "TR-Card",
  },
];

export const TRDateDataCategories = [
  {
    order: 1,
    id: "TR-General",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Stepper",
  },
  {
    order: 5,
    id: "TR-Card",
  },
  {
    order: 6,
    id: "TR-Button2",
  },
];

export const TRTimeDataCategories = [
  {
    order: 1,
    id: "TR-General",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Stepper",
  },
  {
    order: 5,
    id: "TR-Card",
  },
  {
    order: 6,
    id: "TR-Button2",
  },
];

export const TRFormDataCategories = [
  {
    order: 1,
    id: "TR-General",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Stepper",
  },
  {
    order: 5,
    id: "TR-FormField",
  },
  {
    order: 6,
    id: "TR-Button1",
  },
  {
    order: 7,
    id: "TR-Button2",
  },

];

export const TRSuccessDataCategories = [
  {
    order: 1,
    id: "TR-General",
  },
  {
    order: 2,
    id: "TR-Header",
  },
  {
    order: 3,
    id: "TR-Form",
  },
  {
    order: 4,
    id: "TR-Button1",
  },
];

export const MHGeneralDataCategories = [
  {
    order: 1,
    id: "MH-General",
  },
];

export const MFGeneralDataCategories = [
  {
    order: 1,
    id: "MF-General",
  },
];

export const HMGeneralDataCategories = [
  {
    order: 1,
    id: "HM-General",
  },
  {
    order: 2,
    id: "HM-Card",
  },
];

// iFrameCategoriesMap.js
export const iFrameCategoriesMap = {
  OC: {
    default: OCFormDataCategories,
    form: OCFormDataCategories,
    consent: OCConsentDataCategories,
    generalTerm: OCgeneralTermDataCategories,
    success: OCSuccessDataCategories,
  },
  TR: {
    default: TRGuestDataCategories,
    guest: TRGuestDataCategories,
    date: TRDateDataCategories,
    time: TRTimeDataCategories,
    form: TRFormDataCategories,
    success: TRSuccessDataCategories,
  },
  MH: {
    default: MHGeneralDataCategories,
  },
  MF: {
    default: MFGeneralDataCategories,
  },
  HM: {
    default: HMGeneralDataCategories,
  },
};
