import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { motion, AnimatePresence } from "framer-motion";
import { AlertCircle, AlertTriangle, CheckCircle2, ChevronDown, ChevronRight, } from "lucide-react";
import { SubMenu } from "./SubMenu";
import { useMenuDataProcessor } from "../../../hooks/useMenuDataProcessor";

/**
* @namespace MainSection
* @memberof module:HotelHome
* @description Main component responsible for displaying hotel services navigation.
* Manages menu state, animations, and interactive elements for service navigation.
*
* @component
* @param {Object} props - Component props
* @param {string} props.detailId - Reservation detail identifier
* @param {Function} props.onMenuStateChange - Callback for menu state changes
* @param {string} props.posSection - Section position identifier
* @param {Function} props.setActiveSection - Function to update active section
* @param {Function} props.setIsExiting - Function to handle exit animations
*
* @returns {JSX.Element} MainSection component
*
* @example
* <MainSection
*   detailId="123"
*   onMenuStateChange={handleMenuChange}
*   posSection="MAIN"
*   setActiveSection={setActiveSection}
*   setIsExiting={handleExit}
* />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const MainSection = ({ detailId, onMenuStateChange, posSection, setActiveSection, setIsExiting}) => {
  const [state] = useContext(AppContext);
  const [t] = useTranslation("global");
  const [cookie, setCookie] = useCookies([ "sUid", "profileKey", "GUid", "platformUid", "detailId", ]);
  const [menuData, setMenuData] = useState([]);
  const [openSections, setOpenSections] = useState({});
  const [activeMenu, setActiveMenu] = useState(null);
  const MotionLink = motion.create(Link);

   /**
  * @function toggleSection
  * @memberof module:HotelHome.MainSection
  * @description Toggles the expansion state of a menu section.
  *
  * @param {string} serviceCode - Service identifier code
  *
  * @example
  * toggleSection("SERVICE_01")
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const toggleSection = (serviceCode) => {
    setOpenSections((prev) => ({ ...prev, [serviceCode]: !prev[serviceCode] }));
  };

  /**
  * @function useEffectMenuState
  * @memberof module:HotelHome.MainSection
  * @description Effect that notifies parent component of menu state changes.
  *
  * @effect Updates parent component with menu state
  * @dependencies [activeMenu, onMenuStateChange]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    // Notifica o pai sempre que o estado do menu muda
    onMenuStateChange?.(!!activeMenu);
  }, [activeMenu, onMenuStateChange]);


 /**
  * @function getCompletionIndicator
  * @memberof module:HotelHome.MainSection
  * @description Determines and returns the appropriate completion indicator icon.
  *
  * @param {number} rate - Completion rate percentage
  * @returns {JSX.Element} Icon component based on completion rate
  *
  * @example
  * getCompletionIndicator(75)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const getCompletionIndicator = (rate) => {
    if (rate <= 40) {
      return (
        <AlertCircle className="w-5 h-5 text-red-500" aria-label="Low completion rate" />
      );
    } else if (rate <= 70) {
      return (
        <AlertTriangle className="w-5 h-5 text-yellow-500" aria-label="Medium completion rate" />
      );
    } else {
      return (
        <CheckCircle2 className="w-5 h-5 text-green-500 dark:text-green-300" aria-label="High completion rate" />
      );
    }
  };

  const processMenuData = useMenuDataProcessor( posSection, setMenuData );

   /**
  * @function useEffectProcessMenu
  * @memberof module:HotelHome.MainSection
  * @description Effect that processes menu data when hotel menu or reservation changes.
  *
  * @effect Updates processed menu data
  * @dependencies [state.hotelMenu, state.reservation]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (state.hotelMenu && state.reservation) {
      processMenuData();
    }
  }, [state.hotelMenu, state.reservation]);

 /**
  * @constant {Array} typesToStrikethrough
  * @memberof module:HotelHome.MainSection
  * @description Array of service types that should be displayed with strikethrough styling.
  * Used to visually indicate unavailable or deprecated services.
  *
  * @type {Array<string>}
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 const typesToStrikethrough = [
    ];

 /**
  * @function renderMenuItem
  * @memberof module:HotelHome.MainSection
  * @description Renders a menu item based on its type and properties.
  *
  * @param {Object} service - Service item data
  * @param {number} index - Item index
  * @returns {JSX.Element} Rendered menu item
  *
  * @example
  * renderMenuItem(serviceData, 0)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const renderMenuItem = (service, index) => {
    if (!service.subItems || service.subItems.length === 0) {
      const isStrikethrough = typesToStrikethrough.includes(service.Type);
      return service.ImagesUrl.length === 0 ? (
        <MotionLink to={service.LinkTo} key={service.id || index} className="bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border min-h-24 rounded-lg shadow p-4 w-full relative transition duration-300 ease-in-out transform hover:shadow-lg" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
          <div className="flex justify-center items-center h-full">
            <div className="flex items-center flex-grow justify-start pl-6">
              <span dangerouslySetInnerHTML={{ __html: service.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp_violet dark:text-gxp_violet_dark pr-4" />
              <span className={`${typesToStrikethrough.includes(service.Type) ? "line-through" : ""}`}>{service.Description}</span>
            </div>
            {typeof service.rate === 'number' && (
              <div className="flex items-center gap-2 absolute right-7 top-5">
                {getCompletionIndicator(service.rate)}
                <span className={`text-sm text-gray-600 ${service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300"}`}>
                  {service.rate}%
                </span>
              </div>
            )}
          </div>
        </MotionLink>
      ) : (
        <MotionLink key={service.Id} to={service.LinkTo} className="block" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
          <div className="relative h-48 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow">
            <img src={service.ImagesUrl[0]} alt={service.Description} className="w-full h-full object-cover" />
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent" />
            <div className="absolute bottom-0 left-0 right-0 p-4">
              <h2 className="text-white text-xl font-semibold">
                {service.Description}
              </h2>
            </div>
            {typeof service.rate === 'number' && (
              <div className="flex items-center gap-2 absolute right-7 top-5 bg-white dark:bg-gxp_dark_3 p-2 rounded-lg">
                {getCompletionIndicator(service.rate)}
                <span className={`text-sm text-gray-600 ${service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300"}`}>
                  {service.rate}%
                </span>
              </div>
            )}
          </div>
        </MotionLink>
      );
    }

    // Menu de navegação
    if (service.LinkType === "MENU") {
      return service.ImagesUrl.length === 0 ? (
        <motion.button key={service.Id || index} onClick={() => setActiveMenu(service)} className="w-full min-h-24 rounded-lg shadow p-4 relative transition-all duration-300 ease-in-out hover:shadow-lg bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
          <div className="flex justify-between items-center">
            <div className="flex items-center flex-grow justify-start pl-6">
              <span dangerouslySetInnerHTML={{ __html: service.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp_violet dark:text-violet-400 pr-4" />
              <span className={`${typesToStrikethrough.includes(service.Type) ? "line-through" : ""}`} >{service.Description}</span>
            </div>
            {service.rate && (
              <div className="flex items-center gap-2 absolute right-4 top-5">
                {getCompletionIndicator(service.rate)}
                <span className={`text-sm text-gray-600 ${ service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} >
                  {service.rate}%
                </span>
              </div>
            )}
            <span>
              <ChevronRight />
            </span>
          </div>
        </motion.button>
      ):(
        <motion.button
        key={service.Id} onClick={() => setActiveMenu(service)} className="block w-full" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
        <div className="flex relative h-48 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow" style={{ backgroundImage: `url(${service.ImagesUrl[0]})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
          {/* Dark gradient overlay */}
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent">
            {/* Description text */}
            <h2 className="text-white text-xl font-semibold text-left absolute bottom-0 left-0 p-4">
              {service.Description}
            </h2>
          </div>
          {/* Completion indicator */}
          {typeof service.rate === 'number' && (
            <div className="flex items-center gap-2 absolute right-7 top-5 bg-white dark:bg-gxp_dark_3 p-2 rounded-lg">
              {getCompletionIndicator(service.rate)}
              <span className={`text-sm ${ service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} > {service.rate}% </span>
            </div>
          )}

          {/* Chevron */}
          <div className="absolute right-4 top-1/2 -translate-y-1/2">
            <ChevronRight className="text-white h-6 w-6" />
          </div>
        </div>
      </motion.button>
      );
    }

    // Menu dropdown (MENU-DD)
    if (service.LinkType === "MENU-DD") {
    return (
      <div>
        { service.ImagesUrl.length === 0 ?
        <motion.button key={service.Id || index} onClick={() => toggleSection(service.Code)} className={`w-full rounded-lg min-h-24 shadow p-4 relative transition-all duration-300 ease-in-out hover:shadow-lg bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border ${ openSections[service.Code] ? "bg-gray-100" : "bg-white" }`} whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
          <div className="flex justify-between items-center">
            <div className="flex items-center flex-grow justify-start pl-6">
            <span dangerouslySetInnerHTML={{ __html: service.IconTag.replace(/<i /, '<i style="font-size: 25px" ') }} className="text-gxp_violet dark:text-violet-400 pr-4" />
            <span className={`${typesToStrikethrough.includes(service.Type) ? "line-through" : ""}`} >{service.Description}</span>
            </div>
            <motion.span animate={{ rotate: openSections[service.Code] ? 180 : 0 }} transition={{ duration: 0.3 }} >
              <ChevronDown />
            </motion.span>
          </div>
          {service.rate && (
            <div className="flex items-center gap-2 absolute right-4 top-5">
              {getCompletionIndicator(service.rate)}
              <span className={`text-sm text-gray-600 ${ service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} >
                {service.rate}%
              </span>
            </div>
          )}
        </motion.button>
        :
        <motion.button key={service.Id} onClick={() => toggleSection(service.Code)} className="block w-full" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
          <div className="flex relative h-48 rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow" style={{ backgroundImage: `url(${service.ImagesUrl[0]})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
            {/* Dark gradient overlay */}
            <div className="absolute inset-0 bg-gradient-to-t from-black/80 to-transparent">
              {/* Description text */}
              <h2 className="text-white text-xl font-semibold text-left absolute bottom-0 left-0 p-4">
                {service.Description}
              </h2>
            </div>
            {/* Completion indicator */}
            {typeof service.rate === 'number' && (
              <div className="flex items-center gap-2 absolute right-7 top-5 bg-white dark:bg-gxp_dark_3 p-2 rounded-lg">
                {getCompletionIndicator(service.rate)}
                <span className={`text-sm ${ service.rate <= 40 ? "text-red-500" : service.rate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} > {service.rate}% </span>
              </div>
            )}

            {/* Chevron */}
            <motion.div className="absolute right-4 top-1/2 -translate-y-1/2" animate={{ rotate: openSections[service.Code] ? 180 : 0 }} transition={{ duration: 0.3 }}>
              <ChevronDown className="text-white h-6 w-6" />
            </motion.div>
          </div>
        </motion.button>
        }

        <AnimatePresence>
          {openSections[service.Code] && (
            <motion.div initial={{ opacity: 0, height: 0 }} animate={{ opacity: 1, height: "auto", transition: { height: { duration: 0.4, ease: "easeOut" }, opacity: { duration: 0.3, delay: 0.1 }, }, }} exit={{ opacity: 0, height: 0, transition: { height: { duration: 0.3, ease: "easeIn" }, opacity: { duration: 0.2 }, }, }} className=" overflow-hidden" >
              <motion.div initial={{ y: -20 }} animate={{ y: 0 }} exit={{ y: -20 }} transition={{ duration: 0.3 }} className="p-2 rounded-lg shadow-lg w-full" >
                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-5 gap-2">
                  {service.subItems.map((subItem, index) => (
                    subItem.ImagesUrl.length === 0 ? (
                      <motion.div key={subItem.Id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.1, duration: 0.3 }}  >
                        <MotionLink to={subItem.LinkTo} className="rounded-lg min-h-24 shadow p-4 w-full h-full relative transition duration-300 ease-in-out transform hover:shadow-lg block bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} >
                          <div className="flex justify-between items-center h-full">
                            <div className="flex flex-col items-center flex-grow justify-center text-center" >
                              <span dangerouslySetInnerHTML={{ __html: subItem.IconTag, }} className="w-7 text-gxp_violet dark:text-violet-400" />
                              <span className={`${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
                            </div>
                            {subItem.rate && (
                              <div className="flex items-center gap-2 absolute right-4 top-5">
                                {getCompletionIndicator(
                                  state.reservation.OnlineCheckinCompletionRate
                                )}
                                <span className={`text-sm text-gray-600 ${ state.reservation .OnlineCheckinCompletionRate <= 40 ? "text-red-500" : state.reservation .OnlineCheckinCompletionRate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} >
                                  {state.reservation.OnlineCheckinCompletionRate}%
                                </span>
                              </div>
                            )}
                          </div>
                        </MotionLink>
                      </motion.div>)
                    :
                    (
                      <motion.div key={subItem.Id} initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: index * 0.1, duration: 0.3 }}  >
                      <MotionLink to={subItem.LinkTo} className="rounded-lg min-h-24 shadow p-4 w-full h-full relative transition duration-300 ease-in-out transform hover:shadow-lg block bg-white dark:bg-gxp_dark_3 text-white dark:border-gxp_dark_1 border" whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }} style={{ backgroundImage: `url(${subItem.ImagesUrl[0]})`, backgroundSize: 'cover', backgroundPosition: 'center' }} >
                        <div className="flex justify-between items-center h-full absolute inset-0 bg-gradient-to-t from-black/80 to-transparent rounded-md">
                          <div className="flex items-end h-full w-full justify-center pb-2" >
                            <span className={`${typesToStrikethrough.includes(subItem.Type) ? "line-through" : ""}`}>{subItem.Description}</span>
                          </div>
                          {subItem.rate && (
                            <div className="flex items-center gap-2 absolute right-4 top-5">
                              {getCompletionIndicator( state.reservation.OnlineCheckinCompletionRate )}
                              <span className={`text-sm text-gray-600 ${ state.reservation .OnlineCheckinCompletionRate <= 40 ? "text-red-500" : state.reservation .OnlineCheckinCompletionRate <= 70 ? "text-yellow-500" : "text-green-500 dark:text-green-300" }`} >
                                {state.reservation.OnlineCheckinCompletionRate}%
                              </span>
                            </div>
                          )}
                        </div>
                      </MotionLink>
                    </motion.div>
                    )
                  ))}
                </div>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );}
  };

  return (
    menuData && menuData.length > 0 && (
      <div className="w-full max-w-7xl mx-auto px-4 my-2">
        <AnimatePresence mode="wait">
          {activeMenu ? (
            <SubMenu key="submenu" menu={activeMenu} onBack={() => { setActiveMenu(null); setActiveSection(null); }} posSection={posSection} setActiveSection={setActiveSection} setIsExiting={setIsExiting} />
          ) : (
            <motion.section key="mainmenu" className="grid grid-cols-1 gap-2" >
              {menuData.map((service, index) => (
                <motion.div key={index} initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} exit={{ opacity: 0, y: -20 }} transition={{ delay: index * 0.1 }} className="flex h-full w-full flex-col" >
                  {renderMenuItem(service, index)}
                </motion.div>
              ))}
            </motion.section>
          )}
        </AnimatePresence>
      </div>
    )
  );
};
