import { ArrowBigLeftDash, Save, X, XCircle } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { copyThemeDescription, saveThemeDescription } from "../../../services";
import { AppContext } from "../../../context/AppContext";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { hostSelectCustomStyles } from "../utils/hostSelectStyles";

export const ThemeHotelPanelModal = ({ isThemeHotelPanelModalOpen, onCloseThemeDefaultModal, title, type, themeDefault, setSelectedTheme, }) => {
  const [isSending, setIsSending] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [isGroup, setIsGroup] = useState(false);
  const [saveStatus, setSaveStatus] = useState({ status: null, message: "" });
  const [formData, setFormData] = useState({
    id: "",
    description: "",
    groupId: "",
    hotelId: "",
    isDefault: false,
    isActive: "",
    user: "",
  });
  const location = useLocation();
  const isThemePage = location.pathname.includes("/themes");
  const [t] = useTranslation("global");
  const [copyFromTheme, setCopyFromTheme] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (name === "description" && type !== "checkbox") {
      // Verifica se já existe um themeDefault com esta descrição
      const themeDefaultExists = state.adminInitialData?.themeDescriptions.some(
        (theme) =>
          theme.description.toLowerCase() === value.toLowerCase() &&
          (!themeDefault || theme.id !== themeDefault.id) // Ignora o próprio registro em caso de edição
      );

      if (themeDefaultExists) {
        setSaveStatus({
          status: "error",
          message: t(`ThemeHotelPanelModal.errorDescription`),
        });
      } else {
        setSaveStatus({
          status: null,
          message: "",
        });
      }
    }

    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const postThemeDescription = async () => {
    try {
      setIsSending(true); // Set sending state to true
      await saveThemeDescription(formData); // Send request
      setSaveStatus({
        status: "success",
        message: t(`ThemeHotelPanelModal.success`),
      });
      return true;
    } catch (error) {
      console.error("Erro ao guardar o tema:", error);
      setSaveStatus({
        status: "error",
        message: error.message || t(`ThemeHotelPanelModal.error`),
      });
      return false;
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

  const copyPostThemeDescription = async (newThemeObject) => {

    try {
      setIsSending(true); // Set sending state to true
      await copyThemeDescription(newThemeObject); // Send request
      setSaveStatus({
        status: "success",
        message: t(`ThemeHotelPanelModal.success`),
      });
      return true;
    } catch (error) {
      console.error("Erro ao guardar o tema:", error);
      setSaveStatus({
        status: "error",
        message: error.message || t(`ThemeHotelPanelModal.error`),
      });
      return false;
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

  const handleThemeDescriptionSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation(); // Add this to prevent event bubbling
    setIsSending(true);
    if (type === "add") {

      const newThemeObject = {
        copyFromTheme: copyFromTheme.id,
        themeDescriptionRequest: formData
      }

      const success = await copyPostThemeDescription(newThemeObject);

      if (success) {
        // Aguarda 2 segundos para mostrar o feedback antes de fechar
        setTimeout(() => {
          if (isThemePage) {
            dispatch({
              type: "SET_GLOBAL_REFRESH",
              payload: state.globalRefresh + 1,
            });
          }
          onCloseThemeDefaultModal();
        }, 2000);
      }
    } else if (type === "edit") {
      const success = await postThemeDescription(formData);

      if (success) {
        setTimeout(() => {
          if (isThemePage) {
            dispatch({
              type: "SET_GLOBAL_REFRESH",
              payload: state.globalRefresh + 1,
            });
            setSelectedTheme({
              ...themeDefault,
              description: formData.description,
              isActive: formData.isActive,
              groupId: formData.isGroup ? state.currentHotel.groupId : "",
              user: state.pmsUserLoggedIn,
            });
            localStorage.setItem(
              "themeDefaultSelected",
              JSON.stringify({
                ...themeDefault,
                description: formData.description,
                isActive: formData.isActive,
                groupId: formData.isGroup ? state.currentHotel.groupId : "",
                user: state.pmsUserLoggedIn,
              })
            );
          }
          onCloseThemeDefaultModal();
        }, 2000);
      }
    }
  };

  useEffect(() => {
    if (type === "add") {
      setFormData({
        id: 0,
        description: "",
        groupId: isGroup ? state.currentHotel.groupId : "",
        hotelId: state.currentHotel.hotelId,
        isDefault: false,
        isActive: true,
        user: state.pmsUserLoggedIn,
      });
      setSaveStatus({
        status: null,
        message: "",
      });
    } else if (type === "edit") {
      setFormData({
        id: themeDefault.id,
        description: themeDefault.description,
        groupId: isGroup ? state.currentHotel.groupId : "",
        hotelId: state.currentHotel.hotelId,
        isDefault: false,
        isActive: themeDefault.isActive,
        user: state.pmsUserLoggedIn,
      });
      setSaveStatus({
        status: null,
        message: "",
      });
    }
  }, [isThemeHotelPanelModalOpen]);

  useEffect(() => {
    if (state.currentHotel) {
      setFormData((prev) => ({
        ...prev,
        groupId: isGroup ? state?.currentHotel?.groupId : "",
      }));
    }
  }, [isGroup]);

  return (
    <AnimatePresence>
      {isThemeHotelPanelModalOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 bg-black bg-opacity-20 flex items-center justify-center z-[70]"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{
              duration: 0.2,
              ease: "easeOut",
            }}
            className="bg-white rounded-lg shadow-xl w-full max-w-md"
          >
            <motion.div
              className="flex items-center justify-between p-2 border-b"
              initial={{ y: -10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.1 }}
            >
              <h2 className="pl-2 host-title">{title}</h2>
              <motion.button
                onClick={onCloseThemeDefaultModal}
                className="p-1 hover:bg-gray-100 rounded-full"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
              >
                <XCircle className="w-5 h-5 host-text" />
              </motion.button>
            </motion.div>

            <motion.div
              className="p-4"
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.15 }}
            >
              <form
                onSubmit={(e) => {
                  handleThemeDescriptionSubmit(e);
                }}
                className="space-y-4"
              >
                {type === "add" && (
                <div>
                    <label className="block host-text pb-2">
                    {t(`ThemeHotelPanelModal.copyfrom`)}
                  </label>
                  <Select
                    classNamePrefix="select"
                    styles={hostSelectCustomStyles}
                    isSearchable={false}
                    placeholder={t(`ThemeHotelPanelModal.selectTheme`)}
                    value={copyFromTheme}
                    options={state.hotelPanelInitialData.themeDescriptions} // Use categories em vez de filteredCategories
                    getOptionValue={(theme) => theme.id}
                    getOptionLabel={(theme) => theme.description}
                    onChange={(theme) => setCopyFromTheme(theme)}
                  />
                </div>
                )}

                <div>
                  <label className="block host-text pb-2">
                    {t(`Admin.ThemeDefaultModal.description`)}
                  </label>
                  <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    className="w-full block px-3 py-2 host-search-input"
                    required
                  />
                </div>

                <div className="flex items-center">
                  <input
                    type="checkbox"
                    id="isGroup"
                    name="isGroup"
                    checked={isGroup}
                    onChange={() => setIsGroup(!isGroup)}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label
                    className="ml-2 block host-text"
                  >
                    {t(`ThemeHotelPanelModal.isgroup`)}
                  </label>
                </div>

                {type === "edit" && (
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="isActive"
                      name="isActive"
                      checked={formData.isActive}
                      onChange={handleChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <label
                      className="ml-2 block host-text"
                    >
                      {t(`Admin.ThemeDefaultModal.active`)}
                    </label>
                  </div>
                )}

                {/* Status Message */}
                {saveStatus.status && (
                  <div
                    className={`p-3 rounded-md ${
                      saveStatus.status === "success"
                        ? "bg-green-50 text-green-700"
                        : "bg-red-50 text-red-700"
                    }`}
                  >
                    {saveStatus.message}
                  </div>
                )}

                <div className="flex justify-end space-x-3">
                  <button
                    type="button"
                    onClick={(e) => {
                      onCloseThemeDefaultModal();
                    }}
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  >
                    <ArrowBigLeftDash className="w-5 h-5" />
                  </button>
                  {isSending ? (
                    <button
                      type="submit"
                      className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <Loader />
                    </button>
                  ) : (
                    <button
                      type="submit"
                      disabled={saveStatus.status === "error" || isSending}
                      className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                      <Save className="w-5 h-5" />
                    </button>
                  )}
                </div>
              </form>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
