import { ChevronDown, Moon, Sun } from "lucide-react";
import React, { useContext, useEffect, useRef, useState, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/AppContext";
import packageJson from "../../../../package.json";
import { updateI18nLanguage } from "../../../pages/Settings/Language/ConfigureI18n";
import { motion, AnimatePresence } from "framer-motion";
import { useLocation } from "react-router-dom";
import { previewLanguagesData } from "../../../pages/Others/Utilities/previewModeData";

const gxpLanguages = [
  { code: "pt", name: "Português", cultureCode: "pt-PT" },
  { code: "gb", name: "English", cultureCode: "en-GB" },
  { code: "us", name: "English", cultureCode: "en-US" },
];

const menuVariants = {
  hidden: {
    opacity: 0,
    scale: 0.95,
    y: -10,
    transition: {
      duration: 0.15,
      ease: "easeOut",
    },
  },
  visible: {
    opacity: 1,
    scale: 1,
    y: 0,
    transition: {
      duration: 0.2,
      ease: "easeOut",
    },
  },
  exit: {
    opacity: 0,
    scale: 0.95,
    y: -10,
    transition: {
      duration: 0.15,
      ease: "easeIn",
    },
  },
};

const languageListVariants = {
  hidden: {
    opacity: 0,
    y: -5,
    transition: {
      duration: 0.1,
    },
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
  exit: {
    opacity: 0,
    y: -5,
    transition: {
      duration: 0.1,
    },
  },
};

export const HeaderUserMenu = ({ isOpen, setIsOpen }) => {
  const [t] = useTranslation("global");
  const [darkMode, setDarkMode] = useState( JSON.parse(localStorage.getItem(`darkMode`)) || false );
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const dropdownRef = useRef(null);
  const languageSelectRef = useRef(null);
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/");

  // Memoize available languages
  const avaliableLanguages = useMemo(() => {

    if (isPreviewMode) {
      return previewLanguagesData
    }

    if (!state.hotelInitialData?.Hotel?.LanguagesList) return null;

    return gxpLanguages
      .filter(
        (gxpLang) =>
          state.hotelInitialData.Hotel.LanguagesList[gxpLang.cultureCode]
      )
      .map((gxpLang) => ({
        ...gxpLang,
        ...state.hotelInitialData.Hotel.LanguagesList[gxpLang.cultureCode],
      }));
  }, [state.hotelInitialData?.Hotel?.LanguagesList]);

  // Memoize selected language
  const [selectedLanguage, setSelectedLanguage] = useState(() => {
    const storedLanguage = localStorage.getItem("userLanguage");
    if (storedLanguage) {
      return gxpLanguages.find((l) => l.cultureCode === storedLanguage) || null;
    }
    return null;
  });

  // Single useEffect for language initialization
  useEffect(() => {
    if (!avaliableLanguages?.length) return;

    let newLanguage = null;
    const storedLanguage = localStorage.getItem("userLanguage");

    if (storedLanguage) {
      newLanguage = avaliableLanguages.find(
        (l) => l.cultureCode === storedLanguage
      );
    }

    if (!newLanguage && state.userDetails?.CultureCode) {
      newLanguage = avaliableLanguages.find(
        (l) => l.cultureCode === state.userDetails.CultureCode
      );
    }

    if (!newLanguage && state.hotelInitialData?.Hotel?.DefaultLanguage) {
      newLanguage =
        avaliableLanguages.find(
          (l) => l.cultureCode === state.hotelInitialData.Hotel.DefaultLanguage
        ) || avaliableLanguages.find((l) => l.cultureCode === "en-US");
    }

    if (!newLanguage) {
      newLanguage = avaliableLanguages[0];
    }

    if ( newLanguage && (!selectedLanguage || newLanguage.cultureCode !== selectedLanguage.cultureCode) ) {
      setSelectedLanguage(newLanguage);
      localStorage.setItem("userLanguage", newLanguage.cultureCode);
      dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: newLanguage });
      dispatch({ type: "SET_USER_LANGUAGE", payload: newLanguage });
      updateI18nLanguage(newLanguage.cultureCode);
    }
  }, [ avaliableLanguages, state.userDetails?.CultureCode, state.hotelInitialData?.Hotel?.DefaultLanguage, ]);

  const handleLanguageChange = (langCode) => {
    const language = avaliableLanguages.find((l) => l.cultureCode === langCode);
    if (language && language.cultureCode !== selectedLanguage?.cultureCode) {
      setSelectedLanguage(language);
      localStorage.setItem("userLanguage", langCode);
      dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: language.cultureCode });
      dispatch({ type: "SET_USER_LANGUAGE", payload: language.cultureCode });
      updateI18nLanguage(language.cultureCode);

    }
  };

  const handleDarkModeToggle = () => {
    const newDarkMode = !darkMode;
    setDarkMode(newDarkMode);
    localStorage.setItem("darkMode", JSON.stringify(newDarkMode));
    dispatch({ type: "SET_DARK_MODE", payload: newDarkMode });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        languageSelectRef.current &&
        !languageSelectRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    localStorage.setItem(`darkMode`, JSON.stringify(darkMode));
    if (darkMode) {
      document.documentElement.classList.add(`dark`);
    } else {
      document.documentElement.classList.remove(`dark`);
    }
  }, [darkMode]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsLanguageOpen(false);
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <div className="relative z-30">
        <AnimatePresence>
          <motion.div
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={menuVariants}
          >
            <div className="absolute right-0 mt-2 w-56 z-20 MH-bg_color MH-border_color dark:bg-gxp_dark_1 dark:border-white rounded-lg shadow-lg border py-1">
              <div className="px-4 py-2 text-sm MH-title_color MH-title_size MH-title_font_family MH-title_variant border-b MH-border_color dark:border-white dark:text-white">
                {t("settings")}
              </div>

              {/* Dark Mode Toggle */}
              <div className="px-2 pt-2 dark:text-white cursor-pointer flex items-center justify-between">
                <div className="flex items-center text-sm MH-text_color MH-text_size MH-text_font_family MH-text_variant dark:text-white">
                  {darkMode ? (
                    <Moon className="mr-2 h-4 w-4" />
                  ) : (
                    <Sun className="mr-2 h-4 w-4" />
                  )}
                  <span className="">{t("darkMode")}</span>
                </div>
                <div
                  onClick={handleDarkModeToggle}
                  className={`w-10 h-5 flex items-center rounded-full p-1 cursor-pointer ${
                    darkMode ? "bg-blue-600" : "bg-gray-300"
                  }`}
                >
                  <div
                    className={`bg-white w-4 h-4 rounded-full shadow-md transform transition-transform duration-300 ${
                      darkMode ? "translate-x-4" : "translate-x-0"
                    }`}
                  />
                </div>
              </div>

              {/* Language Selector */}
              {avaliableLanguages && selectedLanguage && (
                <div className="px-2 py-1 flex items-center gap-1 dark:text-white ">
                  <span className="MH-text_color MH-text_size MH-text_font_family MH-text_variant dark:text-white">{t("language")}</span>
                  <div className="relative w-full">
                    <div
                      onClick={() => setIsLanguageOpen(!isLanguageOpen)}
                      className="w-full p-1 rounded dark:bg-gxp_dark_1 hover:bg-gray-200 MH-text_color MH-text_size MH-text_font_family MH-text_variant dark:hover:bg-gray-700 dark:text-white cursor-pointer flex items-center justify-between"
                    >
                      <div className="flex items-center justify-end w-full">
                        <img
                          src={`https://flagcdn.com/w20/${selectedLanguage?.code}.png`}
                          alt={
                            avaliableLanguages.find(
                              (l) => l.code === selectedLanguage?.code
                            )?.name
                          }
                          className="w-6 h-4 mr-2 object-contain"
                        />
                        <span className="MH-text_color text-sm MH-text_font_family MH-text_variant dark:text-white">
                          {
                            avaliableLanguages.find(
                              (l) => l.code === selectedLanguage?.code
                            )?.name
                          }
                        </span>
                      </div>
                      <span className="ml-2">
                        <ChevronDown
                          className={`transition-all ${
                            isLanguageOpen ? "rotate-180" : ""
                          }`}
                        />
                      </span>
                    </div>

                    {isLanguageOpen && (
                      <motion.div
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={languageListVariants}
                      >
                        <div className="absolute top-full left-0 w-full mt-1 border dark:border-gray-600 rounded bg-white dark:bg-gxp_dark_1 shadow-lg z-50">
                          {avaliableLanguages.map((lang) => (
                            <div
                              key={lang.code}
                              onClick={() => {
                                handleLanguageChange(lang.cultureCode);
                                setIsLanguageOpen(false);
                              }}
                              className="p-2 cursor-pointer hover:bg-gray-100 dark:hover:bg-gxp_dark_2 flex items-center"
                            >
                              <img
                                src={`https://flagcdn.com/w20/${lang.code}.png`}
                                alt={`${lang.name} flag`}
                                className="w-6 h-4 mr-2"
                              />
                              <span className="text-sm">{lang.name}</span>
                            </div>
                          ))}
                        </div>
                      </motion.div>
                    )}
                  </div>
                </div>
              )}

              {/* Add App Version Information */}
              <div className="px-2 py-1 text-sm MH-text_color MH-text_variant MH-text_font_family dark:text-white text-center border-t MH-border_color dark:border-white">
                {t("appVersion")} {packageJson.version}
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </>
  );
};
