import { Camera, ScanLine } from 'lucide-react'
import React, { useContext, useEffect, useState } from 'react'
import { createDocumentReaderLink, getGuestYouverse } from '../../../../services/hostService'
import { Loader } from '../../../../components/Layout/components/Loader/Loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../context/AppContext';

export default function ScanDocYouverse({guest,guests, detailId, redirectUrl, platformId, setActiveTab, setGuests}) {
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [isCheck, setIsCheck] = useState(false);
    const [state,dispatch] = useContext(AppContext);

    const request = {
      guestId: guest.id.toString(),
      redirectUrl: redirectUrl,
      platformId: platformId,
      detailId: detailId
  }

  const updateGuestData = (guests, data, state, setGuests) => {
    // Função auxiliar para converter data para o formato correto
    const formatDate = (dateString) => {
      if (!dateString) return null;
      try {
        const date = new Date(dateString);
        return date.toISOString().split('.')[0] + '.000';
      } catch (error) {
        console.error('Error formatting date:', error);
        return null;
      }
    };

    // Função auxiliar para determinar o tipo de documento
    const getDocumentTypeId = (documentType, documentTypes) => {
      if (!documentType || !documentTypes) return null;

      const docTypeMap = {
        'IDENTITY_CARD': 'IDCARD',
        'PASSPORT': 'PASSPORT'
      };

      const mappedCode = docTypeMap[documentType];
      if (!mappedCode) return null;

      const foundType = documentTypes.find(doc => doc.Code === mappedCode);
      return foundType?.Id || null;
    };

    // Encontra o país correto baseado no IsoCode3LetterDocumentReader
    const country = data.nationality ? state.hotelInitialData.CountryList.find(
      country => country.IsoCode3LetterDocumentReader === data.nationality
    ) : null;

    // Determina o tipo de documento
    const docTypeId = getDocumentTypeId(
      data.documentType,
      state.hotelInitialData.GuestDocumentTypes
    );

    // Formata as datas
    const formattedBirthDate = formatDate(data.birthDate);
    const formattedDocExpiry = formatDate(data.dateOfExpiry);

    // Atualiza o guest correto
    const updatedGuests = guests.map(guest => {
      if (guest.id.toString() === data.guestId) {
        return {
          ...guest,
          data: {
            ...guest.data,
            FirstName: data.firstName || guest.data.FirstName,
            LastName: data.lastName || guest.data.LastName,
            BirthDate: formattedBirthDate || guest.data.BirthDate,
            DocVal: formattedDocExpiry || guest.data.DocVal,
            FiscalNumber: data.fiscalNumber || guest.data.FiscalNumber,
            Gender: data.gender || guest.data.Gender,
            DocNumber: data.documentNumber || guest.data.DocNumber,
            // Mantém os valores existentes se os novos forem null/undefined
            NationalityIso: country?.IsoCode || guest.data.NationalityIso,
            NationalityId: country?.Id || guest.data.NationalityId,
            DocType: docTypeId || guest.data.DocType
          }
        };
      }
      return guest;
    });

    setGuests(updatedGuests);
  };

    const createConnection = async () => {

      try {
        setIsLoading(true);

        const response = await createDocumentReaderLink(request)

        if (response.success === true) {
          console.log(response.data);
          // Então esperamos 2 segundos
          await new Promise(resolve => setTimeout(resolve, 2000));
          // Só depois redirecionamos
          window.location.href = response.data;

        }
        if (response.success === false) {
          toast.error(response.errors[0]);
          console.error(response.errors);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    }


    const getGuestProfileYouverse = async (hotelId, detailId, guestId) => {

      try {
        setIsLoading(true);

        const response = await getGuestYouverse(hotelId, detailId, guestId)

        if (response.data.success === true) {

          updateGuestData(guests, response.data.data, state, setGuests);
          const tabNumber = parseInt(guestId, 10);
          setActiveTab(tabNumber);
          const newPath = pathname.split('/scancheck')[0];
          navigate(newPath);
          toast.success("Documento verificado com sucesso!");

        }
        if (response.data.success === false) {
          toast.error(response.errors[0]);
          console.error(response.errors);
        }
      } catch (error) {
        console.error(error);
        toast.error(error.message);
      } finally {
        setIsLoading(false);
      }
    }

    useEffect(() => {

      if(pathname.includes("/scancheck")){
        setIsCheck(true);
      }

    }, [])

    useEffect(() => {
      if(isCheck){
        getGuestProfileYouverse(platformId, detailId, request.guestId)
      }
    }, [isCheck])

  return (
    <div className='flex items-center justify-center rounded-md px-2 py-1 hover:shadow-md cursor-pointer bg-white OC-FormField-icon_border_color border-2 dark:border-gxp_violet ' >
      {isLoading ?
        <Loader />
        :
        <ScanLine
            size={24}
            className='OC-FormField-icon_color dark:text-gxp_violet'
            onClick={createConnection}
        />
      }
    </div>
  )
}

