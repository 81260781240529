import { Calendar, Clock, Users } from "lucide-react";
import { useTranslation } from "react-i18next";

/**
 * @component DynamicHeader
 * @description A component that displays the current steps of the reservation process,
 * allowing the user to navigate between selecting guests, date, and time.
 *
 * @param {Object} props - The component properties.
 * @param {number} props.step - The current step of the reservation process.
 * @param {number} props.people - The number of guests for the reservation.
 * @param {Date} props.date - The selected date for the reservation.
 * @param {string} props.time - The selected time for the reservation.
 * @param {function} props.setStep - Function to update the current step of the reservation process.
 * @param {function} props.t - Translation function for localization.
 *
 * @returns {JSX.Element} The dynamic header component.
 *
 * @example
 * <DynamicHeader step={step} people={people} date={date} time={time} setStep={setStep} t={t} />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const DynamicHeader = ({ step, setStep, people, date, time }) => {
  const [t] = useTranslation("global"); // Function for text translation

  return (
    <div className="flex items-center rounded-lg ">
      <div
        onClick={() => setStep(1)}
        className={` flex p-2 items-center cursor-pointer justify-center text-center w-1/3 space-x-2 border-2 group dark:text-gxp_violet dark:hover:bg-gxp_violet_dark dark:hover:text-white TR-Stepper-hover_bg_color TR-Stepper-bg_color rounded-l-lg border-r-2 ${
          step === 1 ? " dark:border-gxp_violet border-2 TR-Stepper-active_color rounded-l-lg" : ""

        }`}

      >
        <Users className="TR-Stepper-icon_color dark:text-gxp_violet dark:group-hover:text-white" size={20} />
        <span>
          {people} {`${t(`SubmissionForm.guests`)}`}
        </span>
      </div>
      <div
        onClick={() => {
          if (people) {
            setStep(2);
          }
        }}
        className={`group flex p-2 items-center cursor-pointer justify-center w-1/3 border-2 group dark:text-gxp_violet dark:hover:bg-gxp_violet_dark dark:hover:text-white TR-Stepper-hover_bg_color TR-Stepper-bg_color space-x-2 ${
          step === 2 ? " dark:border-gxp_violet border-2 TR-Stepper-active_color" : ""
        }`}
      >
        <Calendar className="TR-Stepper-icon_color dark:text-gxp_violet dark:group-hover:text-white" size={20} />
        <span>
          {date
            ? date.toLocaleDateString("pt-PT", {
                day: "2-digit",
                month: "2-digit",
              })
            : ""}
        </span>
      </div>
      <div
        onClick={() => {
          if (people && date) {
            setStep(3);
          }
        }}
        className={`flex p-2 items-center cursor-pointer justify-center w-1/3 border-2 group dark:text-gxp_violet dark:hover:bg-gxp_violet_dark dark:hover:text-white rounded-r-lg TR-Stepper-hover_bg_color TR-Stepper-bg_color space-x-2 border-l-2 ${
          step === 3 ? "dark:border-gxp_violet border-2 TR-Stepper-active_color rounded-r-lg" : ""
        }`}
      >
        <Clock className="TR-Stepper-icon_color dark:text-gxp_violet dark:group-hover:text-white" size={20} />
        <span>{time}</span>
      </div>
    </div>
  );
};
