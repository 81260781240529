import { useEffect, useContext, useState, useRef, useCallback, useMemo, } from "react";
import { Home } from "lucide-react";
import { useCookies } from "react-cookie";
import { AppContext } from "../../context/AppContext";
import { getHostProfile, getHotelMenu } from "../../services";
import { toast } from "react-toastify";
import { useLocation, Link, useParams } from "react-router-dom";
import { Loader } from "./components/Loader/Loader.js";
import { useMenuDataProcessor } from "../../hooks/useMenuDataProcessor";
import { previewMenuEN, previewMenuPT } from "../../pages/Others/Utilities/previewModeData.js";

export const MobileFooter = () => {
  const { pathname } = useLocation();
  const [activeSubitems, setActiveSubitems] = useState(null);
  const { platformUid, detailId} = useParams()
  const footerRef = useRef(null);
  const subfooterRef = useRef(null);
  const [menuData, setMenuData] = useState([]);
  const [cookie, setCookie] = useCookies([ "sUid", "profileKey", "GUid", "platformUid", "detailId", "guest",'isProfileLoaded','currentHotel', 'initialLink' ]);
  const [state, dispatch] = useContext(AppContext);
  const posSection = "FOOTER"
  const isHome = pathname.includes("/home");
  const isPreviewMode = pathname.includes("/previewmode/");
  const processMenuData = useMenuDataProcessor( posSection, setMenuData,isPreviewMode );
  const [isLoading, setIsLoading] = useState(true);
  const [userLanguage, setUserLanguage]=useState()
  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
  const MFGeneralRef = useRef(null);

  ///////////////////////// UTILIZADO PARA O PREVIEW MODE /////////////////////////

  const handleMouseEnter = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hover",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'Hover on specific hoverable element'
      }, "*");
    }
  };

  const handleMouseLeave = (e, elementRef) => {

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hoverOut",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'HoverOut from specific hoverable element'
      }, "*");
    }
  };

  const handleElementEnter = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Se houver um hover ativo em outro elemento
    if (isHovered && isHovered !== id && currentRef) {
        handleMouseLeave(e, currentRef);
    }

    // Atualiza o estado para o novo elemento
    if(inspectMode){
      console.log("handleElementEnter", id);
    setCurrentRef(ref);
    handleMouseEnter(e, ref);
    setIsHovered(id);
    }
};


const handleElementLeave = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Apenas limpa o estado se for o elemento atual com hover
    if (isHovered === id) {
        handleMouseLeave(e, ref);
        setIsHovered(null);
        setCurrentRef(null);
    }
};

useEffect(() => {
  const handleMessage = (event) => {
    // Log para debug
    // console.log("Mensagem recebida no iframe:", event.data);

    if (event.data.action === "toggleInspectMode") {
      const newMode = event.data.value;
      setInspectMode(newMode);
      localStorage.setItem("inspectMode", newMode.toString());
    }
  };

  window.addEventListener("message", handleMessage);
  return () => window.removeEventListener("message", handleMessage);
}, []);

//////////////////////////////////////////////////////////////////////////////////////


  // Reset menu data when hotel changes
  useEffect(() => {
    setMenuData([]); // Clear existing menu data
    setIsLoading(true);
  }, [state.currentHotel?.subscriptionKey]);

  const homeItem = useMemo(() => (
    {
      Description: "HOME", Active: true, IconTag: Home, LinkTo: isPreviewMode ? '' : cookie.initialLink, SortOrder: 0
    }
), [platformUid, cookie.sUid]); // dependências

const toggleSubitems = useCallback((item, event) => {
  event.stopPropagation();
  // Agora comparamos com o Description do item
  setActiveSubitems((current) => (current?.Description === item.Description ? null : item));
}, []);

const fetchHotelMenu = async () => {
  if (!state.currentHotel?.subscriptionKey || !userLanguage) return;

  try {
    setIsLoading(true);
    const result = await getHotelMenu(userLanguage, state.currentHotel.subscriptionKey);
    dispatch({ type:"SET_HOTEL_MENU", payload: result });
  } catch (error) {
    toast.error("Unable to get hotel Menu!");
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {
  if (isPreviewMode) {
      const menuData = userLanguage === "pt-PT" ? previewMenuPT : previewMenuEN
      dispatch({ type:"SET_HOTEL_MENU", payload: menuData });
      return
  }

  fetchHotelMenu();
}, [state.currentHotel?.subscriptionKey, userLanguage, platformUid, state.userLanguage]);

useEffect(() => {
  setUserLanguage(state.userLanguage)
}, [state.userLanguage])

  useEffect(() => {
    const handleClickOutside = (event) => {
      // Verifica se o clique foi fora do footer E do subfooter
      if ( MFGeneralRef.current && !MFGeneralRef.current.contains(event.target) && subfooterRef.current && !subfooterRef.current.contains(event.target) ) {
        setActiveSubitems(null); }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => { document.removeEventListener("mousedown", handleClickOutside); };
  }, []);

  useEffect(() => {
    setActiveSubitems(null);
  }, [pathname]);

  /**
   * UseEffect that search host info using shortuid and hotel subscriptionKey
   */
  useEffect(() => {
    const subscriptionKey = state.currentHotel?.subscriptionKey || state.currentHotel?.subscriptionKey;
    const shouldFetchProfile = subscriptionKey && cookie.sUid && !pathname.includes("/checkindirect") && !cookie.isProfileLoaded && !isPreviewMode;

    if (shouldFetchProfile) {
      getHostProfile(cookie.sUid, cookie.profileKey, subscriptionKey)
        .then((data) => {
          dispatch({ type: "SET_USER_DETAILS", payload: data });

          // Update cookies with user data
          const cookieOptions = { path: "/" };
          setCookie('isProfileLoaded', true, cookieOptions);
          setCookie("profileKey", data.ProfileKey, cookieOptions);
          setCookie("GUid", data.Uid, cookieOptions);
          setCookie("guest", JSON.stringify(data), cookieOptions);
        })
        .catch((error) => {
          console.error("Failed to get host profile:", error);
          toast.error("Unable to get host!");
        });
    }
  }, [cookie.sUid, cookie.profileKey, platformUid, cookie.isProfileLoaded, state.currentHotel]);

  // Process menu data when hotel menu or reservation changes
  useEffect(() => {
    if (state.hotelMenu) {

      processMenuData();
      setMenuData(prevMenu => {
        const menuWithoutHome = prevMenu.filter(item => item.Description !== "HOME");
        return [...menuWithoutHome, homeItem].sort((a, b) => (a.SortOrder ?? 999) - (b.SortOrder ?? 999));
      });
      setIsLoading(false);
    }
  }, [state.hotelMenu, homeItem, platformUid, detailId]);

      // Lista de types que devem aparecer como rasurados
      const typesToStrikethrough = [
      ];

      const footerContent = useMemo(() => {
        if (isLoading || menuData.length <= 1) {
          return (
            <footer className="bg-white dark:bg-gray-800 fixed bottom-0 left-0 right-0 shadow-lg border-t border-gray-200 z-80 h-20">
              <div className="flex flex-col justify-center items-center py-4 h-full w-full">
                <Loader className="text-gray-500 h-16 w-16" />
              </div>
            </footer>
          );
        }

        return (
          menuData.length > 1 && userLanguage && (
            <>
              <div className={`z-60 MF-bg_color MF-title_color MF-title_size MF-title_font_family MF-title_variant MF-border_color MF-border_rounded MF-border_position MF-border_width dark:bg-gxp_dark_1 dark:text-white fixed bottom-20 left-0 right-0 flex flex-col items-center transition-all duration-500 ease-in-out transform overflow-hidden ${ activeSubitems ? "h-20 max-sm:h-fit opacity-100" : "h-0 opacity-0" }`}>
                <div ref={subfooterRef} className={`w-full h-full lg:max-w-4xl grid auto-cols-fr grid-flow-col gap-2 transition-all duration-500 ease-in-out transform ${ activeSubitems ? "opacity-100 translate-y-0 delay-100" : "opacity-0 translate-y-full" }`}>
                  {menuData.find(item => item.Description === activeSubitems?.Description)?.subItems?.map((subitem, index) => (
                    <div className="flex justify-center flex-col items-center" key={`${subitem.Description}-${index}`}>
                      <Link to={subitem?.LinkTo} onClick={() => setActiveSubitems(null)} className={`flex p-2 rounded-lg w-fit h-fit flex-col items-center justify-center mx-4 text-center group ${ isHome ? "opacity-50" : "opacity-100 hover:bg-white hover:text-gray-800" }`} >
                        {subitem.IconTag && (
                          <span
                            dangerouslySetInnerHTML={{
                              __html: subitem.IconTag?.replace(/<i /, '<i style="font-size: 24px" ')
                            }}
                            className="mb-1 MF-icon_color dark:text-white dark:group-hover:text-gray-800 transition-all duration-300"
                          />
                        )}
                        <span className={`${typesToStrikethrough.includes(subitem.Type) ? "line-through" : ""} transition-all duration-300`}>
                          {subitem.Description}
                        </span>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>

              <footer id="MF-General" ref={MFGeneralRef} onMouseOver={(e) => handleElementEnter(e, MFGeneralRef, "MF-General")} onMouseLeave={(e) => handleElementLeave(e, MFGeneralRef, "MF-General")} className={` ${isPreviewMode && isHovered === "MF-General" ? "diagonal-stripes" : ""} dark:bg-gxp_dark_1 dark:text-white MF-bg_color MF-title_color MF-title_size MF-title_font_family MF-title_variant MF-border_color MF-border_rounded MF-border_position MF-border_width fixed bottom-0 left-0 right-0 shadow-lg z-80 h-20 w-full`}>
                <div className="relative w-full flex flex-col items-center">
                  <nav className="grid auto-cols-fr grid-flow-col py-2 w-full lg:max-w-4xl">
                    {menuData.map((item, index) => (
                      <div key={index} className="flex flex-col items-center">
                        {item.Description === "HOME" ? (
                          <Link
                            to={item.LinkTo}
                            className={`flex flex-col items-center transition-all duration-300 ease-in-out rounded-lg p-2 ${
                              isHome ? "" : "opacity-100 dark:hover:text-gray-800 dark:hover:bg-white group"
                            }`}
                          >
                            <item.IconTag className="w-6 h-6 mb-1 MF-icon_color dark:text-white dark:group-hover:text-gray-800 transition-all duration-300 " />
                            <span className={`${typesToStrikethrough.includes(item.Type) ? "line-through" : ""}`}>
                              {item.Description}
                            </span>
                          </Link>
                        ) : (
                          <button
                            disabled={isHome}
                            className={`flex flex-col items-center transition-all duration-300 ease-in-out rounded-lg p-2 ${
                              isHome ? "opacity-50" : "opacity-100 dark:hover:bg-white dark:hover:text-gray-800 group"
                            }`}
                            onClick={(e) => item.subItems && toggleSubitems(item, e)}
                          >
                            {item.IconTag && (
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: item.IconTag?.replace(/<i /, '<i style="font-size: 24px" ')
                                }}
                                className="mb-1 MF-icon_color dark:text-white dark:group-hover:text-gray-800 transition-all duration-300"
                              />
                            )}
                            <span className={`${typesToStrikethrough.includes(item.Type) ? "line-through" : ""} `}>
                              {item.Description}
                            </span>
                          </button>
                        )}
                      </div>
                    ))}
                  </nav>
                </div>
              </footer>
            </>
          )
        );
      }, [ state.currentHotel?.subscriptionKey, menuData, isLoading, activeSubitems, isHome, toggleSubitems, platformUid, isHovered, inspectMode, isPreviewMode]);

      return footerContent;
     };