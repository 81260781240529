import { ArrowBigLeftDash, Save, X } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { saveGroupHotel } from '../../../services';
import { AppContext } from '../../../context/AppContext';
import { Loader } from '../../../components/Layout/components/Loader/Loader';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export const GroupHotelModal = ({ isGroupModalOpen, onCloseGroupModal, title, type, groupHotel, onSuccess }) => {

    const [isSending, setIsSending] = useState(false);
    const [state, dispatch] = useContext(AppContext);
    const [saveStatus, setSaveStatus] = useState({
      status: null,
      message: ''
    });
    const [formData, setFormData] = useState({
      id: '',
      groupId: '',
      description: '',
      isActive: '',
      user:''
    });
    const location = useLocation()
    const isHotelGroupPage = location.pathname.includes('/grouphotels');
    const [t] = useTranslation("global");


    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;

      if (name === 'groupId' && type !== 'checkbox') {
        // Verifica se já existe um groupId igual
        const groupExists = state.adminInitialData?.groupHotels.some(
          group => group.groupId.toLowerCase() === value.toLowerCase().trim() &&
          (!groupHotel || group.id !== groupHotel.id) // Ignora o próprio registro em caso de edição
        );

        if (groupExists) {
          setSaveStatus({
            status: 'error',
            message: `${t(`Admin.groupHotelList.errorGroupID`)}`
          });
        } else {
          setSaveStatus({
            status: null,
            message: ''
          });
        }
      }

      setFormData(prev => ({
        ...prev,
        [name]: type === 'checkbox' ? checked : value
      }));
    };

    const postGroupHotel = async () => {
      try {
        setIsSending(true); // Set sending state to true
        await saveGroupHotel(formData); // Send request
        setSaveStatus({
          status: 'success',
          message: `${t(`Admin.groupHotelList.success`)}`
        });
        return true;
      } catch (error) {
        console.error("Error saving group hotel:", error);
        setSaveStatus({
          status: 'error',
          message: error.message || `${t(`Admin.groupHotelList.error`)}`
        });
        return false;
      } finally {
        setIsSending(false); // Set sending state back to false
      }
    }

    const handleGroupHotelSubmit = async (e) => {

        e.preventDefault();
        e.stopPropagation(); // Add this to prevent event bubbling
        setIsSending(true)
      if (type === 'add') {

        const success = await postGroupHotel(formData);

        if (success) {

          if (onSuccess) {
            await onSuccess();
          }

          // Aguarda 2 segundos para mostrar o feedback antes de fechar
          setTimeout(() => {
            if(isHotelGroupPage){
              dispatch({ type: "SET_GLOBAL_REFRESH", payload: state.globalRefresh+1 });
            }
            onCloseGroupModal();
            }, 2000);
        }
      } else if (type === 'edit') {

        const success = await saveGroupHotel(formData);

        if (success) {

          setTimeout(() => {
            if(isHotelGroupPage){
              dispatch({ type: "SET_GLOBAL_REFRESH", payload: state.globalRefresh+1 });
            }
            onCloseGroupModal();

          }, 2000);
        }

    };}

    useEffect(() => {
      if(type === 'add'){
        setFormData({
          id: 0,
          groupId: '',
          description: '',
          isActive: true,
          user: state.adminLoggedIn.username
        });
        setSaveStatus({
          status: null,
          message: ''
        })
      } else if (type === 'edit') {
        setFormData({
          id: groupHotel.id,
          groupId: groupHotel.groupId,
          description: groupHotel.description,
          isActive: groupHotel.isActive,
          user: state.adminLoggedIn.username
        });
        setSaveStatus({
          status: null,
          message: ''
        })
      }
    }, [isGroupModalOpen]);

  return (
    <AnimatePresence>
      {isGroupModalOpen && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.2 }} className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-[70]" >
          <motion.div
            initial={{ scale: 0.95, opacity: 0, y: 20 }}
            animate={{ scale: 1, opacity: 1, y: 0 }}
            exit={{ scale: 0.95, opacity: 0, y: 20 }}
            transition={{
              duration: 0.2,
              ease: "easeOut"
            }}
            className="bg-white rounded-lg shadow-xl w-full max-w-md"
          >
            <motion.div className="flex items-center justify-between p-4 border-b" initial={{ y: -10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.1 }} >
              <h2 className="text-xl font-semibold">{title}</h2>
              <motion.button onClick={onCloseGroupModal} className="p-1 hover:bg-gray-100 rounded-full" whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }} >
                <X className="w-5 h-5" />
              </motion.button>
            </motion.div>

            <motion.div className="p-4" initial={{ y: 10, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.15 }} >

                <form onSubmit={(e) => {handleGroupHotelSubmit(e)}}  className="space-y-4">
                    <div>
                    <label className="block text-sm font-medium text-gray-700">{`${t(`Admin.groupHotelList.groupId`)}`}</label>
                    <input
                        type="text"
                        name="groupId"
                        value={formData.groupId}
                        onChange={handleChange}
                        className={`mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500 ${type === 'edit' ? 'bg-gray-100' : ''}`}
                        required
                        disabled={type === 'edit'}
                    />
                    </div>

                    <div>
                    <label className="block text-sm font-medium text-gray-700">{`${t(`Admin.groupHotelList.description`)}`}</label>
                    <input
                        type="text"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
                        required
                    />
                    </div>

                    {type === 'edit' && (
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id="isActive"
                          name="isActive"
                          checked={formData.isActive}
                          onChange={handleChange}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor="isActive" className="ml-2 block text-sm text-gray-900">
                          {`${t(`Admin.groupHotelList.active`)}`}
                        </label>
                      </div>
                    )}

                    {/* Status Message */}
                    {saveStatus.status && (
                    <div className={`p-3 rounded-md ${
                        saveStatus.status === 'success'
                        ? 'bg-green-50 text-green-700'
                        : 'bg-red-50 text-red-700'
                    }`}>
                        {saveStatus.message}
                    </div>
                    )}

                    <div className="flex justify-end space-x-3 pt-4">
                    <button type="button" onClick={(e) => { onCloseGroupModal()}} className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                        <ArrowBigLeftDash className="w-5 h-5" />
                      </button>
                      {isSending ?
                            <button type="submit" className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                               <Loader />
                            </button>
                        :
                            <button type="submit" disabled={saveStatus.status === 'error' || isSending} className="px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500" >
                              <Save className="w-5 h-5" />
                            </button>
                        }
                    </div>
                </form>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};