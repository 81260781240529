import { useState, useContext, useEffect } from "react";
import { Send } from "lucide-react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import { useCookies } from "react-cookie";
import { getReservationByDetailId, maintenanceRequest } from "../../services";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { HeadersPage } from "../../components/Layout/HeadersPage";

/**
* @module MaintenanceRequest
* @description Core module for handling maintenance requests in the hotel system.
* Manages request submission, form state, and reservation data display.
*/

/**
* @namespace MaintenanceRequest
* @memberof module:MaintenanceRequest
* @description Component responsible for maintenance request functionality.
* Handles form submission, validation, and feedback display.
*
* @component
* @returns {JSX.Element} MaintenanceRequest component
*
* @example
* <MaintenanceRequest />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const MaintenanceRequest = () => {
  const [request, setRequest] = useState(""); // Holds the content of the maintenance request
  const [submitted, setSubmitted] = useState(false); // Tracks if the request was successfully submitted
  const [state, dispatch] = useContext(AppContext); // Access the app context for global state management
  const [t] = useTranslation("global"); // For translation of strings
  const [reservation, setReservation] = useState(state.reservation); // Holds reservation details
  const [cookie] = useCookies(["sUid", "currentHotel"]); // Fetch cookies for session and hotel info
  const { detailId } = useParams(); // Get reservation detailId from the URL
  const [isSending, setIsSending] = useState(false); // Tracks if the request is being sent

 /**
  * @function handleSubmit
  * @memberof module:MaintenanceRequest.MaintenanceRequest
  * @description Handles the submission of maintenance requests.
  * Validates input, submits request, and manages submission state.
  *
  * @param {Event} e - Form submission event
  * @returns {Promise<void>}
  *
  * @example
  * handleSubmit(event)
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!request.trim()) return; // Don't send empty requests

    const req = { sUid: cookie.sUid, detailId: detailId, info: request }; // Request payload

    try {
      setIsSending(true); // Set sending state to true
      await maintenanceRequest(state.currentHotel, req); // Send request
      setSubmitted(true); // Mark as submitted
      setRequest(""); // Clear the request field
      toast.success(t(`MaintenanceRequest.success`)); // Show success message
    } catch (error) {
      toast.error(t(`HostFormComplete.formfail`)); // Show error message on failure
      console.error("Maintenance request error:", error);
    } finally {
      setIsSending(false); // Set sending state back to false
    }
  };

 /**
  * @function fetchReservationByDetailId
  * @memberof module:MaintenanceRequest.MaintenanceRequest
  * @description Fetches reservation details using the detail ID.
  * Updates both local and global state with reservation data.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchReservationByDetailId()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchReservationByDetailId = async () => {
    if (state.currentHotel?.subscriptionKey && detailId) {
      try {
        const results = await getReservationByDetailId( detailId, state.currentHotel, cookie.sUid );
        setReservation(results[0]); // Set reservation state
        dispatch({ type: "SET_USER_RESERVATION", payload: results[0] }); // Update global context with reservation data
      } catch (error) {
        console.log(error);
        toast.error("Unable to get reservations!"); // Error handling
      }
    }
  };

   /**
  * @function useEffectFetchReservation
  * @memberof module:MaintenanceRequest.MaintenanceRequest
  * @description Effect that triggers reservation fetching when needed.
  *
  * @effect Fetches reservation data if not already present
  * @dependencies [detailId]
  *
  * @example
  * useEffect(() => {
  *   if (!reservation) {
  *     fetchReservationByDetailId();
  *   }
  * }, [detailId]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (!reservation) {
      fetchReservationByDetailId();
    }
  }, [detailId]); // eslint-disable-line

  return (
    <>
      {reservation && (
        <div className={`${ state.darkMode ? "" : "page_bg_color" } antialiased flex flex-col h-full`} >
          <section className="shadow-md overflow-hidden flex-grow">
            <HeadersPage title={`${t(`MaintenanceRequest.maintenance`)}`} room={reservation?.room} />
            <main className="container mx-auto ">
              <div className=" p-6">
                <form onSubmit={handleSubmit}>
                  <div className="mb-4">
                    <label htmlFor="maintenance-request" className="block dark:text-white font-medium mb-2" > {t("MaintenanceRequest.maintenancerequest")} </label>
                    <textarea id="maintenance-request" rows={5} className=" dark:bg-gxp_dark_3 w-full px-3 py-2 text-gray-700 dark:text-white dark:placeholder:text-white dark:border-gxp_dark_1 border rounded-lg focus:outline-none focus:border-blue-500" value={request} onChange={(e) => setRequest(e.target.value)} placeholder={`${t(`MaintenanceRequest.description`)}`} ></textarea>
                  </div>
                  <button type="submit" disabled={isSending} className="bg-blue-600 text-white px-4 py-2 cursor-pointer w-40 h-10 rounded-lg hover:bg-blue-700 transition duration-300 flex items-center justify-center" >
                    {isSending ? ( <Loader /> ) : ( <> <Send size={18} className="mr-2" />{" "} {`${t(`MaintenanceRequest.send`)}`} </> )}
                  </button>
                </form>
                {submitted && (
                  <div className="mt-4 p-4 bg-green-100 border-l-4 border-green-500 text-green-700 rounded-lg">
                    <p>{`${t(`MaintenanceRequest.success`)}`}</p>
                  </div>
                )}
              </div>
            </main>
          </section>
        </div>
      )}
    </>
  );
};
