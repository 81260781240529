import { useState, useContext, useEffect, useRef } from "react";
import { HostForm } from "./HostForm";
import { AppContext } from "../../../context/AppContext";
import { getDefaultPreviewInitialData, getHotelTitles, saveReservationGuests } from "../../../services";
import { toast } from "react-toastify";
import { Consent } from "./Consent";
import { GeneralTerms } from "./GeneralTerms";
import { SquarePen } from "lucide-react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Stepper from "./ProgressBar/Stepper";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { HeadersPageOC } from "./Utilities/HeadersPageOC";
import { previewHotelInitialData } from "./Utilities/previewModeData";
import { HamburgerMenuIcon } from "@radix-ui/react-icons";
import { HeaderUserMenu } from "../../../components/Layout/components/HeaderUserMenu";
import { ArrivalTime } from "./FormFields/ArrivalTime";

/**
 * @module HostFormComplete
 * @description Renders the host form for guests' online check-in, allowing adding and removing guests, and submitting their information.
 * @param {Object} props - Component props.
 * @param {Object} props.hotel - Hotel data.
 * @param {Object} props.reservation - Reservation data.
 * @param {function} props.setReservation - Function to update the reservation state.
 * @returns {JSX.Element} Rendered component.
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 * @example
 * <HostFormComplete hotel={hotel} reservation={reservation} setReservation={setReservation} />
 */
export const HostFormComplete = ({ data, hotel, reservation, setReservation, showMenus, cultureCode, isPreviewMode, handleMouseEnter, handleMouseLeave}) => {
  const { detailId } = useParams()
  const [guests, setGuests] = useState([]);
  const [activeTab, setActiveTab] = useState(() => {
    if (isPreviewMode) {
      return 0;
    }
    try {
      const storedTab = localStorage.getItem("localTab");
      if (!storedTab) return 0;

      const parsedData = JSON.parse(storedTab);
      if (parsedData.id === detailId) {
        return parsedData.tab;
      }
      return 0;
    } catch (error) {
      console.error("Error parsing localTab:", error);
      return 0;
    }
  });
  const [state,dispatch] = useContext(AppContext);
  const { pathname } = useLocation();
  const [initialData, setInitialData] = useState(isPreviewMode ? previewHotelInitialData : state.hotelInitialData);
  const [showConsent, setShowConsent] = useState(() => {
    try {
      if(isPreviewMode){
        return false
      }
        const storedConsent = localStorage.getItem("showConsent");
        if (!storedConsent) return false;

        const parsedData = JSON.parse(storedConsent);
        if (parsedData.id === detailId) {
            return parsedData.showConsent;
        }
        return false;
    } catch (error) {
        console.error("Error parsing showConsent:", error);
        return false;
    }
  });
  const [showGeneralTerms, setShowGeneralTerms] = useState(() => {
    if(isPreviewMode){
      return false
    }
    try {
        const storedGeneralTerms = localStorage.getItem("showGeneralTerms");
        if (!storedGeneralTerms) return false;

        const parsedData = JSON.parse(storedGeneralTerms);
        if (parsedData.id === detailId) {
            return parsedData.showGeneralTerms;
        }
        return false;
    } catch (error) {
        console.error("Error parsing showGeneralTerms:", error);
        return false;
    }
});
  const [canSubmit, setCanSubmit] = useState(false);
  const [successSubmit, setSuccessSubmit] = useState(false);
  const [t] = useTranslation("global");
  const [step, setStep] = useState(() => {
    if(isPreviewMode){
      return 1
    }
    try {
        const storedStep = localStorage.getItem("localStep");
        if (storedStep === null) return 1;

        const parsedStep = JSON.parse(storedStep);
        return typeof parsedStep === 'number' ? parsedStep : 1;
    } catch (error) {
        console.error("Error parsing localStep:", error);
        return 1;
    }
});
  const [totalSteps, setTotalSteps] = useState(3);
  const [militaryMode, setMilitaryMode]= useState(false)
  const [titles, setTitles]= useState({})
  const [titlesLoading, setTitlesLoading] = useState(true);
  const [post, setPost] = useState("")
  const navigate = useNavigate()
  const [isSending, setIsSending]=useState(false)
  const [localReservation, setLocalReservation] = useState(null)
  const {defaultThemeId} = useParams()
  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
  const OCStepperRef = useRef(null);
  const OCHeaderRef = useRef(null);
  const OCGeneralRef = useRef(null);
  const OCFormFieldRef = useRef(null);
  const OCButton1Ref = useRef(null);
  const OCButton2Ref = useRef(null);
  const OCArrivalTimeRef = useRef(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isDirect = pathname.includes("checkindirect");
  const menuRef = useRef(null);


  useEffect(() => {
    const handleMessage = (event) => {
      // Log para debug
      // console.log("Mensagem recebida no iframe:", event.data);

      if (event.data.action === "toggleInspectMode") {
        const newMode = event.data.value;
        setInspectMode(newMode);
        localStorage.setItem("inspectMode", newMode.toString());
      }
    };

    window.addEventListener("message", handleMessage);
    return () => window.removeEventListener("message", handleMessage);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target) &&
          !event.target.closest('button')) {
        setIsMobileMenuOpen(false);
      }
    };

    if (isMobileMenuOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isMobileMenuOpen]);

// Handle initial load from localStorage
useEffect(() => {
  if (!reservation || !detailId) return;

    const localStReservation = JSON.parse(localStorage.getItem("localReservation"));

    if (localStReservation && localStReservation.detailId === detailId) {
      setLocalReservation(localStReservation);
    } else {

      const newLocalReservation = {
        detailId: detailId,
        billingType: 'sameAsGuest',
        reservation: reservation
      };
      localStorage.setItem("localReservation", JSON.stringify(newLocalReservation));
      setLocalReservation(newLocalReservation);
    }

}, [detailId, reservation]);

  useEffect(() => {
    if(localReservation){
      localStorage.setItem("localReservation", JSON.stringify(localReservation))
    }
  }, [localReservation])

  useEffect(()=>{
    const guest = guests[0]
    if(militaryMode && titles && guest){
      const newTitle = titles?.result?.title.find((element) => element.ID === guest.data.Title_Id)
      setPost(newTitle)
    }
  },[guests, titles, militaryMode])

  /**
   * @function addNewGuest
   * @description Adds a new guest to the guests list and sets the active tab to the new guest.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * addNewGuest();
   */
  const addNewGuest = () => {
    const newGuest = {
      id: guests.length,
      title: `${t(`HostFormComplete.guest`)} ${guests.length + 1}`,
      data: {},
    };
    setGuests([...guests, newGuest]);
    setActiveTab(newGuest.id);
  };

  /**
   * @function removeGuest
   * @description Removes a guest from the guests list based on the provided ID.
   * @param {number} id - The ID of the guest to remove.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * removeGuest(1);
   */
  const removeGuest = (id) => {
    if (id === 0) return; // Do not allow removal of the main guest
    const newGuests = guests.filter((guest) => guest.id !== id);
    setGuests(newGuests);
    setActiveTab(newGuests[newGuests.length - 1].id);
  };

  useEffect(()=>{

    const tabData = {
      id: detailId,
      tab: activeTab
  };
    localStorage.setItem("localTab", JSON.stringify(tabData));

    const stepData = {
      id: detailId,
      step: step
  };
    localStorage.setItem("localStep", JSON.stringify(stepData));

    const showConsentData = {
      id: detailId,
      showConsent: showConsent
    }
    localStorage.setItem("showConsent", JSON.stringify(showConsentData))

    const showGeneralTermsData = {
      id: detailId,
      showGeneralTerms: showGeneralTerms
    }
    localStorage.setItem("showGeneralTerms", JSON.stringify(showGeneralTermsData))
  },[step, showConsent, showGeneralTerms, detailId, activeTab])

  /**
   * @function extractData
   * @description Extracts guest data from the guests list.
   * @param {Array} guests - List of guest objects.
   * @returns {Array} Array of guest data objects.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * const data = extractData(guests);
   */
  function extractData(guests) {
    return guests.map((guest) => guest.data);
  }

  /**
   * @function handleSubmit
   * @description Handles form submission, checking for required data and saving the reservation guests.
   * @param {Event} e - The form submit event.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * handleSubmit(event);
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if(isPreviewMode){
      setSuccessSubmit(true);
      handleElementLeave(e, OCButton1Ref, "OC-Button1");

      window.parent.postMessage({
        action: "OC-SuccessPage",
        debugInfo: 'Change to Success Page'
      }, "*");
      return;
    }

    if (!guests[0].data.GeneralTermsAccepted) {
      alert(`${t(`HostFormComplete.acceptgeneral`)}`);
      return;
    }
    if (!guests[0].data.ArrivalTime) {
      alert(`${t(`HostFormComplete.arrivaltime`)}`);
      return;
    }

    if (guests.length > 0 && reservation) {
      setReservation((prevReservation) => ({
        ...prevReservation,
        Guests: guests.map((guest) => guest.data),
      }));
    }


    if (canSubmit) {
      try {
        setIsSending(true);

        const data = await saveReservationGuests(hotel, extractData(guests));
        if(data){
          setSuccessSubmit(true);
          localStorage.removeItem("localReservation")
          localStorage.removeItem("localStep")
          localStorage.removeItem("localTab")
          localStorage.removeItem("showConsent")
          localStorage.removeItem("showGeneralTerms")

        }
      } catch (error) {
        alert(`${t('HostFormComplete.formfail')}`, error);
      } finally {
        setIsSending(false);
      }
    } else {
      alert(`${t(`HostFormComplete.arrivaltime`)}`);
    }
  };

  const handleBack = () => {
    if(isPreviewMode){
      setSuccessSubmit(false)

      window.parent.postMessage({
        action: "OC-GeneralTermPage",
        debugInfo: 'Change to General Terms Page'
      }, "*");

      return;
    }
    setSuccessSubmit(false)
  }

  /**
   * @function next
   * @description Handles the logic for moving to the next guest or showing the consent or general terms.
   * @param {Event} e - The event triggered by the button click.
   * @param {number} id - The current guest index.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * next(event, guestId);
   */
  const next = (e, id) => {
    e.preventDefault();
    if (!showGeneralTerms && showConsent && id === guests.length - 1) {
      setShowGeneralTerms(true);
      setShowConsent(false);
      setStep(step + 1);
      window.scrollTo(0, 0);
      return;
    }
    if (!showGeneralTerms && !showConsent && id === guests.length - 1) {
      setShowConsent(true);
      setActiveTab(0);
      setStep(step + 1);
      window.scrollTo(0, 0);
      return;
    }
    setActiveTab(id + 1);
    window.scrollTo(0, 0);
  };

  /**
   * @function previous
   * @description Handles the logic for moving to the previous guest or showing the consent or general terms.
   * @param {Event} e - The event triggered by the button click.
   * @param {number} id - The current guest index.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * previous(event, guestId);
   */
  const previous = (e, id) => {
    e.preventDefault();
    if (showGeneralTerms && !showConsent && id === 0) {
      setShowConsent(true);
      setShowGeneralTerms(false);
      setActiveTab(guests.length - 1);
      setStep(step - 1);
      window.scrollTo(0, 0);
      return;
    }
    if (!showGeneralTerms && showConsent && id === 0) {
      setShowConsent(false);
      setActiveTab(guests.length - 1);
      setStep(step - 1);
      window.scrollTo(0, 0);
      return;
    }
    setActiveTab(id - 1);
    window.scrollTo(0, 0);
  };

  const fetchHostTitles = async () =>{
    try {
      const result = await getHotelTitles(cultureCode, hotel.subscriptionKey)
      setTitles(result)
    } catch (error) {
      console.error("Error loading host titles:", error);
      toast.error(t("Error loading host titles"));
    } finally {
      setTitlesLoading(false)
    }

  }

  const handleElementEnter = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Se houver um hover ativo em outro elemento
    if (isHovered && isHovered !== id && currentRef) {
        handleMouseLeave(e, currentRef);
    }

    // Atualiza o estado para o novo elemento
    if(inspectMode){
    setCurrentRef(ref);
    handleMouseEnter(e, ref);
    setIsHovered(id);
    }
};


const handleElementLeave = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Apenas limpa o estado se for o elemento atual com hover
    if (isHovered === id) {
        handleMouseLeave(e, ref);
        setIsHovered(null);
        setCurrentRef(null);
    }
};

// Função auxiliar para limpar todos os estados
const clearAllHoverStates = () => {
    if (currentRef) {
        handleMouseLeave(null, currentRef);
    }
    setIsHovered(null);
    setCurrentRef(null);
};

  /**
   * @events useEffectSetGuests
   * @description Sets the guests state based on the reservation data.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, [reservation]);
   */

  useEffect(() => {
    if (!isPreviewMode) {
      if (!reservation || !detailId) return;
    }

    const isMatchingReservation = reservation?.DetailId === parseInt(detailId);
    const sourceReservation = isMatchingReservation
      ? JSON.parse(localStorage.getItem("localReservation"))
      : { detailId, billingType: 'sameAsGuest', reservation };

    const numberOfAdults = (sourceReservation?.reservation || reservation).Adults || 0;

    const createGuests = (sourceGuests, useLocalData) => {
      return sourceGuests.map((guestData, index) => ({
        id: index,
        title: guestData.IsMainGuest ? t(`HostFormComplete.mainguest`) : `${t(`HostFormComplete.guest`)} ${index + 1}`,
        data: {
          ...guestData,
          invoiceEntityVATNumber: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityVATNumber || guestData.FiscalNumber : guestData.FiscalNumber,
          invoiceEntityFirstName: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityFirstName || guestData.FirstName : guestData.FirstName,
          invoiceEntityLastName: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityLastName || guestData.LastName : guestData.LastName,
          invoiceEntityAddress1: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityAddress1 || guestData.Address1 : guestData.Address1,
          invoiceEntityTown: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityTown || guestData.Town : guestData.Town,
          invoiceEntityZipCode: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityZipCode || guestData.ZipCode : guestData.ZipCode,
          invoiceEntityCountryIso: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityCountryIso || guestData.CountryIso : guestData.CountryIso,
          invoiceEntityCountryId: useLocalData ? sourceReservation.reservation.Guests[index]?.invoiceEntityCountryId || guestData.CountryId : guestData.CountryId,
          billingType: useLocalData ? sourceReservation.reservation.Guests[index]?.billingType || "sameAsGuest" : "sameAsGuest"
        }
      }));
    };

    const sourceGuests = isMatchingReservation
      ? sourceReservation.reservation.Guests
      : reservation.Guests;

    const newGuests = createGuests(sourceGuests, isMatchingReservation);

    // Find the main guest from the existing guests
    const mainGuest = newGuests.find(guest => guest.data.IsMainGuest);

    if (newGuests.length < numberOfAdults && mainGuest) {
      // Fields that should be empty or different for additional guests
      const fieldsToReset = {
        GuestId: -1,
        GuestUid: "",
        IsMainGuest: false,
        GuestSignatureBase64: "",
        Email: "",
        Phone: "",
        Mobile: "",
        DocType: "",
        FillLater: false,
        Gender: "",
        IsChildren: false,
        NationalityId: null,
        TitleLong_Id: null,
        TitleShort_Id: null,
        Title_Id: null,
        NationalityIso: "",
        DocNumber: "",
        BirthDate: "",
        FirstName: "",
        LastName: "",
        SocialName: "",
        FiscalNumber: "",
        DocVal: "",
        Address1: "",
        Address2: "",
        Address3: "",
        AddressState:"",
        Town: "",
        ZipCode: "",
        CountryIso: "",
        CountryId: null,
        invoiceEntityVATNumber: "",
        invoiceEntityFirstName: "",
        invoiceEntityLastName: "",
        invoiceEntityAddress1: "",
        invoiceEntityTown: "",
        invoiceEntityZipCode:"",
        invoiceEntityCountryIso:"",
        invoiceEntityCountryId: null,
        billingType: "sameAsGuest"
      };

           // Create new guests based on main guest template
           for (let i = newGuests.length; i < numberOfAdults; i++) {
            // Copy and modify Consents from main guest
            const modifiedConsents = mainGuest.data.Consents?.map(consent => ({
              ...consent,
              GuestHasConsent: false
            })) || [];

            newGuests.push({
              id: i,
              title: `${t(`HostFormComplete.guest`)} ${i + 1}`,
              data: {
                ...mainGuest.data,  // Copy all data from main guest
                ...fieldsToReset,   // Reset specific fields
                ResDetailId: reservation.DetailId,
                Consents: modifiedConsents  // Add modified consents
              }
            });
          }
        }

    setGuests(newGuests);
    setLocalReservation(sourceReservation);

  }, [reservation, detailId]);

  useEffect(() => {
    if (localReservation && guests?.length > 0) {
      const updatedLocalReservation = {
        ...localReservation,
        reservation: {
          ...localReservation.reservation,
          Guests: guests.map(guest => guest.data)
        }
      };
      localStorage.setItem("localReservation", JSON.stringify(updatedLocalReservation));
    }
  }, [guests]);

  /**
   * @events useEffectUpdateReservation
   * @description Updates the reservation state with the guests' data when the guests change.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, []);
   */
  useEffect(() => {
    if (guests && guests.length>0 && guests.length!==localReservation?.reservation?.Guests?.length) {
      setLocalReservation((prevReservation) => ({
        ...prevReservation,
        reservation: {
          ...prevReservation.reservation,
          Guests: guests.map((guest) => guest.data),
        },
      }));
    }
  }, [reservation]); // Agora depende de guests

  /**
   * @events useEffectFetchHotel
   * @description Fetches initial hotel data when the hotel prop changes.
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   * @example
   * useEffect(() => {
   *   // Logic here
   * }, [hotel]);
   */
  useEffect(() => {
    if(isPreviewMode){
      setInitialData(previewHotelInitialData);
      setMilitaryMode(previewHotelInitialData?.Parameters?.MilitaryMode);
      return
    }

    setInitialData(state.hotelInitialData);
    setMilitaryMode(state.hotelInitialData?.Parameters?.MilitaryMode);
  }, [state.hotelInitialData]); // eslint-disable-line

  useEffect(() => {
    if (militaryMode) {
      fetchHostTitles();
    }
  }, [initialData]);

  useEffect(() => {
    setTotalSteps(guests.length * 2 + 1);
  }, [guests]);

  useEffect(() => {
    const fetchDefaultPreviewInitialData = async () => {
      try {
        const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
        dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
      } catch (error) {
        console.error("Error loading initialData-AllRoutes:", error);
      }
    }

    if (isPreviewMode) {
      fetchDefaultPreviewInitialData()
    }
  }, [defaultThemeId]); // eslint-disable-line

  if (successSubmit) {
    // Success component
    return (

      <div id="OC-General" ref={OCGeneralRef} onMouseOver={(e) => handleElementEnter(e, OCGeneralRef, "OC-General")} onMouseLeave={(e) => handleElementLeave(e, OCGeneralRef, "OC-General")} className={`${isPreviewMode && isHovered === "OC-General" ? "diagonal-stripes" : ""} relative h-full w-full flex flex-col`}> {/* Container relativo para posicionamento absoluto */}
          {/* Language Selector posicionado absolutamente */}
          {/* Language Selector posicionado absolutamente */}
          {isDirect && (
            <div className="bg-white absolute top-6 right-6 z-50 rounded-md">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="p-2 rounded-md hover:bg-gray-200">
                <HamburgerMenuIcon className="w-6 h-6" />
              </button>
            </div>
          )}
          {isMobileMenuOpen && (
            <div ref={menuRef} className="absolute top-16 right-6">
              <HeaderUserMenu isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
            </div>
          )}
        <div id="onlineCheckinForm" onSubmit={handleSubmit} className={`mx-auto OC-bg_color dark:gxp_dark_2 h-screen w-full flex flex-col `} >
        <div id="OC-Header" ref={OCHeaderRef} onMouseOver={(e) => handleElementEnter(e, OCHeaderRef, "OC-Header")} onMouseLeave={(e) => handleElementLeave(e, OCHeaderRef, "OC-Header")} className={`${isPreviewMode && isHovered === "OC-Header" ? "diagonal-stripes" : ""}`}>
          <HeadersPageOC title={`${t(`HostFormComplete.checkin`)}`} room={reservation?.room} militaryMode={militaryMode} guest={guests[0]?.data} post={post} />
        </div>
          <div className="text-center">
            <div className={` px-8 py-6 rounded-xl mx-auto h-full w-full`} >
              <div className="flex justify-between items-center">
                {" "}
                <h2 className="OC-title_color OC-title_size OC-title_variant OC-title_font_family mb-4 dark:text-white">{`${t( `HostFormComplete.success` )}`}</h2>{" "}
                <SquarePen onClick={handleBack} className="OC-Form-title_color cursor-pointer dark:text-white" />{" "}
              </div>

              <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-4 flex justify-between">
                { militaryMode && post?
                  <p> {`${t(`HostFormComplete.thankyou`)}`} {post.Description} {guests[0].data?.LastName || `${t(`HostFormComplete.guest`)}`} {`${t(`HostFormComplete.formsuccess`)}`} </p>
                :
                  <p> {`${t(`HostFormComplete.thankyou`)}`} {guests[0].data?.FirstName || `${t(`HostFormComplete.guest`)}`} {`${t(`HostFormComplete.formsuccess`)}`} </p>
                }
                { pathname.includes(`checkindirect`)?
                  <button disabled={isPreviewMode} onClick={() => navigate(-1)} className="">
                  <b>{`${t(`HostFormComplete.backpage`)}`}</b>{" "}
                  </button>
                :
                  <Link to={`/hotel/${hotel?.hotelId?.substring(0, 8)}/${reservation.DetailId}`}>
                  <b>{`${t(`HostFormComplete.backhotel`)}`}</b>{" "}
                  </Link>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
        <div id="OC-General" ref={OCGeneralRef} onMouseOver={(e) => handleElementEnter(e, OCGeneralRef, "OC-General")} onMouseLeave={(e) => handleElementLeave(e, OCGeneralRef, "OC-General")} className={`${isPreviewMode && isHovered === "OC-General" ? "diagonal-stripes" : ""} relative flex flex-col min-h-screen h-full w-full`}> {/* Container relativo para posicionamento absoluto */}
          {/* Language Selector posicionado absolutamente */}
          {isDirect && (
            <div className="bg-white absolute top-6 right-6 z-50 rounded-md">
              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="p-2 rounded-md hover:bg-gray-200">
                <HamburgerMenuIcon className="w-6 h-6" />
              </button>
            </div>
          )}
          {isMobileMenuOpen && (
            <div ref={menuRef} className="absolute top-16 right-6">
              <HeaderUserMenu isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
            </div>
          )}
          <form id="onlineCheckinForm" onSubmit={handleSubmit} className={`mx-auto OC-bg_color dark:gxp_dark_2 flex flex-col flex-grow w-full h-full`} >

            <div id="OC-Header" ref={OCHeaderRef} onMouseOver={(e) => handleElementEnter(e, OCHeaderRef, "OC-Header")} onMouseLeave={(e) => handleElementLeave(e, OCHeaderRef, "OC-Header")} className={`${isPreviewMode && isHovered === "OC-Header" ? "diagonal-stripes" : ""}`}>
              <HeadersPageOC title={`${t(`HostFormComplete.checkin`)}`} room={reservation?.room} militaryMode={militaryMode} guest={guests[0]?.data} post={post} />
            </div>
            <div id="OC-Stepper" ref={OCStepperRef} onMouseOver={(e) => handleElementEnter(e, OCStepperRef, "OC-Stepper")} onMouseLeave={(e) => handleElementLeave(e, OCStepperRef, "OC-Stepper")} className={`${isPreviewMode && isHovered === "OC-Stepper" ? "diagonal-stripes" : ""} flex flex-col justify-center`}>
              <Stepper step={step} totalSteps={totalSteps} />
            </div>
            {reservation && hotel && guests.length > 0 && state.activeTheme && step ? (
              <>
                {guests.map((guest, index) => (
                  <div key={guest.id} style={{ display: activeTab === guest.id ? "block" : "none" }} className={`md:px-10 w-full h-full`} >
                    {!showConsent && !showGeneralTerms && (
                      <HostForm localReservation={localReservation} setLocalReservation={setLocalReservation} OCButton1Ref={OCButton1Ref} OCFormFieldRef={OCFormFieldRef} isHovered={isHovered} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} isPreviewMode={isPreviewMode} titlesLoading={titlesLoading} setTitlesLoading={setTitlesLoading} activeTab={activeTab} setActiveTab={setActiveTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} isMain={index === 0} mainGuest={guests.findIndex((g) => g.data.IsMainGuest) === index} guest={guest} guests={guests} setGuests={setGuests} initialData={initialData} titles={titles.result} militaryMode={militaryMode} />
                    )}
                    {showConsent && !showGeneralTerms && (
                      <Consent OCButton1Ref={OCButton1Ref} OCButton2Ref={OCButton2Ref} isHovered={isHovered} isPreviewMode={isPreviewMode} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} guest={guest} guests={guests} setGuests={setGuests} titles={titles} militaryMode={militaryMode} />
                    )}
                    {showGeneralTerms && !showConsent && (
                      <GeneralTerms OCButton2Ref={OCButton2Ref} isHovered={isHovered} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} isPreviewMode={isPreviewMode} setCanSubmit={setCanSubmit} activeTab={activeTab} showGeneralTerms={showGeneralTerms} showConsent={showConsent} previous={previous} next={next} guests={guests} setGuests={setGuests} initialData={initialData} />
                    )}
                  </div>
                ))}
              </>
          )
          :
          (<div className="w-full h-full flex flex-col items-center"> <Loader className={`mt-20 mb-20 h-20 w-20 dark:text-white`} /> </div> )}

          <div className="flex justify-center w-full">
            <ArrivalTime isHovered={isHovered} isPreviewMode={isPreviewMode} OCArrivalTimeRef={OCArrivalTimeRef} handleElementEnter={handleElementEnter} handleElementLeave={handleElementLeave} guests={guests} setGuests={setGuests} />
          </div>



            {guests.length > 0 && reservation && showGeneralTerms && (
              <div className="flex justify-center">
                {isSending ? (
                  <button type="button" className={`w-auto px-6 py-3 mt-5 mb-6  ${ canSubmit ? "OC-Button1-bg_color" : "bg-gray-400 cursor-not-allowed" } OC-Button1-icon_color OC-Button1-border_color font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                    <Loader />
                  </button>
                ) : (
                <button id="OC-Button1" ref={OCButton1Ref} onMouseOver={(e) => handleElementEnter(e, OCButton1Ref, "OC-Button1")} onMouseLeave={(e) => handleElementLeave(e, OCButton1Ref, "OC-Button1")} onSubmit={(e) => handleSubmit(e)} className={`${isPreviewMode && isHovered === "OC-Button1" ? "diagonal-stripes" : ""} w-auto px-6 py-3 mt-5 mb-6  ${ canSubmit || isPreviewMode ? "OC-Button1-bg_color dark:bg-gxp_violet" : "bg-gray-400 cursor-not-allowed" } dark:text-white dark:border-white border OC-Button1-icon_color OC-Button1-border_color font-medium rounded-lg shadow-md transition duration-150 ease-in-out`} >
                  {`${t(`HostFormComplete.submit`)}`}
                </button>)}
              </div>
            )}
          </form>
        </div>
    </>
  );
};
