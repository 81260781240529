import onlineCheckInDesktop from "../../../../assets/images/onlineCheckInDesktop.jpeg";
import onlineCheckIn from "../../../../assets/images/onlineCheckIn.jpeg";
import hhsLogo from "../../../../assets/images/hhsLogo.png";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../context/AppContext";

export const HeadersPageTR = ({ service, room, guest }) => {
  const [t] = useTranslation("global");
  const [cookie] = useCookies(["guest"]);
  const [state,dispatch] = useContext(AppContext);
  const [imageUrl, setImageUrl] = useState("");
  const [logoUrl, setLogoUrl] = useState("");

  return (
    <section>
      <div className="sm:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-40" style={{ backgroundImage: `url(${service?.logoUrl})`}}>
            <div className="flex justify-center items-center h-full w-ful">
            </div>
            <div className="flex ml-6">
              <h1 className={`dark:text-white TR-Header-title_color TR-Header-title_size TR-Header-title_font_family TR-Header-title_variant mb-2 sm:mb-4`}> {" "} {service.Name}{" "} </h1>
            </div>
          </div>
            <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white TR-Header-text_color TR-Header-text_size TR-Header-text_font_family TR-Header-text_variant TR-Header-bar_bg_color TR-Header-bar_border_color TR-Header-bar_border_width TR-Header-bar_border_rounded TR-Header-bar_border_position w-full py-4 items-center flex justify-between">
              <h1 className="ml-6"> {" "} {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName} </h1>
              <span className="ml-6"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`} </span>
            </div>
      </div>

      <div className="max-sm:hidden lg:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-52" style={{ backgroundImage: `url(${service?.logoUrl})`}}>
            <div className="flex justify-center items-center h-full w-ful">
            </div>
            <div className="flex ml-6">
              <h1 className={`dark:text-white TR-Header-title_color TR-Header-title_size TR-Header-title_font_family TR-Header-title_variant mb-2 sm:mb-4`}> {" "} {service.Name}{" "} </h1>
            </div>
          </div>
            <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white TR-Header-text_color TR-Header-text_size TR-Header-text_font_family TR-Header-text_variant TR-Header-bar_bg_color TR-Header-bar_border_color TR-Header-bar_border_width TR-Header-bar_border_rounded TR-Header-bar_border_position w-full py-4 items-center flex justify-between">
              <h1 className="ml-6"> {" "} {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName} </h1>
              <span className="ml-6"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`}</span>
            </div>
      </div>

      <div className="max-lg:hidden">
          <div className="flex-col text-white flex justify-between bg-cover h-52" style={{ backgroundImage: `url(${service?.logoUrl})`}}>
            <div className="flex justify-center items-center h-full w-ful">
            </div>
            <div className="flex ml-6">
              <h1 className={`dark:text-white TR-Header-title_color TR-Header-title_size font-bold TR-Header-title_font_family TR-Header-title_variant mb-2 sm:mb-4`} > {" "} {service.Name}{" "} </h1>
            </div>
          </div>
            <div className="dark:bg-gxp_dark_1 dark:border-white dark:text-white TR-Header-text_color TR-Header-text_size TR-Header-text_font_family TR-Header-text_variant TR-Header-bar_bg_color TR-Header-bar_border_color TR-Header-bar_border_width TR-Header-bar_border_rounded TR-Header-bar_border_position w-full py-4 items-center flex justify-between">
              <h1 className="ml-6"> {" "} {t("Checkout.welcome")} {guest?.FirstName} {guest?.LastName} </h1>
              <span className="ml-6"> { room==="" || room===undefined|| room===null ? "" :`${t("Checkout.room")} ${room}`} </span>
            </div>
      </div>
    </section>
  );
};
