import React, { useState, useEffect } from 'react';
import { X } from 'lucide-react';

export const PasswordValidation = ({ password, onValidationChange, showValidation }) => {
  const [validations, setValidations] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false
  });

  useEffect(() => {

    if (password === '**********') {
      setValidations({
        length: true,
        uppercase: true,
        lowercase: true,
        number: true,
        special: true
      });
      onValidationChange(true);
      return;
    }

    const newValidations = {
      length: password.length >= 8,
      uppercase: /[A-Z]/.test(password),
      lowercase: /[a-z]/.test(password),
      number: /[0-9]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password)
    };

    setValidations(newValidations);
    onValidationChange(Object.values(newValidations).every(v => v));
  }, [password]);

  const ValidationItem = ({ text }) => (
    <div className="flex items-center gap-2 text-[10px]">
      <X className="w-[10px] h-[10px] text-red-500" />
      <span className="text-red-500">{text}</span>
    </div>
  );

  if (!showValidation) return null;

  const failedValidations = {
    length: !validations.length && "Minimum 8 characters",
    uppercase: !validations.uppercase && "At least one uppercase letter",
    lowercase: !validations.lowercase && "At least one lowercase letter",
    number: !validations.number && "At least one number",
    special: !validations.special && "At least one special character"
  };

  const failedItems = Object.values(failedValidations).filter(Boolean);

  if (failedItems.length === 0) return null;

  return (
    <div className="mt-1 space-y-1">
      {failedItems.map((text, index) => (
        <ValidationItem key={index} text={text} />
      ))}
    </div>
  );
};