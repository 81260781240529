export const previewOutlet = {
    "outletInfo": [
        {
            "Id": 4,
            "Code": "2",
            "Name": "My Modern Outlet",
            "Seats": 65,
            "logoUrl": "https://dev.hostpms.com/img/3ec01884-06b3-40e4-948f-bbdf08851452.jpg?conn=GXP_EPIC_LISBOA_1020",
            "schedules": [
                {
                    "Id": 11901,
                    "SectorId": 4,
                    "Day": 0,
                    "FromHour": "1900-01-01T12:30:00.000",
                    "ToHour": "1900-01-01T15:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11902,
                    "SectorId": 4,
                    "Day": 0,
                    "FromHour": "1900-01-01T19:00:00.000",
                    "ToHour": "1900-01-01T23:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11903,
                    "SectorId": 4,
                    "Day": 1,
                    "FromHour": "1900-01-01T12:30:00.000",
                    "ToHour": "1900-01-01T15:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11904,
                    "SectorId": 4,
                    "Day": 1,
                    "FromHour": "1900-01-01T19:00:00.000",
                    "ToHour": "1900-01-01T23:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11905,
                    "SectorId": 4,
                    "Day": 2,
                    "FromHour": "1900-01-01T12:30:00.000",
                    "ToHour": "1900-01-01T15:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11906,
                    "SectorId": 4,
                    "Day": 2,
                    "FromHour": "1900-01-01T19:00:00.000",
                    "ToHour": "1900-01-01T23:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11907,
                    "SectorId": 4,
                    "Day": 3,
                    "FromHour": "1900-01-01T12:30:00.000",
                    "ToHour": "1900-01-01T15:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11908,
                    "SectorId": 4,
                    "Day": 3,
                    "FromHour": "1900-01-01T19:00:00.000",
                    "ToHour": "1900-01-01T23:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11909,
                    "SectorId": 4,
                    "Day": 4,
                    "FromHour": "1900-01-01T12:30:00.000",
                    "ToHour": "1900-01-01T15:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11910,
                    "SectorId": 4,
                    "Day": 4,
                    "FromHour": "1900-01-01T19:00:00.000",
                    "ToHour": "1900-01-01T23:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11911,
                    "SectorId": 4,
                    "Day": 5,
                    "FromHour": "1900-01-01T12:30:00.000",
                    "ToHour": "1900-01-01T15:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11912,
                    "SectorId": 4,
                    "Day": 5,
                    "FromHour": "1900-01-01T19:00:00.000",
                    "ToHour": "1900-01-01T23:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11913,
                    "SectorId": 4,
                    "Day": 6,
                    "FromHour": "1900-01-01T12:30:00.000",
                    "ToHour": "1900-01-01T15:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                },
                {
                    "Id": 11914,
                    "SectorId": 4,
                    "Day": 6,
                    "FromHour": "1900-01-01T19:00:00.000",
                    "ToHour": "1900-01-01T23:00:00.000",
                    "MenuId": 0,
                    "MenuDetailId": 0,
                    "ForInfoOnly": false
                }
            ],
            "scheduleExceptions": [],
            "duration": 90
        }
    ],
    "daysInCalendar": 120,
    "mainDialCodes": [
        "PRT",
        "GBR",
        "DEU",
        "ESP",
        "FRA",
        "USA"
    ],
    "countries": [
        {
            "Id": 33,
            "Code": "AD",
            "Description": "Andorra",
            "IsoCode": "AD",
            "IsoCode3Letter": "AND",
            "IsoCode3LetterDocumentReader": "AND",
            "PhoneDialCode": "+376",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 83,
            "Code": "AE",
            "Description": "Emiratos Árabes Unidos",
            "IsoCode": "AE",
            "IsoCode3Letter": "ARE",
            "IsoCode3LetterDocumentReader": "ARE",
            "PhoneDialCode": "+971",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 27,
            "Code": "AF",
            "Description": "Afeganistão",
            "IsoCode": "AF",
            "IsoCode3Letter": "AFG",
            "IsoCode3LetterDocumentReader": "AFG",
            "PhoneDialCode": "+93",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 37,
            "Code": "AG",
            "Description": "Antígua e Barbuda",
            "IsoCode": "AG",
            "IsoCode3Letter": "ATG",
            "IsoCode3LetterDocumentReader": "ATG",
            "PhoneDialCode": "+1268",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 35,
            "Code": "AI",
            "Description": "Anguila",
            "IsoCode": "AI",
            "IsoCode3Letter": "AIA",
            "IsoCode3LetterDocumentReader": "AIA",
            "PhoneDialCode": "+1264",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 31,
            "Code": "AL",
            "Description": "Albânia",
            "IsoCode": "AL",
            "IsoCode3Letter": "ALB",
            "IsoCode3LetterDocumentReader": "ALB",
            "PhoneDialCode": "+355",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 41,
            "Code": "AM",
            "Description": "Arménia",
            "IsoCode": "AM",
            "IsoCode3Letter": "ARM",
            "IsoCode3LetterDocumentReader": "ARM",
            "PhoneDialCode": "+374",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 38,
            "Code": "AN",
            "Description": "Antilhas holandesas",
            "IsoCode": "AN",
            "IsoCode3Letter": "ANT",
            "IsoCode3LetterDocumentReader": "ANT",
            "PhoneDialCode": null,
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 34,
            "Code": "AO",
            "Description": "Angola",
            "IsoCode": "AO",
            "IsoCode3Letter": "AGO",
            "IsoCode3LetterDocumentReader": "AGO",
            "PhoneDialCode": "+244",
            "FiscalZone": "OU",
            "DefaultCultureCode": "pt-PT"
        },
        {
            "Id": 36,
            "Code": "AQ",
            "Description": "Antárctica",
            "IsoCode": "AQ",
            "IsoCode3Letter": "ATA",
            "IsoCode3LetterDocumentReader": "ATA",
            "PhoneDialCode": "+672",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 40,
            "Code": "AR",
            "Description": "Argentina",
            "IsoCode": "AR",
            "IsoCode3Letter": "ARG",
            "IsoCode3LetterDocumentReader": "ARG",
            "PhoneDialCode": "+54",
            "FiscalZone": "OU",
            "DefaultCultureCode": "es-ES"
        },
        {
            "Id": 197,
            "Code": "AS",
            "Description": "Samoa Americana",
            "IsoCode": "AS",
            "IsoCode3Letter": "ASM",
            "IsoCode3LetterDocumentReader": "ASM",
            "PhoneDialCode": "+1684",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 1,
            "Code": "AT",
            "Description": "Áustria",
            "IsoCode": "AT",
            "IsoCode3Letter": "AUT",
            "IsoCode3LetterDocumentReader": "AUT",
            "PhoneDialCode": "+43",
            "FiscalZone": "EU",
            "DefaultCultureCode": "de-DE"
        },
        {
            "Id": 43,
            "Code": "AU",
            "Description": "Austrália",
            "IsoCode": "AU",
            "IsoCode3Letter": "AUS",
            "IsoCode3LetterDocumentReader": "AUS",
            "PhoneDialCode": "+61",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 42,
            "Code": "AW",
            "Description": "Aruba",
            "IsoCode": "AW",
            "IsoCode3Letter": "ABW",
            "IsoCode3LetterDocumentReader": "ABW",
            "PhoneDialCode": "+297",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 247,
            "Code": "AX",
            "Description": "Ilhas Aland",
            "IsoCode": "AX",
            "IsoCode3Letter": "ALA",
            "IsoCode3LetterDocumentReader": "ALA",
            "PhoneDialCode": "+358",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 44,
            "Code": "AZ",
            "Description": "Azerbaijão",
            "IsoCode": "AZ",
            "IsoCode3Letter": "AZE",
            "IsoCode3LetterDocumentReader": "AZE",
            "PhoneDialCode": "+994",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 54,
            "Code": "BA",
            "Description": "Bósnia e Herzegovina",
            "IsoCode": "BA",
            "IsoCode3Letter": "BIH",
            "IsoCode3LetterDocumentReader": "BIH",
            "PhoneDialCode": "+387",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 47,
            "Code": "BB",
            "Description": "Barbados",
            "IsoCode": "BB",
            "IsoCode3Letter": "BRB",
            "IsoCode3LetterDocumentReader": "BRB",
            "PhoneDialCode": "+1246",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 46,
            "Code": "BD",
            "Description": "Bangladesh",
            "IsoCode": "BD",
            "IsoCode3Letter": "BGD",
            "IsoCode3LetterDocumentReader": "BGD",
            "PhoneDialCode": "+880",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 9,
            "Code": "BE",
            "Description": "Bélgica",
            "IsoCode": "BE",
            "IsoCode3Letter": "BEL",
            "IsoCode3LetterDocumentReader": "BEL",
            "PhoneDialCode": "+32",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 58,
            "Code": "BF",
            "Description": "Burkina Faso",
            "IsoCode": "BF",
            "IsoCode3Letter": "BFA",
            "IsoCode3LetterDocumentReader": "BFA",
            "PhoneDialCode": "+226",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 57,
            "Code": "BG",
            "Description": "Bulgária",
            "IsoCode": "BG",
            "IsoCode3Letter": "BGR",
            "IsoCode3LetterDocumentReader": "BGR",
            "PhoneDialCode": "+359",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 48,
            "Code": "BH",
            "Description": "Barém",
            "IsoCode": "BH",
            "IsoCode3Letter": "BHR",
            "IsoCode3LetterDocumentReader": "BHR",
            "PhoneDialCode": "+973",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 59,
            "Code": "BI",
            "Description": "Burundi",
            "IsoCode": "BI",
            "IsoCode3Letter": "BDI",
            "IsoCode3LetterDocumentReader": "BDI",
            "PhoneDialCode": "+257",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 50,
            "Code": "BJ",
            "Description": "Benin",
            "IsoCode": "BJ",
            "IsoCode3Letter": "BEN",
            "IsoCode3LetterDocumentReader": "BEN",
            "PhoneDialCode": "+229",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 252,
            "Code": "BL",
            "Description": "São Bartolomeu",
            "IsoCode": "BL",
            "IsoCode3Letter": "BLM",
            "IsoCode3LetterDocumentReader": "BLM",
            "PhoneDialCode": "+590",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 51,
            "Code": "BM",
            "Description": "Bermuda",
            "IsoCode": "BM",
            "IsoCode3Letter": "BMU",
            "IsoCode3LetterDocumentReader": "BMU",
            "PhoneDialCode": "+1441",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 56,
            "Code": "BN",
            "Description": "Brunei Darussalam",
            "IsoCode": "BN",
            "IsoCode3Letter": "BRN",
            "IsoCode3LetterDocumentReader": "BRN",
            "PhoneDialCode": "+673",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 53,
            "Code": "BO",
            "Description": "Bolívia",
            "IsoCode": "BO",
            "IsoCode3Letter": "BOL",
            "IsoCode3LetterDocumentReader": "BOL",
            "PhoneDialCode": "+591",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 254,
            "Code": "BQ",
            "Description": "Bonaire, Saba e Santo Eustáquio",
            "IsoCode": "BQ",
            "IsoCode3Letter": "BES",
            "IsoCode3LetterDocumentReader": "BES",
            "PhoneDialCode": "+599",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 10,
            "Code": "BR",
            "Description": "Brasil",
            "IsoCode": "BR",
            "IsoCode3Letter": "BRA",
            "IsoCode3LetterDocumentReader": "BRA",
            "PhoneDialCode": "+55",
            "FiscalZone": "OU",
            "DefaultCultureCode": "pt-PT"
        },
        {
            "Id": 45,
            "Code": "BS",
            "Description": "Bahamas",
            "IsoCode": "BS",
            "IsoCode3Letter": "BHS",
            "IsoCode3LetterDocumentReader": "BHS",
            "PhoneDialCode": "+1242",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 60,
            "Code": "BT",
            "Description": "Butão",
            "IsoCode": "BT",
            "IsoCode3Letter": "BTN",
            "IsoCode3LetterDocumentReader": "BTN",
            "PhoneDialCode": "+975",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 113,
            "Code": "BV",
            "Description": "Ilhas Bouvet",
            "IsoCode": "BV",
            "IsoCode3Letter": "BVT",
            "IsoCode3LetterDocumentReader": "BVT",
            "PhoneDialCode": "+47",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 55,
            "Code": "BW",
            "Description": "Botswana",
            "IsoCode": "BW",
            "IsoCode3Letter": "BWA",
            "IsoCode3LetterDocumentReader": "BWA",
            "PhoneDialCode": "+267",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 52,
            "Code": "BY",
            "Description": "Bielorrússia",
            "IsoCode": "BY",
            "IsoCode3Letter": "BLR",
            "IsoCode3LetterDocumentReader": "BLR",
            "PhoneDialCode": "+375",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 49,
            "Code": "BZ",
            "Description": "Belize",
            "IsoCode": "BZ",
            "IsoCode3Letter": "BLZ",
            "IsoCode3LetterDocumentReader": "BLZ",
            "PhoneDialCode": "+501",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 11,
            "Code": "CA",
            "Description": "Canadá",
            "IsoCode": "CA",
            "IsoCode3Letter": "CAN",
            "IsoCode3LetterDocumentReader": "CAN",
            "PhoneDialCode": "+1",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 116,
            "Code": "CC",
            "Description": "Ilhas Cocos (Keeling)",
            "IsoCode": "CC",
            "IsoCode3Letter": "CCK",
            "IsoCode3LetterDocumentReader": "CCK",
            "PhoneDialCode": "+61",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 73,
            "Code": "CD",
            "Description": "Congo (República Democrática do)",
            "IsoCode": "CD",
            "IsoCode3Letter": "COD",
            "IsoCode3LetterDocumentReader": "COD",
            "PhoneDialCode": "+243",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 66,
            "Code": "CF",
            "Description": "Centro-Africana (República)",
            "IsoCode": "CF",
            "IsoCode3Letter": "CAF",
            "IsoCode3LetterDocumentReader": "CAF",
            "PhoneDialCode": "+236",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 72,
            "Code": "CG",
            "Description": "Congo",
            "IsoCode": "CG",
            "IsoCode3Letter": "COG",
            "IsoCode3LetterDocumentReader": "COG",
            "PhoneDialCode": "+242",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 19,
            "Code": "CH",
            "Description": "Suíça",
            "IsoCode": "CH",
            "IsoCode3Letter": "CHE",
            "IsoCode3LetterDocumentReader": "CHE",
            "PhoneDialCode": "+41",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 76,
            "Code": "CI",
            "Description": "Costa do Marfim",
            "IsoCode": "CI",
            "IsoCode3Letter": "CIV",
            "IsoCode3LetterDocumentReader": "CIV",
            "PhoneDialCode": "+225",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 117,
            "Code": "CK",
            "Description": "Ilhas Cook",
            "IsoCode": "CK",
            "IsoCode3Letter": "COK",
            "IsoCode3LetterDocumentReader": "COK",
            "PhoneDialCode": "+682",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 68,
            "Code": "CL",
            "Description": "Chile",
            "IsoCode": "CL",
            "IsoCode3Letter": "CHL",
            "IsoCode3LetterDocumentReader": "CHL",
            "PhoneDialCode": "+56",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 62,
            "Code": "CM",
            "Description": "Camarões",
            "IsoCode": "CM",
            "IsoCode3Letter": "CMR",
            "IsoCode3LetterDocumentReader": "CMR",
            "PhoneDialCode": "+237",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 25,
            "Code": "CN",
            "Description": "China",
            "IsoCode": "CN",
            "IsoCode3Letter": "CHN",
            "IsoCode3LetterDocumentReader": "CHN",
            "PhoneDialCode": "+86",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 70,
            "Code": "CO",
            "Description": "Colômbia",
            "IsoCode": "CO",
            "IsoCode3Letter": "COL",
            "IsoCode3LetterDocumentReader": "COL",
            "PhoneDialCode": "+57",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 77,
            "Code": "CR",
            "Description": "Costa Rica",
            "IsoCode": "CR",
            "IsoCode3Letter": "CRI",
            "IsoCode3LetterDocumentReader": "CRI",
            "PhoneDialCode": "+506",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 79,
            "Code": "CU",
            "Description": "Cuba",
            "IsoCode": "CU",
            "IsoCode3Letter": "CUB",
            "IsoCode3LetterDocumentReader": "CUB",
            "PhoneDialCode": "+53",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 61,
            "Code": "CV",
            "Description": "Cabo Verde",
            "IsoCode": "CV",
            "IsoCode3Letter": "CPV",
            "IsoCode3LetterDocumentReader": "CPV",
            "PhoneDialCode": "+238",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 255,
            "Code": "CW",
            "Description": "Curaçau",
            "IsoCode": "CW",
            "IsoCode3Letter": "CUW",
            "IsoCode3LetterDocumentReader": "CUW",
            "PhoneDialCode": "+5999",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 115,
            "Code": "CX",
            "Description": "Ilhas Christmas",
            "IsoCode": "CX",
            "IsoCode3Letter": "CXR",
            "IsoCode3LetterDocumentReader": "CXR",
            "PhoneDialCode": "+61",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 69,
            "Code": "CY",
            "Description": "Chipre",
            "IsoCode": "CY",
            "IsoCode3Letter": "CYP",
            "IsoCode3LetterDocumentReader": "CYP",
            "PhoneDialCode": "+357",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 191,
            "Code": "CZ",
            "Description": "República Checa",
            "IsoCode": "CZ",
            "IsoCode3Letter": "CZE",
            "IsoCode3LetterDocumentReader": "CZE",
            "PhoneDialCode": "+420",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 8,
            "Code": "DE",
            "Description": "Alemanha",
            "IsoCode": "DE",
            "IsoCode3Letter": "DEU",
            "IsoCode3LetterDocumentReader": "DEU",
            "PhoneDialCode": "+49",
            "FiscalZone": "EU",
            "DefaultCultureCode": "de-DE"
        },
        {
            "Id": 136,
            "Code": "DJ",
            "Description": "Jibuti",
            "IsoCode": "DJ",
            "IsoCode3Letter": "DJI",
            "IsoCode3LetterDocumentReader": "DJI",
            "PhoneDialCode": "+253",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 16,
            "Code": "DK",
            "Description": "Dinamarca",
            "IsoCode": "DK",
            "IsoCode3Letter": "DNK",
            "IsoCode3LetterDocumentReader": "DNK",
            "PhoneDialCode": "+45",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 80,
            "Code": "DM",
            "Description": "Domínica",
            "IsoCode": "DM",
            "IsoCode3Letter": "DMA",
            "IsoCode3LetterDocumentReader": "DMA",
            "PhoneDialCode": "+1767",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 192,
            "Code": "DO",
            "Description": "República Dominicana",
            "IsoCode": "DO",
            "IsoCode3Letter": "DOM",
            "IsoCode3LetterDocumentReader": "DOM",
            "PhoneDialCode": "+1",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 32,
            "Code": "DZ",
            "Description": "Argélia",
            "IsoCode": "DZ",
            "IsoCode3Letter": "DZA",
            "IsoCode3LetterDocumentReader": "DZA",
            "PhoneDialCode": "+213",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 84,
            "Code": "EC",
            "Description": "Equador",
            "IsoCode": "EC",
            "IsoCode3Letter": "ECU",
            "IsoCode3LetterDocumentReader": "ECU",
            "PhoneDialCode": "+593",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 88,
            "Code": "EE",
            "Description": "Estónia",
            "IsoCode": "EE",
            "IsoCode3Letter": "EST",
            "IsoCode3LetterDocumentReader": "EST",
            "PhoneDialCode": "+372",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 81,
            "Code": "EG",
            "Description": "Egipto",
            "IsoCode": "EG",
            "IsoCode3Letter": "EGY",
            "IsoCode3LetterDocumentReader": "EGY",
            "PhoneDialCode": "+20",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 206,
            "Code": "EH",
            "Description": "Sara Ocidental",
            "IsoCode": "EH",
            "IsoCode3Letter": "ESH",
            "IsoCode3LetterDocumentReader": "ESH",
            "PhoneDialCode": "+212",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 85,
            "Code": "ER",
            "Description": "Eritreia",
            "IsoCode": "ER",
            "IsoCode3Letter": "ERI",
            "IsoCode3LetterDocumentReader": "ERI",
            "PhoneDialCode": "+291",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 7,
            "Code": "ES",
            "Description": "Espanha",
            "IsoCode": "ES",
            "IsoCode3Letter": "ESP",
            "IsoCode3LetterDocumentReader": "ESP",
            "PhoneDialCode": "+34",
            "FiscalZone": "EU",
            "DefaultCultureCode": "es-ES"
        },
        {
            "Id": 89,
            "Code": "ET",
            "Description": "Etiópia",
            "IsoCode": "ET",
            "IsoCode3Letter": "ETH",
            "IsoCode3LetterDocumentReader": "ETH",
            "PhoneDialCode": "+251",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 91,
            "Code": "FI",
            "Description": "Finlândia",
            "IsoCode": "FI",
            "IsoCode3Letter": "FIN",
            "IsoCode3LetterDocumentReader": "FIN",
            "PhoneDialCode": "+358",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 120,
            "Code": "FJ",
            "Description": "Ilhas Fiji",
            "IsoCode": "FJ",
            "IsoCode3Letter": "FJI",
            "IsoCode3LetterDocumentReader": "FJI",
            "PhoneDialCode": "+679",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 118,
            "Code": "FK",
            "Description": "Ilhas Falkland (Malvinas)",
            "IsoCode": "FK",
            "IsoCode3Letter": "FLK",
            "IsoCode3LetterDocumentReader": "FLK",
            "PhoneDialCode": "+500",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 164,
            "Code": "FM",
            "Description": "Micronésia (Estados Federados da)",
            "IsoCode": "FM",
            "IsoCode3Letter": "FSM",
            "IsoCode3LetterDocumentReader": "FSM",
            "PhoneDialCode": "+691",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 119,
            "Code": "FO",
            "Description": "Ilhas Faroé",
            "IsoCode": "FO",
            "IsoCode3Letter": "FRO",
            "IsoCode3LetterDocumentReader": "FRO",
            "PhoneDialCode": "+298",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 13,
            "Code": "FR",
            "Description": "França",
            "IsoCode": "FR",
            "IsoCode3Letter": "FRA",
            "IsoCode3LetterDocumentReader": "FRA",
            "PhoneDialCode": "+33",
            "FiscalZone": "EU",
            "DefaultCultureCode": "fr-FR"
        },
        {
            "Id": 92,
            "Code": "GA",
            "Description": "Gabão",
            "IsoCode": "GA",
            "IsoCode3Letter": "GAB",
            "IsoCode3LetterDocumentReader": "GAB",
            "PhoneDialCode": "+241",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 6,
            "Code": "GB",
            "Description": "Reino Unido",
            "IsoCode": "GB",
            "IsoCode3Letter": "GBR",
            "IsoCode3LetterDocumentReader": "GBR",
            "PhoneDialCode": "+44",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 98,
            "Code": "GD",
            "Description": "Granada",
            "IsoCode": "GD",
            "IsoCode3Letter": "GRD",
            "IsoCode3LetterDocumentReader": "GRD",
            "PhoneDialCode": "+1473",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 95,
            "Code": "GE",
            "Description": "Geórgia",
            "IsoCode": "GE",
            "IsoCode3Letter": "GEO",
            "IsoCode3LetterDocumentReader": "GEO",
            "PhoneDialCode": "+995",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 104,
            "Code": "GF",
            "Description": "Guiana Francesa",
            "IsoCode": "GF",
            "IsoCode3Letter": "GUF",
            "IsoCode3LetterDocumentReader": "GUF",
            "PhoneDialCode": "+594",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 248,
            "Code": "GG",
            "Description": "Guernsey",
            "IsoCode": "GG",
            "IsoCode3Letter": "GGY",
            "IsoCode3LetterDocumentReader": "GGY",
            "PhoneDialCode": "+44",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 94,
            "Code": "GH",
            "Description": "Gana",
            "IsoCode": "GH",
            "IsoCode3Letter": "GHA",
            "IsoCode3LetterDocumentReader": "GHA",
            "PhoneDialCode": "+233",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 97,
            "Code": "GI",
            "Description": "Gibraltar",
            "IsoCode": "GI",
            "IsoCode3Letter": "GIB",
            "IsoCode3LetterDocumentReader": "GIB",
            "PhoneDialCode": "+350",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 99,
            "Code": "GL",
            "Description": "Gronelândia",
            "IsoCode": "GL",
            "IsoCode3Letter": "GRL",
            "IsoCode3LetterDocumentReader": "GRL",
            "PhoneDialCode": "+299",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 93,
            "Code": "GM",
            "Description": "Gâmbia",
            "IsoCode": "GM",
            "IsoCode3Letter": "GMB",
            "IsoCode3LetterDocumentReader": "GMB",
            "PhoneDialCode": "+220",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 105,
            "Code": "GN",
            "Description": "Guiné",
            "IsoCode": "GN",
            "IsoCode3Letter": "GIN",
            "IsoCode3LetterDocumentReader": "GIN",
            "PhoneDialCode": "+224",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 100,
            "Code": "GP",
            "Description": "Guadalupe",
            "IsoCode": "GP",
            "IsoCode3Letter": "GLP",
            "IsoCode3LetterDocumentReader": "GLP",
            "PhoneDialCode": "+590",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 106,
            "Code": "GQ",
            "Description": "Guiné Equatorial",
            "IsoCode": "GQ",
            "IsoCode3Letter": "GNQ",
            "IsoCode3LetterDocumentReader": "GNQ",
            "PhoneDialCode": "+240",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 24,
            "Code": "GR",
            "Description": "Grécia",
            "IsoCode": "GR",
            "IsoCode3Letter": "GRC",
            "IsoCode3LetterDocumentReader": "GRC",
            "PhoneDialCode": "+30",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 96,
            "Code": "GS",
            "Description": "Georgia do Sul e Ilhas Sandwich",
            "IsoCode": "GS",
            "IsoCode3Letter": "SGS",
            "IsoCode3LetterDocumentReader": "SGS",
            "PhoneDialCode": "+500",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 102,
            "Code": "GT",
            "Description": "Guatemala",
            "IsoCode": "GT",
            "IsoCode3Letter": "GTM",
            "IsoCode3LetterDocumentReader": "GTM",
            "PhoneDialCode": "+502",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 101,
            "Code": "GU",
            "Description": "Guam",
            "IsoCode": "GU",
            "IsoCode3Letter": "GUM",
            "IsoCode3LetterDocumentReader": "GUM",
            "PhoneDialCode": "+1671",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 107,
            "Code": "GW",
            "Description": "Guiné-Bissau",
            "IsoCode": "GW",
            "IsoCode3Letter": "GNB",
            "IsoCode3LetterDocumentReader": "GNB",
            "PhoneDialCode": "+245",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 103,
            "Code": "GY",
            "Description": "Guiana",
            "IsoCode": "GY",
            "IsoCode3Letter": "GUY",
            "IsoCode3LetterDocumentReader": "GUY",
            "PhoneDialCode": "+592",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 110,
            "Code": "HK",
            "Description": "Hong Kong",
            "IsoCode": "HK",
            "IsoCode3Letter": "HKG",
            "IsoCode3LetterDocumentReader": "HKG",
            "PhoneDialCode": "+852",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 121,
            "Code": "HM",
            "Description": "Ilhas Heard e Ilhas McDonald",
            "IsoCode": "HM",
            "IsoCode3Letter": "HMD",
            "IsoCode3LetterDocumentReader": "HMD",
            "PhoneDialCode": "+672",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 109,
            "Code": "HN",
            "Description": "Honduras",
            "IsoCode": "HN",
            "IsoCode3Letter": "HND",
            "IsoCode3LetterDocumentReader": "HND",
            "PhoneDialCode": "+504",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 78,
            "Code": "HR",
            "Description": "Croácia",
            "IsoCode": "HR",
            "IsoCode3Letter": "HRV",
            "IsoCode3LetterDocumentReader": "HRV",
            "PhoneDialCode": "+385",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 108,
            "Code": "HT",
            "Description": "Haiti",
            "IsoCode": "HT",
            "IsoCode3Letter": "HTI",
            "IsoCode3LetterDocumentReader": "HTI",
            "PhoneDialCode": "+509",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 111,
            "Code": "HU",
            "Description": "Hungria",
            "IsoCode": "HU",
            "IsoCode3Letter": "HUN",
            "IsoCode3LetterDocumentReader": "HUN",
            "PhoneDialCode": "+36",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 130,
            "Code": "ID",
            "Description": "Indonésia",
            "IsoCode": "ID",
            "IsoCode3Letter": "IDN",
            "IsoCode3LetterDocumentReader": "IDN",
            "PhoneDialCode": "+62",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 15,
            "Code": "IE",
            "Description": "Irlanda",
            "IsoCode": "IE",
            "IsoCode3Letter": "IRL",
            "IsoCode3LetterDocumentReader": "IRL",
            "PhoneDialCode": "+353",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 134,
            "Code": "IL",
            "Description": "Israel",
            "IsoCode": "IL",
            "IsoCode3Letter": "ISR",
            "IsoCode3LetterDocumentReader": "ISR",
            "PhoneDialCode": "+972",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 249,
            "Code": "IM",
            "Description": "Ilha de Man",
            "IsoCode": "IM",
            "IsoCode3Letter": "IMN",
            "IsoCode3LetterDocumentReader": "IMN",
            "PhoneDialCode": "+44",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 129,
            "Code": "IN",
            "Description": "Índia",
            "IsoCode": "IN",
            "IsoCode3Letter": "IND",
            "IsoCode3LetterDocumentReader": "IND",
            "PhoneDialCode": "+91",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 222,
            "Code": "IO",
            "Description": "Território Britânico do Oceano Índico",
            "IsoCode": "IO",
            "IsoCode3Letter": "IOT",
            "IsoCode3LetterDocumentReader": "IOT",
            "PhoneDialCode": "+246",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 132,
            "Code": "IQ",
            "Description": "Iraque",
            "IsoCode": "IQ",
            "IsoCode3Letter": "IRQ",
            "IsoCode3LetterDocumentReader": "IRQ",
            "PhoneDialCode": "+964",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 131,
            "Code": "IR",
            "Description": "Irão (República Islâmica)",
            "IsoCode": "IR",
            "IsoCode3Letter": "IRN",
            "IsoCode3LetterDocumentReader": "IRN",
            "PhoneDialCode": "+98",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 133,
            "Code": "IS",
            "Description": "Islândia",
            "IsoCode": "IS",
            "IsoCode3Letter": "ISL",
            "IsoCode3LetterDocumentReader": "ISL",
            "PhoneDialCode": "+354",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 18,
            "Code": "IT",
            "Description": "Itália",
            "IsoCode": "IT",
            "IsoCode3Letter": "ITA",
            "IsoCode3LetterDocumentReader": "ITA",
            "PhoneDialCode": "+39",
            "FiscalZone": "EU",
            "DefaultCultureCode": "it-IT"
        },
        {
            "Id": 250,
            "Code": "JE",
            "Description": "Jersey",
            "IsoCode": "JE",
            "IsoCode3Letter": "JEY",
            "IsoCode3LetterDocumentReader": "JEY",
            "PhoneDialCode": "+44",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 135,
            "Code": "JM",
            "Description": "Jamaica",
            "IsoCode": "JM",
            "IsoCode3Letter": "JAM",
            "IsoCode3LetterDocumentReader": "JAM",
            "PhoneDialCode": "+1876",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 137,
            "Code": "JO",
            "Description": "Jordânia",
            "IsoCode": "JO",
            "IsoCode3Letter": "JOR",
            "IsoCode3LetterDocumentReader": "JOR",
            "PhoneDialCode": "+962",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 23,
            "Code": "JP",
            "Description": "Japão",
            "IsoCode": "JP",
            "IsoCode3Letter": "JPN",
            "IsoCode3LetterDocumentReader": "JPN",
            "PhoneDialCode": "+81",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 139,
            "Code": "KE",
            "Description": "Kenya",
            "IsoCode": "KE",
            "IsoCode3Letter": "KEN",
            "IsoCode3LetterDocumentReader": "KEN",
            "PhoneDialCode": "+254",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 190,
            "Code": "KG",
            "Description": "Quirguizistão",
            "IsoCode": "KG",
            "IsoCode3Letter": "KGZ",
            "IsoCode3LetterDocumentReader": "KGZ",
            "PhoneDialCode": "+996",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 63,
            "Code": "KH",
            "Description": "Camboja",
            "IsoCode": "KH",
            "IsoCode3Letter": "KHM",
            "IsoCode3LetterDocumentReader": "KHM",
            "PhoneDialCode": "+855",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 140,
            "Code": "KI",
            "Description": "Kiribati",
            "IsoCode": "KI",
            "IsoCode3Letter": "KIR",
            "IsoCode3LetterDocumentReader": "KIR",
            "PhoneDialCode": "+686",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 71,
            "Code": "KM",
            "Description": "Comores",
            "IsoCode": "KM",
            "IsoCode3Letter": "COM",
            "IsoCode3LetterDocumentReader": "COM",
            "PhoneDialCode": "+269",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 201,
            "Code": "KN",
            "Description": "São Cristóvão e Nevis",
            "IsoCode": "KN",
            "IsoCode3Letter": "KNA",
            "IsoCode3LetterDocumentReader": "KNA",
            "PhoneDialCode": "+1869",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 75,
            "Code": "KP",
            "Description": "Coreia (República Popular Democrática da)",
            "IsoCode": "KP",
            "IsoCode3Letter": "PRK",
            "IsoCode3LetterDocumentReader": "PRK",
            "PhoneDialCode": "+850",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 74,
            "Code": "KR",
            "Description": "Coreia (República da)",
            "IsoCode": "KR",
            "IsoCode3Letter": "KOR",
            "IsoCode3LetterDocumentReader": "KOR",
            "PhoneDialCode": "+82",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 141,
            "Code": "KW",
            "Description": "Kuwait",
            "IsoCode": "KW",
            "IsoCode3Letter": "KWT",
            "IsoCode3LetterDocumentReader": "KWT",
            "PhoneDialCode": "+965",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 114,
            "Code": "KY",
            "Description": "Ilhas Caimão",
            "IsoCode": "KY",
            "IsoCode3Letter": "CYM",
            "IsoCode3LetterDocumentReader": "CYM",
            "PhoneDialCode": "+1345",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 65,
            "Code": "KZ",
            "Description": "Cazaquistão",
            "IsoCode": "KZ",
            "IsoCode3Letter": "KAZ",
            "IsoCode3LetterDocumentReader": "KAZ",
            "PhoneDialCode": "+7",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 142,
            "Code": "LA",
            "Description": "Laos (Rep. Popular Democrática do)",
            "IsoCode": "LA",
            "IsoCode3Letter": "LAO",
            "IsoCode3LetterDocumentReader": "LAO",
            "PhoneDialCode": "+856",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 145,
            "Code": "LB",
            "Description": "Líbano",
            "IsoCode": "LB",
            "IsoCode3Letter": "LBN",
            "IsoCode3LetterDocumentReader": "LBN",
            "PhoneDialCode": "+961",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 199,
            "Code": "LC",
            "Description": "Santa Lúcia",
            "IsoCode": "LC",
            "IsoCode3Letter": "LCA",
            "IsoCode3LetterDocumentReader": "LCA",
            "PhoneDialCode": "+1758",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 148,
            "Code": "LI",
            "Description": "Liechtenstein",
            "IsoCode": "LI",
            "IsoCode3Letter": "LIE",
            "IsoCode3LetterDocumentReader": "LIE",
            "PhoneDialCode": "+423",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 213,
            "Code": "LK",
            "Description": "Sri Lanka",
            "IsoCode": "LK",
            "IsoCode3Letter": "LKA",
            "IsoCode3LetterDocumentReader": "LKA",
            "PhoneDialCode": "+94",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 146,
            "Code": "LR",
            "Description": "Libéria",
            "IsoCode": "LR",
            "IsoCode3Letter": "LBR",
            "IsoCode3LetterDocumentReader": "LBR",
            "PhoneDialCode": "+231",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 143,
            "Code": "LS",
            "Description": "Lesoto",
            "IsoCode": "LS",
            "IsoCode3Letter": "LSO",
            "IsoCode3LetterDocumentReader": "LSO",
            "PhoneDialCode": "+266",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 149,
            "Code": "LT",
            "Description": "Lituânia",
            "IsoCode": "LT",
            "IsoCode3Letter": "LTU",
            "IsoCode3LetterDocumentReader": "LTU",
            "PhoneDialCode": "+370",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 22,
            "Code": "LU",
            "Description": "Luxemburgo",
            "IsoCode": "LU",
            "IsoCode3Letter": "LUX",
            "IsoCode3LetterDocumentReader": "LUX",
            "PhoneDialCode": "+352",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 144,
            "Code": "LV",
            "Description": "Letónia",
            "IsoCode": "LV",
            "IsoCode3Letter": "LVA",
            "IsoCode3LetterDocumentReader": "LVA",
            "PhoneDialCode": "+371",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 147,
            "Code": "LY",
            "Description": "Líbia (Jamahiriya Árabe da)",
            "IsoCode": "LY",
            "IsoCode3Letter": "LBY",
            "IsoCode3LetterDocumentReader": "LBY",
            "PhoneDialCode": "+218",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 158,
            "Code": "MA",
            "Description": "Marrocos",
            "IsoCode": "MA",
            "IsoCode3Letter": "MAR",
            "IsoCode3LetterDocumentReader": "MAR",
            "PhoneDialCode": "+212",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 167,
            "Code": "MC",
            "Description": "Mónaco",
            "IsoCode": "MC",
            "IsoCode3Letter": "MCO",
            "IsoCode3LetterDocumentReader": "MCO",
            "PhoneDialCode": "+377",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 166,
            "Code": "MD",
            "Description": "Moldova (República de)",
            "IsoCode": "MD",
            "IsoCode3Letter": "MDA",
            "IsoCode3LetterDocumentReader": "MDA",
            "PhoneDialCode": "+373",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 245,
            "Code": "ME",
            "Description": "Montenegro",
            "IsoCode": "ME",
            "IsoCode3Letter": "MNE",
            "IsoCode3LetterDocumentReader": "MNE",
            "PhoneDialCode": "+382",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 251,
            "Code": "MF",
            "Description": "São Martinho (parte francesa)",
            "IsoCode": "MF",
            "IsoCode3Letter": "MAF",
            "IsoCode3LetterDocumentReader": "MAF",
            "PhoneDialCode": "+590",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 152,
            "Code": "MG",
            "Description": "Madagáscar",
            "IsoCode": "MG",
            "IsoCode3Letter": "MDG",
            "IsoCode3LetterDocumentReader": "MDG",
            "PhoneDialCode": "+261",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 123,
            "Code": "MH",
            "Description": "Ilhas Marshall",
            "IsoCode": "MH",
            "IsoCode3Letter": "MHL",
            "IsoCode3LetterDocumentReader": "MHL",
            "PhoneDialCode": "+692",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 151,
            "Code": "MK",
            "Description": "Macedónia (antiga rep. jugoslava da)",
            "IsoCode": "MK",
            "IsoCode3Letter": "MKD",
            "IsoCode3LetterDocumentReader": "MKD",
            "PhoneDialCode": "+389",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 156,
            "Code": "ML",
            "Description": "Mali",
            "IsoCode": "ML",
            "IsoCode3Letter": "MLI",
            "IsoCode3LetterDocumentReader": "MLI",
            "PhoneDialCode": "+223",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 170,
            "Code": "MM",
            "Description": "Myanmar",
            "IsoCode": "MM",
            "IsoCode3Letter": "MMR",
            "IsoCode3LetterDocumentReader": "MMR",
            "PhoneDialCode": "+95",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 168,
            "Code": "MN",
            "Description": "Mongólia",
            "IsoCode": "MN",
            "IsoCode3Letter": "MNG",
            "IsoCode3LetterDocumentReader": "MNG",
            "PhoneDialCode": "+976",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 150,
            "Code": "MO",
            "Description": "Macau",
            "IsoCode": "MO",
            "IsoCode3Letter": "MAC",
            "IsoCode3LetterDocumentReader": "MAC",
            "PhoneDialCode": "+853",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 122,
            "Code": "MP",
            "Description": "Ilhas Marianas do Norte",
            "IsoCode": "MP",
            "IsoCode3Letter": "MNP",
            "IsoCode3LetterDocumentReader": "MNP",
            "PhoneDialCode": "+1670",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 159,
            "Code": "MQ",
            "Description": "Martinica",
            "IsoCode": "MQ",
            "IsoCode3Letter": "MTQ",
            "IsoCode3LetterDocumentReader": "MTQ",
            "PhoneDialCode": "+596",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 161,
            "Code": "MR",
            "Description": "Mauritânia",
            "IsoCode": "MR",
            "IsoCode3Letter": "MRT",
            "IsoCode3LetterDocumentReader": "MRT",
            "PhoneDialCode": "+222",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 169,
            "Code": "MS",
            "Description": "Monserrate",
            "IsoCode": "MS",
            "IsoCode3Letter": "MSR",
            "IsoCode3LetterDocumentReader": "MSR",
            "PhoneDialCode": "+1664",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 157,
            "Code": "MT",
            "Description": "Malta",
            "IsoCode": "MT",
            "IsoCode3Letter": "MLT",
            "IsoCode3LetterDocumentReader": "MLT",
            "PhoneDialCode": "+356",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 160,
            "Code": "MU",
            "Description": "Maurícias",
            "IsoCode": "MU",
            "IsoCode3Letter": "MUS",
            "IsoCode3LetterDocumentReader": "MUS",
            "PhoneDialCode": "+230",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 155,
            "Code": "MV",
            "Description": "Maldivas",
            "IsoCode": "MV",
            "IsoCode3Letter": "MDV",
            "IsoCode3LetterDocumentReader": "MDV",
            "PhoneDialCode": "+960",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 154,
            "Code": "MW",
            "Description": "Malawi",
            "IsoCode": "MW",
            "IsoCode3Letter": "MWI",
            "IsoCode3LetterDocumentReader": "MWI",
            "PhoneDialCode": "+265",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 163,
            "Code": "MX",
            "Description": "México",
            "IsoCode": "MX",
            "IsoCode3Letter": "MEX",
            "IsoCode3LetterDocumentReader": "MEX",
            "PhoneDialCode": "+52",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 153,
            "Code": "MY",
            "Description": "Malásia",
            "IsoCode": "MY",
            "IsoCode3Letter": "MYS",
            "IsoCode3LetterDocumentReader": "MYS",
            "PhoneDialCode": "+60",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 165,
            "Code": "MZ",
            "Description": "Moçambique",
            "IsoCode": "MZ",
            "IsoCode3Letter": "MOZ",
            "IsoCode3LetterDocumentReader": "MOZ",
            "PhoneDialCode": "+258",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 171,
            "Code": "NA",
            "Description": "Namíbia",
            "IsoCode": "NA",
            "IsoCode3Letter": "NAM",
            "IsoCode3LetterDocumentReader": "NAM",
            "PhoneDialCode": "+264",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 178,
            "Code": "NC",
            "Description": "Nova Caledónia",
            "IsoCode": "NC",
            "IsoCode3Letter": "NCL",
            "IsoCode3LetterDocumentReader": "NCL",
            "PhoneDialCode": "+687",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 175,
            "Code": "NE",
            "Description": "Niger",
            "IsoCode": "NE",
            "IsoCode3Letter": "NER",
            "IsoCode3LetterDocumentReader": "NER",
            "PhoneDialCode": "+227",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 125,
            "Code": "NF",
            "Description": "Ilhas Norfolk",
            "IsoCode": "NF",
            "IsoCode3Letter": "NFK",
            "IsoCode3LetterDocumentReader": "NFK",
            "PhoneDialCode": "+672",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 176,
            "Code": "NG",
            "Description": "Nigéria",
            "IsoCode": "NG",
            "IsoCode3Letter": "NGA",
            "IsoCode3LetterDocumentReader": "NGA",
            "PhoneDialCode": "+234",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 174,
            "Code": "NI",
            "Description": "Nicarágua",
            "IsoCode": "NI",
            "IsoCode3Letter": "NIC",
            "IsoCode3LetterDocumentReader": "NIC",
            "PhoneDialCode": "+505",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 26,
            "Code": "NL",
            "Description": "Países Baixos",
            "IsoCode": "NL",
            "IsoCode3Letter": "NLD",
            "IsoCode3LetterDocumentReader": "NLD",
            "PhoneDialCode": "+31",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 21,
            "Code": "NO",
            "Description": "Noruega",
            "IsoCode": "NO",
            "IsoCode3Letter": "NOR",
            "IsoCode3LetterDocumentReader": "NOR",
            "PhoneDialCode": "+47",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 173,
            "Code": "NP",
            "Description": "Nepal",
            "IsoCode": "NP",
            "IsoCode3Letter": "NPL",
            "IsoCode3LetterDocumentReader": "NPL",
            "PhoneDialCode": "+977",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 172,
            "Code": "NR",
            "Description": "Nauru",
            "IsoCode": "NR",
            "IsoCode3Letter": "NRU",
            "IsoCode3LetterDocumentReader": "NRU",
            "PhoneDialCode": "+674",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 177,
            "Code": "NU",
            "Description": "Niue",
            "IsoCode": "NU",
            "IsoCode3Letter": "NIU",
            "IsoCode3LetterDocumentReader": "NIU",
            "PhoneDialCode": "+683",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 29,
            "Code": "NZ",
            "Description": "Nova Zelândia",
            "IsoCode": "NZ",
            "IsoCode3Letter": "NZL",
            "IsoCode3LetterDocumentReader": "NZL",
            "PhoneDialCode": "+64",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 179,
            "Code": "OM",
            "Description": "Omã",
            "IsoCode": "OM",
            "IsoCode3Letter": "OMN",
            "IsoCode3LetterDocumentReader": "OMN",
            "PhoneDialCode": "+968",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 181,
            "Code": "PA",
            "Description": "Panamá",
            "IsoCode": "PA",
            "IsoCode3Letter": "PAN",
            "IsoCode3LetterDocumentReader": "PAN",
            "PhoneDialCode": "+507",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 185,
            "Code": "PE",
            "Description": "Peru",
            "IsoCode": "PE",
            "IsoCode3Letter": "PER",
            "IsoCode3LetterDocumentReader": "PER",
            "PhoneDialCode": "+51",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 187,
            "Code": "PF",
            "Description": "Polinésia Francesa",
            "IsoCode": "PF",
            "IsoCode3Letter": "PYF",
            "IsoCode3LetterDocumentReader": "PYF",
            "PhoneDialCode": "+689",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 182,
            "Code": "PG",
            "Description": "Papuásia-Nova Guiné",
            "IsoCode": "PG",
            "IsoCode3Letter": "PNG",
            "IsoCode3LetterDocumentReader": "PNG",
            "PhoneDialCode": "+675",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 90,
            "Code": "PH",
            "Description": "Filipinas",
            "IsoCode": "PH",
            "IsoCode3Letter": "PHL",
            "IsoCode3LetterDocumentReader": "PHL",
            "PhoneDialCode": "+63",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 183,
            "Code": "PK",
            "Description": "Paquistão",
            "IsoCode": "PK",
            "IsoCode3Letter": "PAK",
            "IsoCode3LetterDocumentReader": "PAK",
            "PhoneDialCode": "+92",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 188,
            "Code": "PL",
            "Description": "Polónia",
            "IsoCode": "PL",
            "IsoCode3Letter": "POL",
            "IsoCode3LetterDocumentReader": "POL",
            "PhoneDialCode": "+48",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 203,
            "Code": "PM",
            "Description": "São Pedro e Miquelon",
            "IsoCode": "PM",
            "IsoCode3Letter": "SPM",
            "IsoCode3LetterDocumentReader": "SPM",
            "PhoneDialCode": "+508",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 186,
            "Code": "PN",
            "Description": "Pitcairn",
            "IsoCode": "PN",
            "IsoCode3Letter": "PCN",
            "IsoCode3LetterDocumentReader": "PCN",
            "PhoneDialCode": "+870",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 189,
            "Code": "PR",
            "Description": "Porto Rico",
            "IsoCode": "PR",
            "IsoCode3Letter": "PRI",
            "IsoCode3LetterDocumentReader": "PRI",
            "PhoneDialCode": "+1",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 223,
            "Code": "PS",
            "Description": "Palestina",
            "IsoCode": "PS",
            "IsoCode3Letter": "PSE",
            "IsoCode3LetterDocumentReader": "PSE",
            "PhoneDialCode": "+970",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 2,
            "Code": "PT",
            "Description": "Portugal",
            "IsoCode": "PT",
            "IsoCode3Letter": "PRT",
            "IsoCode3LetterDocumentReader": "PRT",
            "PhoneDialCode": "+351",
            "FiscalZone": "PT",
            "DefaultCultureCode": "pt-PT"
        },
        {
            "Id": 180,
            "Code": "PW",
            "Description": "Palau",
            "IsoCode": "PW",
            "IsoCode3Letter": "PLW",
            "IsoCode3LetterDocumentReader": "PLW",
            "PhoneDialCode": "+680",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 184,
            "Code": "PY",
            "Description": "Paraguai",
            "IsoCode": "PY",
            "IsoCode3Letter": "PRY",
            "IsoCode3LetterDocumentReader": "PRY",
            "PhoneDialCode": "+595",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 64,
            "Code": "QA",
            "Description": "Catar",
            "IsoCode": "QA",
            "IsoCode3Letter": "QAT",
            "IsoCode3LetterDocumentReader": "QAT",
            "PhoneDialCode": "+974",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 193,
            "Code": "RE",
            "Description": "Reunião",
            "IsoCode": "RE",
            "IsoCode3Letter": "REU",
            "IsoCode3LetterDocumentReader": "REU",
            "PhoneDialCode": "+262",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 253,
            "Code": "XK",
            "Description": "Kosovo, República do",
            "IsoCode": "RKS",
            "IsoCode3Letter": "RKS",
            "IsoCode3LetterDocumentReader": "RKS",
            "PhoneDialCode": "+383",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 194,
            "Code": "RO",
            "Description": "Roménia",
            "IsoCode": "RO",
            "IsoCode3Letter": "ROU",
            "IsoCode3LetterDocumentReader": "ROU",
            "PhoneDialCode": "+40",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 246,
            "Code": "RS",
            "Description": "Sérvia",
            "IsoCode": "RS",
            "IsoCode3Letter": "SRB",
            "IsoCode3LetterDocumentReader": "SRB",
            "PhoneDialCode": "+381",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 20,
            "Code": "RU",
            "Description": "Rússia (Federação da)",
            "IsoCode": "RU",
            "IsoCode3Letter": "RUS",
            "IsoCode3LetterDocumentReader": "RUS",
            "PhoneDialCode": "+7",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 195,
            "Code": "RW",
            "Description": "Ruanda",
            "IsoCode": "RW",
            "IsoCode3Letter": "RWA",
            "IsoCode3LetterDocumentReader": "RWA",
            "PhoneDialCode": "+250",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 39,
            "Code": "SA",
            "Description": "Arábia Saudita",
            "IsoCode": "SA",
            "IsoCode3Letter": "SAU",
            "IsoCode3LetterDocumentReader": "SAU",
            "PhoneDialCode": "+966",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 126,
            "Code": "SB",
            "Description": "Ilhas Salomão",
            "IsoCode": "SB",
            "IsoCode3Letter": "SLB",
            "IsoCode3LetterDocumentReader": "SLB",
            "PhoneDialCode": "+677",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 209,
            "Code": "SC",
            "Description": "Seychelles",
            "IsoCode": "SC",
            "IsoCode3Letter": "SYC",
            "IsoCode3LetterDocumentReader": "SYC",
            "PhoneDialCode": "+248",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 215,
            "Code": "SD",
            "Description": "Sudão",
            "IsoCode": "SD",
            "IsoCode3Letter": "SDN",
            "IsoCode3LetterDocumentReader": "SDN",
            "PhoneDialCode": "+249",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 14,
            "Code": "SE",
            "Description": "Suécia",
            "IsoCode": "SE",
            "IsoCode3Letter": "SWE",
            "IsoCode3LetterDocumentReader": "SWE",
            "PhoneDialCode": "+46",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 210,
            "Code": "SG",
            "Description": "Singapura",
            "IsoCode": "SG",
            "IsoCode3Letter": "SGP",
            "IsoCode3LetterDocumentReader": "SGP",
            "PhoneDialCode": "+65",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 198,
            "Code": "SH",
            "Description": "Santa Helena",
            "IsoCode": "SH",
            "IsoCode3Letter": "SHN",
            "IsoCode3LetterDocumentReader": "SHN",
            "PhoneDialCode": "+290 n",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 87,
            "Code": "SI",
            "Description": "Eslovénia",
            "IsoCode": "SI",
            "IsoCode3Letter": "SVN",
            "IsoCode3LetterDocumentReader": "SVN",
            "PhoneDialCode": "+386",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 217,
            "Code": "SJ",
            "Description": "Svalbard e a Ilha de Jan Mayen",
            "IsoCode": "SJ",
            "IsoCode3Letter": "SJM",
            "IsoCode3LetterDocumentReader": "SJM",
            "PhoneDialCode": "+47",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 86,
            "Code": "SK",
            "Description": "Eslovaca (República)",
            "IsoCode": "SK",
            "IsoCode3Letter": "SVK",
            "IsoCode3LetterDocumentReader": "SVK",
            "PhoneDialCode": "+421",
            "FiscalZone": "EU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 208,
            "Code": "SL",
            "Description": "Serra Leoa",
            "IsoCode": "SL",
            "IsoCode3Letter": "SLE",
            "IsoCode3LetterDocumentReader": "SLE",
            "PhoneDialCode": "+232",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 202,
            "Code": "SM",
            "Description": "São Marino",
            "IsoCode": "SM",
            "IsoCode3Letter": "SMR",
            "IsoCode3LetterDocumentReader": "SMR",
            "PhoneDialCode": "+378",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 207,
            "Code": "SN",
            "Description": "Senegal",
            "IsoCode": "SN",
            "IsoCode3Letter": "SEN",
            "IsoCode3LetterDocumentReader": "SEN",
            "PhoneDialCode": "+221",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 212,
            "Code": "SO",
            "Description": "Somália",
            "IsoCode": "SO",
            "IsoCode3Letter": "SOM",
            "IsoCode3LetterDocumentReader": "SOM",
            "PhoneDialCode": "+252",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 216,
            "Code": "SR",
            "Description": "Suriname",
            "IsoCode": "SR",
            "IsoCode3Letter": "SUR",
            "IsoCode3LetterDocumentReader": "SUR",
            "PhoneDialCode": "+597",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 256,
            "Code": "SS",
            "Description": "Sudão do Sul",
            "IsoCode": "SS",
            "IsoCode3Letter": "SSD",
            "IsoCode3LetterDocumentReader": "SSD",
            "PhoneDialCode": "+211",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 204,
            "Code": "ST",
            "Description": "São Tomé e Príncipe",
            "IsoCode": "ST",
            "IsoCode3Letter": "STP",
            "IsoCode3LetterDocumentReader": "STP",
            "PhoneDialCode": "+239",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 82,
            "Code": "SV",
            "Description": "El Salvador",
            "IsoCode": "SV",
            "IsoCode3Letter": "SLV",
            "IsoCode3LetterDocumentReader": "SLV",
            "PhoneDialCode": "+503",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 257,
            "Code": "SX",
            "Description": "São Martinho (Países Baixos)",
            "IsoCode": "SX",
            "IsoCode3Letter": "SXM",
            "IsoCode3LetterDocumentReader": "SXM",
            "PhoneDialCode": "+1721",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 211,
            "Code": "SY",
            "Description": "Síria (República Árabe da)",
            "IsoCode": "SY",
            "IsoCode3Letter": "SYR",
            "IsoCode3LetterDocumentReader": "SYR",
            "PhoneDialCode": "+963",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 214,
            "Code": "SZ",
            "Description": "Suazilândia",
            "IsoCode": "SZ",
            "IsoCode3Letter": "SWZ",
            "IsoCode3LetterDocumentReader": "SWZ",
            "PhoneDialCode": "+268",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 231,
            "Code": "TC",
            "Description": "Turcos e Caicos (Ilhas)",
            "IsoCode": "TC",
            "IsoCode3Letter": "TCA",
            "IsoCode3LetterDocumentReader": "TCA",
            "PhoneDialCode": "+1649",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 67,
            "Code": "TD",
            "Description": "Chade",
            "IsoCode": "TD",
            "IsoCode3Letter": "TCD",
            "IsoCode3LetterDocumentReader": "TCD",
            "PhoneDialCode": "+235",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 224,
            "Code": "TF",
            "Description": "Territórios Franceses do Sul",
            "IsoCode": "TF",
            "IsoCode3Letter": "ATF",
            "IsoCode3LetterDocumentReader": "ATF",
            "PhoneDialCode": "+262",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 226,
            "Code": "TG",
            "Description": "Togo",
            "IsoCode": "TG",
            "IsoCode3Letter": "TGO",
            "IsoCode3LetterDocumentReader": "TGO",
            "PhoneDialCode": "+228",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 218,
            "Code": "TH",
            "Description": "Tailândia",
            "IsoCode": "TH",
            "IsoCode3Letter": "THA",
            "IsoCode3LetterDocumentReader": "THA",
            "PhoneDialCode": "+66",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 220,
            "Code": "TJ",
            "Description": "Tajiquistão",
            "IsoCode": "TJ",
            "IsoCode3Letter": "TJK",
            "IsoCode3LetterDocumentReader": "TJK",
            "PhoneDialCode": "+992",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 227,
            "Code": "TK",
            "Description": "Tokelau",
            "IsoCode": "TK",
            "IsoCode3Letter": "TKL",
            "IsoCode3LetterDocumentReader": "TKL",
            "PhoneDialCode": "+690",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 225,
            "Code": "TL",
            "Description": "Timor Leste",
            "IsoCode": "TL",
            "IsoCode3Letter": "TLS",
            "IsoCode3LetterDocumentReader": "TLS",
            "PhoneDialCode": "+670",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 232,
            "Code": "TM",
            "Description": "Turquemenistão",
            "IsoCode": "TM",
            "IsoCode3Letter": "TKM",
            "IsoCode3LetterDocumentReader": "TKM",
            "PhoneDialCode": "+993",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 230,
            "Code": "TN",
            "Description": "Tunísia",
            "IsoCode": "TN",
            "IsoCode3Letter": "TUN",
            "IsoCode3LetterDocumentReader": "TUN",
            "PhoneDialCode": "+216",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 228,
            "Code": "TO",
            "Description": "Tonga",
            "IsoCode": "TO",
            "IsoCode3Letter": "TON",
            "IsoCode3LetterDocumentReader": "TON",
            "PhoneDialCode": "+676",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 233,
            "Code": "TR",
            "Description": "Turquia",
            "IsoCode": "TR",
            "IsoCode3Letter": "TUR",
            "IsoCode3LetterDocumentReader": "TUR",
            "PhoneDialCode": "+90",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 229,
            "Code": "TT",
            "Description": "Trindade e Tobago",
            "IsoCode": "TT",
            "IsoCode3Letter": "TTO",
            "IsoCode3LetterDocumentReader": "TTO",
            "PhoneDialCode": "+1868",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 234,
            "Code": "TV",
            "Description": "Tuvalu",
            "IsoCode": "TV",
            "IsoCode3Letter": "TUV",
            "IsoCode3LetterDocumentReader": "TUV",
            "PhoneDialCode": "+688",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 219,
            "Code": "TW",
            "Description": "Taiwan (Província da China)",
            "IsoCode": "TW",
            "IsoCode3Letter": "TWN",
            "IsoCode3LetterDocumentReader": "TWN",
            "PhoneDialCode": "+886",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 221,
            "Code": "TZ",
            "Description": "Tanzânia, República Unida da",
            "IsoCode": "TZ",
            "IsoCode3Letter": "TZA",
            "IsoCode3LetterDocumentReader": "TZA",
            "PhoneDialCode": "+255",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 235,
            "Code": "UA",
            "Description": "Ucrânia",
            "IsoCode": "UA",
            "IsoCode3Letter": "UKR",
            "IsoCode3LetterDocumentReader": "UKR",
            "PhoneDialCode": "+380",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 236,
            "Code": "UG",
            "Description": "Uganda",
            "IsoCode": "UG",
            "IsoCode3Letter": "UGA",
            "IsoCode3LetterDocumentReader": "UGA",
            "PhoneDialCode": "+256",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 124,
            "Code": "UM",
            "Description": "Ilhas menores distantes dos Estados Unidos",
            "IsoCode": "UM",
            "IsoCode3Letter": "UMI",
            "IsoCode3LetterDocumentReader": "UMI",
            "PhoneDialCode": null,
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 12,
            "Code": "US",
            "Description": "Estados Unidos",
            "IsoCode": "US",
            "IsoCode3Letter": "USA",
            "IsoCode3LetterDocumentReader": "USA",
            "PhoneDialCode": "+1",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 237,
            "Code": "UY",
            "Description": "Uruguai",
            "IsoCode": "UY",
            "IsoCode3Letter": "URY",
            "IsoCode3LetterDocumentReader": "URY",
            "PhoneDialCode": "+598",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 238,
            "Code": "UZ",
            "Description": "Usbequistão",
            "IsoCode": "UZ",
            "IsoCode3Letter": "UZB",
            "IsoCode3LetterDocumentReader": "UZB",
            "PhoneDialCode": "+998",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 200,
            "Code": "VA",
            "Description": "Santa Sé (Cidade Estado do Vaticano)",
            "IsoCode": "VA",
            "IsoCode3Letter": "VAT",
            "IsoCode3LetterDocumentReader": "VAT",
            "PhoneDialCode": "+3906",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 205,
            "Code": "VC",
            "Description": "São Vicente e Granadinas",
            "IsoCode": "VC",
            "IsoCode3Letter": "VCT",
            "IsoCode3LetterDocumentReader": "VCT",
            "PhoneDialCode": "+1784",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 240,
            "Code": "VE",
            "Description": "Venezuela",
            "IsoCode": "VE",
            "IsoCode3Letter": "VEN",
            "IsoCode3LetterDocumentReader": "VEN",
            "PhoneDialCode": "+58",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 127,
            "Code": "VG",
            "Description": "Ilhas Virgens (Britânicas)",
            "IsoCode": "VG",
            "IsoCode3Letter": "VGB",
            "IsoCode3LetterDocumentReader": "VGB",
            "PhoneDialCode": "+1284",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 128,
            "Code": "VI",
            "Description": "Ilhas Virgens (Estados Unidos)",
            "IsoCode": "VI",
            "IsoCode3Letter": "VIR",
            "IsoCode3LetterDocumentReader": "VIR",
            "PhoneDialCode": "+1340",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 241,
            "Code": "VN",
            "Description": "Vietname",
            "IsoCode": "VN",
            "IsoCode3Letter": "VNM",
            "IsoCode3LetterDocumentReader": "VNM",
            "PhoneDialCode": "+84",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 239,
            "Code": "VU",
            "Description": "Vanuatu",
            "IsoCode": "VU",
            "IsoCode3Letter": "VUT",
            "IsoCode3LetterDocumentReader": "VUT",
            "PhoneDialCode": "+678",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 242,
            "Code": "WF",
            "Description": "Wallis e Futuna (Ilha)",
            "IsoCode": "WF",
            "IsoCode3Letter": "WLF",
            "IsoCode3LetterDocumentReader": "WLF",
            "PhoneDialCode": "+681",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 196,
            "Code": "WS",
            "Description": "Samoa",
            "IsoCode": "WS",
            "IsoCode3Letter": "WSM",
            "IsoCode3LetterDocumentReader": "WSM",
            "PhoneDialCode": "+685",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 112,
            "Code": "YE",
            "Description": "Iémen",
            "IsoCode": "YE",
            "IsoCode3Letter": "YEM",
            "IsoCode3LetterDocumentReader": "YEM",
            "PhoneDialCode": "+967",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 162,
            "Code": "YT",
            "Description": "Mayotte",
            "IsoCode": "YT",
            "IsoCode3Letter": "MYT",
            "IsoCode3LetterDocumentReader": "MYT",
            "PhoneDialCode": "+262",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 138,
            "Code": "YU",
            "Description": "Jugoslávia",
            "IsoCode": "YU",
            "IsoCode3Letter": "XXX",
            "IsoCode3LetterDocumentReader": "XXX",
            "PhoneDialCode": null,
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 30,
            "Code": "ZA",
            "Description": "África do Sul",
            "IsoCode": "ZA",
            "IsoCode3Letter": "ZAF",
            "IsoCode3LetterDocumentReader": "ZAF",
            "PhoneDialCode": "+27",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 243,
            "Code": "ZM",
            "Description": "Zâmbia",
            "IsoCode": "ZM",
            "IsoCode3Letter": "ZMB",
            "IsoCode3LetterDocumentReader": "ZMB",
            "PhoneDialCode": "+260",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        },
        {
            "Id": 244,
            "Code": "ZW",
            "Description": "Zimbabwe",
            "IsoCode": "ZW",
            "IsoCode3Letter": "ZWE",
            "IsoCode3LetterDocumentReader": "ZWE",
            "PhoneDialCode": "+263",
            "FiscalZone": "OU",
            "DefaultCultureCode": "en-US"
        }
    ],
    "ServerTime": "2025-02-03T11:16:27.893"
}

export const previewProfile = {
    "ProfileKey": "example",
    "Uid": "example",
    "LastName": "Doe",
    "FirstName": "John",
    "Emails": [
        "john.doe@email.com"
    ],
    "Phones": [
        ""
    ],
    "CultureCode": "en-US"
}

export const previewGroupHotels = [
    {
        "id": 1,
        "hotelId": "example",
        "name1": "Hotel Demo Lisboa",
        "name2": "Hotel Demo Lisboa",
        "groupId": "grupo_hotel_demo_host",
        "subscriptionKey": "example",
        "isActive": true,
        "createdAt": "2024-12-20T14:36:05.753115+00:00",
        "createdBy": "admin",
        "updatedAt": "2024-12-20T14:36:05.753115+00:00",
        "updatedBy": "admin"
    }
]

export const previewCurrentHotel = {
   "id": 1,
        "hotelId": "example",
        "name1": "Hotel Demo Lisboa",
        "name2": "Hotel Demo Lisboa",
        "groupId": "grupo_hotel_demo_host",
        "subscriptionKey": "example",
        "isActive": true,
        "createdAt": "2024-12-20T14:36:05.753115+00:00",
        "createdBy": "admin",
        "updatedAt": "2024-12-20T14:36:05.753115+00:00",
        "updatedBy": "admin"
}

export const previewHotelInitialData = {
    "Hotel": {
        "HotelId": 1,
        "HotelCode": "Hotel Demo Lisboa",
        "HotelName": "Hotel Demo Lisboa",
        "HotelName2": "Hotel Demo Lisboa",
        "CurrencyCode": "Eur",
        "DefaultLanguage": "pt-PT",
        "DefaultCurrency": "Eur",
        "DefaultCurrencySymbol": "€",
        "CountryCodeLic": "PT",
        "Address1": "",
        "Address2": "",
        "City": "PT",
        "ZipCode": "",
        "Country": "Portugal",
        "EMail": "",
        "Phone": "",
        "WebSite": "",
        "GpsCoordinates": {
            "Latitude": null,
            "Longitude": null
        },
        "HotelDate": "2024-09-04T00:00:00.000",
        "ServerTime": "2025-01-10T11:53:29.943",
        "LicenseInterfaceModules": "",
        "LanguagesList": {
            "pt-PT": {
                "EnglishName": "Portuguese",
                "NativeName": "português",
                "DisplayName": "Português",
                "ThreeLetterISOLanguageName": "por",
                "ThreeLetterWindowsLanguageName": "PTB",
                "TwoLetterISORegionName": "PT"
            },
            "en-US": {
                "EnglishName": "English",
                "NativeName": "English",
                "DisplayName": "Inglês",
                "ThreeLetterISOLanguageName": "eng",
                "ThreeLetterWindowsLanguageName": "ENU",
                "TwoLetterISORegionName": "US"
            },
            "es-ES": {
                "EnglishName": "Spanish",
                "NativeName": "español",
                "DisplayName": "Espanhol",
                "ThreeLetterISOLanguageName": "spa",
                "ThreeLetterWindowsLanguageName": "ESP",
                "TwoLetterISORegionName": "ES"
            },
            "fr-FR": {
                "EnglishName": "French",
                "NativeName": "français",
                "DisplayName": "Francês",
                "ThreeLetterISOLanguageName": "fra",
                "ThreeLetterWindowsLanguageName": "FRA",
                "TwoLetterISORegionName": "FR"
            },
            "de-DE": {
                "EnglishName": "German",
                "NativeName": "Deutsch",
                "DisplayName": "Alemão",
                "ThreeLetterISOLanguageName": "deu",
                "ThreeLetterWindowsLanguageName": "DEU",
                "TwoLetterISORegionName": "DE"
            }
        },
        "HotelImages": [
        ]
    },
    "Parameters": {
        "IdleTimeout": 20,
        "UseVirtualKeyboard": true,
        "numberOfLicensedKiosks": 0,
        "numberOfActiveKiosks": 1,
        "AllowReservationNote": true,
        "AllowArrivalTime": true,
        "MinimumArrivalTime": "14:00",
        "MaximumArrivalTime": "23:45",
        "UseGuestSignature": true,
        "LocalConfigSignature": false,
        "HasCameraScanner": false,
        "HasMobilePayment": false,
        "AllGuestsMustFill": false,
        "AutoFillLater": false,
        "OnlyMainGuest": false,
        "ConsiderChildrenAsAdditional": false,
        "ChildrenWithValidationSef": false,
        "ChildrenMaximumAgeAtCheckinDate": -1,
        "PostActionUrl": "",
        "BrazilMandatoryCpf": false,
        "AllowAnyAgeInCalendar": false,
        "AllowRoomreadyNotification": false,
        "ZipCodeValidationRequiredForCountryId": 0,
        "ZipCodeValidationMask": "",
        "AllowExternalDocumentReader": false,
        "PartnerDocumentReader": 0,
        "AdditionalGuestWithMandatoryField": false,
        "CanAddExtraGuestsToReservation": false,
        "CheckinTime": "14:00",
        "MilitaryMode": false,
        "PrioritizeUpgradeFromInsteadOfCategory": false
    },
    "GuestFields": [
        {
            "Field": "LastName",
            "IsMandatory": true
        },
        {
            "Field": "FirstName",
            "IsMandatory": true
        },
        {
            "Field": "FiscalNumber",
            "IsMandatory": true
        },
        {
            "Field": "Email",
            "IsMandatory": true
        }
    ],
    "GuestDocumentTypes": [
        {
            "Id": 1,
            "Code": "PASSPORT",
            "Description": "Passport"
        },
        {
            "Id": 2,
            "Code": "IDCARD",
            "Description": "ID Card"
        }
    ],
    "Currencies": [
        {
            "ID": 365,
            "CurrencySymbol": "€",
            "IsLocalCurrency": true,
            "ISOCode": "Eur"
        }
    ],
    "PaymentMethods": [],
    "CountryList": [
        {
            "Id": 33,
            "IsoCode": "AD",
            "IsoCode3LetterDocumentReader": "AND",
            "NonTranslatedDescription": "Andorra",
            "TranslatedDescription": "Andorra"
        },
        {
            "Id": 83,
            "IsoCode": "AE",
            "IsoCode3LetterDocumentReader": "ARE",
            "NonTranslatedDescription": "Emiratos Árabes Unidos",
            "TranslatedDescription": "United Arab Emirates"
        },
        {
            "Id": 27,
            "IsoCode": "AF",
            "IsoCode3LetterDocumentReader": "AFG",
            "NonTranslatedDescription": "Afeganistão",
            "TranslatedDescription": "Afghanistan"
        },
        {
            "Id": 37,
            "IsoCode": "AG",
            "IsoCode3LetterDocumentReader": "ATG",
            "NonTranslatedDescription": "Antígua e Barbuda",
            "TranslatedDescription": "Antigua and Barbuda"
        },
        {
            "Id": 35,
            "IsoCode": "AI",
            "IsoCode3LetterDocumentReader": "AIA",
            "NonTranslatedDescription": "Anguila",
            "TranslatedDescription": "Anguilla"
        },
        {
            "Id": 31,
            "IsoCode": "AL",
            "IsoCode3LetterDocumentReader": "ALB",
            "NonTranslatedDescription": "Albânia",
            "TranslatedDescription": "Albania"
        },
        {
            "Id": 41,
            "IsoCode": "AM",
            "IsoCode3LetterDocumentReader": "ARM",
            "NonTranslatedDescription": "Arménia",
            "TranslatedDescription": "Armenia"
        },
        {
            "Id": 38,
            "IsoCode": "AN",
            "IsoCode3LetterDocumentReader": "ANT",
            "NonTranslatedDescription": "Antilhas holandesas",
            "TranslatedDescription": "Netherlands Antilles"
        },
        {
            "Id": 34,
            "IsoCode": "AO",
            "IsoCode3LetterDocumentReader": "AGO",
            "NonTranslatedDescription": "Angola",
            "TranslatedDescription": "Angola"
        },
        {
            "Id": 36,
            "IsoCode": "AQ",
            "IsoCode3LetterDocumentReader": "ATA",
            "NonTranslatedDescription": "Antárctica",
            "TranslatedDescription": "Antarctica"
        },
        {
            "Id": 40,
            "IsoCode": "AR",
            "IsoCode3LetterDocumentReader": "ARG",
            "NonTranslatedDescription": "Argentina",
            "TranslatedDescription": "Argentina"
        },
        {
            "Id": 197,
            "IsoCode": "AS",
            "IsoCode3LetterDocumentReader": "ASM",
            "NonTranslatedDescription": "Samoa Americana",
            "TranslatedDescription": "American Samoa"
        },
        {
            "Id": 1,
            "IsoCode": "AT",
            "IsoCode3LetterDocumentReader": "AUT",
            "NonTranslatedDescription": "Áustria",
            "TranslatedDescription": "Austria"
        },
        {
            "Id": 43,
            "IsoCode": "AU",
            "IsoCode3LetterDocumentReader": "AUS",
            "NonTranslatedDescription": "Austrália",
            "TranslatedDescription": "Australia"
        },
        {
            "Id": 42,
            "IsoCode": "AW",
            "IsoCode3LetterDocumentReader": "ABW",
            "NonTranslatedDescription": "Aruba",
            "TranslatedDescription": "Aruba"
        },
        {
            "Id": 247,
            "IsoCode": "AX",
            "IsoCode3LetterDocumentReader": "ALA",
            "NonTranslatedDescription": "Ilhas Aland",
            "TranslatedDescription": "Ilhas Aland"
        },
        {
            "Id": 44,
            "IsoCode": "AZ",
            "IsoCode3LetterDocumentReader": "AZE",
            "NonTranslatedDescription": "Azerbaijão",
            "TranslatedDescription": "Azerbaijan"
        },
        {
            "Id": 54,
            "IsoCode": "BA",
            "IsoCode3LetterDocumentReader": "BIH",
            "NonTranslatedDescription": "Bósnia e Herzegovina",
            "TranslatedDescription": "Bosnia and Herzegovina"
        },
        {
            "Id": 47,
            "IsoCode": "BB",
            "IsoCode3LetterDocumentReader": "BRB",
            "NonTranslatedDescription": "Barbados",
            "TranslatedDescription": "Barbados"
        },
        {
            "Id": 46,
            "IsoCode": "BD",
            "IsoCode3LetterDocumentReader": "BGD",
            "NonTranslatedDescription": "Bangladesh",
            "TranslatedDescription": "Bangladesh"
        },
        {
            "Id": 9,
            "IsoCode": "BE",
            "IsoCode3LetterDocumentReader": "BEL",
            "NonTranslatedDescription": "Bélgica",
            "TranslatedDescription": "Belgium"
        },
        {
            "Id": 58,
            "IsoCode": "BF",
            "IsoCode3LetterDocumentReader": "BFA",
            "NonTranslatedDescription": "Burkina Faso",
            "TranslatedDescription": "Burkina Faso"
        },
        {
            "Id": 57,
            "IsoCode": "BG",
            "IsoCode3LetterDocumentReader": "BGR",
            "NonTranslatedDescription": "Bulgária",
            "TranslatedDescription": "Bulgaria"
        },
        {
            "Id": 48,
            "IsoCode": "BH",
            "IsoCode3LetterDocumentReader": "BHR",
            "NonTranslatedDescription": "Barém",
            "TranslatedDescription": "Bahrain"
        },
        {
            "Id": 59,
            "IsoCode": "BI",
            "IsoCode3LetterDocumentReader": "BDI",
            "NonTranslatedDescription": "Burundi",
            "TranslatedDescription": "Burundi"
        },
        {
            "Id": 50,
            "IsoCode": "BJ",
            "IsoCode3LetterDocumentReader": "BEN",
            "NonTranslatedDescription": "Benin",
            "TranslatedDescription": "Benin"
        },
        {
            "Id": 252,
            "IsoCode": "BL",
            "IsoCode3LetterDocumentReader": "BLM",
            "NonTranslatedDescription": "São Bartolomeu",
            "TranslatedDescription": "Saint Barthélemy"
        },
        {
            "Id": 51,
            "IsoCode": "BM",
            "IsoCode3LetterDocumentReader": "BMU",
            "NonTranslatedDescription": "Bermuda",
            "TranslatedDescription": "Bermuda"
        },
        {
            "Id": 56,
            "IsoCode": "BN",
            "IsoCode3LetterDocumentReader": "BRN",
            "NonTranslatedDescription": "Brunei Darussalam",
            "TranslatedDescription": "Brunei Darussalam"
        },
        {
            "Id": 53,
            "IsoCode": "BO",
            "IsoCode3LetterDocumentReader": "BOL",
            "NonTranslatedDescription": "Bolívia",
            "TranslatedDescription": "Bolivia"
        },
        {
            "Id": 254,
            "IsoCode": "BQ",
            "IsoCode3LetterDocumentReader": "BES",
            "NonTranslatedDescription": "Bonaire, Saba e Santo Eustáquio",
            "TranslatedDescription": "Bonaire, Sint Eustatius and Saba"
        },
        {
            "Id": 10,
            "IsoCode": "BR",
            "IsoCode3LetterDocumentReader": "BRA",
            "NonTranslatedDescription": "Brasil",
            "TranslatedDescription": "Brazil"
        },
        {
            "Id": 45,
            "IsoCode": "BS",
            "IsoCode3LetterDocumentReader": "BHS",
            "NonTranslatedDescription": "Bahamas",
            "TranslatedDescription": "Bahamas"
        },
        {
            "Id": 60,
            "IsoCode": "BT",
            "IsoCode3LetterDocumentReader": "BTN",
            "NonTranslatedDescription": "Butão",
            "TranslatedDescription": "Bhutan"
        },
        {
            "Id": 113,
            "IsoCode": "BV",
            "IsoCode3LetterDocumentReader": "BVT",
            "NonTranslatedDescription": "Ilhas Bouvet",
            "TranslatedDescription": "Bouvet Island"
        },
        {
            "Id": 55,
            "IsoCode": "BW",
            "IsoCode3LetterDocumentReader": "BWA",
            "NonTranslatedDescription": "Botswana",
            "TranslatedDescription": "Botswana"
        },
        {
            "Id": 52,
            "IsoCode": "BY",
            "IsoCode3LetterDocumentReader": "BLR",
            "NonTranslatedDescription": "Bielorrússia",
            "TranslatedDescription": "Belarus"
        },
        {
            "Id": 49,
            "IsoCode": "BZ",
            "IsoCode3LetterDocumentReader": "BLZ",
            "NonTranslatedDescription": "Belize",
            "TranslatedDescription": "Belize"
        },
        {
            "Id": 11,
            "IsoCode": "CA",
            "IsoCode3LetterDocumentReader": "CAN",
            "NonTranslatedDescription": "Canadá",
            "TranslatedDescription": "Canada"
        },
        {
            "Id": 116,
            "IsoCode": "CC",
            "IsoCode3LetterDocumentReader": "CCK",
            "NonTranslatedDescription": "Ilhas Cocos (Keeling)",
            "TranslatedDescription": "Cocos (Keeling) Islands"
        },
        {
            "Id": 73,
            "IsoCode": "CD",
            "IsoCode3LetterDocumentReader": "COD",
            "NonTranslatedDescription": "Congo (República Democrática do)",
            "TranslatedDescription": "Congo, the Democratic Republic of the"
        },
        {
            "Id": 66,
            "IsoCode": "CF",
            "IsoCode3LetterDocumentReader": "CAF",
            "NonTranslatedDescription": "Centro-Africana (República)",
            "TranslatedDescription": "Central African Republic"
        },
        {
            "Id": 72,
            "IsoCode": "CG",
            "IsoCode3LetterDocumentReader": "COG",
            "NonTranslatedDescription": "Congo",
            "TranslatedDescription": "Congo"
        },
        {
            "Id": 19,
            "IsoCode": "CH",
            "IsoCode3LetterDocumentReader": "CHE",
            "NonTranslatedDescription": "Suíça",
            "TranslatedDescription": "Switzerland"
        },
        {
            "Id": 76,
            "IsoCode": "CI",
            "IsoCode3LetterDocumentReader": "CIV",
            "NonTranslatedDescription": "Costa do Marfim",
            "TranslatedDescription": "Cote D'Ivoire"
        },
        {
            "Id": 117,
            "IsoCode": "CK",
            "IsoCode3LetterDocumentReader": "COK",
            "NonTranslatedDescription": "Ilhas Cook",
            "TranslatedDescription": "Cook Islands"
        },
        {
            "Id": 68,
            "IsoCode": "CL",
            "IsoCode3LetterDocumentReader": "CHL",
            "NonTranslatedDescription": "Chile",
            "TranslatedDescription": "Chile"
        },
        {
            "Id": 62,
            "IsoCode": "CM",
            "IsoCode3LetterDocumentReader": "CMR",
            "NonTranslatedDescription": "Camarões",
            "TranslatedDescription": "Cameroon"
        },
        {
            "Id": 25,
            "IsoCode": "CN",
            "IsoCode3LetterDocumentReader": "CHN",
            "NonTranslatedDescription": "China",
            "TranslatedDescription": "China"
        },
        {
            "Id": 70,
            "IsoCode": "CO",
            "IsoCode3LetterDocumentReader": "COL",
            "NonTranslatedDescription": "Colômbia",
            "TranslatedDescription": "Colombia"
        },
        {
            "Id": 77,
            "IsoCode": "CR",
            "IsoCode3LetterDocumentReader": "CRI",
            "NonTranslatedDescription": "Costa Rica",
            "TranslatedDescription": "Costa Rica"
        },
        {
            "Id": 79,
            "IsoCode": "CU",
            "IsoCode3LetterDocumentReader": "CUB",
            "NonTranslatedDescription": "Cuba",
            "TranslatedDescription": "Cuba"
        },
        {
            "Id": 61,
            "IsoCode": "CV",
            "IsoCode3LetterDocumentReader": "CPV",
            "NonTranslatedDescription": "Cabo Verde",
            "TranslatedDescription": "Cape Verde"
        },
        {
            "Id": 255,
            "IsoCode": "CW",
            "IsoCode3LetterDocumentReader": "CUW",
            "NonTranslatedDescription": "Curaçau",
            "TranslatedDescription": "Curaçau"
        },
        {
            "Id": 115,
            "IsoCode": "CX",
            "IsoCode3LetterDocumentReader": "CXR",
            "NonTranslatedDescription": "Ilhas Christmas",
            "TranslatedDescription": "Christmas Island"
        },
        {
            "Id": 69,
            "IsoCode": "CY",
            "IsoCode3LetterDocumentReader": "CYP",
            "NonTranslatedDescription": "Chipre",
            "TranslatedDescription": "Cyprus"
        },
        {
            "Id": 191,
            "IsoCode": "CZ",
            "IsoCode3LetterDocumentReader": "CZE",
            "NonTranslatedDescription": "República Checa",
            "TranslatedDescription": "Czech Republic"
        },
        {
            "Id": 8,
            "IsoCode": "DE",
            "IsoCode3LetterDocumentReader": "DEU",
            "NonTranslatedDescription": "Alemanha",
            "TranslatedDescription": "Germany"
        },
        {
            "Id": 136,
            "IsoCode": "DJ",
            "IsoCode3LetterDocumentReader": "DJI",
            "NonTranslatedDescription": "Jibuti",
            "TranslatedDescription": "Djibouti"
        },
        {
            "Id": 16,
            "IsoCode": "DK",
            "IsoCode3LetterDocumentReader": "DNK",
            "NonTranslatedDescription": "Dinamarca",
            "TranslatedDescription": "Denmark"
        },
        {
            "Id": 80,
            "IsoCode": "DM",
            "IsoCode3LetterDocumentReader": "DMA",
            "NonTranslatedDescription": "Domínica",
            "TranslatedDescription": "Dominica"
        },
        {
            "Id": 192,
            "IsoCode": "DO",
            "IsoCode3LetterDocumentReader": "DOM",
            "NonTranslatedDescription": "República Dominicana",
            "TranslatedDescription": "Dominican Republic"
        },
        {
            "Id": 32,
            "IsoCode": "DZ",
            "IsoCode3LetterDocumentReader": "DZA",
            "NonTranslatedDescription": "Argélia",
            "TranslatedDescription": "Algeria"
        },
        {
            "Id": 84,
            "IsoCode": "EC",
            "IsoCode3LetterDocumentReader": "ECU",
            "NonTranslatedDescription": "Equador",
            "TranslatedDescription": "Ecuador"
        },
        {
            "Id": 88,
            "IsoCode": "EE",
            "IsoCode3LetterDocumentReader": "EST",
            "NonTranslatedDescription": "Estónia",
            "TranslatedDescription": "Estonia"
        },
        {
            "Id": 81,
            "IsoCode": "EG",
            "IsoCode3LetterDocumentReader": "EGY",
            "NonTranslatedDescription": "Egipto",
            "TranslatedDescription": "Egypt"
        },
        {
            "Id": 206,
            "IsoCode": "EH",
            "IsoCode3LetterDocumentReader": "ESH",
            "NonTranslatedDescription": "Sara Ocidental",
            "TranslatedDescription": "Western Sahara"
        },
        {
            "Id": 85,
            "IsoCode": "ER",
            "IsoCode3LetterDocumentReader": "ERI",
            "NonTranslatedDescription": "Eritreia",
            "TranslatedDescription": "Eritrea"
        },
        {
            "Id": 7,
            "IsoCode": "ES",
            "IsoCode3LetterDocumentReader": "ESP",
            "NonTranslatedDescription": "Espanha",
            "TranslatedDescription": "Spain"
        },
        {
            "Id": 89,
            "IsoCode": "ET",
            "IsoCode3LetterDocumentReader": "ETH",
            "NonTranslatedDescription": "Etiópia",
            "TranslatedDescription": "Ethiopia"
        },
        {
            "Id": 91,
            "IsoCode": "FI",
            "IsoCode3LetterDocumentReader": "FIN",
            "NonTranslatedDescription": "Finlândia",
            "TranslatedDescription": "Finland"
        },
        {
            "Id": 120,
            "IsoCode": "FJ",
            "IsoCode3LetterDocumentReader": "FJI",
            "NonTranslatedDescription": "Ilhas Fiji",
            "TranslatedDescription": "Fiji"
        },
        {
            "Id": 118,
            "IsoCode": "FK",
            "IsoCode3LetterDocumentReader": "FLK",
            "NonTranslatedDescription": "Ilhas Falkland (Malvinas)",
            "TranslatedDescription": "Falkland Islands (Malvinas)"
        },
        {
            "Id": 164,
            "IsoCode": "FM",
            "IsoCode3LetterDocumentReader": "FSM",
            "NonTranslatedDescription": "Micronésia (Estados Federados da)",
            "TranslatedDescription": "Micronesia, Federated States of"
        },
        {
            "Id": 119,
            "IsoCode": "FO",
            "IsoCode3LetterDocumentReader": "FRO",
            "NonTranslatedDescription": "Ilhas Faroé",
            "TranslatedDescription": "Faroe Islands"
        },
        {
            "Id": 13,
            "IsoCode": "FR",
            "IsoCode3LetterDocumentReader": "FRA",
            "NonTranslatedDescription": "França",
            "TranslatedDescription": "France"
        },
        {
            "Id": 92,
            "IsoCode": "GA",
            "IsoCode3LetterDocumentReader": "GAB",
            "NonTranslatedDescription": "Gabão",
            "TranslatedDescription": "Gabon"
        },
        {
            "Id": 6,
            "IsoCode": "GB",
            "IsoCode3LetterDocumentReader": "GBR",
            "NonTranslatedDescription": "Reino Unido",
            "TranslatedDescription": "United Kingdom"
        },
        {
            "Id": 98,
            "IsoCode": "GD",
            "IsoCode3LetterDocumentReader": "GRD",
            "NonTranslatedDescription": "Granada",
            "TranslatedDescription": "Grenada"
        },
        {
            "Id": 95,
            "IsoCode": "GE",
            "IsoCode3LetterDocumentReader": "GEO",
            "NonTranslatedDescription": "Geórgia",
            "TranslatedDescription": "Georgia"
        },
        {
            "Id": 104,
            "IsoCode": "GF",
            "IsoCode3LetterDocumentReader": "GUF",
            "NonTranslatedDescription": "Guiana Francesa",
            "TranslatedDescription": "French Guiana"
        },
        {
            "Id": 248,
            "IsoCode": "GG",
            "IsoCode3LetterDocumentReader": "GGY",
            "NonTranslatedDescription": "Guernsey",
            "TranslatedDescription": "Guernsey"
        },
        {
            "Id": 94,
            "IsoCode": "GH",
            "IsoCode3LetterDocumentReader": "GHA",
            "NonTranslatedDescription": "Gana",
            "TranslatedDescription": "Ghana"
        },
        {
            "Id": 97,
            "IsoCode": "GI",
            "IsoCode3LetterDocumentReader": "GIB",
            "NonTranslatedDescription": "Gibraltar",
            "TranslatedDescription": "Gibraltar"
        },
        {
            "Id": 99,
            "IsoCode": "GL",
            "IsoCode3LetterDocumentReader": "GRL",
            "NonTranslatedDescription": "Gronelândia",
            "TranslatedDescription": "Greenland"
        },
        {
            "Id": 93,
            "IsoCode": "GM",
            "IsoCode3LetterDocumentReader": "GMB",
            "NonTranslatedDescription": "Gâmbia",
            "TranslatedDescription": "Gambia"
        },
        {
            "Id": 105,
            "IsoCode": "GN",
            "IsoCode3LetterDocumentReader": "GIN",
            "NonTranslatedDescription": "Guiné",
            "TranslatedDescription": "Guinea"
        },
        {
            "Id": 100,
            "IsoCode": "GP",
            "IsoCode3LetterDocumentReader": "GLP",
            "NonTranslatedDescription": "Guadalupe",
            "TranslatedDescription": "Guadeloupe"
        },
        {
            "Id": 106,
            "IsoCode": "GQ",
            "IsoCode3LetterDocumentReader": "GNQ",
            "NonTranslatedDescription": "Guiné Equatorial",
            "TranslatedDescription": "Equatorial Guinea"
        },
        {
            "Id": 24,
            "IsoCode": "GR",
            "IsoCode3LetterDocumentReader": "GRC",
            "NonTranslatedDescription": "Grécia",
            "TranslatedDescription": "Greece"
        },
        {
            "Id": 96,
            "IsoCode": "GS",
            "IsoCode3LetterDocumentReader": "SGS",
            "NonTranslatedDescription": "Georgia do Sul e Ilhas Sandwich",
            "TranslatedDescription": "South Georgia and the South Sandwich Islands"
        },
        {
            "Id": 102,
            "IsoCode": "GT",
            "IsoCode3LetterDocumentReader": "GTM",
            "NonTranslatedDescription": "Guatemala",
            "TranslatedDescription": "Guatemala"
        },
        {
            "Id": 101,
            "IsoCode": "GU",
            "IsoCode3LetterDocumentReader": "GUM",
            "NonTranslatedDescription": "Guam",
            "TranslatedDescription": "Guam"
        },
        {
            "Id": 107,
            "IsoCode": "GW",
            "IsoCode3LetterDocumentReader": "GNB",
            "NonTranslatedDescription": "Guiné-Bissau",
            "TranslatedDescription": "Guinea-Bissau"
        },
        {
            "Id": 103,
            "IsoCode": "GY",
            "IsoCode3LetterDocumentReader": "GUY",
            "NonTranslatedDescription": "Guiana",
            "TranslatedDescription": "Guyana"
        },
        {
            "Id": 110,
            "IsoCode": "HK",
            "IsoCode3LetterDocumentReader": "HKG",
            "NonTranslatedDescription": "Hong Kong",
            "TranslatedDescription": "Hong Kong"
        },
        {
            "Id": 121,
            "IsoCode": "HM",
            "IsoCode3LetterDocumentReader": "HMD",
            "NonTranslatedDescription": "Ilhas Heard e Ilhas McDonald",
            "TranslatedDescription": "Heard Island and Mcdonald Islands"
        },
        {
            "Id": 109,
            "IsoCode": "HN",
            "IsoCode3LetterDocumentReader": "HND",
            "NonTranslatedDescription": "Honduras",
            "TranslatedDescription": "Honduras"
        },
        {
            "Id": 78,
            "IsoCode": "HR",
            "IsoCode3LetterDocumentReader": "HRV",
            "NonTranslatedDescription": "Croácia",
            "TranslatedDescription": "Croatia"
        },
        {
            "Id": 108,
            "IsoCode": "HT",
            "IsoCode3LetterDocumentReader": "HTI",
            "NonTranslatedDescription": "Haiti",
            "TranslatedDescription": "Haiti"
        },
        {
            "Id": 111,
            "IsoCode": "HU",
            "IsoCode3LetterDocumentReader": "HUN",
            "NonTranslatedDescription": "Hungria",
            "TranslatedDescription": "Hungary"
        },
        {
            "Id": 130,
            "IsoCode": "ID",
            "IsoCode3LetterDocumentReader": "IDN",
            "NonTranslatedDescription": "Indonésia",
            "TranslatedDescription": "Indonesia"
        },
        {
            "Id": 15,
            "IsoCode": "IE",
            "IsoCode3LetterDocumentReader": "IRL",
            "NonTranslatedDescription": "Irlanda",
            "TranslatedDescription": "Ireland"
        },
        {
            "Id": 134,
            "IsoCode": "IL",
            "IsoCode3LetterDocumentReader": "ISR",
            "NonTranslatedDescription": "Israel",
            "TranslatedDescription": "Israel"
        },
        {
            "Id": 249,
            "IsoCode": "IM",
            "IsoCode3LetterDocumentReader": "IMN",
            "NonTranslatedDescription": "Ilha de Man",
            "TranslatedDescription": "Ilha de Man"
        },
        {
            "Id": 129,
            "IsoCode": "IN",
            "IsoCode3LetterDocumentReader": "IND",
            "NonTranslatedDescription": "Índia",
            "TranslatedDescription": "India"
        },
        {
            "Id": 222,
            "IsoCode": "IO",
            "IsoCode3LetterDocumentReader": "IOT",
            "NonTranslatedDescription": "Território Britânico do Oceano Índico",
            "TranslatedDescription": "British Indian Ocean Territory"
        },
        {
            "Id": 132,
            "IsoCode": "IQ",
            "IsoCode3LetterDocumentReader": "IRQ",
            "NonTranslatedDescription": "Iraque",
            "TranslatedDescription": "Iraq"
        },
        {
            "Id": 131,
            "IsoCode": "IR",
            "IsoCode3LetterDocumentReader": "IRN",
            "NonTranslatedDescription": "Irão (República Islâmica)",
            "TranslatedDescription": "Iran, Islamic Republic of"
        },
        {
            "Id": 133,
            "IsoCode": "IS",
            "IsoCode3LetterDocumentReader": "ISL",
            "NonTranslatedDescription": "Islândia",
            "TranslatedDescription": "Iceland"
        },
        {
            "Id": 18,
            "IsoCode": "IT",
            "IsoCode3LetterDocumentReader": "ITA",
            "NonTranslatedDescription": "Itália",
            "TranslatedDescription": "Italy"
        },
        {
            "Id": 250,
            "IsoCode": "JE",
            "IsoCode3LetterDocumentReader": "JEY",
            "NonTranslatedDescription": "Jersey",
            "TranslatedDescription": "Jersey"
        },
        {
            "Id": 135,
            "IsoCode": "JM",
            "IsoCode3LetterDocumentReader": "JAM",
            "NonTranslatedDescription": "Jamaica",
            "TranslatedDescription": "Jamaica"
        },
        {
            "Id": 137,
            "IsoCode": "JO",
            "IsoCode3LetterDocumentReader": "JOR",
            "NonTranslatedDescription": "Jordânia",
            "TranslatedDescription": "Jordan"
        },
        {
            "Id": 23,
            "IsoCode": "JP",
            "IsoCode3LetterDocumentReader": "JPN",
            "NonTranslatedDescription": "Japão",
            "TranslatedDescription": "Japan"
        },
        {
            "Id": 139,
            "IsoCode": "KE",
            "IsoCode3LetterDocumentReader": "KEN",
            "NonTranslatedDescription": "Kenya",
            "TranslatedDescription": "Kenya"
        },
        {
            "Id": 190,
            "IsoCode": "KG",
            "IsoCode3LetterDocumentReader": "KGZ",
            "NonTranslatedDescription": "Quirguizistão",
            "TranslatedDescription": "Kyrgyzstan"
        },
        {
            "Id": 63,
            "IsoCode": "KH",
            "IsoCode3LetterDocumentReader": "KHM",
            "NonTranslatedDescription": "Camboja",
            "TranslatedDescription": "Cambodia"
        },
        {
            "Id": 140,
            "IsoCode": "KI",
            "IsoCode3LetterDocumentReader": "KIR",
            "NonTranslatedDescription": "Kiribati",
            "TranslatedDescription": "Kiribati"
        },
        {
            "Id": 71,
            "IsoCode": "KM",
            "IsoCode3LetterDocumentReader": "COM",
            "NonTranslatedDescription": "Comores",
            "TranslatedDescription": "Comoros"
        },
        {
            "Id": 201,
            "IsoCode": "KN",
            "IsoCode3LetterDocumentReader": "KNA",
            "NonTranslatedDescription": "São Cristóvão e Nevis",
            "TranslatedDescription": "Saint Kitts and Nevis"
        },
        {
            "Id": 75,
            "IsoCode": "KP",
            "IsoCode3LetterDocumentReader": "PRK",
            "NonTranslatedDescription": "Coreia (República Popular Democrática da)",
            "TranslatedDescription": "Korea, Democratic People's Republic of"
        },
        {
            "Id": 74,
            "IsoCode": "KR",
            "IsoCode3LetterDocumentReader": "KOR",
            "NonTranslatedDescription": "Coreia (República da)",
            "TranslatedDescription": "Korea, Republic of"
        },
        {
            "Id": 141,
            "IsoCode": "KW",
            "IsoCode3LetterDocumentReader": "KWT",
            "NonTranslatedDescription": "Kuwait",
            "TranslatedDescription": "Kuwait"
        },
        {
            "Id": 114,
            "IsoCode": "KY",
            "IsoCode3LetterDocumentReader": "CYM",
            "NonTranslatedDescription": "Ilhas Caimão",
            "TranslatedDescription": "Cayman Islands"
        },
        {
            "Id": 65,
            "IsoCode": "KZ",
            "IsoCode3LetterDocumentReader": "KAZ",
            "NonTranslatedDescription": "Cazaquistão",
            "TranslatedDescription": "Kazakhstan"
        },
        {
            "Id": 142,
            "IsoCode": "LA",
            "IsoCode3LetterDocumentReader": "LAO",
            "NonTranslatedDescription": "Laos (Rep. Popular Democrática do)",
            "TranslatedDescription": "Lao People's Democratic Republic"
        },
        {
            "Id": 145,
            "IsoCode": "LB",
            "IsoCode3LetterDocumentReader": "LBN",
            "NonTranslatedDescription": "Líbano",
            "TranslatedDescription": "Lebanon"
        },
        {
            "Id": 199,
            "IsoCode": "LC",
            "IsoCode3LetterDocumentReader": "LCA",
            "NonTranslatedDescription": "Santa Lúcia",
            "TranslatedDescription": "Saint Lucia"
        },
        {
            "Id": 148,
            "IsoCode": "LI",
            "IsoCode3LetterDocumentReader": "LIE",
            "NonTranslatedDescription": "Liechtenstein",
            "TranslatedDescription": "Liechtenstein"
        },
        {
            "Id": 213,
            "IsoCode": "LK",
            "IsoCode3LetterDocumentReader": "LKA",
            "NonTranslatedDescription": "Sri Lanka",
            "TranslatedDescription": "Sri Lanka"
        },
        {
            "Id": 146,
            "IsoCode": "LR",
            "IsoCode3LetterDocumentReader": "LBR",
            "NonTranslatedDescription": "Libéria",
            "TranslatedDescription": "Liberia"
        },
        {
            "Id": 143,
            "IsoCode": "LS",
            "IsoCode3LetterDocumentReader": "LSO",
            "NonTranslatedDescription": "Lesoto",
            "TranslatedDescription": "Lesotho"
        },
        {
            "Id": 149,
            "IsoCode": "LT",
            "IsoCode3LetterDocumentReader": "LTU",
            "NonTranslatedDescription": "Lituânia",
            "TranslatedDescription": "Lithuania"
        },
        {
            "Id": 22,
            "IsoCode": "LU",
            "IsoCode3LetterDocumentReader": "LUX",
            "NonTranslatedDescription": "Luxemburgo",
            "TranslatedDescription": "Luxembourg"
        },
        {
            "Id": 144,
            "IsoCode": "LV",
            "IsoCode3LetterDocumentReader": "LVA",
            "NonTranslatedDescription": "Letónia",
            "TranslatedDescription": "Latvia"
        },
        {
            "Id": 147,
            "IsoCode": "LY",
            "IsoCode3LetterDocumentReader": "LBY",
            "NonTranslatedDescription": "Líbia (Jamahiriya Árabe da)",
            "TranslatedDescription": "Libyan Arab Jamahiriya"
        },
        {
            "Id": 158,
            "IsoCode": "MA",
            "IsoCode3LetterDocumentReader": "MAR",
            "NonTranslatedDescription": "Marrocos",
            "TranslatedDescription": "Morocco"
        },
        {
            "Id": 167,
            "IsoCode": "MC",
            "IsoCode3LetterDocumentReader": "MCO",
            "NonTranslatedDescription": "Mónaco",
            "TranslatedDescription": "Monaco"
        },
        {
            "Id": 166,
            "IsoCode": "MD",
            "IsoCode3LetterDocumentReader": "MDA",
            "NonTranslatedDescription": "Moldova (República de)",
            "TranslatedDescription": "Moldova, Republic of"
        },
        {
            "Id": 245,
            "IsoCode": "ME",
            "IsoCode3LetterDocumentReader": "MNE",
            "NonTranslatedDescription": "Montenegro",
            "TranslatedDescription": "Montenegro"
        },
        {
            "Id": 251,
            "IsoCode": "MF",
            "IsoCode3LetterDocumentReader": "MAF",
            "NonTranslatedDescription": "São Martinho (parte francesa)",
            "TranslatedDescription": "Collectivity of Saint Martin"
        },
        {
            "Id": 152,
            "IsoCode": "MG",
            "IsoCode3LetterDocumentReader": "MDG",
            "NonTranslatedDescription": "Madagáscar",
            "TranslatedDescription": "Madagascar"
        },
        {
            "Id": 123,
            "IsoCode": "MH",
            "IsoCode3LetterDocumentReader": "MHL",
            "NonTranslatedDescription": "Ilhas Marshall",
            "TranslatedDescription": "Marshall Islands"
        },
        {
            "Id": 151,
            "IsoCode": "MK",
            "IsoCode3LetterDocumentReader": "MKD",
            "NonTranslatedDescription": "Macedónia (antiga rep. jugoslava da)",
            "TranslatedDescription": "Macedonia, the Former Yugoslav Republic of"
        },
        {
            "Id": 156,
            "IsoCode": "ML",
            "IsoCode3LetterDocumentReader": "MLI",
            "NonTranslatedDescription": "Mali",
            "TranslatedDescription": "Mali"
        },
        {
            "Id": 170,
            "IsoCode": "MM",
            "IsoCode3LetterDocumentReader": "MMR",
            "NonTranslatedDescription": "Myanmar",
            "TranslatedDescription": "Myanmar"
        },
        {
            "Id": 168,
            "IsoCode": "MN",
            "IsoCode3LetterDocumentReader": "MNG",
            "NonTranslatedDescription": "Mongólia",
            "TranslatedDescription": "Mongolia"
        },
        {
            "Id": 150,
            "IsoCode": "MO",
            "IsoCode3LetterDocumentReader": "MAC",
            "NonTranslatedDescription": "Macau",
            "TranslatedDescription": "Macao"
        },
        {
            "Id": 122,
            "IsoCode": "MP",
            "IsoCode3LetterDocumentReader": "MNP",
            "NonTranslatedDescription": "Ilhas Marianas do Norte",
            "TranslatedDescription": "Northern Mariana Islands"
        },
        {
            "Id": 159,
            "IsoCode": "MQ",
            "IsoCode3LetterDocumentReader": "MTQ",
            "NonTranslatedDescription": "Martinica",
            "TranslatedDescription": "Martinique"
        },
        {
            "Id": 161,
            "IsoCode": "MR",
            "IsoCode3LetterDocumentReader": "MRT",
            "NonTranslatedDescription": "Mauritânia",
            "TranslatedDescription": "Mauritania"
        },
        {
            "Id": 169,
            "IsoCode": "MS",
            "IsoCode3LetterDocumentReader": "MSR",
            "NonTranslatedDescription": "Monserrate",
            "TranslatedDescription": "Montserrat"
        },
        {
            "Id": 157,
            "IsoCode": "MT",
            "IsoCode3LetterDocumentReader": "MLT",
            "NonTranslatedDescription": "Malta",
            "TranslatedDescription": "Malta"
        },
        {
            "Id": 160,
            "IsoCode": "MU",
            "IsoCode3LetterDocumentReader": "MUS",
            "NonTranslatedDescription": "Maurícias",
            "TranslatedDescription": "Mauritius"
        },
        {
            "Id": 155,
            "IsoCode": "MV",
            "IsoCode3LetterDocumentReader": "MDV",
            "NonTranslatedDescription": "Maldivas",
            "TranslatedDescription": "Maldives"
        },
        {
            "Id": 154,
            "IsoCode": "MW",
            "IsoCode3LetterDocumentReader": "MWI",
            "NonTranslatedDescription": "Malawi",
            "TranslatedDescription": "Malawi"
        },
        {
            "Id": 163,
            "IsoCode": "MX",
            "IsoCode3LetterDocumentReader": "MEX",
            "NonTranslatedDescription": "México",
            "TranslatedDescription": "Mexico"
        },
        {
            "Id": 153,
            "IsoCode": "MY",
            "IsoCode3LetterDocumentReader": "MYS",
            "NonTranslatedDescription": "Malásia",
            "TranslatedDescription": "Malaysia"
        },
        {
            "Id": 165,
            "IsoCode": "MZ",
            "IsoCode3LetterDocumentReader": "MOZ",
            "NonTranslatedDescription": "Moçambique",
            "TranslatedDescription": "Mozambique"
        },
        {
            "Id": 171,
            "IsoCode": "NA",
            "IsoCode3LetterDocumentReader": "NAM",
            "NonTranslatedDescription": "Namíbia",
            "TranslatedDescription": "Namibia"
        },
        {
            "Id": 178,
            "IsoCode": "NC",
            "IsoCode3LetterDocumentReader": "NCL",
            "NonTranslatedDescription": "Nova Caledónia",
            "TranslatedDescription": "New Caledonia"
        },
        {
            "Id": 175,
            "IsoCode": "NE",
            "IsoCode3LetterDocumentReader": "NER",
            "NonTranslatedDescription": "Niger",
            "TranslatedDescription": "Niger"
        },
        {
            "Id": 125,
            "IsoCode": "NF",
            "IsoCode3LetterDocumentReader": "NFK",
            "NonTranslatedDescription": "Ilhas Norfolk",
            "TranslatedDescription": "Norfolk Island"
        },
        {
            "Id": 176,
            "IsoCode": "NG",
            "IsoCode3LetterDocumentReader": "NGA",
            "NonTranslatedDescription": "Nigéria",
            "TranslatedDescription": "Nigeria"
        },
        {
            "Id": 174,
            "IsoCode": "NI",
            "IsoCode3LetterDocumentReader": "NIC",
            "NonTranslatedDescription": "Nicarágua",
            "TranslatedDescription": "Nicaragua"
        },
        {
            "Id": 26,
            "IsoCode": "NL",
            "IsoCode3LetterDocumentReader": "NLD",
            "NonTranslatedDescription": "Países Baixos",
            "TranslatedDescription": "Netherlands"
        },
        {
            "Id": 21,
            "IsoCode": "NO",
            "IsoCode3LetterDocumentReader": "NOR",
            "NonTranslatedDescription": "Noruega",
            "TranslatedDescription": "Norway"
        },
        {
            "Id": 173,
            "IsoCode": "NP",
            "IsoCode3LetterDocumentReader": "NPL",
            "NonTranslatedDescription": "Nepal",
            "TranslatedDescription": "Nepal"
        },
        {
            "Id": 172,
            "IsoCode": "NR",
            "IsoCode3LetterDocumentReader": "NRU",
            "NonTranslatedDescription": "Nauru",
            "TranslatedDescription": "Nauru"
        },
        {
            "Id": 177,
            "IsoCode": "NU",
            "IsoCode3LetterDocumentReader": "NIU",
            "NonTranslatedDescription": "Niue",
            "TranslatedDescription": "Niue"
        },
        {
            "Id": 29,
            "IsoCode": "NZ",
            "IsoCode3LetterDocumentReader": "NZL",
            "NonTranslatedDescription": "Nova Zelândia",
            "TranslatedDescription": "New Zealand"
        },
        {
            "Id": 179,
            "IsoCode": "OM",
            "IsoCode3LetterDocumentReader": "OMN",
            "NonTranslatedDescription": "Omã",
            "TranslatedDescription": "Oman"
        },
        {
            "Id": 181,
            "IsoCode": "PA",
            "IsoCode3LetterDocumentReader": "PAN",
            "NonTranslatedDescription": "Panamá",
            "TranslatedDescription": "Panama"
        },
        {
            "Id": 185,
            "IsoCode": "PE",
            "IsoCode3LetterDocumentReader": "PER",
            "NonTranslatedDescription": "Peru",
            "TranslatedDescription": "Peru"
        },
        {
            "Id": 187,
            "IsoCode": "PF",
            "IsoCode3LetterDocumentReader": "PYF",
            "NonTranslatedDescription": "Polinésia Francesa",
            "TranslatedDescription": "French Polynesia"
        },
        {
            "Id": 182,
            "IsoCode": "PG",
            "IsoCode3LetterDocumentReader": "PNG",
            "NonTranslatedDescription": "Papuásia-Nova Guiné",
            "TranslatedDescription": "Papua New Guinea"
        },
        {
            "Id": 90,
            "IsoCode": "PH",
            "IsoCode3LetterDocumentReader": "PHL",
            "NonTranslatedDescription": "Filipinas",
            "TranslatedDescription": "Philippines"
        },
        {
            "Id": 183,
            "IsoCode": "PK",
            "IsoCode3LetterDocumentReader": "PAK",
            "NonTranslatedDescription": "Paquistão",
            "TranslatedDescription": "Pakistan"
        },
        {
            "Id": 188,
            "IsoCode": "PL",
            "IsoCode3LetterDocumentReader": "POL",
            "NonTranslatedDescription": "Polónia",
            "TranslatedDescription": "Poland"
        },
        {
            "Id": 203,
            "IsoCode": "PM",
            "IsoCode3LetterDocumentReader": "SPM",
            "NonTranslatedDescription": "São Pedro e Miquelon",
            "TranslatedDescription": "Saint Pierre and Miquelon"
        },
        {
            "Id": 186,
            "IsoCode": "PN",
            "IsoCode3LetterDocumentReader": "PCN",
            "NonTranslatedDescription": "Pitcairn",
            "TranslatedDescription": "Pitcairn"
        },
        {
            "Id": 189,
            "IsoCode": "PR",
            "IsoCode3LetterDocumentReader": "PRI",
            "NonTranslatedDescription": "Porto Rico",
            "TranslatedDescription": "Puerto Rico"
        },
        {
            "Id": 223,
            "IsoCode": "PS",
            "IsoCode3LetterDocumentReader": "PSE",
            "NonTranslatedDescription": "Palestina",
            "TranslatedDescription": "Palestine"
        },
        {
            "Id": 2,
            "IsoCode": "PT",
            "IsoCode3LetterDocumentReader": "PRT",
            "NonTranslatedDescription": "Portugal",
            "TranslatedDescription": "Portugal"
        },
        {
            "Id": 180,
            "IsoCode": "PW",
            "IsoCode3LetterDocumentReader": "PLW",
            "NonTranslatedDescription": "Palau",
            "TranslatedDescription": "Palau"
        },
        {
            "Id": 184,
            "IsoCode": "PY",
            "IsoCode3LetterDocumentReader": "PRY",
            "NonTranslatedDescription": "Paraguai",
            "TranslatedDescription": "Paraguay"
        },
        {
            "Id": 64,
            "IsoCode": "QA",
            "IsoCode3LetterDocumentReader": "QAT",
            "NonTranslatedDescription": "Catar",
            "TranslatedDescription": "Qatar"
        },
        {
            "Id": 193,
            "IsoCode": "RE",
            "IsoCode3LetterDocumentReader": "REU",
            "NonTranslatedDescription": "Reunião",
            "TranslatedDescription": "Reunion"
        },
        {
            "Id": 253,
            "IsoCode": "RKS",
            "IsoCode3LetterDocumentReader": "RKS",
            "NonTranslatedDescription": "Kosovo, República do",
            "TranslatedDescription": "Kosovo, República do"
        },
        {
            "Id": 194,
            "IsoCode": "RO",
            "IsoCode3LetterDocumentReader": "ROU",
            "NonTranslatedDescription": "Roménia",
            "TranslatedDescription": "Romania"
        },
        {
            "Id": 246,
            "IsoCode": "RS",
            "IsoCode3LetterDocumentReader": "SRB",
            "NonTranslatedDescription": "Sérvia",
            "TranslatedDescription": "Sérvia"
        },
        {
            "Id": 20,
            "IsoCode": "RU",
            "IsoCode3LetterDocumentReader": "RUS",
            "NonTranslatedDescription": "Rússia (Federação da)",
            "TranslatedDescription": "Russian Federation"
        },
        {
            "Id": 195,
            "IsoCode": "RW",
            "IsoCode3LetterDocumentReader": "RWA",
            "NonTranslatedDescription": "Ruanda",
            "TranslatedDescription": "Rwanda"
        },
        {
            "Id": 39,
            "IsoCode": "SA",
            "IsoCode3LetterDocumentReader": "SAU",
            "NonTranslatedDescription": "Arábia Saudita",
            "TranslatedDescription": "Saudi Arabia"
        },
        {
            "Id": 126,
            "IsoCode": "SB",
            "IsoCode3LetterDocumentReader": "SLB",
            "NonTranslatedDescription": "Ilhas Salomão",
            "TranslatedDescription": "Solomon Islands"
        },
        {
            "Id": 209,
            "IsoCode": "SC",
            "IsoCode3LetterDocumentReader": "SYC",
            "NonTranslatedDescription": "Seychelles",
            "TranslatedDescription": "Seychelles"
        },
        {
            "Id": 215,
            "IsoCode": "SD",
            "IsoCode3LetterDocumentReader": "SDN",
            "NonTranslatedDescription": "Sudão",
            "TranslatedDescription": "Sudan"
        },
        {
            "Id": 14,
            "IsoCode": "SE",
            "IsoCode3LetterDocumentReader": "SWE",
            "NonTranslatedDescription": "Suécia",
            "TranslatedDescription": "Sweden"
        },
        {
            "Id": 210,
            "IsoCode": "SG",
            "IsoCode3LetterDocumentReader": "SGP",
            "NonTranslatedDescription": "Singapura",
            "TranslatedDescription": "Singapore"
        },
        {
            "Id": 198,
            "IsoCode": "SH",
            "IsoCode3LetterDocumentReader": "SHN",
            "NonTranslatedDescription": "Santa Helena",
            "TranslatedDescription": "Saint Helena"
        },
        {
            "Id": 87,
            "IsoCode": "SI",
            "IsoCode3LetterDocumentReader": "SVN",
            "NonTranslatedDescription": "Eslovénia",
            "TranslatedDescription": "Slovenia"
        },
        {
            "Id": 217,
            "IsoCode": "SJ",
            "IsoCode3LetterDocumentReader": "SJM",
            "NonTranslatedDescription": "Svalbard e a Ilha de Jan Mayen",
            "TranslatedDescription": "Svalbard and Jan Mayen"
        },
        {
            "Id": 86,
            "IsoCode": "SK",
            "IsoCode3LetterDocumentReader": "SVK",
            "NonTranslatedDescription": "Eslovaca (República)",
            "TranslatedDescription": "Slovakia"
        },
        {
            "Id": 208,
            "IsoCode": "SL",
            "IsoCode3LetterDocumentReader": "SLE",
            "NonTranslatedDescription": "Serra Leoa",
            "TranslatedDescription": "Sierra Leone"
        },
        {
            "Id": 202,
            "IsoCode": "SM",
            "IsoCode3LetterDocumentReader": "SMR",
            "NonTranslatedDescription": "São Marino",
            "TranslatedDescription": "San Marino"
        },
        {
            "Id": 207,
            "IsoCode": "SN",
            "IsoCode3LetterDocumentReader": "SEN",
            "NonTranslatedDescription": "Senegal",
            "TranslatedDescription": "Senegal"
        },
        {
            "Id": 212,
            "IsoCode": "SO",
            "IsoCode3LetterDocumentReader": "SOM",
            "NonTranslatedDescription": "Somália",
            "TranslatedDescription": "Somalia"
        },
        {
            "Id": 216,
            "IsoCode": "SR",
            "IsoCode3LetterDocumentReader": "SUR",
            "NonTranslatedDescription": "Suriname",
            "TranslatedDescription": "Suriname"
        },
        {
            "Id": 256,
            "IsoCode": "SS",
            "IsoCode3LetterDocumentReader": "SSD",
            "NonTranslatedDescription": "Sudão do Sul",
            "TranslatedDescription": "South Sudan"
        },
        {
            "Id": 204,
            "IsoCode": "ST",
            "IsoCode3LetterDocumentReader": "STP",
            "NonTranslatedDescription": "São Tomé e Príncipe",
            "TranslatedDescription": "Sao Tome and Principe"
        },
        {
            "Id": 82,
            "IsoCode": "SV",
            "IsoCode3LetterDocumentReader": "SLV",
            "NonTranslatedDescription": "El Salvador",
            "TranslatedDescription": "El Salvador"
        },
        {
            "Id": 257,
            "IsoCode": "SX",
            "IsoCode3LetterDocumentReader": "SXM",
            "NonTranslatedDescription": "São Martinho (Países Baixos)",
            "TranslatedDescription": "Sint Maarten"
        },
        {
            "Id": 211,
            "IsoCode": "SY",
            "IsoCode3LetterDocumentReader": "SYR",
            "NonTranslatedDescription": "Síria (República Árabe da)",
            "TranslatedDescription": "Syrian Arab Republic"
        },
        {
            "Id": 214,
            "IsoCode": "SZ",
            "IsoCode3LetterDocumentReader": "SWZ",
            "NonTranslatedDescription": "Suazilândia",
            "TranslatedDescription": "Swaziland"
        },
        {
            "Id": 231,
            "IsoCode": "TC",
            "IsoCode3LetterDocumentReader": "TCA",
            "NonTranslatedDescription": "Turcos e Caicos (Ilhas)",
            "TranslatedDescription": "Turks and Caicos Islands"
        },
        {
            "Id": 67,
            "IsoCode": "TD",
            "IsoCode3LetterDocumentReader": "TCD",
            "NonTranslatedDescription": "Chade",
            "TranslatedDescription": "Chad"
        },
        {
            "Id": 224,
            "IsoCode": "TF",
            "IsoCode3LetterDocumentReader": "ATF",
            "NonTranslatedDescription": "Territórios Franceses do Sul",
            "TranslatedDescription": "French Southern Territories"
        },
        {
            "Id": 226,
            "IsoCode": "TG",
            "IsoCode3LetterDocumentReader": "TGO",
            "NonTranslatedDescription": "Togo",
            "TranslatedDescription": "Togo"
        },
        {
            "Id": 218,
            "IsoCode": "TH",
            "IsoCode3LetterDocumentReader": "THA",
            "NonTranslatedDescription": "Tailândia",
            "TranslatedDescription": "Thailand"
        },
        {
            "Id": 220,
            "IsoCode": "TJ",
            "IsoCode3LetterDocumentReader": "TJK",
            "NonTranslatedDescription": "Tajiquistão",
            "TranslatedDescription": "Tajikistan"
        },
        {
            "Id": 227,
            "IsoCode": "TK",
            "IsoCode3LetterDocumentReader": "TKL",
            "NonTranslatedDescription": "Tokelau",
            "TranslatedDescription": "Tokelau"
        },
        {
            "Id": 225,
            "IsoCode": "TL",
            "IsoCode3LetterDocumentReader": "TLS",
            "NonTranslatedDescription": "Timor Leste",
            "TranslatedDescription": "Timor-Leste"
        },
        {
            "Id": 232,
            "IsoCode": "TM",
            "IsoCode3LetterDocumentReader": "TKM",
            "NonTranslatedDescription": "Turquemenistão",
            "TranslatedDescription": "Turkmenistan"
        },
        {
            "Id": 230,
            "IsoCode": "TN",
            "IsoCode3LetterDocumentReader": "TUN",
            "NonTranslatedDescription": "Tunísia",
            "TranslatedDescription": "Tunisia"
        },
        {
            "Id": 228,
            "IsoCode": "TO",
            "IsoCode3LetterDocumentReader": "TON",
            "NonTranslatedDescription": "Tonga",
            "TranslatedDescription": "Tonga"
        },
        {
            "Id": 233,
            "IsoCode": "TR",
            "IsoCode3LetterDocumentReader": "TUR",
            "NonTranslatedDescription": "Turquia",
            "TranslatedDescription": "Turkey"
        },
        {
            "Id": 229,
            "IsoCode": "TT",
            "IsoCode3LetterDocumentReader": "TTO",
            "NonTranslatedDescription": "Trindade e Tobago",
            "TranslatedDescription": "Trinidad and Tobago"
        },
        {
            "Id": 234,
            "IsoCode": "TV",
            "IsoCode3LetterDocumentReader": "TUV",
            "NonTranslatedDescription": "Tuvalu",
            "TranslatedDescription": "Tuvalu"
        },
        {
            "Id": 219,
            "IsoCode": "TW",
            "IsoCode3LetterDocumentReader": "TWN",
            "NonTranslatedDescription": "Taiwan (Província da China)",
            "TranslatedDescription": "Taiwan, Province of China"
        },
        {
            "Id": 221,
            "IsoCode": "TZ",
            "IsoCode3LetterDocumentReader": "TZA",
            "NonTranslatedDescription": "Tanzânia, República Unida da",
            "TranslatedDescription": "Tanzania, United Republic of"
        },
        {
            "Id": 235,
            "IsoCode": "UA",
            "IsoCode3LetterDocumentReader": "UKR",
            "NonTranslatedDescription": "Ucrânia",
            "TranslatedDescription": "Ukraine"
        },
        {
            "Id": 236,
            "IsoCode": "UG",
            "IsoCode3LetterDocumentReader": "UGA",
            "NonTranslatedDescription": "Uganda",
            "TranslatedDescription": "Uganda"
        },
        {
            "Id": 124,
            "IsoCode": "UM",
            "IsoCode3LetterDocumentReader": "UMI",
            "NonTranslatedDescription": "Ilhas menores distantes dos Estados Unidos",
            "TranslatedDescription": "United States Minor Outlying Islands"
        },
        {
            "Id": 12,
            "IsoCode": "US",
            "IsoCode3LetterDocumentReader": "USA",
            "NonTranslatedDescription": "Estados Unidos",
            "TranslatedDescription": "United States"
        },
        {
            "Id": 237,
            "IsoCode": "UY",
            "IsoCode3LetterDocumentReader": "URY",
            "NonTranslatedDescription": "Uruguai",
            "TranslatedDescription": "Uruguay"
        },
        {
            "Id": 238,
            "IsoCode": "UZ",
            "IsoCode3LetterDocumentReader": "UZB",
            "NonTranslatedDescription": "Usbequistão",
            "TranslatedDescription": "Uzbekistan"
        },
        {
            "Id": 200,
            "IsoCode": "VA",
            "IsoCode3LetterDocumentReader": "VAT",
            "NonTranslatedDescription": "Santa Sé (Cidade Estado do Vaticano)",
            "TranslatedDescription": "Holy See (Vatican City State)"
        },
        {
            "Id": 205,
            "IsoCode": "VC",
            "IsoCode3LetterDocumentReader": "VCT",
            "NonTranslatedDescription": "São Vicente e Granadinas",
            "TranslatedDescription": "Saint Vincent and the Grenadines"
        },
        {
            "Id": 240,
            "IsoCode": "VE",
            "IsoCode3LetterDocumentReader": "VEN",
            "NonTranslatedDescription": "Venezuela",
            "TranslatedDescription": "Venezuela"
        },
        {
            "Id": 127,
            "IsoCode": "VG",
            "IsoCode3LetterDocumentReader": "VGB",
            "NonTranslatedDescription": "Ilhas Virgens (Britânicas)",
            "TranslatedDescription": "Virgin Islands, British"
        },
        {
            "Id": 128,
            "IsoCode": "VI",
            "IsoCode3LetterDocumentReader": "VIR",
            "NonTranslatedDescription": "Ilhas Virgens (Estados Unidos)",
            "TranslatedDescription": "Virgin Islands, U.s."
        },
        {
            "Id": 241,
            "IsoCode": "VN",
            "IsoCode3LetterDocumentReader": "VNM",
            "NonTranslatedDescription": "Vietname",
            "TranslatedDescription": "Viet Nam"
        },
        {
            "Id": 239,
            "IsoCode": "VU",
            "IsoCode3LetterDocumentReader": "VUT",
            "NonTranslatedDescription": "Vanuatu",
            "TranslatedDescription": "Vanuatu"
        },
        {
            "Id": 242,
            "IsoCode": "WF",
            "IsoCode3LetterDocumentReader": "WLF",
            "NonTranslatedDescription": "Wallis e Futuna (Ilha)",
            "TranslatedDescription": "Wallis and Futuna"
        },
        {
            "Id": 196,
            "IsoCode": "WS",
            "IsoCode3LetterDocumentReader": "WSM",
            "NonTranslatedDescription": "Samoa",
            "TranslatedDescription": "Samoa"
        },
        {
            "Id": 112,
            "IsoCode": "YE",
            "IsoCode3LetterDocumentReader": "YEM",
            "NonTranslatedDescription": "Iémen",
            "TranslatedDescription": "Yemen"
        },
        {
            "Id": 162,
            "IsoCode": "YT",
            "IsoCode3LetterDocumentReader": "MYT",
            "NonTranslatedDescription": "Mayotte",
            "TranslatedDescription": "Mayotte"
        },
        {
            "Id": 138,
            "IsoCode": "YU",
            "IsoCode3LetterDocumentReader": "XXX",
            "NonTranslatedDescription": "Jugoslávia",
            "TranslatedDescription": "Jugoslávia"
        },
        {
            "Id": 30,
            "IsoCode": "ZA",
            "IsoCode3LetterDocumentReader": "ZAF",
            "NonTranslatedDescription": "África do Sul",
            "TranslatedDescription": "South Africa"
        },
        {
            "Id": 243,
            "IsoCode": "ZM",
            "IsoCode3LetterDocumentReader": "ZMB",
            "NonTranslatedDescription": "Zâmbia",
            "TranslatedDescription": "Zambia"
        },
        {
            "Id": 244,
            "IsoCode": "ZW",
            "IsoCode3LetterDocumentReader": "ZWE",
            "NonTranslatedDescription": "Zimbabwe",
            "TranslatedDescription": "Zimbabwe"
        }
    ],
    "RegionList": [
        {
            "Id": 414,
            "Code": "Aveiro",
            "Description": "Aveiro",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 409,
            "Code": "Beja",
            "Description": "Beja",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 403,
            "Code": "Braga",
            "Description": "Braga",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 418,
            "Code": "Bragança",
            "Description": "Bragança",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 416,
            "Code": "Castelo Br",
            "Description": "Castelo Branco",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 405,
            "Code": "Coimbra",
            "Description": "Coimbra",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 413,
            "Code": "Évora",
            "Description": "Évora",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 401,
            "Code": "Faro",
            "Description": "Faro",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 410,
            "Code": "Guarda",
            "Description": "Guarda",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 425,
            "Code": "Graciosa",
            "Description": "Ilha da Graciosa",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 402,
            "Code": "Madeira",
            "Description": "Ilha da Madeira",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 417,
            "Code": "Flores",
            "Description": "Ilha das Flores",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 422,
            "Code": "Porto Sant",
            "Description": "Ilha de Porto Santo",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 424,
            "Code": "Santa Mari",
            "Description": "Ilha de Santa Maria",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 426,
            "Code": "São Jorge",
            "Description": "Ilha de São Jorge",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 420,
            "Code": "São Miguel",
            "Description": "Ilha de São Miguel",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 427,
            "Code": "Corvo",
            "Description": "Ilha do Corvo",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 421,
            "Code": "Faial",
            "Description": "Ilha do Faial",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 423,
            "Code": "Pico",
            "Description": "Ilha do Pico",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 419,
            "Code": "Terceira",
            "Description": "Ilha Terceira",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 407,
            "Code": "Leiria",
            "Description": "Leiria",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 404,
            "Code": "Lisboa",
            "Description": "Lisboa",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 415,
            "Code": "Portalegre",
            "Description": "Portalegre",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 400,
            "Code": "Porto",
            "Description": "Porto",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 408,
            "Code": "Santarém",
            "Description": "Santarém",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 406,
            "Code": "Setúbal",
            "Description": "Setúbal",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 399,
            "Code": "Viana do C",
            "Description": "Viana do Castelo",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 412,
            "Code": "Vila Real",
            "Description": "Vila Real",
            "CountryCode": "PT",
            "CountryId": 2
        },
        {
            "Id": 411,
            "Code": "Viseu",
            "Description": "Viseu",
            "CountryCode": "PT",
            "CountryId": 2
        }
    ],
    "DialCodes": {
        "AT": "+43",
        "PT": "+351",
        "GB": "+44",
        "ES": "+34",
        "DE": "+49",
        "BE": "+32",
        "BR": "+55",
        "CA": "+1",
        "US": "+1",
        "FR": "+33",
        "SE": "+46",
        "IE": "+353",
        "DK": "+45",
        "IT": "+39",
        "CH": "+41",
        "RU": "+7",
        "NO": "+47",
        "LU": "+352",
        "JP": "+81",
        "GR": "+30",
        "CN": "+86",
        "NL": "+31",
        "AF": "+93",
        "NZ": "+64",
        "ZA": "+27",
        "AL": "+355",
        "DZ": "+213",
        "AD": "+376",
        "AO": "+244",
        "AI": "+1264",
        "AQ": "+672",
        "AG": "+1268",
        "AN": "",
        "SA": "+966",
        "AR": "+54",
        "AM": "+374",
        "AW": "+297",
        "AU": "+61",
        "AZ": "+994",
        "BS": "+1242",
        "BD": "+880",
        "BB": "+1246",
        "BH": "+973",
        "BZ": "+501",
        "BJ": "+229",
        "BM": "+1441",
        "BY": "+375",
        "BO": "+591",
        "BA": "+387",
        "BW": "+267",
        "BN": "+673",
        "BG": "+359",
        "BF": "+226",
        "BI": "+257",
        "BT": "+975",
        "CV": "+238",
        "CM": "+237",
        "KH": "+855",
        "QA": "+974",
        "KZ": "+7",
        "CF": "+236",
        "TD": "+235",
        "CL": "+56",
        "CY": "+357",
        "CO": "+57",
        "KM": "+269",
        "CG": "+242",
        "CD": "+243",
        "KR": "+82",
        "KP": "+850",
        "CI": "+225",
        "CR": "+506",
        "HR": "+385",
        "CU": "+53",
        "DM": "+1767",
        "EG": "+20",
        "SV": "+503",
        "AE": "+971",
        "EC": "+593",
        "ER": "+291",
        "SK": "+421",
        "SI": "+386",
        "EE": "+372",
        "ET": "+251",
        "PH": "+63",
        "FI": "+358",
        "GA": "+241",
        "GM": "+220",
        "GH": "+233",
        "GE": "+995",
        "GS": "+500",
        "GI": "+350",
        "GD": "+1473",
        "GL": "+299",
        "GP": "+590",
        "GU": "+1671",
        "GT": "+502",
        "GY": "+592",
        "GF": "+594",
        "GN": "+224",
        "GQ": "+240",
        "GW": "+245",
        "HT": "+509",
        "HN": "+504",
        "HK": "+852",
        "HU": "+36",
        "YE": "+967",
        "BV": "+47",
        "KY": "+1345",
        "CX": "+61",
        "CC": "+61",
        "CK": "+682",
        "FK": "+500",
        "FO": "+298",
        "FJ": "+679",
        "HM": "+672",
        "MP": "+1670",
        "MH": "+692",
        "UM": "",
        "NF": "+672",
        "SB": "+677",
        "VG": "+1284",
        "VI": "+1340",
        "IN": "+91",
        "ID": "+62",
        "IR": "+98",
        "IQ": "+964",
        "IS": "+354",
        "IL": "+972",
        "JM": "+1876",
        "DJ": "+253",
        "JO": "+962",
        "YU": "",
        "KE": "+254",
        "KI": "+686",
        "KW": "+965",
        "LA": "+856",
        "LS": "+266",
        "LV": "+371",
        "LB": "+961",
        "LR": "+231",
        "LY": "+218",
        "LI": "+423",
        "LT": "+370",
        "MO": "+853",
        "MK": "+389",
        "MG": "+261",
        "MY": "+60",
        "MW": "+265",
        "MV": "+960",
        "ML": "+223",
        "MT": "+356",
        "MA": "+212",
        "MQ": "+596",
        "MU": "+230",
        "MR": "+222",
        "YT": "+262",
        "MX": "+52",
        "FM": "+691",
        "MZ": "+258",
        "MD": "+373",
        "MC": "+377",
        "MN": "+976",
        "MS": "+1664",
        "MM": "+95",
        "NA": "+264",
        "NR": "+674",
        "NP": "+977",
        "NI": "+505",
        "NE": "+227",
        "NG": "+234",
        "NU": "+683",
        "NC": "+687",
        "OM": "+968",
        "PW": "+680",
        "PA": "+507",
        "PG": "+675",
        "PK": "+92",
        "PY": "+595",
        "PE": "+51",
        "PN": "+870",
        "PF": "+689",
        "PL": "+48",
        "PR": "+1",
        "KG": "+996",
        "CZ": "+420",
        "DO": "+1",
        "RE": "+262",
        "RO": "+40",
        "RW": "+250",
        "WS": "+685",
        "AS": "+1684",
        "SH": "+290 n",
        "LC": "+1758",
        "VA": "+3906",
        "KN": "+1869",
        "SM": "+378",
        "PM": "+508",
        "ST": "+239",
        "VC": "+1784",
        "EH": "+212",
        "SN": "+221",
        "SL": "+232",
        "SC": "+248",
        "SG": "+65",
        "SY": "+963",
        "SO": "+252",
        "LK": "+94",
        "SZ": "+268",
        "SD": "+249",
        "SR": "+597",
        "SJ": "+47",
        "TH": "+66",
        "TW": "+886",
        "TJ": "+992",
        "TZ": "+255",
        "IO": "+246",
        "PS": "+970",
        "TF": "+262",
        "TL": "+670",
        "TG": "+228",
        "TK": "+690",
        "TO": "+676",
        "TT": "+1868",
        "TN": "+216",
        "TC": "+1649",
        "TM": "+993",
        "TR": "+90",
        "TV": "+688",
        "UA": "+380",
        "UG": "+256",
        "UY": "+598",
        "UZ": "+998",
        "VU": "+678",
        "VE": "+58",
        "VN": "+84",
        "WF": "+681",
        "ZM": "+260",
        "ZW": "+263",
        "ME": "+382",
        "RS": "+381",
        "AX": "+358",
        "GG": "+44",
        "IM": "+44",
        "JE": "+44",
        "MF": "+590",
        "BL": "+590",
        "RKS": "+383",
        "BQ": "+599",
        "CW": "+5999",
        "SS": "+211",
        "SX": "+1721"
    },
    "mainDialCodes": [
        "PRT",
        "GBR",
        "DEU",
        "ESP",
        "FRA",
        "USA"
    ],
    "DataConsents": [
        {
            "Id": 8,
            "IsGeneralTerms": true,
            "Code": "SELF-RESPONSABILITY",
            "Description": "The signatory acknowledges and accepts all terms and conditions",
            "IsMandatory": true,
            "TextTitle": [
                {
                    "Culture": "en-US",
                    "TextPlain": "Terms & Conditions"
                },
                {
                    "Culture": "en-GB",
                    "TextPlain": "Terms & Conditions"
                },
                {
                    "Culture": "pt-PT",
                    "TextPlain": "Termos & Condições"
                }
            ],
            "TextShort": [
                {
                    "Culture": "en-US",
                    "TextPlain": "Terms & Conditions"
                },
                {
                    "Culture": "en-GB",
                    "TextPlain": "Terms & Conditions"
                },
                {
                    "Culture": "pt-PT",
                    "TextPlain": "Termos & Condições"
                }
            ],
            "TextLong": [
                {
                    "Culture": "en-US",
                    "TextPlain": "Safes are provided in all guest rooms. Management assumes no responsibility for any belongings in the room or other hotel locations. Any valuable items shall be declared and stored in the safes available at the front desk. The signatory acknowledges and accepts all registered charges until balance has been paid, as well as any damage to the Hotel property. The Hotel reserves all rights to debit the guest credit card for any outstanding amount."
                },
                {
                    "Culture": "pt-PT",
                    "TextPlain": "São disponibilizados cofres em todos os quartos de hóspedes. A gerência não assume responsabilidade por quaisquer pertences no quarto ou noutras áreas do hotel. Quaisquer objetos de valor devem ser declarados e guardados nos cofres disponíveis na receção. O signatário reconhece e aceita todas as despesas registadas até que o saldo seja pago, bem como quaisquer danos à propriedade do Hotel. O Hotel reserva-se o direito de debitar no cartão de crédito do hóspede qualquer valor em dívida."
                },
                {
                    "Culture": "en-GB",
                    "TextPlain": "Safes are provided in all guest rooms. Management assumes no responsibility for any belongings in the room or other hotel locations. Any valuable items shall be declared and stored in the safes available at the front desk. The signatory acknowledges and accepts all registered charges until balance has been paid, as well as any damage to the Hotel property. The Hotel reserves all rights to debit the guest credit card for any outstanding amount."
                }
            ]
        }
    ],
    "SocialNetworks": [],
    "Images": {
        "DefaultBackcolor": "#808080",
        "DefaultForecolor": "white",
        "HotelImage": ""
    },
    "GuestNotifications": [
    ]
}

export const previewSchedules = {
    "schedules": [
        {
            "FromDate": "2025-02-03T12:15:00.000",
            "ToDate": "2025-02-03T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T12:30:00.000",
            "ToDate": "2025-02-03T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T12:45:00.000",
            "ToDate": "2025-02-03T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T13:00:00.000",
            "ToDate": "2025-02-03T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T13:15:00.000",
            "ToDate": "2025-02-03T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T13:30:00.000",
            "ToDate": "2025-02-03T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T13:45:00.000",
            "ToDate": "2025-02-03T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T14:00:00.000",
            "ToDate": "2025-02-03T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T14:15:00.000",
            "ToDate": "2025-02-03T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T14:30:00.000",
            "ToDate": "2025-02-03T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T14:45:00.000",
            "ToDate": "2025-02-03T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T18:00:00.000",
            "ToDate": "2025-02-03T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T18:15:00.000",
            "ToDate": "2025-02-03T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T18:30:00.000",
            "ToDate": "2025-02-03T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T18:45:00.000",
            "ToDate": "2025-02-03T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T19:00:00.000",
            "ToDate": "2025-02-03T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T19:15:00.000",
            "ToDate": "2025-02-03T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T19:30:00.000",
            "ToDate": "2025-02-03T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T19:45:00.000",
            "ToDate": "2025-02-03T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T20:00:00.000",
            "ToDate": "2025-02-03T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T20:15:00.000",
            "ToDate": "2025-02-03T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T20:30:00.000",
            "ToDate": "2025-02-03T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T20:45:00.000",
            "ToDate": "2025-02-03T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T21:00:00.000",
            "ToDate": "2025-02-03T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T21:15:00.000",
            "ToDate": "2025-02-03T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T21:30:00.000",
            "ToDate": "2025-02-03T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T21:45:00.000",
            "ToDate": "2025-02-03T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T22:00:00.000",
            "ToDate": "2025-02-03T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T22:15:00.000",
            "ToDate": "2025-02-03T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T22:30:00.000",
            "ToDate": "2025-02-03T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T22:45:00.000",
            "ToDate": "2025-02-03T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T23:00:00.000",
            "ToDate": "2025-02-03T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-03T23:15:00.000",
            "ToDate": "2025-02-03T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T12:15:00.000",
            "ToDate": "2025-02-04T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T12:30:00.000",
            "ToDate": "2025-02-04T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T12:45:00.000",
            "ToDate": "2025-02-04T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T13:00:00.000",
            "ToDate": "2025-02-04T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T13:15:00.000",
            "ToDate": "2025-02-04T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T13:30:00.000",
            "ToDate": "2025-02-04T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T13:45:00.000",
            "ToDate": "2025-02-04T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T14:00:00.000",
            "ToDate": "2025-02-04T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T14:15:00.000",
            "ToDate": "2025-02-04T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T14:30:00.000",
            "ToDate": "2025-02-04T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T14:45:00.000",
            "ToDate": "2025-02-04T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T18:00:00.000",
            "ToDate": "2025-02-04T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T18:15:00.000",
            "ToDate": "2025-02-04T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T18:30:00.000",
            "ToDate": "2025-02-04T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T18:45:00.000",
            "ToDate": "2025-02-04T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T19:00:00.000",
            "ToDate": "2025-02-04T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T19:15:00.000",
            "ToDate": "2025-02-04T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T19:30:00.000",
            "ToDate": "2025-02-04T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T19:45:00.000",
            "ToDate": "2025-02-04T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T20:00:00.000",
            "ToDate": "2025-02-04T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T20:15:00.000",
            "ToDate": "2025-02-04T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T20:30:00.000",
            "ToDate": "2025-02-04T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T20:45:00.000",
            "ToDate": "2025-02-04T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T21:00:00.000",
            "ToDate": "2025-02-04T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T21:15:00.000",
            "ToDate": "2025-02-04T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T21:30:00.000",
            "ToDate": "2025-02-04T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T21:45:00.000",
            "ToDate": "2025-02-04T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T22:00:00.000",
            "ToDate": "2025-02-04T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T22:15:00.000",
            "ToDate": "2025-02-04T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T22:30:00.000",
            "ToDate": "2025-02-04T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T22:45:00.000",
            "ToDate": "2025-02-04T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T23:00:00.000",
            "ToDate": "2025-02-04T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-04T23:15:00.000",
            "ToDate": "2025-02-04T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T12:00:00.000",
            "ToDate": "2025-02-05T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T12:15:00.000",
            "ToDate": "2025-02-05T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T12:30:00.000",
            "ToDate": "2025-02-05T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T12:45:00.000",
            "ToDate": "2025-02-05T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T13:00:00.000",
            "ToDate": "2025-02-05T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T13:15:00.000",
            "ToDate": "2025-02-05T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T13:30:00.000",
            "ToDate": "2025-02-05T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T13:45:00.000",
            "ToDate": "2025-02-05T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T14:00:00.000",
            "ToDate": "2025-02-05T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T14:15:00.000",
            "ToDate": "2025-02-05T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T14:30:00.000",
            "ToDate": "2025-02-05T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T14:45:00.000",
            "ToDate": "2025-02-05T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T18:00:00.000",
            "ToDate": "2025-02-05T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T18:15:00.000",
            "ToDate": "2025-02-05T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T18:30:00.000",
            "ToDate": "2025-02-05T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T18:45:00.000",
            "ToDate": "2025-02-05T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T19:00:00.000",
            "ToDate": "2025-02-05T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T19:15:00.000",
            "ToDate": "2025-02-05T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T19:30:00.000",
            "ToDate": "2025-02-05T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T19:45:00.000",
            "ToDate": "2025-02-05T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T20:00:00.000",
            "ToDate": "2025-02-05T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T20:15:00.000",
            "ToDate": "2025-02-05T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T20:30:00.000",
            "ToDate": "2025-02-05T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T20:45:00.000",
            "ToDate": "2025-02-05T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T21:00:00.000",
            "ToDate": "2025-02-05T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T21:15:00.000",
            "ToDate": "2025-02-05T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T21:30:00.000",
            "ToDate": "2025-02-05T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T21:45:00.000",
            "ToDate": "2025-02-05T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T22:00:00.000",
            "ToDate": "2025-02-05T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T22:15:00.000",
            "ToDate": "2025-02-05T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T22:30:00.000",
            "ToDate": "2025-02-05T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T22:45:00.000",
            "ToDate": "2025-02-05T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T23:00:00.000",
            "ToDate": "2025-02-05T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-05T23:15:00.000",
            "ToDate": "2025-02-05T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T12:15:00.000",
            "ToDate": "2025-02-06T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T12:30:00.000",
            "ToDate": "2025-02-06T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T12:45:00.000",
            "ToDate": "2025-02-06T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T13:00:00.000",
            "ToDate": "2025-02-06T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T13:15:00.000",
            "ToDate": "2025-02-06T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T13:30:00.000",
            "ToDate": "2025-02-06T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T13:45:00.000",
            "ToDate": "2025-02-06T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T14:00:00.000",
            "ToDate": "2025-02-06T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T14:15:00.000",
            "ToDate": "2025-02-06T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T14:30:00.000",
            "ToDate": "2025-02-06T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T14:45:00.000",
            "ToDate": "2025-02-06T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T18:00:00.000",
            "ToDate": "2025-02-06T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T18:15:00.000",
            "ToDate": "2025-02-06T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T18:30:00.000",
            "ToDate": "2025-02-06T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T18:45:00.000",
            "ToDate": "2025-02-06T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T19:00:00.000",
            "ToDate": "2025-02-06T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T19:15:00.000",
            "ToDate": "2025-02-06T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T19:30:00.000",
            "ToDate": "2025-02-06T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T19:45:00.000",
            "ToDate": "2025-02-06T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T20:00:00.000",
            "ToDate": "2025-02-06T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T20:15:00.000",
            "ToDate": "2025-02-06T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T20:30:00.000",
            "ToDate": "2025-02-06T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T20:45:00.000",
            "ToDate": "2025-02-06T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T21:00:00.000",
            "ToDate": "2025-02-06T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T21:15:00.000",
            "ToDate": "2025-02-06T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T21:30:00.000",
            "ToDate": "2025-02-06T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T21:45:00.000",
            "ToDate": "2025-02-06T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T22:00:00.000",
            "ToDate": "2025-02-06T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T22:15:00.000",
            "ToDate": "2025-02-06T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T22:30:00.000",
            "ToDate": "2025-02-06T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T22:45:00.000",
            "ToDate": "2025-02-06T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T23:00:00.000",
            "ToDate": "2025-02-06T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-06T23:15:00.000",
            "ToDate": "2025-02-06T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T12:00:00.000",
            "ToDate": "2025-02-07T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T12:15:00.000",
            "ToDate": "2025-02-07T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T12:30:00.000",
            "ToDate": "2025-02-07T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T12:45:00.000",
            "ToDate": "2025-02-07T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T13:00:00.000",
            "ToDate": "2025-02-07T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T13:15:00.000",
            "ToDate": "2025-02-07T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T13:30:00.000",
            "ToDate": "2025-02-07T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T13:45:00.000",
            "ToDate": "2025-02-07T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T14:00:00.000",
            "ToDate": "2025-02-07T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T14:15:00.000",
            "ToDate": "2025-02-07T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T14:30:00.000",
            "ToDate": "2025-02-07T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T14:45:00.000",
            "ToDate": "2025-02-07T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T18:00:00.000",
            "ToDate": "2025-02-07T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T18:15:00.000",
            "ToDate": "2025-02-07T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T18:30:00.000",
            "ToDate": "2025-02-07T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T18:45:00.000",
            "ToDate": "2025-02-07T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T19:00:00.000",
            "ToDate": "2025-02-07T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T19:15:00.000",
            "ToDate": "2025-02-07T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T19:30:00.000",
            "ToDate": "2025-02-07T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T19:45:00.000",
            "ToDate": "2025-02-07T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T20:00:00.000",
            "ToDate": "2025-02-07T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T20:15:00.000",
            "ToDate": "2025-02-07T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T20:30:00.000",
            "ToDate": "2025-02-07T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T20:45:00.000",
            "ToDate": "2025-02-07T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T21:00:00.000",
            "ToDate": "2025-02-07T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T21:15:00.000",
            "ToDate": "2025-02-07T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T21:30:00.000",
            "ToDate": "2025-02-07T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T21:45:00.000",
            "ToDate": "2025-02-07T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T22:00:00.000",
            "ToDate": "2025-02-07T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T22:15:00.000",
            "ToDate": "2025-02-07T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T22:30:00.000",
            "ToDate": "2025-02-07T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T22:45:00.000",
            "ToDate": "2025-02-07T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T23:00:00.000",
            "ToDate": "2025-02-07T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-07T23:15:00.000",
            "ToDate": "2025-02-07T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T12:00:00.000",
            "ToDate": "2025-02-08T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T12:15:00.000",
            "ToDate": "2025-02-08T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T12:30:00.000",
            "ToDate": "2025-02-08T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T12:45:00.000",
            "ToDate": "2025-02-08T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T13:00:00.000",
            "ToDate": "2025-02-08T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T13:15:00.000",
            "ToDate": "2025-02-08T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T13:30:00.000",
            "ToDate": "2025-02-08T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T13:45:00.000",
            "ToDate": "2025-02-08T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T14:00:00.000",
            "ToDate": "2025-02-08T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T14:15:00.000",
            "ToDate": "2025-02-08T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T14:30:00.000",
            "ToDate": "2025-02-08T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T14:45:00.000",
            "ToDate": "2025-02-08T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T18:00:00.000",
            "ToDate": "2025-02-08T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T18:15:00.000",
            "ToDate": "2025-02-08T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T18:30:00.000",
            "ToDate": "2025-02-08T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T18:45:00.000",
            "ToDate": "2025-02-08T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T19:00:00.000",
            "ToDate": "2025-02-08T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T19:15:00.000",
            "ToDate": "2025-02-08T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T19:30:00.000",
            "ToDate": "2025-02-08T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T19:45:00.000",
            "ToDate": "2025-02-08T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T20:00:00.000",
            "ToDate": "2025-02-08T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T20:15:00.000",
            "ToDate": "2025-02-08T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T20:30:00.000",
            "ToDate": "2025-02-08T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T20:45:00.000",
            "ToDate": "2025-02-08T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T21:00:00.000",
            "ToDate": "2025-02-08T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T21:15:00.000",
            "ToDate": "2025-02-08T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T21:30:00.000",
            "ToDate": "2025-02-08T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T21:45:00.000",
            "ToDate": "2025-02-08T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T22:00:00.000",
            "ToDate": "2025-02-08T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T22:15:00.000",
            "ToDate": "2025-02-08T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T22:30:00.000",
            "ToDate": "2025-02-08T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T22:45:00.000",
            "ToDate": "2025-02-08T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T23:00:00.000",
            "ToDate": "2025-02-08T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-08T23:15:00.000",
            "ToDate": "2025-02-08T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T12:00:00.000",
            "ToDate": "2025-02-09T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T12:15:00.000",
            "ToDate": "2025-02-09T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T12:30:00.000",
            "ToDate": "2025-02-09T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T12:45:00.000",
            "ToDate": "2025-02-09T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T13:00:00.000",
            "ToDate": "2025-02-09T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T13:15:00.000",
            "ToDate": "2025-02-09T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T13:30:00.000",
            "ToDate": "2025-02-09T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T13:45:00.000",
            "ToDate": "2025-02-09T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T14:00:00.000",
            "ToDate": "2025-02-09T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T14:15:00.000",
            "ToDate": "2025-02-09T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T14:30:00.000",
            "ToDate": "2025-02-09T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T14:45:00.000",
            "ToDate": "2025-02-09T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T18:00:00.000",
            "ToDate": "2025-02-09T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T18:15:00.000",
            "ToDate": "2025-02-09T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T18:30:00.000",
            "ToDate": "2025-02-09T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T18:45:00.000",
            "ToDate": "2025-02-09T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T19:00:00.000",
            "ToDate": "2025-02-09T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T19:15:00.000",
            "ToDate": "2025-02-09T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T19:30:00.000",
            "ToDate": "2025-02-09T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T19:45:00.000",
            "ToDate": "2025-02-09T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T20:00:00.000",
            "ToDate": "2025-02-09T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T20:15:00.000",
            "ToDate": "2025-02-09T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T20:30:00.000",
            "ToDate": "2025-02-09T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T20:45:00.000",
            "ToDate": "2025-02-09T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T21:00:00.000",
            "ToDate": "2025-02-09T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T21:15:00.000",
            "ToDate": "2025-02-09T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T21:30:00.000",
            "ToDate": "2025-02-09T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T21:45:00.000",
            "ToDate": "2025-02-09T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T22:00:00.000",
            "ToDate": "2025-02-09T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T22:15:00.000",
            "ToDate": "2025-02-09T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T22:30:00.000",
            "ToDate": "2025-02-09T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T22:45:00.000",
            "ToDate": "2025-02-09T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T23:00:00.000",
            "ToDate": "2025-02-09T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-09T23:15:00.000",
            "ToDate": "2025-02-09T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T12:00:00.000",
            "ToDate": "2025-02-10T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T12:15:00.000",
            "ToDate": "2025-02-10T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T12:30:00.000",
            "ToDate": "2025-02-10T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T12:45:00.000",
            "ToDate": "2025-02-10T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T13:00:00.000",
            "ToDate": "2025-02-10T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T13:15:00.000",
            "ToDate": "2025-02-10T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T13:30:00.000",
            "ToDate": "2025-02-10T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T13:45:00.000",
            "ToDate": "2025-02-10T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T14:00:00.000",
            "ToDate": "2025-02-10T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T14:15:00.000",
            "ToDate": "2025-02-10T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T14:30:00.000",
            "ToDate": "2025-02-10T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T14:45:00.000",
            "ToDate": "2025-02-10T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T18:00:00.000",
            "ToDate": "2025-02-10T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T18:15:00.000",
            "ToDate": "2025-02-10T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T18:30:00.000",
            "ToDate": "2025-02-10T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T18:45:00.000",
            "ToDate": "2025-02-10T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T19:00:00.000",
            "ToDate": "2025-02-10T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T19:15:00.000",
            "ToDate": "2025-02-10T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T19:30:00.000",
            "ToDate": "2025-02-10T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T19:45:00.000",
            "ToDate": "2025-02-10T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T20:00:00.000",
            "ToDate": "2025-02-10T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T20:15:00.000",
            "ToDate": "2025-02-10T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T20:30:00.000",
            "ToDate": "2025-02-10T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T20:45:00.000",
            "ToDate": "2025-02-10T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T21:00:00.000",
            "ToDate": "2025-02-10T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T21:15:00.000",
            "ToDate": "2025-02-10T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T21:30:00.000",
            "ToDate": "2025-02-10T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T21:45:00.000",
            "ToDate": "2025-02-10T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T22:00:00.000",
            "ToDate": "2025-02-10T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T22:15:00.000",
            "ToDate": "2025-02-10T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T22:30:00.000",
            "ToDate": "2025-02-10T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T22:45:00.000",
            "ToDate": "2025-02-10T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T23:00:00.000",
            "ToDate": "2025-02-10T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-10T23:15:00.000",
            "ToDate": "2025-02-10T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T12:00:00.000",
            "ToDate": "2025-02-11T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T12:15:00.000",
            "ToDate": "2025-02-11T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T12:30:00.000",
            "ToDate": "2025-02-11T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T12:45:00.000",
            "ToDate": "2025-02-11T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T13:00:00.000",
            "ToDate": "2025-02-11T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T13:15:00.000",
            "ToDate": "2025-02-11T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T13:30:00.000",
            "ToDate": "2025-02-11T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T13:45:00.000",
            "ToDate": "2025-02-11T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T14:00:00.000",
            "ToDate": "2025-02-11T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T14:15:00.000",
            "ToDate": "2025-02-11T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T14:30:00.000",
            "ToDate": "2025-02-11T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T14:45:00.000",
            "ToDate": "2025-02-11T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T18:00:00.000",
            "ToDate": "2025-02-11T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T18:15:00.000",
            "ToDate": "2025-02-11T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T18:30:00.000",
            "ToDate": "2025-02-11T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T18:45:00.000",
            "ToDate": "2025-02-11T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T19:00:00.000",
            "ToDate": "2025-02-11T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T19:15:00.000",
            "ToDate": "2025-02-11T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T19:30:00.000",
            "ToDate": "2025-02-11T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T19:45:00.000",
            "ToDate": "2025-02-11T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T20:00:00.000",
            "ToDate": "2025-02-11T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T20:15:00.000",
            "ToDate": "2025-02-11T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T20:30:00.000",
            "ToDate": "2025-02-11T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T20:45:00.000",
            "ToDate": "2025-02-11T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T21:00:00.000",
            "ToDate": "2025-02-11T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T21:15:00.000",
            "ToDate": "2025-02-11T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T21:30:00.000",
            "ToDate": "2025-02-11T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T21:45:00.000",
            "ToDate": "2025-02-11T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T22:00:00.000",
            "ToDate": "2025-02-11T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T22:15:00.000",
            "ToDate": "2025-02-11T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T22:30:00.000",
            "ToDate": "2025-02-11T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T22:45:00.000",
            "ToDate": "2025-02-11T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T23:00:00.000",
            "ToDate": "2025-02-11T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-11T23:15:00.000",
            "ToDate": "2025-02-11T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T12:00:00.000",
            "ToDate": "2025-02-12T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T12:15:00.000",
            "ToDate": "2025-02-12T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T12:30:00.000",
            "ToDate": "2025-02-12T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T12:45:00.000",
            "ToDate": "2025-02-12T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T13:00:00.000",
            "ToDate": "2025-02-12T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T13:15:00.000",
            "ToDate": "2025-02-12T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T13:30:00.000",
            "ToDate": "2025-02-12T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T13:45:00.000",
            "ToDate": "2025-02-12T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T14:00:00.000",
            "ToDate": "2025-02-12T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T14:15:00.000",
            "ToDate": "2025-02-12T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T14:30:00.000",
            "ToDate": "2025-02-12T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T14:45:00.000",
            "ToDate": "2025-02-12T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T18:00:00.000",
            "ToDate": "2025-02-12T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T18:15:00.000",
            "ToDate": "2025-02-12T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T18:30:00.000",
            "ToDate": "2025-02-12T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T18:45:00.000",
            "ToDate": "2025-02-12T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T19:00:00.000",
            "ToDate": "2025-02-12T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T19:15:00.000",
            "ToDate": "2025-02-12T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T19:30:00.000",
            "ToDate": "2025-02-12T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T19:45:00.000",
            "ToDate": "2025-02-12T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T20:00:00.000",
            "ToDate": "2025-02-12T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T20:15:00.000",
            "ToDate": "2025-02-12T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T20:30:00.000",
            "ToDate": "2025-02-12T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T20:45:00.000",
            "ToDate": "2025-02-12T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T21:00:00.000",
            "ToDate": "2025-02-12T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T21:15:00.000",
            "ToDate": "2025-02-12T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T21:30:00.000",
            "ToDate": "2025-02-12T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T21:45:00.000",
            "ToDate": "2025-02-12T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T22:00:00.000",
            "ToDate": "2025-02-12T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T22:15:00.000",
            "ToDate": "2025-02-12T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T22:30:00.000",
            "ToDate": "2025-02-12T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T22:45:00.000",
            "ToDate": "2025-02-12T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T23:00:00.000",
            "ToDate": "2025-02-12T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-12T23:15:00.000",
            "ToDate": "2025-02-12T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T12:00:00.000",
            "ToDate": "2025-02-13T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T12:15:00.000",
            "ToDate": "2025-02-13T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T12:30:00.000",
            "ToDate": "2025-02-13T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T12:45:00.000",
            "ToDate": "2025-02-13T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T13:00:00.000",
            "ToDate": "2025-02-13T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T13:15:00.000",
            "ToDate": "2025-02-13T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T13:30:00.000",
            "ToDate": "2025-02-13T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T13:45:00.000",
            "ToDate": "2025-02-13T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T14:00:00.000",
            "ToDate": "2025-02-13T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T14:15:00.000",
            "ToDate": "2025-02-13T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T14:30:00.000",
            "ToDate": "2025-02-13T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T14:45:00.000",
            "ToDate": "2025-02-13T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T18:00:00.000",
            "ToDate": "2025-02-13T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T18:15:00.000",
            "ToDate": "2025-02-13T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T18:30:00.000",
            "ToDate": "2025-02-13T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T18:45:00.000",
            "ToDate": "2025-02-13T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T19:00:00.000",
            "ToDate": "2025-02-13T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T19:15:00.000",
            "ToDate": "2025-02-13T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T19:30:00.000",
            "ToDate": "2025-02-13T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T19:45:00.000",
            "ToDate": "2025-02-13T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T20:00:00.000",
            "ToDate": "2025-02-13T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T20:15:00.000",
            "ToDate": "2025-02-13T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T20:30:00.000",
            "ToDate": "2025-02-13T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T20:45:00.000",
            "ToDate": "2025-02-13T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T21:00:00.000",
            "ToDate": "2025-02-13T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T21:15:00.000",
            "ToDate": "2025-02-13T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T21:30:00.000",
            "ToDate": "2025-02-13T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T21:45:00.000",
            "ToDate": "2025-02-13T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T22:00:00.000",
            "ToDate": "2025-02-13T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T22:15:00.000",
            "ToDate": "2025-02-13T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T22:30:00.000",
            "ToDate": "2025-02-13T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T22:45:00.000",
            "ToDate": "2025-02-13T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T23:00:00.000",
            "ToDate": "2025-02-13T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-13T23:15:00.000",
            "ToDate": "2025-02-13T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T12:00:00.000",
            "ToDate": "2025-02-14T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T12:15:00.000",
            "ToDate": "2025-02-14T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T12:30:00.000",
            "ToDate": "2025-02-14T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T12:45:00.000",
            "ToDate": "2025-02-14T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T13:00:00.000",
            "ToDate": "2025-02-14T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T13:15:00.000",
            "ToDate": "2025-02-14T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T13:30:00.000",
            "ToDate": "2025-02-14T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T13:45:00.000",
            "ToDate": "2025-02-14T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T14:00:00.000",
            "ToDate": "2025-02-14T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T14:15:00.000",
            "ToDate": "2025-02-14T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T14:30:00.000",
            "ToDate": "2025-02-14T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T14:45:00.000",
            "ToDate": "2025-02-14T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T18:00:00.000",
            "ToDate": "2025-02-14T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T18:15:00.000",
            "ToDate": "2025-02-14T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T18:30:00.000",
            "ToDate": "2025-02-14T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T18:45:00.000",
            "ToDate": "2025-02-14T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T19:00:00.000",
            "ToDate": "2025-02-14T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T19:15:00.000",
            "ToDate": "2025-02-14T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T19:30:00.000",
            "ToDate": "2025-02-14T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T19:45:00.000",
            "ToDate": "2025-02-14T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T20:00:00.000",
            "ToDate": "2025-02-14T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T20:15:00.000",
            "ToDate": "2025-02-14T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T20:30:00.000",
            "ToDate": "2025-02-14T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T20:45:00.000",
            "ToDate": "2025-02-14T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T21:00:00.000",
            "ToDate": "2025-02-14T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T21:15:00.000",
            "ToDate": "2025-02-14T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T21:30:00.000",
            "ToDate": "2025-02-14T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T21:45:00.000",
            "ToDate": "2025-02-14T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T22:00:00.000",
            "ToDate": "2025-02-14T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T22:15:00.000",
            "ToDate": "2025-02-14T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T22:30:00.000",
            "ToDate": "2025-02-14T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T22:45:00.000",
            "ToDate": "2025-02-14T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T23:00:00.000",
            "ToDate": "2025-02-14T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-14T23:15:00.000",
            "ToDate": "2025-02-14T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T12:00:00.000",
            "ToDate": "2025-02-15T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T12:15:00.000",
            "ToDate": "2025-02-15T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T12:30:00.000",
            "ToDate": "2025-02-15T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T12:45:00.000",
            "ToDate": "2025-02-15T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T13:00:00.000",
            "ToDate": "2025-02-15T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T13:15:00.000",
            "ToDate": "2025-02-15T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T13:30:00.000",
            "ToDate": "2025-02-15T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T13:45:00.000",
            "ToDate": "2025-02-15T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T14:00:00.000",
            "ToDate": "2025-02-15T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T14:15:00.000",
            "ToDate": "2025-02-15T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T14:30:00.000",
            "ToDate": "2025-02-15T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T14:45:00.000",
            "ToDate": "2025-02-15T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T18:00:00.000",
            "ToDate": "2025-02-15T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T18:15:00.000",
            "ToDate": "2025-02-15T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T18:30:00.000",
            "ToDate": "2025-02-15T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T18:45:00.000",
            "ToDate": "2025-02-15T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T19:00:00.000",
            "ToDate": "2025-02-15T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T19:15:00.000",
            "ToDate": "2025-02-15T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T19:30:00.000",
            "ToDate": "2025-02-15T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T19:45:00.000",
            "ToDate": "2025-02-15T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T20:00:00.000",
            "ToDate": "2025-02-15T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T20:15:00.000",
            "ToDate": "2025-02-15T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T20:30:00.000",
            "ToDate": "2025-02-15T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T20:45:00.000",
            "ToDate": "2025-02-15T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T21:00:00.000",
            "ToDate": "2025-02-15T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T21:15:00.000",
            "ToDate": "2025-02-15T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T21:30:00.000",
            "ToDate": "2025-02-15T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T21:45:00.000",
            "ToDate": "2025-02-15T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T22:00:00.000",
            "ToDate": "2025-02-15T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T22:15:00.000",
            "ToDate": "2025-02-15T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T22:30:00.000",
            "ToDate": "2025-02-15T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T22:45:00.000",
            "ToDate": "2025-02-15T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T23:00:00.000",
            "ToDate": "2025-02-15T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-15T23:15:00.000",
            "ToDate": "2025-02-15T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T12:00:00.000",
            "ToDate": "2025-02-16T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T12:15:00.000",
            "ToDate": "2025-02-16T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T12:30:00.000",
            "ToDate": "2025-02-16T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T12:45:00.000",
            "ToDate": "2025-02-16T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T13:00:00.000",
            "ToDate": "2025-02-16T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T13:15:00.000",
            "ToDate": "2025-02-16T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T13:30:00.000",
            "ToDate": "2025-02-16T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T13:45:00.000",
            "ToDate": "2025-02-16T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T14:00:00.000",
            "ToDate": "2025-02-16T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T14:15:00.000",
            "ToDate": "2025-02-16T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T14:30:00.000",
            "ToDate": "2025-02-16T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T14:45:00.000",
            "ToDate": "2025-02-16T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T18:00:00.000",
            "ToDate": "2025-02-16T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T18:15:00.000",
            "ToDate": "2025-02-16T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T18:30:00.000",
            "ToDate": "2025-02-16T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T18:45:00.000",
            "ToDate": "2025-02-16T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T19:00:00.000",
            "ToDate": "2025-02-16T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T19:15:00.000",
            "ToDate": "2025-02-16T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T19:30:00.000",
            "ToDate": "2025-02-16T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T19:45:00.000",
            "ToDate": "2025-02-16T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T20:00:00.000",
            "ToDate": "2025-02-16T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T20:15:00.000",
            "ToDate": "2025-02-16T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T20:30:00.000",
            "ToDate": "2025-02-16T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T20:45:00.000",
            "ToDate": "2025-02-16T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T21:00:00.000",
            "ToDate": "2025-02-16T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T21:15:00.000",
            "ToDate": "2025-02-16T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T21:30:00.000",
            "ToDate": "2025-02-16T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T21:45:00.000",
            "ToDate": "2025-02-16T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T22:00:00.000",
            "ToDate": "2025-02-16T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T22:15:00.000",
            "ToDate": "2025-02-16T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T22:30:00.000",
            "ToDate": "2025-02-16T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T22:45:00.000",
            "ToDate": "2025-02-16T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T23:00:00.000",
            "ToDate": "2025-02-16T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-16T23:15:00.000",
            "ToDate": "2025-02-16T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T12:00:00.000",
            "ToDate": "2025-02-17T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T12:15:00.000",
            "ToDate": "2025-02-17T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T12:30:00.000",
            "ToDate": "2025-02-17T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T12:45:00.000",
            "ToDate": "2025-02-17T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T13:00:00.000",
            "ToDate": "2025-02-17T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T13:15:00.000",
            "ToDate": "2025-02-17T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T13:30:00.000",
            "ToDate": "2025-02-17T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T13:45:00.000",
            "ToDate": "2025-02-17T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T14:00:00.000",
            "ToDate": "2025-02-17T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T14:15:00.000",
            "ToDate": "2025-02-17T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T14:30:00.000",
            "ToDate": "2025-02-17T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T14:45:00.000",
            "ToDate": "2025-02-17T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T18:00:00.000",
            "ToDate": "2025-02-17T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T18:15:00.000",
            "ToDate": "2025-02-17T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T18:30:00.000",
            "ToDate": "2025-02-17T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T18:45:00.000",
            "ToDate": "2025-02-17T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T19:00:00.000",
            "ToDate": "2025-02-17T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T19:15:00.000",
            "ToDate": "2025-02-17T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T19:30:00.000",
            "ToDate": "2025-02-17T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T19:45:00.000",
            "ToDate": "2025-02-17T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T20:00:00.000",
            "ToDate": "2025-02-17T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T20:15:00.000",
            "ToDate": "2025-02-17T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T20:30:00.000",
            "ToDate": "2025-02-17T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T20:45:00.000",
            "ToDate": "2025-02-17T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T21:00:00.000",
            "ToDate": "2025-02-17T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T21:15:00.000",
            "ToDate": "2025-02-17T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T21:30:00.000",
            "ToDate": "2025-02-17T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T21:45:00.000",
            "ToDate": "2025-02-17T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T22:00:00.000",
            "ToDate": "2025-02-17T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T22:15:00.000",
            "ToDate": "2025-02-17T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T22:30:00.000",
            "ToDate": "2025-02-17T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T22:45:00.000",
            "ToDate": "2025-02-17T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T23:00:00.000",
            "ToDate": "2025-02-17T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-17T23:15:00.000",
            "ToDate": "2025-02-17T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T12:00:00.000",
            "ToDate": "2025-02-18T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T12:15:00.000",
            "ToDate": "2025-02-18T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T12:30:00.000",
            "ToDate": "2025-02-18T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T12:45:00.000",
            "ToDate": "2025-02-18T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T13:00:00.000",
            "ToDate": "2025-02-18T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T13:15:00.000",
            "ToDate": "2025-02-18T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T13:30:00.000",
            "ToDate": "2025-02-18T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T13:45:00.000",
            "ToDate": "2025-02-18T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T14:00:00.000",
            "ToDate": "2025-02-18T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T14:15:00.000",
            "ToDate": "2025-02-18T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T14:30:00.000",
            "ToDate": "2025-02-18T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T14:45:00.000",
            "ToDate": "2025-02-18T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T18:00:00.000",
            "ToDate": "2025-02-18T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T18:15:00.000",
            "ToDate": "2025-02-18T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T18:30:00.000",
            "ToDate": "2025-02-18T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T18:45:00.000",
            "ToDate": "2025-02-18T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T19:00:00.000",
            "ToDate": "2025-02-18T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T19:15:00.000",
            "ToDate": "2025-02-18T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T19:30:00.000",
            "ToDate": "2025-02-18T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T19:45:00.000",
            "ToDate": "2025-02-18T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T20:00:00.000",
            "ToDate": "2025-02-18T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T20:15:00.000",
            "ToDate": "2025-02-18T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T20:30:00.000",
            "ToDate": "2025-02-18T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T20:45:00.000",
            "ToDate": "2025-02-18T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T21:00:00.000",
            "ToDate": "2025-02-18T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T21:15:00.000",
            "ToDate": "2025-02-18T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T21:30:00.000",
            "ToDate": "2025-02-18T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T21:45:00.000",
            "ToDate": "2025-02-18T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T22:00:00.000",
            "ToDate": "2025-02-18T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T22:15:00.000",
            "ToDate": "2025-02-18T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T22:30:00.000",
            "ToDate": "2025-02-18T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T22:45:00.000",
            "ToDate": "2025-02-18T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T23:00:00.000",
            "ToDate": "2025-02-18T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-18T23:15:00.000",
            "ToDate": "2025-02-18T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T12:00:00.000",
            "ToDate": "2025-02-19T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T12:15:00.000",
            "ToDate": "2025-02-19T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T12:30:00.000",
            "ToDate": "2025-02-19T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T12:45:00.000",
            "ToDate": "2025-02-19T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T13:00:00.000",
            "ToDate": "2025-02-19T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T13:15:00.000",
            "ToDate": "2025-02-19T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T13:30:00.000",
            "ToDate": "2025-02-19T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T13:45:00.000",
            "ToDate": "2025-02-19T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T14:00:00.000",
            "ToDate": "2025-02-19T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T14:15:00.000",
            "ToDate": "2025-02-19T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T14:30:00.000",
            "ToDate": "2025-02-19T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T14:45:00.000",
            "ToDate": "2025-02-19T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T18:00:00.000",
            "ToDate": "2025-02-19T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T18:15:00.000",
            "ToDate": "2025-02-19T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T18:30:00.000",
            "ToDate": "2025-02-19T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T18:45:00.000",
            "ToDate": "2025-02-19T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T19:00:00.000",
            "ToDate": "2025-02-19T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T19:15:00.000",
            "ToDate": "2025-02-19T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T19:30:00.000",
            "ToDate": "2025-02-19T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T19:45:00.000",
            "ToDate": "2025-02-19T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T20:00:00.000",
            "ToDate": "2025-02-19T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T20:15:00.000",
            "ToDate": "2025-02-19T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T20:30:00.000",
            "ToDate": "2025-02-19T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T20:45:00.000",
            "ToDate": "2025-02-19T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T21:00:00.000",
            "ToDate": "2025-02-19T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T21:15:00.000",
            "ToDate": "2025-02-19T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T21:30:00.000",
            "ToDate": "2025-02-19T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T21:45:00.000",
            "ToDate": "2025-02-19T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T22:00:00.000",
            "ToDate": "2025-02-19T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T22:15:00.000",
            "ToDate": "2025-02-19T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T22:30:00.000",
            "ToDate": "2025-02-19T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T22:45:00.000",
            "ToDate": "2025-02-19T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T23:00:00.000",
            "ToDate": "2025-02-19T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-19T23:15:00.000",
            "ToDate": "2025-02-19T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T12:00:00.000",
            "ToDate": "2025-02-20T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T12:15:00.000",
            "ToDate": "2025-02-20T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T12:30:00.000",
            "ToDate": "2025-02-20T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T12:45:00.000",
            "ToDate": "2025-02-20T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T13:00:00.000",
            "ToDate": "2025-02-20T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T13:15:00.000",
            "ToDate": "2025-02-20T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T13:30:00.000",
            "ToDate": "2025-02-20T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T13:45:00.000",
            "ToDate": "2025-02-20T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T14:00:00.000",
            "ToDate": "2025-02-20T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T14:15:00.000",
            "ToDate": "2025-02-20T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T14:30:00.000",
            "ToDate": "2025-02-20T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T14:45:00.000",
            "ToDate": "2025-02-20T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T18:00:00.000",
            "ToDate": "2025-02-20T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T18:15:00.000",
            "ToDate": "2025-02-20T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T18:30:00.000",
            "ToDate": "2025-02-20T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T18:45:00.000",
            "ToDate": "2025-02-20T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T19:00:00.000",
            "ToDate": "2025-02-20T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T19:15:00.000",
            "ToDate": "2025-02-20T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T19:30:00.000",
            "ToDate": "2025-02-20T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T19:45:00.000",
            "ToDate": "2025-02-20T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T20:00:00.000",
            "ToDate": "2025-02-20T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T20:15:00.000",
            "ToDate": "2025-02-20T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T20:30:00.000",
            "ToDate": "2025-02-20T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T20:45:00.000",
            "ToDate": "2025-02-20T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T21:00:00.000",
            "ToDate": "2025-02-20T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T21:15:00.000",
            "ToDate": "2025-02-20T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T21:30:00.000",
            "ToDate": "2025-02-20T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T21:45:00.000",
            "ToDate": "2025-02-20T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T22:00:00.000",
            "ToDate": "2025-02-20T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T22:15:00.000",
            "ToDate": "2025-02-20T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T22:30:00.000",
            "ToDate": "2025-02-20T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T22:45:00.000",
            "ToDate": "2025-02-20T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T23:00:00.000",
            "ToDate": "2025-02-20T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-20T23:15:00.000",
            "ToDate": "2025-02-20T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T12:00:00.000",
            "ToDate": "2025-02-21T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T12:15:00.000",
            "ToDate": "2025-02-21T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T12:30:00.000",
            "ToDate": "2025-02-21T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T12:45:00.000",
            "ToDate": "2025-02-21T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T13:00:00.000",
            "ToDate": "2025-02-21T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T13:15:00.000",
            "ToDate": "2025-02-21T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T13:30:00.000",
            "ToDate": "2025-02-21T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T13:45:00.000",
            "ToDate": "2025-02-21T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T14:00:00.000",
            "ToDate": "2025-02-21T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T14:15:00.000",
            "ToDate": "2025-02-21T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T14:30:00.000",
            "ToDate": "2025-02-21T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T14:45:00.000",
            "ToDate": "2025-02-21T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T18:00:00.000",
            "ToDate": "2025-02-21T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T18:15:00.000",
            "ToDate": "2025-02-21T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T18:30:00.000",
            "ToDate": "2025-02-21T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T18:45:00.000",
            "ToDate": "2025-02-21T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T19:00:00.000",
            "ToDate": "2025-02-21T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T19:15:00.000",
            "ToDate": "2025-02-21T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T19:30:00.000",
            "ToDate": "2025-02-21T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T19:45:00.000",
            "ToDate": "2025-02-21T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T20:00:00.000",
            "ToDate": "2025-02-21T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T20:15:00.000",
            "ToDate": "2025-02-21T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T20:30:00.000",
            "ToDate": "2025-02-21T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T20:45:00.000",
            "ToDate": "2025-02-21T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T21:00:00.000",
            "ToDate": "2025-02-21T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T21:15:00.000",
            "ToDate": "2025-02-21T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T21:30:00.000",
            "ToDate": "2025-02-21T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T21:45:00.000",
            "ToDate": "2025-02-21T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T22:00:00.000",
            "ToDate": "2025-02-21T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T22:15:00.000",
            "ToDate": "2025-02-21T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T22:30:00.000",
            "ToDate": "2025-02-21T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T22:45:00.000",
            "ToDate": "2025-02-21T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T23:00:00.000",
            "ToDate": "2025-02-21T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-21T23:15:00.000",
            "ToDate": "2025-02-21T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T12:00:00.000",
            "ToDate": "2025-02-22T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T12:15:00.000",
            "ToDate": "2025-02-22T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T12:30:00.000",
            "ToDate": "2025-02-22T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T12:45:00.000",
            "ToDate": "2025-02-22T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T13:00:00.000",
            "ToDate": "2025-02-22T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T13:15:00.000",
            "ToDate": "2025-02-22T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T13:30:00.000",
            "ToDate": "2025-02-22T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T13:45:00.000",
            "ToDate": "2025-02-22T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T14:00:00.000",
            "ToDate": "2025-02-22T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T14:15:00.000",
            "ToDate": "2025-02-22T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T14:30:00.000",
            "ToDate": "2025-02-22T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T14:45:00.000",
            "ToDate": "2025-02-22T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T18:00:00.000",
            "ToDate": "2025-02-22T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T18:15:00.000",
            "ToDate": "2025-02-22T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T18:30:00.000",
            "ToDate": "2025-02-22T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T18:45:00.000",
            "ToDate": "2025-02-22T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T19:00:00.000",
            "ToDate": "2025-02-22T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T19:15:00.000",
            "ToDate": "2025-02-22T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T19:30:00.000",
            "ToDate": "2025-02-22T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T19:45:00.000",
            "ToDate": "2025-02-22T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T20:00:00.000",
            "ToDate": "2025-02-22T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T20:15:00.000",
            "ToDate": "2025-02-22T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T20:30:00.000",
            "ToDate": "2025-02-22T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T20:45:00.000",
            "ToDate": "2025-02-22T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T21:00:00.000",
            "ToDate": "2025-02-22T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T21:15:00.000",
            "ToDate": "2025-02-22T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T21:30:00.000",
            "ToDate": "2025-02-22T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T21:45:00.000",
            "ToDate": "2025-02-22T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T22:00:00.000",
            "ToDate": "2025-02-22T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T22:15:00.000",
            "ToDate": "2025-02-22T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T22:30:00.000",
            "ToDate": "2025-02-22T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T22:45:00.000",
            "ToDate": "2025-02-22T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T23:00:00.000",
            "ToDate": "2025-02-22T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-22T23:15:00.000",
            "ToDate": "2025-02-22T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T12:00:00.000",
            "ToDate": "2025-02-23T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T12:15:00.000",
            "ToDate": "2025-02-23T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T12:30:00.000",
            "ToDate": "2025-02-23T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T12:45:00.000",
            "ToDate": "2025-02-23T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T13:00:00.000",
            "ToDate": "2025-02-23T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T13:15:00.000",
            "ToDate": "2025-02-23T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T13:30:00.000",
            "ToDate": "2025-02-23T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T13:45:00.000",
            "ToDate": "2025-02-23T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T14:00:00.000",
            "ToDate": "2025-02-23T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T14:15:00.000",
            "ToDate": "2025-02-23T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T14:30:00.000",
            "ToDate": "2025-02-23T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T14:45:00.000",
            "ToDate": "2025-02-23T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T18:00:00.000",
            "ToDate": "2025-02-23T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T18:15:00.000",
            "ToDate": "2025-02-23T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T18:30:00.000",
            "ToDate": "2025-02-23T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T18:45:00.000",
            "ToDate": "2025-02-23T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T19:00:00.000",
            "ToDate": "2025-02-23T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T19:15:00.000",
            "ToDate": "2025-02-23T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T19:30:00.000",
            "ToDate": "2025-02-23T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T19:45:00.000",
            "ToDate": "2025-02-23T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T20:00:00.000",
            "ToDate": "2025-02-23T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T20:15:00.000",
            "ToDate": "2025-02-23T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T20:30:00.000",
            "ToDate": "2025-02-23T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T20:45:00.000",
            "ToDate": "2025-02-23T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T21:00:00.000",
            "ToDate": "2025-02-23T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T21:15:00.000",
            "ToDate": "2025-02-23T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T21:30:00.000",
            "ToDate": "2025-02-23T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T21:45:00.000",
            "ToDate": "2025-02-23T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T22:00:00.000",
            "ToDate": "2025-02-23T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T22:15:00.000",
            "ToDate": "2025-02-23T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T22:30:00.000",
            "ToDate": "2025-02-23T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T22:45:00.000",
            "ToDate": "2025-02-23T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T23:00:00.000",
            "ToDate": "2025-02-23T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-23T23:15:00.000",
            "ToDate": "2025-02-23T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T12:00:00.000",
            "ToDate": "2025-02-24T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T12:15:00.000",
            "ToDate": "2025-02-24T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T12:30:00.000",
            "ToDate": "2025-02-24T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T12:45:00.000",
            "ToDate": "2025-02-24T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T13:00:00.000",
            "ToDate": "2025-02-24T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T13:15:00.000",
            "ToDate": "2025-02-24T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T13:30:00.000",
            "ToDate": "2025-02-24T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T13:45:00.000",
            "ToDate": "2025-02-24T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T14:00:00.000",
            "ToDate": "2025-02-24T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T14:15:00.000",
            "ToDate": "2025-02-24T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T14:30:00.000",
            "ToDate": "2025-02-24T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T14:45:00.000",
            "ToDate": "2025-02-24T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T18:00:00.000",
            "ToDate": "2025-02-24T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T18:15:00.000",
            "ToDate": "2025-02-24T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T18:30:00.000",
            "ToDate": "2025-02-24T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T18:45:00.000",
            "ToDate": "2025-02-24T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T19:00:00.000",
            "ToDate": "2025-02-24T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T19:15:00.000",
            "ToDate": "2025-02-24T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T19:30:00.000",
            "ToDate": "2025-02-24T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T19:45:00.000",
            "ToDate": "2025-02-24T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T20:00:00.000",
            "ToDate": "2025-02-24T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T20:15:00.000",
            "ToDate": "2025-02-24T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T20:30:00.000",
            "ToDate": "2025-02-24T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T20:45:00.000",
            "ToDate": "2025-02-24T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T21:00:00.000",
            "ToDate": "2025-02-24T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T21:15:00.000",
            "ToDate": "2025-02-24T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T21:30:00.000",
            "ToDate": "2025-02-24T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T21:45:00.000",
            "ToDate": "2025-02-24T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T22:00:00.000",
            "ToDate": "2025-02-24T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T22:15:00.000",
            "ToDate": "2025-02-24T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T22:30:00.000",
            "ToDate": "2025-02-24T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T22:45:00.000",
            "ToDate": "2025-02-24T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T23:00:00.000",
            "ToDate": "2025-02-24T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-24T23:15:00.000",
            "ToDate": "2025-02-24T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T12:00:00.000",
            "ToDate": "2025-02-25T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T12:15:00.000",
            "ToDate": "2025-02-25T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T12:30:00.000",
            "ToDate": "2025-02-25T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T12:45:00.000",
            "ToDate": "2025-02-25T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T13:00:00.000",
            "ToDate": "2025-02-25T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T13:15:00.000",
            "ToDate": "2025-02-25T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T13:30:00.000",
            "ToDate": "2025-02-25T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T13:45:00.000",
            "ToDate": "2025-02-25T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T14:00:00.000",
            "ToDate": "2025-02-25T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T14:15:00.000",
            "ToDate": "2025-02-25T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T14:30:00.000",
            "ToDate": "2025-02-25T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T14:45:00.000",
            "ToDate": "2025-02-25T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T18:00:00.000",
            "ToDate": "2025-02-25T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T18:15:00.000",
            "ToDate": "2025-02-25T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T18:30:00.000",
            "ToDate": "2025-02-25T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T18:45:00.000",
            "ToDate": "2025-02-25T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T19:00:00.000",
            "ToDate": "2025-02-25T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T19:15:00.000",
            "ToDate": "2025-02-25T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T19:30:00.000",
            "ToDate": "2025-02-25T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T19:45:00.000",
            "ToDate": "2025-02-25T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T20:00:00.000",
            "ToDate": "2025-02-25T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T20:15:00.000",
            "ToDate": "2025-02-25T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T20:30:00.000",
            "ToDate": "2025-02-25T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T20:45:00.000",
            "ToDate": "2025-02-25T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T21:00:00.000",
            "ToDate": "2025-02-25T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T21:15:00.000",
            "ToDate": "2025-02-25T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T21:30:00.000",
            "ToDate": "2025-02-25T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T21:45:00.000",
            "ToDate": "2025-02-25T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T22:00:00.000",
            "ToDate": "2025-02-25T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T22:15:00.000",
            "ToDate": "2025-02-25T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T22:30:00.000",
            "ToDate": "2025-02-25T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T22:45:00.000",
            "ToDate": "2025-02-25T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T23:00:00.000",
            "ToDate": "2025-02-25T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-25T23:15:00.000",
            "ToDate": "2025-02-25T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T12:00:00.000",
            "ToDate": "2025-02-26T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T12:15:00.000",
            "ToDate": "2025-02-26T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T12:30:00.000",
            "ToDate": "2025-02-26T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T12:45:00.000",
            "ToDate": "2025-02-26T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T13:00:00.000",
            "ToDate": "2025-02-26T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T13:15:00.000",
            "ToDate": "2025-02-26T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T13:30:00.000",
            "ToDate": "2025-02-26T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T13:45:00.000",
            "ToDate": "2025-02-26T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T14:00:00.000",
            "ToDate": "2025-02-26T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T14:15:00.000",
            "ToDate": "2025-02-26T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T14:30:00.000",
            "ToDate": "2025-02-26T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T14:45:00.000",
            "ToDate": "2025-02-26T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T18:00:00.000",
            "ToDate": "2025-02-26T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T18:15:00.000",
            "ToDate": "2025-02-26T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T18:30:00.000",
            "ToDate": "2025-02-26T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T18:45:00.000",
            "ToDate": "2025-02-26T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T19:00:00.000",
            "ToDate": "2025-02-26T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T19:15:00.000",
            "ToDate": "2025-02-26T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T19:30:00.000",
            "ToDate": "2025-02-26T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T19:45:00.000",
            "ToDate": "2025-02-26T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T20:00:00.000",
            "ToDate": "2025-02-26T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T20:15:00.000",
            "ToDate": "2025-02-26T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T20:30:00.000",
            "ToDate": "2025-02-26T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T20:45:00.000",
            "ToDate": "2025-02-26T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T21:00:00.000",
            "ToDate": "2025-02-26T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T21:15:00.000",
            "ToDate": "2025-02-26T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T21:30:00.000",
            "ToDate": "2025-02-26T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T21:45:00.000",
            "ToDate": "2025-02-26T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T22:00:00.000",
            "ToDate": "2025-02-26T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T22:15:00.000",
            "ToDate": "2025-02-26T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T22:30:00.000",
            "ToDate": "2025-02-26T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T22:45:00.000",
            "ToDate": "2025-02-26T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T23:00:00.000",
            "ToDate": "2025-02-26T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-26T23:15:00.000",
            "ToDate": "2025-02-26T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T12:00:00.000",
            "ToDate": "2025-02-27T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T12:15:00.000",
            "ToDate": "2025-02-27T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T12:30:00.000",
            "ToDate": "2025-02-27T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T12:45:00.000",
            "ToDate": "2025-02-27T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T13:00:00.000",
            "ToDate": "2025-02-27T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T13:15:00.000",
            "ToDate": "2025-02-27T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T13:30:00.000",
            "ToDate": "2025-02-27T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T13:45:00.000",
            "ToDate": "2025-02-27T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T14:00:00.000",
            "ToDate": "2025-02-27T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T14:15:00.000",
            "ToDate": "2025-02-27T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T14:30:00.000",
            "ToDate": "2025-02-27T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T14:45:00.000",
            "ToDate": "2025-02-27T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T18:00:00.000",
            "ToDate": "2025-02-27T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T18:15:00.000",
            "ToDate": "2025-02-27T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T18:30:00.000",
            "ToDate": "2025-02-27T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T18:45:00.000",
            "ToDate": "2025-02-27T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T19:00:00.000",
            "ToDate": "2025-02-27T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T19:15:00.000",
            "ToDate": "2025-02-27T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T19:30:00.000",
            "ToDate": "2025-02-27T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T19:45:00.000",
            "ToDate": "2025-02-27T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T20:00:00.000",
            "ToDate": "2025-02-27T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T20:15:00.000",
            "ToDate": "2025-02-27T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T20:30:00.000",
            "ToDate": "2025-02-27T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T20:45:00.000",
            "ToDate": "2025-02-27T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T21:00:00.000",
            "ToDate": "2025-02-27T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T21:15:00.000",
            "ToDate": "2025-02-27T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T21:30:00.000",
            "ToDate": "2025-02-27T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T21:45:00.000",
            "ToDate": "2025-02-27T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T22:00:00.000",
            "ToDate": "2025-02-27T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T22:15:00.000",
            "ToDate": "2025-02-27T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T22:30:00.000",
            "ToDate": "2025-02-27T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T22:45:00.000",
            "ToDate": "2025-02-27T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T23:00:00.000",
            "ToDate": "2025-02-27T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-27T23:15:00.000",
            "ToDate": "2025-02-27T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T12:00:00.000",
            "ToDate": "2025-02-28T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T12:15:00.000",
            "ToDate": "2025-02-28T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T12:30:00.000",
            "ToDate": "2025-02-28T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T12:45:00.000",
            "ToDate": "2025-02-28T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T13:00:00.000",
            "ToDate": "2025-02-28T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T13:15:00.000",
            "ToDate": "2025-02-28T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T13:30:00.000",
            "ToDate": "2025-02-28T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T13:45:00.000",
            "ToDate": "2025-02-28T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T14:00:00.000",
            "ToDate": "2025-02-28T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T14:15:00.000",
            "ToDate": "2025-02-28T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T14:30:00.000",
            "ToDate": "2025-02-28T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T14:45:00.000",
            "ToDate": "2025-02-28T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T18:00:00.000",
            "ToDate": "2025-02-28T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T18:15:00.000",
            "ToDate": "2025-02-28T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T18:30:00.000",
            "ToDate": "2025-02-28T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T18:45:00.000",
            "ToDate": "2025-02-28T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T19:00:00.000",
            "ToDate": "2025-02-28T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T19:15:00.000",
            "ToDate": "2025-02-28T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T19:30:00.000",
            "ToDate": "2025-02-28T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T19:45:00.000",
            "ToDate": "2025-02-28T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T20:00:00.000",
            "ToDate": "2025-02-28T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T20:15:00.000",
            "ToDate": "2025-02-28T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T20:30:00.000",
            "ToDate": "2025-02-28T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T20:45:00.000",
            "ToDate": "2025-02-28T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T21:00:00.000",
            "ToDate": "2025-02-28T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T21:15:00.000",
            "ToDate": "2025-02-28T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T21:30:00.000",
            "ToDate": "2025-02-28T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T21:45:00.000",
            "ToDate": "2025-02-28T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T22:00:00.000",
            "ToDate": "2025-02-28T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T22:15:00.000",
            "ToDate": "2025-02-28T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T22:30:00.000",
            "ToDate": "2025-02-28T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T22:45:00.000",
            "ToDate": "2025-02-28T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T23:00:00.000",
            "ToDate": "2025-02-28T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-02-28T23:15:00.000",
            "ToDate": "2025-02-28T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T12:00:00.000",
            "ToDate": "2025-03-01T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T12:15:00.000",
            "ToDate": "2025-03-01T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T12:30:00.000",
            "ToDate": "2025-03-01T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T12:45:00.000",
            "ToDate": "2025-03-01T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T13:00:00.000",
            "ToDate": "2025-03-01T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T13:15:00.000",
            "ToDate": "2025-03-01T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T13:30:00.000",
            "ToDate": "2025-03-01T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T13:45:00.000",
            "ToDate": "2025-03-01T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T14:00:00.000",
            "ToDate": "2025-03-01T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T14:15:00.000",
            "ToDate": "2025-03-01T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T14:30:00.000",
            "ToDate": "2025-03-01T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T14:45:00.000",
            "ToDate": "2025-03-01T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T18:00:00.000",
            "ToDate": "2025-03-01T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T18:15:00.000",
            "ToDate": "2025-03-01T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T18:30:00.000",
            "ToDate": "2025-03-01T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T18:45:00.000",
            "ToDate": "2025-03-01T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T19:00:00.000",
            "ToDate": "2025-03-01T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T19:15:00.000",
            "ToDate": "2025-03-01T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T19:30:00.000",
            "ToDate": "2025-03-01T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T19:45:00.000",
            "ToDate": "2025-03-01T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T20:00:00.000",
            "ToDate": "2025-03-01T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T20:15:00.000",
            "ToDate": "2025-03-01T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T20:30:00.000",
            "ToDate": "2025-03-01T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T20:45:00.000",
            "ToDate": "2025-03-01T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T21:00:00.000",
            "ToDate": "2025-03-01T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T21:15:00.000",
            "ToDate": "2025-03-01T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T21:30:00.000",
            "ToDate": "2025-03-01T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T21:45:00.000",
            "ToDate": "2025-03-01T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T22:00:00.000",
            "ToDate": "2025-03-01T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T22:15:00.000",
            "ToDate": "2025-03-01T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T22:30:00.000",
            "ToDate": "2025-03-01T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T22:45:00.000",
            "ToDate": "2025-03-01T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T23:00:00.000",
            "ToDate": "2025-03-01T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-01T23:15:00.000",
            "ToDate": "2025-03-01T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T12:00:00.000",
            "ToDate": "2025-03-02T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T12:15:00.000",
            "ToDate": "2025-03-02T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T12:30:00.000",
            "ToDate": "2025-03-02T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T12:45:00.000",
            "ToDate": "2025-03-02T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T13:00:00.000",
            "ToDate": "2025-03-02T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T13:15:00.000",
            "ToDate": "2025-03-02T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T13:30:00.000",
            "ToDate": "2025-03-02T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T13:45:00.000",
            "ToDate": "2025-03-02T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T14:00:00.000",
            "ToDate": "2025-03-02T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T14:15:00.000",
            "ToDate": "2025-03-02T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T14:30:00.000",
            "ToDate": "2025-03-02T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T14:45:00.000",
            "ToDate": "2025-03-02T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T18:00:00.000",
            "ToDate": "2025-03-02T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T18:15:00.000",
            "ToDate": "2025-03-02T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T18:30:00.000",
            "ToDate": "2025-03-02T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T18:45:00.000",
            "ToDate": "2025-03-02T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T19:00:00.000",
            "ToDate": "2025-03-02T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T19:15:00.000",
            "ToDate": "2025-03-02T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T19:30:00.000",
            "ToDate": "2025-03-02T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T19:45:00.000",
            "ToDate": "2025-03-02T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T20:00:00.000",
            "ToDate": "2025-03-02T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T20:15:00.000",
            "ToDate": "2025-03-02T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T20:30:00.000",
            "ToDate": "2025-03-02T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T20:45:00.000",
            "ToDate": "2025-03-02T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T21:00:00.000",
            "ToDate": "2025-03-02T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T21:15:00.000",
            "ToDate": "2025-03-02T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T21:30:00.000",
            "ToDate": "2025-03-02T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T21:45:00.000",
            "ToDate": "2025-03-02T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T22:00:00.000",
            "ToDate": "2025-03-02T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T22:15:00.000",
            "ToDate": "2025-03-02T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T22:30:00.000",
            "ToDate": "2025-03-02T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T22:45:00.000",
            "ToDate": "2025-03-02T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T23:00:00.000",
            "ToDate": "2025-03-02T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-02T23:15:00.000",
            "ToDate": "2025-03-02T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T12:00:00.000",
            "ToDate": "2025-03-03T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T12:15:00.000",
            "ToDate": "2025-03-03T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T12:30:00.000",
            "ToDate": "2025-03-03T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T12:45:00.000",
            "ToDate": "2025-03-03T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T13:00:00.000",
            "ToDate": "2025-03-03T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T13:15:00.000",
            "ToDate": "2025-03-03T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T13:30:00.000",
            "ToDate": "2025-03-03T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T13:45:00.000",
            "ToDate": "2025-03-03T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T14:00:00.000",
            "ToDate": "2025-03-03T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T14:15:00.000",
            "ToDate": "2025-03-03T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T14:30:00.000",
            "ToDate": "2025-03-03T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T14:45:00.000",
            "ToDate": "2025-03-03T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T18:00:00.000",
            "ToDate": "2025-03-03T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T18:15:00.000",
            "ToDate": "2025-03-03T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T18:30:00.000",
            "ToDate": "2025-03-03T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T18:45:00.000",
            "ToDate": "2025-03-03T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T19:00:00.000",
            "ToDate": "2025-03-03T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T19:15:00.000",
            "ToDate": "2025-03-03T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T19:30:00.000",
            "ToDate": "2025-03-03T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T19:45:00.000",
            "ToDate": "2025-03-03T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T20:00:00.000",
            "ToDate": "2025-03-03T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T20:15:00.000",
            "ToDate": "2025-03-03T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T20:30:00.000",
            "ToDate": "2025-03-03T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T20:45:00.000",
            "ToDate": "2025-03-03T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T21:00:00.000",
            "ToDate": "2025-03-03T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T21:15:00.000",
            "ToDate": "2025-03-03T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T21:30:00.000",
            "ToDate": "2025-03-03T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T21:45:00.000",
            "ToDate": "2025-03-03T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T22:00:00.000",
            "ToDate": "2025-03-03T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T22:15:00.000",
            "ToDate": "2025-03-03T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T22:30:00.000",
            "ToDate": "2025-03-03T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T22:45:00.000",
            "ToDate": "2025-03-03T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T23:00:00.000",
            "ToDate": "2025-03-03T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-03T23:15:00.000",
            "ToDate": "2025-03-03T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T12:00:00.000",
            "ToDate": "2025-03-04T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T12:15:00.000",
            "ToDate": "2025-03-04T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T12:30:00.000",
            "ToDate": "2025-03-04T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T12:45:00.000",
            "ToDate": "2025-03-04T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T13:00:00.000",
            "ToDate": "2025-03-04T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T13:15:00.000",
            "ToDate": "2025-03-04T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T13:30:00.000",
            "ToDate": "2025-03-04T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T13:45:00.000",
            "ToDate": "2025-03-04T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T14:00:00.000",
            "ToDate": "2025-03-04T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T14:15:00.000",
            "ToDate": "2025-03-04T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T14:30:00.000",
            "ToDate": "2025-03-04T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T14:45:00.000",
            "ToDate": "2025-03-04T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T18:00:00.000",
            "ToDate": "2025-03-04T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T18:15:00.000",
            "ToDate": "2025-03-04T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T18:30:00.000",
            "ToDate": "2025-03-04T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T18:45:00.000",
            "ToDate": "2025-03-04T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T19:00:00.000",
            "ToDate": "2025-03-04T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T19:15:00.000",
            "ToDate": "2025-03-04T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T19:30:00.000",
            "ToDate": "2025-03-04T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T19:45:00.000",
            "ToDate": "2025-03-04T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T20:00:00.000",
            "ToDate": "2025-03-04T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T20:15:00.000",
            "ToDate": "2025-03-04T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T20:30:00.000",
            "ToDate": "2025-03-04T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T20:45:00.000",
            "ToDate": "2025-03-04T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T21:00:00.000",
            "ToDate": "2025-03-04T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T21:15:00.000",
            "ToDate": "2025-03-04T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T21:30:00.000",
            "ToDate": "2025-03-04T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T21:45:00.000",
            "ToDate": "2025-03-04T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T22:00:00.000",
            "ToDate": "2025-03-04T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T22:15:00.000",
            "ToDate": "2025-03-04T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T22:30:00.000",
            "ToDate": "2025-03-04T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T22:45:00.000",
            "ToDate": "2025-03-04T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T23:00:00.000",
            "ToDate": "2025-03-04T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-04T23:15:00.000",
            "ToDate": "2025-03-04T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T12:00:00.000",
            "ToDate": "2025-03-05T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T12:15:00.000",
            "ToDate": "2025-03-05T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T12:30:00.000",
            "ToDate": "2025-03-05T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T12:45:00.000",
            "ToDate": "2025-03-05T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T13:00:00.000",
            "ToDate": "2025-03-05T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T13:15:00.000",
            "ToDate": "2025-03-05T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T13:30:00.000",
            "ToDate": "2025-03-05T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T13:45:00.000",
            "ToDate": "2025-03-05T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T14:00:00.000",
            "ToDate": "2025-03-05T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T14:15:00.000",
            "ToDate": "2025-03-05T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T14:30:00.000",
            "ToDate": "2025-03-05T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T14:45:00.000",
            "ToDate": "2025-03-05T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T18:00:00.000",
            "ToDate": "2025-03-05T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T18:15:00.000",
            "ToDate": "2025-03-05T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T18:30:00.000",
            "ToDate": "2025-03-05T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T18:45:00.000",
            "ToDate": "2025-03-05T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T19:00:00.000",
            "ToDate": "2025-03-05T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T19:15:00.000",
            "ToDate": "2025-03-05T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T19:30:00.000",
            "ToDate": "2025-03-05T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T19:45:00.000",
            "ToDate": "2025-03-05T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T20:00:00.000",
            "ToDate": "2025-03-05T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T20:15:00.000",
            "ToDate": "2025-03-05T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T20:30:00.000",
            "ToDate": "2025-03-05T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T20:45:00.000",
            "ToDate": "2025-03-05T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T21:00:00.000",
            "ToDate": "2025-03-05T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T21:15:00.000",
            "ToDate": "2025-03-05T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T21:30:00.000",
            "ToDate": "2025-03-05T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T21:45:00.000",
            "ToDate": "2025-03-05T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T22:00:00.000",
            "ToDate": "2025-03-05T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T22:15:00.000",
            "ToDate": "2025-03-05T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T22:30:00.000",
            "ToDate": "2025-03-05T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T22:45:00.000",
            "ToDate": "2025-03-05T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T23:00:00.000",
            "ToDate": "2025-03-05T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-05T23:15:00.000",
            "ToDate": "2025-03-05T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T12:00:00.000",
            "ToDate": "2025-03-06T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T12:15:00.000",
            "ToDate": "2025-03-06T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T12:30:00.000",
            "ToDate": "2025-03-06T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T12:45:00.000",
            "ToDate": "2025-03-06T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T13:00:00.000",
            "ToDate": "2025-03-06T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T13:15:00.000",
            "ToDate": "2025-03-06T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T13:30:00.000",
            "ToDate": "2025-03-06T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T13:45:00.000",
            "ToDate": "2025-03-06T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T14:00:00.000",
            "ToDate": "2025-03-06T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T14:15:00.000",
            "ToDate": "2025-03-06T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T14:30:00.000",
            "ToDate": "2025-03-06T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T14:45:00.000",
            "ToDate": "2025-03-06T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T18:00:00.000",
            "ToDate": "2025-03-06T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T18:15:00.000",
            "ToDate": "2025-03-06T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T18:30:00.000",
            "ToDate": "2025-03-06T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T18:45:00.000",
            "ToDate": "2025-03-06T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T19:00:00.000",
            "ToDate": "2025-03-06T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T19:15:00.000",
            "ToDate": "2025-03-06T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T19:30:00.000",
            "ToDate": "2025-03-06T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T19:45:00.000",
            "ToDate": "2025-03-06T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T20:00:00.000",
            "ToDate": "2025-03-06T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T20:15:00.000",
            "ToDate": "2025-03-06T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T20:30:00.000",
            "ToDate": "2025-03-06T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T20:45:00.000",
            "ToDate": "2025-03-06T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T21:00:00.000",
            "ToDate": "2025-03-06T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T21:15:00.000",
            "ToDate": "2025-03-06T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T21:30:00.000",
            "ToDate": "2025-03-06T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T21:45:00.000",
            "ToDate": "2025-03-06T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T22:00:00.000",
            "ToDate": "2025-03-06T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T22:15:00.000",
            "ToDate": "2025-03-06T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T22:30:00.000",
            "ToDate": "2025-03-06T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T22:45:00.000",
            "ToDate": "2025-03-06T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T23:00:00.000",
            "ToDate": "2025-03-06T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-06T23:15:00.000",
            "ToDate": "2025-03-06T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T12:00:00.000",
            "ToDate": "2025-03-07T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T12:15:00.000",
            "ToDate": "2025-03-07T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T12:30:00.000",
            "ToDate": "2025-03-07T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T12:45:00.000",
            "ToDate": "2025-03-07T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T13:00:00.000",
            "ToDate": "2025-03-07T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T13:15:00.000",
            "ToDate": "2025-03-07T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T13:30:00.000",
            "ToDate": "2025-03-07T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T13:45:00.000",
            "ToDate": "2025-03-07T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T14:00:00.000",
            "ToDate": "2025-03-07T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T14:15:00.000",
            "ToDate": "2025-03-07T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T14:30:00.000",
            "ToDate": "2025-03-07T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T14:45:00.000",
            "ToDate": "2025-03-07T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T18:00:00.000",
            "ToDate": "2025-03-07T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T18:15:00.000",
            "ToDate": "2025-03-07T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T18:30:00.000",
            "ToDate": "2025-03-07T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T18:45:00.000",
            "ToDate": "2025-03-07T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T19:00:00.000",
            "ToDate": "2025-03-07T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T19:15:00.000",
            "ToDate": "2025-03-07T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T19:30:00.000",
            "ToDate": "2025-03-07T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T19:45:00.000",
            "ToDate": "2025-03-07T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T20:00:00.000",
            "ToDate": "2025-03-07T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T20:15:00.000",
            "ToDate": "2025-03-07T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T20:30:00.000",
            "ToDate": "2025-03-07T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T20:45:00.000",
            "ToDate": "2025-03-07T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T21:00:00.000",
            "ToDate": "2025-03-07T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T21:15:00.000",
            "ToDate": "2025-03-07T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T21:30:00.000",
            "ToDate": "2025-03-07T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T21:45:00.000",
            "ToDate": "2025-03-07T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T22:00:00.000",
            "ToDate": "2025-03-07T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T22:15:00.000",
            "ToDate": "2025-03-07T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T22:30:00.000",
            "ToDate": "2025-03-07T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T22:45:00.000",
            "ToDate": "2025-03-07T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T23:00:00.000",
            "ToDate": "2025-03-07T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-07T23:15:00.000",
            "ToDate": "2025-03-07T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T12:00:00.000",
            "ToDate": "2025-03-08T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T12:15:00.000",
            "ToDate": "2025-03-08T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T12:30:00.000",
            "ToDate": "2025-03-08T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T12:45:00.000",
            "ToDate": "2025-03-08T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T13:00:00.000",
            "ToDate": "2025-03-08T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T13:15:00.000",
            "ToDate": "2025-03-08T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T13:30:00.000",
            "ToDate": "2025-03-08T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T13:45:00.000",
            "ToDate": "2025-03-08T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T14:00:00.000",
            "ToDate": "2025-03-08T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T14:15:00.000",
            "ToDate": "2025-03-08T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T14:30:00.000",
            "ToDate": "2025-03-08T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T14:45:00.000",
            "ToDate": "2025-03-08T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T18:00:00.000",
            "ToDate": "2025-03-08T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T18:15:00.000",
            "ToDate": "2025-03-08T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T18:30:00.000",
            "ToDate": "2025-03-08T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T18:45:00.000",
            "ToDate": "2025-03-08T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T19:00:00.000",
            "ToDate": "2025-03-08T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T19:15:00.000",
            "ToDate": "2025-03-08T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T19:30:00.000",
            "ToDate": "2025-03-08T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T19:45:00.000",
            "ToDate": "2025-03-08T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T20:00:00.000",
            "ToDate": "2025-03-08T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T20:15:00.000",
            "ToDate": "2025-03-08T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T20:30:00.000",
            "ToDate": "2025-03-08T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T20:45:00.000",
            "ToDate": "2025-03-08T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T21:00:00.000",
            "ToDate": "2025-03-08T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T21:15:00.000",
            "ToDate": "2025-03-08T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T21:30:00.000",
            "ToDate": "2025-03-08T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T21:45:00.000",
            "ToDate": "2025-03-08T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T22:00:00.000",
            "ToDate": "2025-03-08T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T22:15:00.000",
            "ToDate": "2025-03-08T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T22:30:00.000",
            "ToDate": "2025-03-08T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T22:45:00.000",
            "ToDate": "2025-03-08T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T23:00:00.000",
            "ToDate": "2025-03-08T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-08T23:15:00.000",
            "ToDate": "2025-03-08T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T12:00:00.000",
            "ToDate": "2025-03-09T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T12:15:00.000",
            "ToDate": "2025-03-09T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T12:30:00.000",
            "ToDate": "2025-03-09T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T12:45:00.000",
            "ToDate": "2025-03-09T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T13:00:00.000",
            "ToDate": "2025-03-09T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T13:15:00.000",
            "ToDate": "2025-03-09T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T13:30:00.000",
            "ToDate": "2025-03-09T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T13:45:00.000",
            "ToDate": "2025-03-09T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T14:00:00.000",
            "ToDate": "2025-03-09T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T14:15:00.000",
            "ToDate": "2025-03-09T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T14:30:00.000",
            "ToDate": "2025-03-09T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T14:45:00.000",
            "ToDate": "2025-03-09T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T18:00:00.000",
            "ToDate": "2025-03-09T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T18:15:00.000",
            "ToDate": "2025-03-09T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T18:30:00.000",
            "ToDate": "2025-03-09T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T18:45:00.000",
            "ToDate": "2025-03-09T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T19:00:00.000",
            "ToDate": "2025-03-09T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T19:15:00.000",
            "ToDate": "2025-03-09T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T19:30:00.000",
            "ToDate": "2025-03-09T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T19:45:00.000",
            "ToDate": "2025-03-09T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T20:00:00.000",
            "ToDate": "2025-03-09T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T20:15:00.000",
            "ToDate": "2025-03-09T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T20:30:00.000",
            "ToDate": "2025-03-09T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T20:45:00.000",
            "ToDate": "2025-03-09T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T21:00:00.000",
            "ToDate": "2025-03-09T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T21:15:00.000",
            "ToDate": "2025-03-09T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T21:30:00.000",
            "ToDate": "2025-03-09T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T21:45:00.000",
            "ToDate": "2025-03-09T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T22:00:00.000",
            "ToDate": "2025-03-09T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T22:15:00.000",
            "ToDate": "2025-03-09T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T22:30:00.000",
            "ToDate": "2025-03-09T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T22:45:00.000",
            "ToDate": "2025-03-09T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T23:00:00.000",
            "ToDate": "2025-03-09T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-09T23:15:00.000",
            "ToDate": "2025-03-09T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T12:00:00.000",
            "ToDate": "2025-03-10T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T12:15:00.000",
            "ToDate": "2025-03-10T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T12:30:00.000",
            "ToDate": "2025-03-10T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T12:45:00.000",
            "ToDate": "2025-03-10T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T13:00:00.000",
            "ToDate": "2025-03-10T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T13:15:00.000",
            "ToDate": "2025-03-10T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T13:30:00.000",
            "ToDate": "2025-03-10T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T13:45:00.000",
            "ToDate": "2025-03-10T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T14:00:00.000",
            "ToDate": "2025-03-10T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T14:15:00.000",
            "ToDate": "2025-03-10T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T14:30:00.000",
            "ToDate": "2025-03-10T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T14:45:00.000",
            "ToDate": "2025-03-10T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T18:00:00.000",
            "ToDate": "2025-03-10T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T18:15:00.000",
            "ToDate": "2025-03-10T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T18:30:00.000",
            "ToDate": "2025-03-10T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T18:45:00.000",
            "ToDate": "2025-03-10T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T19:00:00.000",
            "ToDate": "2025-03-10T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T19:15:00.000",
            "ToDate": "2025-03-10T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T19:30:00.000",
            "ToDate": "2025-03-10T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T19:45:00.000",
            "ToDate": "2025-03-10T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T20:00:00.000",
            "ToDate": "2025-03-10T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T20:15:00.000",
            "ToDate": "2025-03-10T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T20:30:00.000",
            "ToDate": "2025-03-10T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T20:45:00.000",
            "ToDate": "2025-03-10T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T21:00:00.000",
            "ToDate": "2025-03-10T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T21:15:00.000",
            "ToDate": "2025-03-10T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T21:30:00.000",
            "ToDate": "2025-03-10T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T21:45:00.000",
            "ToDate": "2025-03-10T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T22:00:00.000",
            "ToDate": "2025-03-10T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T22:15:00.000",
            "ToDate": "2025-03-10T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T22:30:00.000",
            "ToDate": "2025-03-10T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T22:45:00.000",
            "ToDate": "2025-03-10T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T23:00:00.000",
            "ToDate": "2025-03-10T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-10T23:15:00.000",
            "ToDate": "2025-03-10T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T12:00:00.000",
            "ToDate": "2025-03-11T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T12:15:00.000",
            "ToDate": "2025-03-11T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T12:30:00.000",
            "ToDate": "2025-03-11T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T12:45:00.000",
            "ToDate": "2025-03-11T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T13:00:00.000",
            "ToDate": "2025-03-11T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T13:15:00.000",
            "ToDate": "2025-03-11T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T13:30:00.000",
            "ToDate": "2025-03-11T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T13:45:00.000",
            "ToDate": "2025-03-11T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T14:00:00.000",
            "ToDate": "2025-03-11T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T14:15:00.000",
            "ToDate": "2025-03-11T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T14:30:00.000",
            "ToDate": "2025-03-11T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T14:45:00.000",
            "ToDate": "2025-03-11T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T18:00:00.000",
            "ToDate": "2025-03-11T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T18:15:00.000",
            "ToDate": "2025-03-11T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T18:30:00.000",
            "ToDate": "2025-03-11T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T18:45:00.000",
            "ToDate": "2025-03-11T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T19:00:00.000",
            "ToDate": "2025-03-11T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T19:15:00.000",
            "ToDate": "2025-03-11T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T19:30:00.000",
            "ToDate": "2025-03-11T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T19:45:00.000",
            "ToDate": "2025-03-11T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T20:00:00.000",
            "ToDate": "2025-03-11T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T20:15:00.000",
            "ToDate": "2025-03-11T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T20:30:00.000",
            "ToDate": "2025-03-11T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T20:45:00.000",
            "ToDate": "2025-03-11T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T21:00:00.000",
            "ToDate": "2025-03-11T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T21:15:00.000",
            "ToDate": "2025-03-11T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T21:30:00.000",
            "ToDate": "2025-03-11T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T21:45:00.000",
            "ToDate": "2025-03-11T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T22:00:00.000",
            "ToDate": "2025-03-11T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T22:15:00.000",
            "ToDate": "2025-03-11T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T22:30:00.000",
            "ToDate": "2025-03-11T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T22:45:00.000",
            "ToDate": "2025-03-11T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T23:00:00.000",
            "ToDate": "2025-03-11T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-11T23:15:00.000",
            "ToDate": "2025-03-11T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T12:00:00.000",
            "ToDate": "2025-03-12T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T12:15:00.000",
            "ToDate": "2025-03-12T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T12:30:00.000",
            "ToDate": "2025-03-12T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T12:45:00.000",
            "ToDate": "2025-03-12T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T13:00:00.000",
            "ToDate": "2025-03-12T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T13:15:00.000",
            "ToDate": "2025-03-12T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T13:30:00.000",
            "ToDate": "2025-03-12T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T13:45:00.000",
            "ToDate": "2025-03-12T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T14:00:00.000",
            "ToDate": "2025-03-12T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T14:15:00.000",
            "ToDate": "2025-03-12T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T14:30:00.000",
            "ToDate": "2025-03-12T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T14:45:00.000",
            "ToDate": "2025-03-12T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T18:00:00.000",
            "ToDate": "2025-03-12T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T18:15:00.000",
            "ToDate": "2025-03-12T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T18:30:00.000",
            "ToDate": "2025-03-12T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T18:45:00.000",
            "ToDate": "2025-03-12T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T19:00:00.000",
            "ToDate": "2025-03-12T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T19:15:00.000",
            "ToDate": "2025-03-12T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T19:30:00.000",
            "ToDate": "2025-03-12T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T19:45:00.000",
            "ToDate": "2025-03-12T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T20:00:00.000",
            "ToDate": "2025-03-12T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T20:15:00.000",
            "ToDate": "2025-03-12T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T20:30:00.000",
            "ToDate": "2025-03-12T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T20:45:00.000",
            "ToDate": "2025-03-12T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T21:00:00.000",
            "ToDate": "2025-03-12T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T21:15:00.000",
            "ToDate": "2025-03-12T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T21:30:00.000",
            "ToDate": "2025-03-12T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T21:45:00.000",
            "ToDate": "2025-03-12T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T22:00:00.000",
            "ToDate": "2025-03-12T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T22:15:00.000",
            "ToDate": "2025-03-12T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T22:30:00.000",
            "ToDate": "2025-03-12T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T22:45:00.000",
            "ToDate": "2025-03-12T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T23:00:00.000",
            "ToDate": "2025-03-12T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-12T23:15:00.000",
            "ToDate": "2025-03-12T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T12:00:00.000",
            "ToDate": "2025-03-13T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T12:15:00.000",
            "ToDate": "2025-03-13T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T12:30:00.000",
            "ToDate": "2025-03-13T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T12:45:00.000",
            "ToDate": "2025-03-13T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T13:00:00.000",
            "ToDate": "2025-03-13T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T13:15:00.000",
            "ToDate": "2025-03-13T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T13:30:00.000",
            "ToDate": "2025-03-13T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T13:45:00.000",
            "ToDate": "2025-03-13T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T14:00:00.000",
            "ToDate": "2025-03-13T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T14:15:00.000",
            "ToDate": "2025-03-13T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T14:30:00.000",
            "ToDate": "2025-03-13T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T14:45:00.000",
            "ToDate": "2025-03-13T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T18:00:00.000",
            "ToDate": "2025-03-13T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T18:15:00.000",
            "ToDate": "2025-03-13T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T18:30:00.000",
            "ToDate": "2025-03-13T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T18:45:00.000",
            "ToDate": "2025-03-13T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T19:00:00.000",
            "ToDate": "2025-03-13T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T19:15:00.000",
            "ToDate": "2025-03-13T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T19:30:00.000",
            "ToDate": "2025-03-13T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T19:45:00.000",
            "ToDate": "2025-03-13T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T20:00:00.000",
            "ToDate": "2025-03-13T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T20:15:00.000",
            "ToDate": "2025-03-13T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T20:30:00.000",
            "ToDate": "2025-03-13T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T20:45:00.000",
            "ToDate": "2025-03-13T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T21:00:00.000",
            "ToDate": "2025-03-13T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T21:15:00.000",
            "ToDate": "2025-03-13T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T21:30:00.000",
            "ToDate": "2025-03-13T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T21:45:00.000",
            "ToDate": "2025-03-13T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T22:00:00.000",
            "ToDate": "2025-03-13T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T22:15:00.000",
            "ToDate": "2025-03-13T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T22:30:00.000",
            "ToDate": "2025-03-13T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T22:45:00.000",
            "ToDate": "2025-03-13T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T23:00:00.000",
            "ToDate": "2025-03-13T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-13T23:15:00.000",
            "ToDate": "2025-03-13T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T12:00:00.000",
            "ToDate": "2025-03-14T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T12:15:00.000",
            "ToDate": "2025-03-14T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T12:30:00.000",
            "ToDate": "2025-03-14T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T12:45:00.000",
            "ToDate": "2025-03-14T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T13:00:00.000",
            "ToDate": "2025-03-14T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T13:15:00.000",
            "ToDate": "2025-03-14T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T13:30:00.000",
            "ToDate": "2025-03-14T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T13:45:00.000",
            "ToDate": "2025-03-14T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T14:00:00.000",
            "ToDate": "2025-03-14T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T14:15:00.000",
            "ToDate": "2025-03-14T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T14:30:00.000",
            "ToDate": "2025-03-14T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T14:45:00.000",
            "ToDate": "2025-03-14T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T18:00:00.000",
            "ToDate": "2025-03-14T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T18:15:00.000",
            "ToDate": "2025-03-14T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T18:30:00.000",
            "ToDate": "2025-03-14T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T18:45:00.000",
            "ToDate": "2025-03-14T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T19:00:00.000",
            "ToDate": "2025-03-14T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T19:15:00.000",
            "ToDate": "2025-03-14T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T19:30:00.000",
            "ToDate": "2025-03-14T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T19:45:00.000",
            "ToDate": "2025-03-14T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T20:00:00.000",
            "ToDate": "2025-03-14T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T20:15:00.000",
            "ToDate": "2025-03-14T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T20:30:00.000",
            "ToDate": "2025-03-14T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T20:45:00.000",
            "ToDate": "2025-03-14T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T21:00:00.000",
            "ToDate": "2025-03-14T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T21:15:00.000",
            "ToDate": "2025-03-14T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T21:30:00.000",
            "ToDate": "2025-03-14T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T21:45:00.000",
            "ToDate": "2025-03-14T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T22:00:00.000",
            "ToDate": "2025-03-14T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T22:15:00.000",
            "ToDate": "2025-03-14T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T22:30:00.000",
            "ToDate": "2025-03-14T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T22:45:00.000",
            "ToDate": "2025-03-14T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T23:00:00.000",
            "ToDate": "2025-03-14T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-14T23:15:00.000",
            "ToDate": "2025-03-14T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T12:00:00.000",
            "ToDate": "2025-03-15T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T12:15:00.000",
            "ToDate": "2025-03-15T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T12:30:00.000",
            "ToDate": "2025-03-15T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T12:45:00.000",
            "ToDate": "2025-03-15T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T13:00:00.000",
            "ToDate": "2025-03-15T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T13:15:00.000",
            "ToDate": "2025-03-15T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T13:30:00.000",
            "ToDate": "2025-03-15T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T13:45:00.000",
            "ToDate": "2025-03-15T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T14:00:00.000",
            "ToDate": "2025-03-15T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T14:15:00.000",
            "ToDate": "2025-03-15T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T14:30:00.000",
            "ToDate": "2025-03-15T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T14:45:00.000",
            "ToDate": "2025-03-15T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T18:00:00.000",
            "ToDate": "2025-03-15T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T18:15:00.000",
            "ToDate": "2025-03-15T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T18:30:00.000",
            "ToDate": "2025-03-15T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T18:45:00.000",
            "ToDate": "2025-03-15T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T19:00:00.000",
            "ToDate": "2025-03-15T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T19:15:00.000",
            "ToDate": "2025-03-15T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T19:30:00.000",
            "ToDate": "2025-03-15T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T19:45:00.000",
            "ToDate": "2025-03-15T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T20:00:00.000",
            "ToDate": "2025-03-15T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T20:15:00.000",
            "ToDate": "2025-03-15T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T20:30:00.000",
            "ToDate": "2025-03-15T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T20:45:00.000",
            "ToDate": "2025-03-15T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T21:00:00.000",
            "ToDate": "2025-03-15T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T21:15:00.000",
            "ToDate": "2025-03-15T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T21:30:00.000",
            "ToDate": "2025-03-15T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T21:45:00.000",
            "ToDate": "2025-03-15T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T22:00:00.000",
            "ToDate": "2025-03-15T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T22:15:00.000",
            "ToDate": "2025-03-15T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T22:30:00.000",
            "ToDate": "2025-03-15T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T22:45:00.000",
            "ToDate": "2025-03-15T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T23:00:00.000",
            "ToDate": "2025-03-15T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-15T23:15:00.000",
            "ToDate": "2025-03-15T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T12:00:00.000",
            "ToDate": "2025-03-16T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T12:15:00.000",
            "ToDate": "2025-03-16T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T12:30:00.000",
            "ToDate": "2025-03-16T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T12:45:00.000",
            "ToDate": "2025-03-16T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T13:00:00.000",
            "ToDate": "2025-03-16T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T13:15:00.000",
            "ToDate": "2025-03-16T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T13:30:00.000",
            "ToDate": "2025-03-16T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T13:45:00.000",
            "ToDate": "2025-03-16T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T14:00:00.000",
            "ToDate": "2025-03-16T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T14:15:00.000",
            "ToDate": "2025-03-16T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T14:30:00.000",
            "ToDate": "2025-03-16T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T14:45:00.000",
            "ToDate": "2025-03-16T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T18:00:00.000",
            "ToDate": "2025-03-16T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T18:15:00.000",
            "ToDate": "2025-03-16T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T18:30:00.000",
            "ToDate": "2025-03-16T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T18:45:00.000",
            "ToDate": "2025-03-16T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T19:00:00.000",
            "ToDate": "2025-03-16T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T19:15:00.000",
            "ToDate": "2025-03-16T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T19:30:00.000",
            "ToDate": "2025-03-16T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T19:45:00.000",
            "ToDate": "2025-03-16T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T20:00:00.000",
            "ToDate": "2025-03-16T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T20:15:00.000",
            "ToDate": "2025-03-16T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T20:30:00.000",
            "ToDate": "2025-03-16T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T20:45:00.000",
            "ToDate": "2025-03-16T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T21:00:00.000",
            "ToDate": "2025-03-16T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T21:15:00.000",
            "ToDate": "2025-03-16T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T21:30:00.000",
            "ToDate": "2025-03-16T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T21:45:00.000",
            "ToDate": "2025-03-16T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T22:00:00.000",
            "ToDate": "2025-03-16T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T22:15:00.000",
            "ToDate": "2025-03-16T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T22:30:00.000",
            "ToDate": "2025-03-16T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T22:45:00.000",
            "ToDate": "2025-03-16T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T23:00:00.000",
            "ToDate": "2025-03-16T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-16T23:15:00.000",
            "ToDate": "2025-03-16T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T12:00:00.000",
            "ToDate": "2025-03-17T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T12:15:00.000",
            "ToDate": "2025-03-17T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T12:30:00.000",
            "ToDate": "2025-03-17T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T12:45:00.000",
            "ToDate": "2025-03-17T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T13:00:00.000",
            "ToDate": "2025-03-17T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T13:15:00.000",
            "ToDate": "2025-03-17T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T13:30:00.000",
            "ToDate": "2025-03-17T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T13:45:00.000",
            "ToDate": "2025-03-17T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T14:00:00.000",
            "ToDate": "2025-03-17T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T14:15:00.000",
            "ToDate": "2025-03-17T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T14:30:00.000",
            "ToDate": "2025-03-17T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T14:45:00.000",
            "ToDate": "2025-03-17T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T18:00:00.000",
            "ToDate": "2025-03-17T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T18:15:00.000",
            "ToDate": "2025-03-17T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T18:30:00.000",
            "ToDate": "2025-03-17T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T18:45:00.000",
            "ToDate": "2025-03-17T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T19:00:00.000",
            "ToDate": "2025-03-17T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T19:15:00.000",
            "ToDate": "2025-03-17T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T19:30:00.000",
            "ToDate": "2025-03-17T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T19:45:00.000",
            "ToDate": "2025-03-17T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T20:00:00.000",
            "ToDate": "2025-03-17T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T20:15:00.000",
            "ToDate": "2025-03-17T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T20:30:00.000",
            "ToDate": "2025-03-17T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T20:45:00.000",
            "ToDate": "2025-03-17T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T21:00:00.000",
            "ToDate": "2025-03-17T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T21:15:00.000",
            "ToDate": "2025-03-17T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T21:30:00.000",
            "ToDate": "2025-03-17T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T21:45:00.000",
            "ToDate": "2025-03-17T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T22:00:00.000",
            "ToDate": "2025-03-17T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T22:15:00.000",
            "ToDate": "2025-03-17T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T22:30:00.000",
            "ToDate": "2025-03-17T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T22:45:00.000",
            "ToDate": "2025-03-17T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T23:00:00.000",
            "ToDate": "2025-03-17T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-17T23:15:00.000",
            "ToDate": "2025-03-17T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T12:00:00.000",
            "ToDate": "2025-03-18T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T12:15:00.000",
            "ToDate": "2025-03-18T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T12:30:00.000",
            "ToDate": "2025-03-18T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T12:45:00.000",
            "ToDate": "2025-03-18T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T13:00:00.000",
            "ToDate": "2025-03-18T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T13:15:00.000",
            "ToDate": "2025-03-18T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T13:30:00.000",
            "ToDate": "2025-03-18T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T13:45:00.000",
            "ToDate": "2025-03-18T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T14:00:00.000",
            "ToDate": "2025-03-18T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T14:15:00.000",
            "ToDate": "2025-03-18T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T14:30:00.000",
            "ToDate": "2025-03-18T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T14:45:00.000",
            "ToDate": "2025-03-18T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T18:00:00.000",
            "ToDate": "2025-03-18T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T18:15:00.000",
            "ToDate": "2025-03-18T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T18:30:00.000",
            "ToDate": "2025-03-18T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T18:45:00.000",
            "ToDate": "2025-03-18T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T19:00:00.000",
            "ToDate": "2025-03-18T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T19:15:00.000",
            "ToDate": "2025-03-18T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T19:30:00.000",
            "ToDate": "2025-03-18T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T19:45:00.000",
            "ToDate": "2025-03-18T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T20:00:00.000",
            "ToDate": "2025-03-18T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T20:15:00.000",
            "ToDate": "2025-03-18T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T20:30:00.000",
            "ToDate": "2025-03-18T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T20:45:00.000",
            "ToDate": "2025-03-18T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T21:00:00.000",
            "ToDate": "2025-03-18T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T21:15:00.000",
            "ToDate": "2025-03-18T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T21:30:00.000",
            "ToDate": "2025-03-18T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T21:45:00.000",
            "ToDate": "2025-03-18T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T22:00:00.000",
            "ToDate": "2025-03-18T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T22:15:00.000",
            "ToDate": "2025-03-18T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T22:30:00.000",
            "ToDate": "2025-03-18T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T22:45:00.000",
            "ToDate": "2025-03-18T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T23:00:00.000",
            "ToDate": "2025-03-18T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-18T23:15:00.000",
            "ToDate": "2025-03-18T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T12:00:00.000",
            "ToDate": "2025-03-19T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T12:15:00.000",
            "ToDate": "2025-03-19T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T12:30:00.000",
            "ToDate": "2025-03-19T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T12:45:00.000",
            "ToDate": "2025-03-19T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T13:00:00.000",
            "ToDate": "2025-03-19T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T13:15:00.000",
            "ToDate": "2025-03-19T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T13:30:00.000",
            "ToDate": "2025-03-19T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T13:45:00.000",
            "ToDate": "2025-03-19T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T14:00:00.000",
            "ToDate": "2025-03-19T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T14:15:00.000",
            "ToDate": "2025-03-19T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T14:30:00.000",
            "ToDate": "2025-03-19T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T14:45:00.000",
            "ToDate": "2025-03-19T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T18:00:00.000",
            "ToDate": "2025-03-19T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T18:15:00.000",
            "ToDate": "2025-03-19T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T18:30:00.000",
            "ToDate": "2025-03-19T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T18:45:00.000",
            "ToDate": "2025-03-19T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T19:00:00.000",
            "ToDate": "2025-03-19T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T19:15:00.000",
            "ToDate": "2025-03-19T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T19:30:00.000",
            "ToDate": "2025-03-19T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T19:45:00.000",
            "ToDate": "2025-03-19T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T20:00:00.000",
            "ToDate": "2025-03-19T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T20:15:00.000",
            "ToDate": "2025-03-19T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T20:30:00.000",
            "ToDate": "2025-03-19T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T20:45:00.000",
            "ToDate": "2025-03-19T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T21:00:00.000",
            "ToDate": "2025-03-19T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T21:15:00.000",
            "ToDate": "2025-03-19T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T21:30:00.000",
            "ToDate": "2025-03-19T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T21:45:00.000",
            "ToDate": "2025-03-19T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T22:00:00.000",
            "ToDate": "2025-03-19T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T22:15:00.000",
            "ToDate": "2025-03-19T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T22:30:00.000",
            "ToDate": "2025-03-19T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T22:45:00.000",
            "ToDate": "2025-03-19T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T23:00:00.000",
            "ToDate": "2025-03-19T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-19T23:15:00.000",
            "ToDate": "2025-03-19T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T12:00:00.000",
            "ToDate": "2025-03-20T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T12:15:00.000",
            "ToDate": "2025-03-20T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T12:30:00.000",
            "ToDate": "2025-03-20T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T12:45:00.000",
            "ToDate": "2025-03-20T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T13:00:00.000",
            "ToDate": "2025-03-20T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T13:15:00.000",
            "ToDate": "2025-03-20T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T13:30:00.000",
            "ToDate": "2025-03-20T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T13:45:00.000",
            "ToDate": "2025-03-20T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T14:00:00.000",
            "ToDate": "2025-03-20T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T14:15:00.000",
            "ToDate": "2025-03-20T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T14:30:00.000",
            "ToDate": "2025-03-20T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T14:45:00.000",
            "ToDate": "2025-03-20T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T18:00:00.000",
            "ToDate": "2025-03-20T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T18:15:00.000",
            "ToDate": "2025-03-20T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T18:30:00.000",
            "ToDate": "2025-03-20T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T18:45:00.000",
            "ToDate": "2025-03-20T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T19:00:00.000",
            "ToDate": "2025-03-20T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T19:15:00.000",
            "ToDate": "2025-03-20T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T19:30:00.000",
            "ToDate": "2025-03-20T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T19:45:00.000",
            "ToDate": "2025-03-20T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T20:00:00.000",
            "ToDate": "2025-03-20T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T20:15:00.000",
            "ToDate": "2025-03-20T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T20:30:00.000",
            "ToDate": "2025-03-20T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T20:45:00.000",
            "ToDate": "2025-03-20T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T21:00:00.000",
            "ToDate": "2025-03-20T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T21:15:00.000",
            "ToDate": "2025-03-20T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T21:30:00.000",
            "ToDate": "2025-03-20T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T21:45:00.000",
            "ToDate": "2025-03-20T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T22:00:00.000",
            "ToDate": "2025-03-20T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T22:15:00.000",
            "ToDate": "2025-03-20T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T22:30:00.000",
            "ToDate": "2025-03-20T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T22:45:00.000",
            "ToDate": "2025-03-20T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T23:00:00.000",
            "ToDate": "2025-03-20T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-20T23:15:00.000",
            "ToDate": "2025-03-20T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T12:00:00.000",
            "ToDate": "2025-03-21T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T12:15:00.000",
            "ToDate": "2025-03-21T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T12:30:00.000",
            "ToDate": "2025-03-21T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T12:45:00.000",
            "ToDate": "2025-03-21T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T13:00:00.000",
            "ToDate": "2025-03-21T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T13:15:00.000",
            "ToDate": "2025-03-21T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T13:30:00.000",
            "ToDate": "2025-03-21T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T13:45:00.000",
            "ToDate": "2025-03-21T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T14:00:00.000",
            "ToDate": "2025-03-21T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T14:15:00.000",
            "ToDate": "2025-03-21T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T14:30:00.000",
            "ToDate": "2025-03-21T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T14:45:00.000",
            "ToDate": "2025-03-21T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T18:00:00.000",
            "ToDate": "2025-03-21T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T18:15:00.000",
            "ToDate": "2025-03-21T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T18:30:00.000",
            "ToDate": "2025-03-21T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T18:45:00.000",
            "ToDate": "2025-03-21T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T19:00:00.000",
            "ToDate": "2025-03-21T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T19:15:00.000",
            "ToDate": "2025-03-21T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T19:30:00.000",
            "ToDate": "2025-03-21T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T19:45:00.000",
            "ToDate": "2025-03-21T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T20:00:00.000",
            "ToDate": "2025-03-21T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T20:15:00.000",
            "ToDate": "2025-03-21T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T20:30:00.000",
            "ToDate": "2025-03-21T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T20:45:00.000",
            "ToDate": "2025-03-21T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T21:00:00.000",
            "ToDate": "2025-03-21T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T21:15:00.000",
            "ToDate": "2025-03-21T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T21:30:00.000",
            "ToDate": "2025-03-21T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T21:45:00.000",
            "ToDate": "2025-03-21T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T22:00:00.000",
            "ToDate": "2025-03-21T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T22:15:00.000",
            "ToDate": "2025-03-21T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T22:30:00.000",
            "ToDate": "2025-03-21T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T22:45:00.000",
            "ToDate": "2025-03-21T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T23:00:00.000",
            "ToDate": "2025-03-21T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-21T23:15:00.000",
            "ToDate": "2025-03-21T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T12:00:00.000",
            "ToDate": "2025-03-22T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T12:15:00.000",
            "ToDate": "2025-03-22T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T12:30:00.000",
            "ToDate": "2025-03-22T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T12:45:00.000",
            "ToDate": "2025-03-22T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T13:00:00.000",
            "ToDate": "2025-03-22T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T13:15:00.000",
            "ToDate": "2025-03-22T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T13:30:00.000",
            "ToDate": "2025-03-22T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T13:45:00.000",
            "ToDate": "2025-03-22T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T14:00:00.000",
            "ToDate": "2025-03-22T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T14:15:00.000",
            "ToDate": "2025-03-22T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T14:30:00.000",
            "ToDate": "2025-03-22T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T14:45:00.000",
            "ToDate": "2025-03-22T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T18:00:00.000",
            "ToDate": "2025-03-22T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T18:15:00.000",
            "ToDate": "2025-03-22T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T18:30:00.000",
            "ToDate": "2025-03-22T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T18:45:00.000",
            "ToDate": "2025-03-22T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T19:00:00.000",
            "ToDate": "2025-03-22T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T19:15:00.000",
            "ToDate": "2025-03-22T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T19:30:00.000",
            "ToDate": "2025-03-22T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T19:45:00.000",
            "ToDate": "2025-03-22T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T20:00:00.000",
            "ToDate": "2025-03-22T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T20:15:00.000",
            "ToDate": "2025-03-22T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T20:30:00.000",
            "ToDate": "2025-03-22T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T20:45:00.000",
            "ToDate": "2025-03-22T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T21:00:00.000",
            "ToDate": "2025-03-22T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T21:15:00.000",
            "ToDate": "2025-03-22T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T21:30:00.000",
            "ToDate": "2025-03-22T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T21:45:00.000",
            "ToDate": "2025-03-22T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T22:00:00.000",
            "ToDate": "2025-03-22T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T22:15:00.000",
            "ToDate": "2025-03-22T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T22:30:00.000",
            "ToDate": "2025-03-22T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T22:45:00.000",
            "ToDate": "2025-03-22T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T23:00:00.000",
            "ToDate": "2025-03-22T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-22T23:15:00.000",
            "ToDate": "2025-03-22T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T12:00:00.000",
            "ToDate": "2025-03-23T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T12:15:00.000",
            "ToDate": "2025-03-23T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T12:30:00.000",
            "ToDate": "2025-03-23T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T12:45:00.000",
            "ToDate": "2025-03-23T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T13:00:00.000",
            "ToDate": "2025-03-23T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T13:15:00.000",
            "ToDate": "2025-03-23T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T13:30:00.000",
            "ToDate": "2025-03-23T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T13:45:00.000",
            "ToDate": "2025-03-23T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T14:00:00.000",
            "ToDate": "2025-03-23T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T14:15:00.000",
            "ToDate": "2025-03-23T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T14:30:00.000",
            "ToDate": "2025-03-23T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T14:45:00.000",
            "ToDate": "2025-03-23T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T18:00:00.000",
            "ToDate": "2025-03-23T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T18:15:00.000",
            "ToDate": "2025-03-23T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T18:30:00.000",
            "ToDate": "2025-03-23T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T18:45:00.000",
            "ToDate": "2025-03-23T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T19:00:00.000",
            "ToDate": "2025-03-23T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T19:15:00.000",
            "ToDate": "2025-03-23T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T19:30:00.000",
            "ToDate": "2025-03-23T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T19:45:00.000",
            "ToDate": "2025-03-23T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T20:00:00.000",
            "ToDate": "2025-03-23T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T20:15:00.000",
            "ToDate": "2025-03-23T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T20:30:00.000",
            "ToDate": "2025-03-23T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T20:45:00.000",
            "ToDate": "2025-03-23T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T21:00:00.000",
            "ToDate": "2025-03-23T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T21:15:00.000",
            "ToDate": "2025-03-23T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T21:30:00.000",
            "ToDate": "2025-03-23T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T21:45:00.000",
            "ToDate": "2025-03-23T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T22:00:00.000",
            "ToDate": "2025-03-23T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T22:15:00.000",
            "ToDate": "2025-03-23T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T22:30:00.000",
            "ToDate": "2025-03-23T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T22:45:00.000",
            "ToDate": "2025-03-23T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T23:00:00.000",
            "ToDate": "2025-03-23T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-23T23:15:00.000",
            "ToDate": "2025-03-23T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T12:00:00.000",
            "ToDate": "2025-03-24T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T12:15:00.000",
            "ToDate": "2025-03-24T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T12:30:00.000",
            "ToDate": "2025-03-24T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T12:45:00.000",
            "ToDate": "2025-03-24T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T13:00:00.000",
            "ToDate": "2025-03-24T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T13:15:00.000",
            "ToDate": "2025-03-24T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T13:30:00.000",
            "ToDate": "2025-03-24T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T13:45:00.000",
            "ToDate": "2025-03-24T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T14:00:00.000",
            "ToDate": "2025-03-24T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T14:15:00.000",
            "ToDate": "2025-03-24T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T14:30:00.000",
            "ToDate": "2025-03-24T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T14:45:00.000",
            "ToDate": "2025-03-24T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T18:00:00.000",
            "ToDate": "2025-03-24T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T18:15:00.000",
            "ToDate": "2025-03-24T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T18:30:00.000",
            "ToDate": "2025-03-24T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T18:45:00.000",
            "ToDate": "2025-03-24T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T19:00:00.000",
            "ToDate": "2025-03-24T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T19:15:00.000",
            "ToDate": "2025-03-24T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T19:30:00.000",
            "ToDate": "2025-03-24T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T19:45:00.000",
            "ToDate": "2025-03-24T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T20:00:00.000",
            "ToDate": "2025-03-24T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T20:15:00.000",
            "ToDate": "2025-03-24T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T20:30:00.000",
            "ToDate": "2025-03-24T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T20:45:00.000",
            "ToDate": "2025-03-24T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T21:00:00.000",
            "ToDate": "2025-03-24T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T21:15:00.000",
            "ToDate": "2025-03-24T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T21:30:00.000",
            "ToDate": "2025-03-24T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T21:45:00.000",
            "ToDate": "2025-03-24T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T22:00:00.000",
            "ToDate": "2025-03-24T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T22:15:00.000",
            "ToDate": "2025-03-24T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T22:30:00.000",
            "ToDate": "2025-03-24T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T22:45:00.000",
            "ToDate": "2025-03-24T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T23:00:00.000",
            "ToDate": "2025-03-24T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-24T23:15:00.000",
            "ToDate": "2025-03-24T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T12:00:00.000",
            "ToDate": "2025-03-25T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T12:15:00.000",
            "ToDate": "2025-03-25T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T12:30:00.000",
            "ToDate": "2025-03-25T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T12:45:00.000",
            "ToDate": "2025-03-25T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T13:00:00.000",
            "ToDate": "2025-03-25T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T13:15:00.000",
            "ToDate": "2025-03-25T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T13:30:00.000",
            "ToDate": "2025-03-25T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T13:45:00.000",
            "ToDate": "2025-03-25T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T14:00:00.000",
            "ToDate": "2025-03-25T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T14:15:00.000",
            "ToDate": "2025-03-25T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T14:30:00.000",
            "ToDate": "2025-03-25T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T14:45:00.000",
            "ToDate": "2025-03-25T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T18:00:00.000",
            "ToDate": "2025-03-25T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T18:15:00.000",
            "ToDate": "2025-03-25T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T18:30:00.000",
            "ToDate": "2025-03-25T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T18:45:00.000",
            "ToDate": "2025-03-25T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T19:00:00.000",
            "ToDate": "2025-03-25T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T19:15:00.000",
            "ToDate": "2025-03-25T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T19:30:00.000",
            "ToDate": "2025-03-25T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T19:45:00.000",
            "ToDate": "2025-03-25T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T20:00:00.000",
            "ToDate": "2025-03-25T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T20:15:00.000",
            "ToDate": "2025-03-25T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T20:30:00.000",
            "ToDate": "2025-03-25T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T20:45:00.000",
            "ToDate": "2025-03-25T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T21:00:00.000",
            "ToDate": "2025-03-25T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T21:15:00.000",
            "ToDate": "2025-03-25T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T21:30:00.000",
            "ToDate": "2025-03-25T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T21:45:00.000",
            "ToDate": "2025-03-25T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T22:00:00.000",
            "ToDate": "2025-03-25T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T22:15:00.000",
            "ToDate": "2025-03-25T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T22:30:00.000",
            "ToDate": "2025-03-25T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T22:45:00.000",
            "ToDate": "2025-03-25T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T23:00:00.000",
            "ToDate": "2025-03-25T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-25T23:15:00.000",
            "ToDate": "2025-03-25T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T12:00:00.000",
            "ToDate": "2025-03-26T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T12:15:00.000",
            "ToDate": "2025-03-26T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T12:30:00.000",
            "ToDate": "2025-03-26T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T12:45:00.000",
            "ToDate": "2025-03-26T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T13:00:00.000",
            "ToDate": "2025-03-26T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T13:15:00.000",
            "ToDate": "2025-03-26T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T13:30:00.000",
            "ToDate": "2025-03-26T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T13:45:00.000",
            "ToDate": "2025-03-26T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T14:00:00.000",
            "ToDate": "2025-03-26T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T14:15:00.000",
            "ToDate": "2025-03-26T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T14:30:00.000",
            "ToDate": "2025-03-26T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T14:45:00.000",
            "ToDate": "2025-03-26T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T18:00:00.000",
            "ToDate": "2025-03-26T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T18:15:00.000",
            "ToDate": "2025-03-26T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T18:30:00.000",
            "ToDate": "2025-03-26T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T18:45:00.000",
            "ToDate": "2025-03-26T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T19:00:00.000",
            "ToDate": "2025-03-26T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T19:15:00.000",
            "ToDate": "2025-03-26T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T19:30:00.000",
            "ToDate": "2025-03-26T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T19:45:00.000",
            "ToDate": "2025-03-26T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T20:00:00.000",
            "ToDate": "2025-03-26T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T20:15:00.000",
            "ToDate": "2025-03-26T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T20:30:00.000",
            "ToDate": "2025-03-26T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T20:45:00.000",
            "ToDate": "2025-03-26T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T21:00:00.000",
            "ToDate": "2025-03-26T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T21:15:00.000",
            "ToDate": "2025-03-26T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T21:30:00.000",
            "ToDate": "2025-03-26T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T21:45:00.000",
            "ToDate": "2025-03-26T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T22:00:00.000",
            "ToDate": "2025-03-26T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T22:15:00.000",
            "ToDate": "2025-03-26T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T22:30:00.000",
            "ToDate": "2025-03-26T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T22:45:00.000",
            "ToDate": "2025-03-26T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T23:00:00.000",
            "ToDate": "2025-03-26T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-26T23:15:00.000",
            "ToDate": "2025-03-26T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T12:00:00.000",
            "ToDate": "2025-03-27T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T12:15:00.000",
            "ToDate": "2025-03-27T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T12:30:00.000",
            "ToDate": "2025-03-27T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T12:45:00.000",
            "ToDate": "2025-03-27T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T13:00:00.000",
            "ToDate": "2025-03-27T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T13:15:00.000",
            "ToDate": "2025-03-27T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T13:30:00.000",
            "ToDate": "2025-03-27T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T13:45:00.000",
            "ToDate": "2025-03-27T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T14:00:00.000",
            "ToDate": "2025-03-27T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T14:15:00.000",
            "ToDate": "2025-03-27T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T14:30:00.000",
            "ToDate": "2025-03-27T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T14:45:00.000",
            "ToDate": "2025-03-27T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T18:00:00.000",
            "ToDate": "2025-03-27T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T18:15:00.000",
            "ToDate": "2025-03-27T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T18:30:00.000",
            "ToDate": "2025-03-27T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T18:45:00.000",
            "ToDate": "2025-03-27T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T19:00:00.000",
            "ToDate": "2025-03-27T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T19:15:00.000",
            "ToDate": "2025-03-27T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T19:30:00.000",
            "ToDate": "2025-03-27T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T19:45:00.000",
            "ToDate": "2025-03-27T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T20:00:00.000",
            "ToDate": "2025-03-27T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T20:15:00.000",
            "ToDate": "2025-03-27T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T20:30:00.000",
            "ToDate": "2025-03-27T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T20:45:00.000",
            "ToDate": "2025-03-27T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T21:00:00.000",
            "ToDate": "2025-03-27T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T21:15:00.000",
            "ToDate": "2025-03-27T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T21:30:00.000",
            "ToDate": "2025-03-27T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T21:45:00.000",
            "ToDate": "2025-03-27T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T22:00:00.000",
            "ToDate": "2025-03-27T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T22:15:00.000",
            "ToDate": "2025-03-27T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T22:30:00.000",
            "ToDate": "2025-03-27T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T22:45:00.000",
            "ToDate": "2025-03-27T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T23:00:00.000",
            "ToDate": "2025-03-27T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-27T23:15:00.000",
            "ToDate": "2025-03-27T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T12:00:00.000",
            "ToDate": "2025-03-28T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T12:15:00.000",
            "ToDate": "2025-03-28T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T12:30:00.000",
            "ToDate": "2025-03-28T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T12:45:00.000",
            "ToDate": "2025-03-28T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T13:00:00.000",
            "ToDate": "2025-03-28T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T13:15:00.000",
            "ToDate": "2025-03-28T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T13:30:00.000",
            "ToDate": "2025-03-28T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T13:45:00.000",
            "ToDate": "2025-03-28T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T14:00:00.000",
            "ToDate": "2025-03-28T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T14:15:00.000",
            "ToDate": "2025-03-28T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T14:30:00.000",
            "ToDate": "2025-03-28T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T14:45:00.000",
            "ToDate": "2025-03-28T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T18:00:00.000",
            "ToDate": "2025-03-28T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T18:15:00.000",
            "ToDate": "2025-03-28T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T18:30:00.000",
            "ToDate": "2025-03-28T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T18:45:00.000",
            "ToDate": "2025-03-28T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T19:00:00.000",
            "ToDate": "2025-03-28T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T19:15:00.000",
            "ToDate": "2025-03-28T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T19:30:00.000",
            "ToDate": "2025-03-28T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T19:45:00.000",
            "ToDate": "2025-03-28T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T20:00:00.000",
            "ToDate": "2025-03-28T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T20:15:00.000",
            "ToDate": "2025-03-28T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T20:30:00.000",
            "ToDate": "2025-03-28T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T20:45:00.000",
            "ToDate": "2025-03-28T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T21:00:00.000",
            "ToDate": "2025-03-28T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T21:15:00.000",
            "ToDate": "2025-03-28T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T21:30:00.000",
            "ToDate": "2025-03-28T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T21:45:00.000",
            "ToDate": "2025-03-28T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T22:00:00.000",
            "ToDate": "2025-03-28T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T22:15:00.000",
            "ToDate": "2025-03-28T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T22:30:00.000",
            "ToDate": "2025-03-28T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T22:45:00.000",
            "ToDate": "2025-03-28T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T23:00:00.000",
            "ToDate": "2025-03-28T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-28T23:15:00.000",
            "ToDate": "2025-03-28T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T12:00:00.000",
            "ToDate": "2025-03-29T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T12:15:00.000",
            "ToDate": "2025-03-29T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T12:30:00.000",
            "ToDate": "2025-03-29T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T12:45:00.000",
            "ToDate": "2025-03-29T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T13:00:00.000",
            "ToDate": "2025-03-29T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T13:15:00.000",
            "ToDate": "2025-03-29T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T13:30:00.000",
            "ToDate": "2025-03-29T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T13:45:00.000",
            "ToDate": "2025-03-29T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T14:00:00.000",
            "ToDate": "2025-03-29T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T14:15:00.000",
            "ToDate": "2025-03-29T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T14:30:00.000",
            "ToDate": "2025-03-29T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T14:45:00.000",
            "ToDate": "2025-03-29T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T18:00:00.000",
            "ToDate": "2025-03-29T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T18:15:00.000",
            "ToDate": "2025-03-29T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T18:30:00.000",
            "ToDate": "2025-03-29T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T18:45:00.000",
            "ToDate": "2025-03-29T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T19:00:00.000",
            "ToDate": "2025-03-29T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T19:15:00.000",
            "ToDate": "2025-03-29T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T19:30:00.000",
            "ToDate": "2025-03-29T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T19:45:00.000",
            "ToDate": "2025-03-29T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T20:00:00.000",
            "ToDate": "2025-03-29T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T20:15:00.000",
            "ToDate": "2025-03-29T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T20:30:00.000",
            "ToDate": "2025-03-29T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T20:45:00.000",
            "ToDate": "2025-03-29T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T21:00:00.000",
            "ToDate": "2025-03-29T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T21:15:00.000",
            "ToDate": "2025-03-29T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T21:30:00.000",
            "ToDate": "2025-03-29T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T21:45:00.000",
            "ToDate": "2025-03-29T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T22:00:00.000",
            "ToDate": "2025-03-29T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T22:15:00.000",
            "ToDate": "2025-03-29T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T22:30:00.000",
            "ToDate": "2025-03-29T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T22:45:00.000",
            "ToDate": "2025-03-29T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T23:00:00.000",
            "ToDate": "2025-03-29T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-29T23:15:00.000",
            "ToDate": "2025-03-29T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T12:00:00.000",
            "ToDate": "2025-03-30T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T12:15:00.000",
            "ToDate": "2025-03-30T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T12:30:00.000",
            "ToDate": "2025-03-30T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T12:45:00.000",
            "ToDate": "2025-03-30T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T13:00:00.000",
            "ToDate": "2025-03-30T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T13:15:00.000",
            "ToDate": "2025-03-30T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T13:30:00.000",
            "ToDate": "2025-03-30T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T13:45:00.000",
            "ToDate": "2025-03-30T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T14:00:00.000",
            "ToDate": "2025-03-30T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T14:15:00.000",
            "ToDate": "2025-03-30T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T14:30:00.000",
            "ToDate": "2025-03-30T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T14:45:00.000",
            "ToDate": "2025-03-30T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T18:00:00.000",
            "ToDate": "2025-03-30T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T18:15:00.000",
            "ToDate": "2025-03-30T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T18:30:00.000",
            "ToDate": "2025-03-30T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T18:45:00.000",
            "ToDate": "2025-03-30T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T19:00:00.000",
            "ToDate": "2025-03-30T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T19:15:00.000",
            "ToDate": "2025-03-30T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T19:30:00.000",
            "ToDate": "2025-03-30T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T19:45:00.000",
            "ToDate": "2025-03-30T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T20:00:00.000",
            "ToDate": "2025-03-30T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T20:15:00.000",
            "ToDate": "2025-03-30T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T20:30:00.000",
            "ToDate": "2025-03-30T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T20:45:00.000",
            "ToDate": "2025-03-30T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T21:00:00.000",
            "ToDate": "2025-03-30T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T21:15:00.000",
            "ToDate": "2025-03-30T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T21:30:00.000",
            "ToDate": "2025-03-30T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T21:45:00.000",
            "ToDate": "2025-03-30T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T22:00:00.000",
            "ToDate": "2025-03-30T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T22:15:00.000",
            "ToDate": "2025-03-30T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T22:30:00.000",
            "ToDate": "2025-03-30T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T22:45:00.000",
            "ToDate": "2025-03-30T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T23:00:00.000",
            "ToDate": "2025-03-30T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-30T23:15:00.000",
            "ToDate": "2025-03-30T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T12:00:00.000",
            "ToDate": "2025-03-31T12:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T12:15:00.000",
            "ToDate": "2025-03-31T12:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T12:30:00.000",
            "ToDate": "2025-03-31T12:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T12:45:00.000",
            "ToDate": "2025-03-31T13:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T13:00:00.000",
            "ToDate": "2025-03-31T13:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T13:15:00.000",
            "ToDate": "2025-03-31T13:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T13:30:00.000",
            "ToDate": "2025-03-31T13:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T13:45:00.000",
            "ToDate": "2025-03-31T14:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T14:00:00.000",
            "ToDate": "2025-03-31T14:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T14:15:00.000",
            "ToDate": "2025-03-31T14:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T14:30:00.000",
            "ToDate": "2025-03-31T14:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T14:45:00.000",
            "ToDate": "2025-03-31T15:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T18:00:00.000",
            "ToDate": "2025-03-31T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T18:15:00.000",
            "ToDate": "2025-03-31T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T18:30:00.000",
            "ToDate": "2025-03-31T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T18:45:00.000",
            "ToDate": "2025-03-31T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T19:00:00.000",
            "ToDate": "2025-03-31T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T19:15:00.000",
            "ToDate": "2025-03-31T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T19:30:00.000",
            "ToDate": "2025-03-31T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T19:45:00.000",
            "ToDate": "2025-03-31T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T20:00:00.000",
            "ToDate": "2025-03-31T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T20:15:00.000",
            "ToDate": "2025-03-31T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T20:30:00.000",
            "ToDate": "2025-03-31T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T20:45:00.000",
            "ToDate": "2025-03-31T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T21:00:00.000",
            "ToDate": "2025-03-31T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T21:15:00.000",
            "ToDate": "2025-03-31T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T21:30:00.000",
            "ToDate": "2025-03-31T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T21:45:00.000",
            "ToDate": "2025-03-31T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T22:00:00.000",
            "ToDate": "2025-03-31T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T22:15:00.000",
            "ToDate": "2025-03-31T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T22:30:00.000",
            "ToDate": "2025-03-31T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T22:45:00.000",
            "ToDate": "2025-03-31T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T23:00:00.000",
            "ToDate": "2025-03-31T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-03-31T23:15:00.000",
            "ToDate": "2025-03-31T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T18:00:00.000",
            "ToDate": "2025-04-01T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T18:15:00.000",
            "ToDate": "2025-04-01T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T18:30:00.000",
            "ToDate": "2025-04-01T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T18:45:00.000",
            "ToDate": "2025-04-01T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T19:00:00.000",
            "ToDate": "2025-04-01T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T19:15:00.000",
            "ToDate": "2025-04-01T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T19:30:00.000",
            "ToDate": "2025-04-01T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T19:45:00.000",
            "ToDate": "2025-04-01T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T20:00:00.000",
            "ToDate": "2025-04-01T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T20:15:00.000",
            "ToDate": "2025-04-01T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T20:30:00.000",
            "ToDate": "2025-04-01T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T20:45:00.000",
            "ToDate": "2025-04-01T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T21:00:00.000",
            "ToDate": "2025-04-01T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T21:15:00.000",
            "ToDate": "2025-04-01T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T21:30:00.000",
            "ToDate": "2025-04-01T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T21:45:00.000",
            "ToDate": "2025-04-01T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T22:00:00.000",
            "ToDate": "2025-04-01T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T22:15:00.000",
            "ToDate": "2025-04-01T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T22:30:00.000",
            "ToDate": "2025-04-01T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T22:45:00.000",
            "ToDate": "2025-04-01T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T23:00:00.000",
            "ToDate": "2025-04-01T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-01T23:15:00.000",
            "ToDate": "2025-04-01T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T18:00:00.000",
            "ToDate": "2025-04-02T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T18:15:00.000",
            "ToDate": "2025-04-02T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T18:30:00.000",
            "ToDate": "2025-04-02T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T18:45:00.000",
            "ToDate": "2025-04-02T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T19:00:00.000",
            "ToDate": "2025-04-02T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T19:15:00.000",
            "ToDate": "2025-04-02T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T19:30:00.000",
            "ToDate": "2025-04-02T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T19:45:00.000",
            "ToDate": "2025-04-02T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T20:00:00.000",
            "ToDate": "2025-04-02T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T20:15:00.000",
            "ToDate": "2025-04-02T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T20:30:00.000",
            "ToDate": "2025-04-02T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T20:45:00.000",
            "ToDate": "2025-04-02T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T21:00:00.000",
            "ToDate": "2025-04-02T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T21:15:00.000",
            "ToDate": "2025-04-02T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T21:30:00.000",
            "ToDate": "2025-04-02T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T21:45:00.000",
            "ToDate": "2025-04-02T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T22:00:00.000",
            "ToDate": "2025-04-02T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T22:15:00.000",
            "ToDate": "2025-04-02T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T22:30:00.000",
            "ToDate": "2025-04-02T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T22:45:00.000",
            "ToDate": "2025-04-02T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T23:00:00.000",
            "ToDate": "2025-04-02T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-02T23:15:00.000",
            "ToDate": "2025-04-02T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T18:00:00.000",
            "ToDate": "2025-04-03T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T18:15:00.000",
            "ToDate": "2025-04-03T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T18:30:00.000",
            "ToDate": "2025-04-03T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T18:45:00.000",
            "ToDate": "2025-04-03T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T19:00:00.000",
            "ToDate": "2025-04-03T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T19:15:00.000",
            "ToDate": "2025-04-03T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T19:30:00.000",
            "ToDate": "2025-04-03T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T19:45:00.000",
            "ToDate": "2025-04-03T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T20:00:00.000",
            "ToDate": "2025-04-03T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T20:15:00.000",
            "ToDate": "2025-04-03T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T20:30:00.000",
            "ToDate": "2025-04-03T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T20:45:00.000",
            "ToDate": "2025-04-03T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T21:00:00.000",
            "ToDate": "2025-04-03T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T21:15:00.000",
            "ToDate": "2025-04-03T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T21:30:00.000",
            "ToDate": "2025-04-03T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T21:45:00.000",
            "ToDate": "2025-04-03T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T22:00:00.000",
            "ToDate": "2025-04-03T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T22:15:00.000",
            "ToDate": "2025-04-03T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T22:30:00.000",
            "ToDate": "2025-04-03T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T22:45:00.000",
            "ToDate": "2025-04-03T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T23:00:00.000",
            "ToDate": "2025-04-03T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-03T23:15:00.000",
            "ToDate": "2025-04-03T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T18:00:00.000",
            "ToDate": "2025-04-04T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T18:15:00.000",
            "ToDate": "2025-04-04T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T18:30:00.000",
            "ToDate": "2025-04-04T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T18:45:00.000",
            "ToDate": "2025-04-04T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T19:00:00.000",
            "ToDate": "2025-04-04T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T19:15:00.000",
            "ToDate": "2025-04-04T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T19:30:00.000",
            "ToDate": "2025-04-04T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T19:45:00.000",
            "ToDate": "2025-04-04T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T20:00:00.000",
            "ToDate": "2025-04-04T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T20:15:00.000",
            "ToDate": "2025-04-04T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T20:30:00.000",
            "ToDate": "2025-04-04T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T20:45:00.000",
            "ToDate": "2025-04-04T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T21:00:00.000",
            "ToDate": "2025-04-04T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T21:15:00.000",
            "ToDate": "2025-04-04T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T21:30:00.000",
            "ToDate": "2025-04-04T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T21:45:00.000",
            "ToDate": "2025-04-04T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T22:00:00.000",
            "ToDate": "2025-04-04T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T22:15:00.000",
            "ToDate": "2025-04-04T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T22:30:00.000",
            "ToDate": "2025-04-04T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T22:45:00.000",
            "ToDate": "2025-04-04T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T23:00:00.000",
            "ToDate": "2025-04-04T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-04T23:15:00.000",
            "ToDate": "2025-04-04T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T18:00:00.000",
            "ToDate": "2025-04-05T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T18:15:00.000",
            "ToDate": "2025-04-05T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T18:30:00.000",
            "ToDate": "2025-04-05T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T18:45:00.000",
            "ToDate": "2025-04-05T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T19:00:00.000",
            "ToDate": "2025-04-05T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T19:15:00.000",
            "ToDate": "2025-04-05T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T19:30:00.000",
            "ToDate": "2025-04-05T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T19:45:00.000",
            "ToDate": "2025-04-05T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T20:00:00.000",
            "ToDate": "2025-04-05T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T20:15:00.000",
            "ToDate": "2025-04-05T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T20:30:00.000",
            "ToDate": "2025-04-05T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T20:45:00.000",
            "ToDate": "2025-04-05T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T21:00:00.000",
            "ToDate": "2025-04-05T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T21:15:00.000",
            "ToDate": "2025-04-05T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T21:30:00.000",
            "ToDate": "2025-04-05T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T21:45:00.000",
            "ToDate": "2025-04-05T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T22:00:00.000",
            "ToDate": "2025-04-05T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T22:15:00.000",
            "ToDate": "2025-04-05T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T22:30:00.000",
            "ToDate": "2025-04-05T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T22:45:00.000",
            "ToDate": "2025-04-05T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T23:00:00.000",
            "ToDate": "2025-04-05T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-05T23:15:00.000",
            "ToDate": "2025-04-05T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T18:00:00.000",
            "ToDate": "2025-04-06T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T18:15:00.000",
            "ToDate": "2025-04-06T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T18:30:00.000",
            "ToDate": "2025-04-06T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T18:45:00.000",
            "ToDate": "2025-04-06T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T19:00:00.000",
            "ToDate": "2025-04-06T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T19:15:00.000",
            "ToDate": "2025-04-06T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T19:30:00.000",
            "ToDate": "2025-04-06T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T19:45:00.000",
            "ToDate": "2025-04-06T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T20:00:00.000",
            "ToDate": "2025-04-06T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T20:15:00.000",
            "ToDate": "2025-04-06T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T20:30:00.000",
            "ToDate": "2025-04-06T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T20:45:00.000",
            "ToDate": "2025-04-06T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T21:00:00.000",
            "ToDate": "2025-04-06T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T21:15:00.000",
            "ToDate": "2025-04-06T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T21:30:00.000",
            "ToDate": "2025-04-06T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T21:45:00.000",
            "ToDate": "2025-04-06T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T22:00:00.000",
            "ToDate": "2025-04-06T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T22:15:00.000",
            "ToDate": "2025-04-06T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T22:30:00.000",
            "ToDate": "2025-04-06T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T22:45:00.000",
            "ToDate": "2025-04-06T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T23:00:00.000",
            "ToDate": "2025-04-06T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-06T23:15:00.000",
            "ToDate": "2025-04-06T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T18:00:00.000",
            "ToDate": "2025-04-07T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T18:15:00.000",
            "ToDate": "2025-04-07T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T18:30:00.000",
            "ToDate": "2025-04-07T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T18:45:00.000",
            "ToDate": "2025-04-07T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T19:00:00.000",
            "ToDate": "2025-04-07T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T19:15:00.000",
            "ToDate": "2025-04-07T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T19:30:00.000",
            "ToDate": "2025-04-07T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T19:45:00.000",
            "ToDate": "2025-04-07T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T20:00:00.000",
            "ToDate": "2025-04-07T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T20:15:00.000",
            "ToDate": "2025-04-07T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T20:30:00.000",
            "ToDate": "2025-04-07T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T20:45:00.000",
            "ToDate": "2025-04-07T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T21:00:00.000",
            "ToDate": "2025-04-07T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T21:15:00.000",
            "ToDate": "2025-04-07T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T21:30:00.000",
            "ToDate": "2025-04-07T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T21:45:00.000",
            "ToDate": "2025-04-07T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T22:00:00.000",
            "ToDate": "2025-04-07T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T22:15:00.000",
            "ToDate": "2025-04-07T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T22:30:00.000",
            "ToDate": "2025-04-07T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T22:45:00.000",
            "ToDate": "2025-04-07T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T23:00:00.000",
            "ToDate": "2025-04-07T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-07T23:15:00.000",
            "ToDate": "2025-04-07T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T18:00:00.000",
            "ToDate": "2025-04-08T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T18:15:00.000",
            "ToDate": "2025-04-08T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T18:30:00.000",
            "ToDate": "2025-04-08T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T18:45:00.000",
            "ToDate": "2025-04-08T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T19:00:00.000",
            "ToDate": "2025-04-08T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T19:15:00.000",
            "ToDate": "2025-04-08T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T19:30:00.000",
            "ToDate": "2025-04-08T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T19:45:00.000",
            "ToDate": "2025-04-08T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T20:00:00.000",
            "ToDate": "2025-04-08T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T20:15:00.000",
            "ToDate": "2025-04-08T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T20:30:00.000",
            "ToDate": "2025-04-08T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T20:45:00.000",
            "ToDate": "2025-04-08T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T21:00:00.000",
            "ToDate": "2025-04-08T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T21:15:00.000",
            "ToDate": "2025-04-08T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T21:30:00.000",
            "ToDate": "2025-04-08T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T21:45:00.000",
            "ToDate": "2025-04-08T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T22:00:00.000",
            "ToDate": "2025-04-08T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T22:15:00.000",
            "ToDate": "2025-04-08T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T22:30:00.000",
            "ToDate": "2025-04-08T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T22:45:00.000",
            "ToDate": "2025-04-08T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T23:00:00.000",
            "ToDate": "2025-04-08T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-08T23:15:00.000",
            "ToDate": "2025-04-08T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T18:00:00.000",
            "ToDate": "2025-04-09T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T18:15:00.000",
            "ToDate": "2025-04-09T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T18:30:00.000",
            "ToDate": "2025-04-09T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T18:45:00.000",
            "ToDate": "2025-04-09T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T19:00:00.000",
            "ToDate": "2025-04-09T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T19:15:00.000",
            "ToDate": "2025-04-09T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T19:30:00.000",
            "ToDate": "2025-04-09T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T19:45:00.000",
            "ToDate": "2025-04-09T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T20:00:00.000",
            "ToDate": "2025-04-09T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T20:15:00.000",
            "ToDate": "2025-04-09T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T20:30:00.000",
            "ToDate": "2025-04-09T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T20:45:00.000",
            "ToDate": "2025-04-09T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T21:00:00.000",
            "ToDate": "2025-04-09T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T21:15:00.000",
            "ToDate": "2025-04-09T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T21:30:00.000",
            "ToDate": "2025-04-09T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T21:45:00.000",
            "ToDate": "2025-04-09T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T22:00:00.000",
            "ToDate": "2025-04-09T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T22:15:00.000",
            "ToDate": "2025-04-09T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T22:30:00.000",
            "ToDate": "2025-04-09T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T22:45:00.000",
            "ToDate": "2025-04-09T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T23:00:00.000",
            "ToDate": "2025-04-09T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-09T23:15:00.000",
            "ToDate": "2025-04-09T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T18:00:00.000",
            "ToDate": "2025-04-10T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T18:15:00.000",
            "ToDate": "2025-04-10T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T18:30:00.000",
            "ToDate": "2025-04-10T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T18:45:00.000",
            "ToDate": "2025-04-10T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T19:00:00.000",
            "ToDate": "2025-04-10T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T19:15:00.000",
            "ToDate": "2025-04-10T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T19:30:00.000",
            "ToDate": "2025-04-10T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T19:45:00.000",
            "ToDate": "2025-04-10T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T20:00:00.000",
            "ToDate": "2025-04-10T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T20:15:00.000",
            "ToDate": "2025-04-10T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T20:30:00.000",
            "ToDate": "2025-04-10T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T20:45:00.000",
            "ToDate": "2025-04-10T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T21:00:00.000",
            "ToDate": "2025-04-10T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T21:15:00.000",
            "ToDate": "2025-04-10T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T21:30:00.000",
            "ToDate": "2025-04-10T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T21:45:00.000",
            "ToDate": "2025-04-10T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T22:00:00.000",
            "ToDate": "2025-04-10T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T22:15:00.000",
            "ToDate": "2025-04-10T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T22:30:00.000",
            "ToDate": "2025-04-10T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T22:45:00.000",
            "ToDate": "2025-04-10T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T23:00:00.000",
            "ToDate": "2025-04-10T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-10T23:15:00.000",
            "ToDate": "2025-04-10T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T18:00:00.000",
            "ToDate": "2025-04-11T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T18:15:00.000",
            "ToDate": "2025-04-11T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T18:30:00.000",
            "ToDate": "2025-04-11T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T18:45:00.000",
            "ToDate": "2025-04-11T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T19:00:00.000",
            "ToDate": "2025-04-11T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T19:15:00.000",
            "ToDate": "2025-04-11T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T19:30:00.000",
            "ToDate": "2025-04-11T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T19:45:00.000",
            "ToDate": "2025-04-11T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T20:00:00.000",
            "ToDate": "2025-04-11T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T20:15:00.000",
            "ToDate": "2025-04-11T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T20:30:00.000",
            "ToDate": "2025-04-11T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T20:45:00.000",
            "ToDate": "2025-04-11T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T21:00:00.000",
            "ToDate": "2025-04-11T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T21:15:00.000",
            "ToDate": "2025-04-11T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T21:30:00.000",
            "ToDate": "2025-04-11T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T21:45:00.000",
            "ToDate": "2025-04-11T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T22:00:00.000",
            "ToDate": "2025-04-11T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T22:15:00.000",
            "ToDate": "2025-04-11T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T22:30:00.000",
            "ToDate": "2025-04-11T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T22:45:00.000",
            "ToDate": "2025-04-11T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T23:00:00.000",
            "ToDate": "2025-04-11T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-11T23:15:00.000",
            "ToDate": "2025-04-11T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T18:00:00.000",
            "ToDate": "2025-04-12T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T18:15:00.000",
            "ToDate": "2025-04-12T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T18:30:00.000",
            "ToDate": "2025-04-12T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T18:45:00.000",
            "ToDate": "2025-04-12T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T19:00:00.000",
            "ToDate": "2025-04-12T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T19:15:00.000",
            "ToDate": "2025-04-12T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T19:30:00.000",
            "ToDate": "2025-04-12T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T19:45:00.000",
            "ToDate": "2025-04-12T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T20:00:00.000",
            "ToDate": "2025-04-12T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T20:15:00.000",
            "ToDate": "2025-04-12T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T20:30:00.000",
            "ToDate": "2025-04-12T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T20:45:00.000",
            "ToDate": "2025-04-12T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T21:00:00.000",
            "ToDate": "2025-04-12T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T21:15:00.000",
            "ToDate": "2025-04-12T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T21:30:00.000",
            "ToDate": "2025-04-12T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T21:45:00.000",
            "ToDate": "2025-04-12T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T22:00:00.000",
            "ToDate": "2025-04-12T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T22:15:00.000",
            "ToDate": "2025-04-12T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T22:30:00.000",
            "ToDate": "2025-04-12T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T22:45:00.000",
            "ToDate": "2025-04-12T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T23:00:00.000",
            "ToDate": "2025-04-12T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-12T23:15:00.000",
            "ToDate": "2025-04-12T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T18:00:00.000",
            "ToDate": "2025-04-13T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T18:15:00.000",
            "ToDate": "2025-04-13T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T18:30:00.000",
            "ToDate": "2025-04-13T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T18:45:00.000",
            "ToDate": "2025-04-13T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T19:00:00.000",
            "ToDate": "2025-04-13T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T19:15:00.000",
            "ToDate": "2025-04-13T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T19:30:00.000",
            "ToDate": "2025-04-13T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T19:45:00.000",
            "ToDate": "2025-04-13T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T20:00:00.000",
            "ToDate": "2025-04-13T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T20:15:00.000",
            "ToDate": "2025-04-13T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T20:30:00.000",
            "ToDate": "2025-04-13T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T20:45:00.000",
            "ToDate": "2025-04-13T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T21:00:00.000",
            "ToDate": "2025-04-13T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T21:15:00.000",
            "ToDate": "2025-04-13T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T21:30:00.000",
            "ToDate": "2025-04-13T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T21:45:00.000",
            "ToDate": "2025-04-13T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T22:00:00.000",
            "ToDate": "2025-04-13T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T22:15:00.000",
            "ToDate": "2025-04-13T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T22:30:00.000",
            "ToDate": "2025-04-13T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T22:45:00.000",
            "ToDate": "2025-04-13T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T23:00:00.000",
            "ToDate": "2025-04-13T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-13T23:15:00.000",
            "ToDate": "2025-04-13T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T18:00:00.000",
            "ToDate": "2025-04-14T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T18:15:00.000",
            "ToDate": "2025-04-14T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T18:30:00.000",
            "ToDate": "2025-04-14T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T18:45:00.000",
            "ToDate": "2025-04-14T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T19:00:00.000",
            "ToDate": "2025-04-14T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T19:15:00.000",
            "ToDate": "2025-04-14T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T19:30:00.000",
            "ToDate": "2025-04-14T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T19:45:00.000",
            "ToDate": "2025-04-14T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T20:00:00.000",
            "ToDate": "2025-04-14T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T20:15:00.000",
            "ToDate": "2025-04-14T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T20:30:00.000",
            "ToDate": "2025-04-14T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T20:45:00.000",
            "ToDate": "2025-04-14T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T21:00:00.000",
            "ToDate": "2025-04-14T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T21:15:00.000",
            "ToDate": "2025-04-14T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T21:30:00.000",
            "ToDate": "2025-04-14T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T21:45:00.000",
            "ToDate": "2025-04-14T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T22:00:00.000",
            "ToDate": "2025-04-14T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T22:15:00.000",
            "ToDate": "2025-04-14T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T22:30:00.000",
            "ToDate": "2025-04-14T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T22:45:00.000",
            "ToDate": "2025-04-14T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T23:00:00.000",
            "ToDate": "2025-04-14T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-14T23:15:00.000",
            "ToDate": "2025-04-14T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T18:00:00.000",
            "ToDate": "2025-04-15T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T18:15:00.000",
            "ToDate": "2025-04-15T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T18:30:00.000",
            "ToDate": "2025-04-15T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T18:45:00.000",
            "ToDate": "2025-04-15T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T19:00:00.000",
            "ToDate": "2025-04-15T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T19:15:00.000",
            "ToDate": "2025-04-15T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T19:30:00.000",
            "ToDate": "2025-04-15T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T19:45:00.000",
            "ToDate": "2025-04-15T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T20:00:00.000",
            "ToDate": "2025-04-15T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T20:15:00.000",
            "ToDate": "2025-04-15T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T20:30:00.000",
            "ToDate": "2025-04-15T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T20:45:00.000",
            "ToDate": "2025-04-15T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T21:00:00.000",
            "ToDate": "2025-04-15T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T21:15:00.000",
            "ToDate": "2025-04-15T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T21:30:00.000",
            "ToDate": "2025-04-15T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T21:45:00.000",
            "ToDate": "2025-04-15T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T22:00:00.000",
            "ToDate": "2025-04-15T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T22:15:00.000",
            "ToDate": "2025-04-15T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T22:30:00.000",
            "ToDate": "2025-04-15T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T22:45:00.000",
            "ToDate": "2025-04-15T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T23:00:00.000",
            "ToDate": "2025-04-15T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-15T23:15:00.000",
            "ToDate": "2025-04-15T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T18:00:00.000",
            "ToDate": "2025-04-16T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T18:15:00.000",
            "ToDate": "2025-04-16T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T18:30:00.000",
            "ToDate": "2025-04-16T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T18:45:00.000",
            "ToDate": "2025-04-16T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T19:00:00.000",
            "ToDate": "2025-04-16T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T19:15:00.000",
            "ToDate": "2025-04-16T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T19:30:00.000",
            "ToDate": "2025-04-16T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T19:45:00.000",
            "ToDate": "2025-04-16T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T20:00:00.000",
            "ToDate": "2025-04-16T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T20:15:00.000",
            "ToDate": "2025-04-16T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T20:30:00.000",
            "ToDate": "2025-04-16T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T20:45:00.000",
            "ToDate": "2025-04-16T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T21:00:00.000",
            "ToDate": "2025-04-16T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T21:15:00.000",
            "ToDate": "2025-04-16T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T21:30:00.000",
            "ToDate": "2025-04-16T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T21:45:00.000",
            "ToDate": "2025-04-16T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T22:00:00.000",
            "ToDate": "2025-04-16T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T22:15:00.000",
            "ToDate": "2025-04-16T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T22:30:00.000",
            "ToDate": "2025-04-16T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T22:45:00.000",
            "ToDate": "2025-04-16T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T23:00:00.000",
            "ToDate": "2025-04-16T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-16T23:15:00.000",
            "ToDate": "2025-04-16T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T18:00:00.000",
            "ToDate": "2025-04-17T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T18:15:00.000",
            "ToDate": "2025-04-17T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T18:30:00.000",
            "ToDate": "2025-04-17T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T18:45:00.000",
            "ToDate": "2025-04-17T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T19:00:00.000",
            "ToDate": "2025-04-17T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T19:15:00.000",
            "ToDate": "2025-04-17T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T19:30:00.000",
            "ToDate": "2025-04-17T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T19:45:00.000",
            "ToDate": "2025-04-17T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T20:00:00.000",
            "ToDate": "2025-04-17T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T20:15:00.000",
            "ToDate": "2025-04-17T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T20:30:00.000",
            "ToDate": "2025-04-17T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T20:45:00.000",
            "ToDate": "2025-04-17T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T21:00:00.000",
            "ToDate": "2025-04-17T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T21:15:00.000",
            "ToDate": "2025-04-17T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T21:30:00.000",
            "ToDate": "2025-04-17T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T21:45:00.000",
            "ToDate": "2025-04-17T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T22:00:00.000",
            "ToDate": "2025-04-17T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T22:15:00.000",
            "ToDate": "2025-04-17T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T22:30:00.000",
            "ToDate": "2025-04-17T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T22:45:00.000",
            "ToDate": "2025-04-17T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T23:00:00.000",
            "ToDate": "2025-04-17T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-17T23:15:00.000",
            "ToDate": "2025-04-17T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T18:00:00.000",
            "ToDate": "2025-04-18T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T18:15:00.000",
            "ToDate": "2025-04-18T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T18:30:00.000",
            "ToDate": "2025-04-18T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T18:45:00.000",
            "ToDate": "2025-04-18T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T19:00:00.000",
            "ToDate": "2025-04-18T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T19:15:00.000",
            "ToDate": "2025-04-18T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T19:30:00.000",
            "ToDate": "2025-04-18T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T19:45:00.000",
            "ToDate": "2025-04-18T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T20:00:00.000",
            "ToDate": "2025-04-18T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T20:15:00.000",
            "ToDate": "2025-04-18T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T20:30:00.000",
            "ToDate": "2025-04-18T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T20:45:00.000",
            "ToDate": "2025-04-18T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T21:00:00.000",
            "ToDate": "2025-04-18T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T21:15:00.000",
            "ToDate": "2025-04-18T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T21:30:00.000",
            "ToDate": "2025-04-18T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T21:45:00.000",
            "ToDate": "2025-04-18T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T22:00:00.000",
            "ToDate": "2025-04-18T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T22:15:00.000",
            "ToDate": "2025-04-18T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T22:30:00.000",
            "ToDate": "2025-04-18T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T22:45:00.000",
            "ToDate": "2025-04-18T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T23:00:00.000",
            "ToDate": "2025-04-18T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-18T23:15:00.000",
            "ToDate": "2025-04-18T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T18:00:00.000",
            "ToDate": "2025-04-19T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T18:15:00.000",
            "ToDate": "2025-04-19T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T18:30:00.000",
            "ToDate": "2025-04-19T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T18:45:00.000",
            "ToDate": "2025-04-19T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T19:00:00.000",
            "ToDate": "2025-04-19T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T19:15:00.000",
            "ToDate": "2025-04-19T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T19:30:00.000",
            "ToDate": "2025-04-19T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T19:45:00.000",
            "ToDate": "2025-04-19T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T20:00:00.000",
            "ToDate": "2025-04-19T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T20:15:00.000",
            "ToDate": "2025-04-19T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T20:30:00.000",
            "ToDate": "2025-04-19T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T20:45:00.000",
            "ToDate": "2025-04-19T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T21:00:00.000",
            "ToDate": "2025-04-19T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T21:15:00.000",
            "ToDate": "2025-04-19T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T21:30:00.000",
            "ToDate": "2025-04-19T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T21:45:00.000",
            "ToDate": "2025-04-19T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T22:00:00.000",
            "ToDate": "2025-04-19T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T22:15:00.000",
            "ToDate": "2025-04-19T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T22:30:00.000",
            "ToDate": "2025-04-19T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T22:45:00.000",
            "ToDate": "2025-04-19T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T23:00:00.000",
            "ToDate": "2025-04-19T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-19T23:15:00.000",
            "ToDate": "2025-04-19T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T18:00:00.000",
            "ToDate": "2025-04-20T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T18:15:00.000",
            "ToDate": "2025-04-20T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T18:30:00.000",
            "ToDate": "2025-04-20T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T18:45:00.000",
            "ToDate": "2025-04-20T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T19:00:00.000",
            "ToDate": "2025-04-20T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T19:15:00.000",
            "ToDate": "2025-04-20T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T19:30:00.000",
            "ToDate": "2025-04-20T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T19:45:00.000",
            "ToDate": "2025-04-20T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T20:00:00.000",
            "ToDate": "2025-04-20T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T20:15:00.000",
            "ToDate": "2025-04-20T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T20:30:00.000",
            "ToDate": "2025-04-20T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T20:45:00.000",
            "ToDate": "2025-04-20T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T21:00:00.000",
            "ToDate": "2025-04-20T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T21:15:00.000",
            "ToDate": "2025-04-20T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T21:30:00.000",
            "ToDate": "2025-04-20T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T21:45:00.000",
            "ToDate": "2025-04-20T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T22:00:00.000",
            "ToDate": "2025-04-20T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T22:15:00.000",
            "ToDate": "2025-04-20T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T22:30:00.000",
            "ToDate": "2025-04-20T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T22:45:00.000",
            "ToDate": "2025-04-20T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T23:00:00.000",
            "ToDate": "2025-04-20T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-20T23:15:00.000",
            "ToDate": "2025-04-20T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T18:00:00.000",
            "ToDate": "2025-04-21T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T18:15:00.000",
            "ToDate": "2025-04-21T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T18:30:00.000",
            "ToDate": "2025-04-21T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T18:45:00.000",
            "ToDate": "2025-04-21T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T19:00:00.000",
            "ToDate": "2025-04-21T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T19:15:00.000",
            "ToDate": "2025-04-21T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T19:30:00.000",
            "ToDate": "2025-04-21T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T19:45:00.000",
            "ToDate": "2025-04-21T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T20:00:00.000",
            "ToDate": "2025-04-21T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T20:15:00.000",
            "ToDate": "2025-04-21T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T20:30:00.000",
            "ToDate": "2025-04-21T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T20:45:00.000",
            "ToDate": "2025-04-21T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T21:00:00.000",
            "ToDate": "2025-04-21T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T21:15:00.000",
            "ToDate": "2025-04-21T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T21:30:00.000",
            "ToDate": "2025-04-21T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T21:45:00.000",
            "ToDate": "2025-04-21T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T22:00:00.000",
            "ToDate": "2025-04-21T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T22:15:00.000",
            "ToDate": "2025-04-21T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T22:30:00.000",
            "ToDate": "2025-04-21T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T22:45:00.000",
            "ToDate": "2025-04-21T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T23:00:00.000",
            "ToDate": "2025-04-21T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-21T23:15:00.000",
            "ToDate": "2025-04-21T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T18:00:00.000",
            "ToDate": "2025-04-22T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T18:15:00.000",
            "ToDate": "2025-04-22T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T18:30:00.000",
            "ToDate": "2025-04-22T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T18:45:00.000",
            "ToDate": "2025-04-22T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T19:00:00.000",
            "ToDate": "2025-04-22T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T19:15:00.000",
            "ToDate": "2025-04-22T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T19:30:00.000",
            "ToDate": "2025-04-22T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T19:45:00.000",
            "ToDate": "2025-04-22T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T20:00:00.000",
            "ToDate": "2025-04-22T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T20:15:00.000",
            "ToDate": "2025-04-22T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T20:30:00.000",
            "ToDate": "2025-04-22T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T20:45:00.000",
            "ToDate": "2025-04-22T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T21:00:00.000",
            "ToDate": "2025-04-22T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T21:15:00.000",
            "ToDate": "2025-04-22T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T21:30:00.000",
            "ToDate": "2025-04-22T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T21:45:00.000",
            "ToDate": "2025-04-22T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T22:00:00.000",
            "ToDate": "2025-04-22T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T22:15:00.000",
            "ToDate": "2025-04-22T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T22:30:00.000",
            "ToDate": "2025-04-22T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T22:45:00.000",
            "ToDate": "2025-04-22T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T23:00:00.000",
            "ToDate": "2025-04-22T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-22T23:15:00.000",
            "ToDate": "2025-04-22T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T18:00:00.000",
            "ToDate": "2025-04-23T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T18:15:00.000",
            "ToDate": "2025-04-23T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T18:30:00.000",
            "ToDate": "2025-04-23T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T18:45:00.000",
            "ToDate": "2025-04-23T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T19:00:00.000",
            "ToDate": "2025-04-23T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T19:15:00.000",
            "ToDate": "2025-04-23T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T19:30:00.000",
            "ToDate": "2025-04-23T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T19:45:00.000",
            "ToDate": "2025-04-23T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T20:00:00.000",
            "ToDate": "2025-04-23T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T20:15:00.000",
            "ToDate": "2025-04-23T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T20:30:00.000",
            "ToDate": "2025-04-23T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T20:45:00.000",
            "ToDate": "2025-04-23T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T21:00:00.000",
            "ToDate": "2025-04-23T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T21:15:00.000",
            "ToDate": "2025-04-23T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T21:30:00.000",
            "ToDate": "2025-04-23T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T21:45:00.000",
            "ToDate": "2025-04-23T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T22:00:00.000",
            "ToDate": "2025-04-23T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T22:15:00.000",
            "ToDate": "2025-04-23T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T22:30:00.000",
            "ToDate": "2025-04-23T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T22:45:00.000",
            "ToDate": "2025-04-23T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T23:00:00.000",
            "ToDate": "2025-04-23T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-23T23:15:00.000",
            "ToDate": "2025-04-23T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T18:00:00.000",
            "ToDate": "2025-04-24T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T18:15:00.000",
            "ToDate": "2025-04-24T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T18:30:00.000",
            "ToDate": "2025-04-24T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T18:45:00.000",
            "ToDate": "2025-04-24T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T19:00:00.000",
            "ToDate": "2025-04-24T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T19:15:00.000",
            "ToDate": "2025-04-24T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T19:30:00.000",
            "ToDate": "2025-04-24T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T19:45:00.000",
            "ToDate": "2025-04-24T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T20:00:00.000",
            "ToDate": "2025-04-24T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T20:15:00.000",
            "ToDate": "2025-04-24T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T20:30:00.000",
            "ToDate": "2025-04-24T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T20:45:00.000",
            "ToDate": "2025-04-24T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T21:00:00.000",
            "ToDate": "2025-04-24T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T21:15:00.000",
            "ToDate": "2025-04-24T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T21:30:00.000",
            "ToDate": "2025-04-24T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T21:45:00.000",
            "ToDate": "2025-04-24T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T22:00:00.000",
            "ToDate": "2025-04-24T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T22:15:00.000",
            "ToDate": "2025-04-24T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T22:30:00.000",
            "ToDate": "2025-04-24T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T22:45:00.000",
            "ToDate": "2025-04-24T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T23:00:00.000",
            "ToDate": "2025-04-24T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-24T23:15:00.000",
            "ToDate": "2025-04-24T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T18:00:00.000",
            "ToDate": "2025-04-25T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T18:15:00.000",
            "ToDate": "2025-04-25T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T18:30:00.000",
            "ToDate": "2025-04-25T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T18:45:00.000",
            "ToDate": "2025-04-25T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T19:00:00.000",
            "ToDate": "2025-04-25T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T19:15:00.000",
            "ToDate": "2025-04-25T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T19:30:00.000",
            "ToDate": "2025-04-25T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T19:45:00.000",
            "ToDate": "2025-04-25T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T20:00:00.000",
            "ToDate": "2025-04-25T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T20:15:00.000",
            "ToDate": "2025-04-25T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T20:30:00.000",
            "ToDate": "2025-04-25T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T20:45:00.000",
            "ToDate": "2025-04-25T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T21:00:00.000",
            "ToDate": "2025-04-25T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T21:15:00.000",
            "ToDate": "2025-04-25T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T21:30:00.000",
            "ToDate": "2025-04-25T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T21:45:00.000",
            "ToDate": "2025-04-25T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T22:00:00.000",
            "ToDate": "2025-04-25T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T22:15:00.000",
            "ToDate": "2025-04-25T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T22:30:00.000",
            "ToDate": "2025-04-25T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T22:45:00.000",
            "ToDate": "2025-04-25T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T23:00:00.000",
            "ToDate": "2025-04-25T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-25T23:15:00.000",
            "ToDate": "2025-04-25T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T18:00:00.000",
            "ToDate": "2025-04-26T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T18:15:00.000",
            "ToDate": "2025-04-26T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T18:30:00.000",
            "ToDate": "2025-04-26T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T18:45:00.000",
            "ToDate": "2025-04-26T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T19:00:00.000",
            "ToDate": "2025-04-26T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T19:15:00.000",
            "ToDate": "2025-04-26T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T19:30:00.000",
            "ToDate": "2025-04-26T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T19:45:00.000",
            "ToDate": "2025-04-26T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T20:00:00.000",
            "ToDate": "2025-04-26T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T20:15:00.000",
            "ToDate": "2025-04-26T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T20:30:00.000",
            "ToDate": "2025-04-26T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T20:45:00.000",
            "ToDate": "2025-04-26T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T21:00:00.000",
            "ToDate": "2025-04-26T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T21:15:00.000",
            "ToDate": "2025-04-26T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T21:30:00.000",
            "ToDate": "2025-04-26T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T21:45:00.000",
            "ToDate": "2025-04-26T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T22:00:00.000",
            "ToDate": "2025-04-26T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T22:15:00.000",
            "ToDate": "2025-04-26T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T22:30:00.000",
            "ToDate": "2025-04-26T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T22:45:00.000",
            "ToDate": "2025-04-26T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T23:00:00.000",
            "ToDate": "2025-04-26T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-26T23:15:00.000",
            "ToDate": "2025-04-26T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T18:00:00.000",
            "ToDate": "2025-04-27T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T18:15:00.000",
            "ToDate": "2025-04-27T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T18:30:00.000",
            "ToDate": "2025-04-27T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T18:45:00.000",
            "ToDate": "2025-04-27T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T19:00:00.000",
            "ToDate": "2025-04-27T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T19:15:00.000",
            "ToDate": "2025-04-27T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T19:30:00.000",
            "ToDate": "2025-04-27T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T19:45:00.000",
            "ToDate": "2025-04-27T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T20:00:00.000",
            "ToDate": "2025-04-27T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T20:15:00.000",
            "ToDate": "2025-04-27T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T20:30:00.000",
            "ToDate": "2025-04-27T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T20:45:00.000",
            "ToDate": "2025-04-27T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T21:00:00.000",
            "ToDate": "2025-04-27T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T21:15:00.000",
            "ToDate": "2025-04-27T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T21:30:00.000",
            "ToDate": "2025-04-27T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T21:45:00.000",
            "ToDate": "2025-04-27T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T22:00:00.000",
            "ToDate": "2025-04-27T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T22:15:00.000",
            "ToDate": "2025-04-27T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T22:30:00.000",
            "ToDate": "2025-04-27T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T22:45:00.000",
            "ToDate": "2025-04-27T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T23:00:00.000",
            "ToDate": "2025-04-27T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-27T23:15:00.000",
            "ToDate": "2025-04-27T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T18:00:00.000",
            "ToDate": "2025-04-28T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T18:15:00.000",
            "ToDate": "2025-04-28T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T18:30:00.000",
            "ToDate": "2025-04-28T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T18:45:00.000",
            "ToDate": "2025-04-28T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T19:00:00.000",
            "ToDate": "2025-04-28T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T19:15:00.000",
            "ToDate": "2025-04-28T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T19:30:00.000",
            "ToDate": "2025-04-28T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T19:45:00.000",
            "ToDate": "2025-04-28T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T20:00:00.000",
            "ToDate": "2025-04-28T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T20:15:00.000",
            "ToDate": "2025-04-28T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T20:30:00.000",
            "ToDate": "2025-04-28T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T20:45:00.000",
            "ToDate": "2025-04-28T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T21:00:00.000",
            "ToDate": "2025-04-28T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T21:15:00.000",
            "ToDate": "2025-04-28T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T21:30:00.000",
            "ToDate": "2025-04-28T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T21:45:00.000",
            "ToDate": "2025-04-28T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T22:00:00.000",
            "ToDate": "2025-04-28T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T22:15:00.000",
            "ToDate": "2025-04-28T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T22:30:00.000",
            "ToDate": "2025-04-28T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T22:45:00.000",
            "ToDate": "2025-04-28T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T23:00:00.000",
            "ToDate": "2025-04-28T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-28T23:15:00.000",
            "ToDate": "2025-04-28T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T18:00:00.000",
            "ToDate": "2025-04-29T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T18:15:00.000",
            "ToDate": "2025-04-29T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T18:30:00.000",
            "ToDate": "2025-04-29T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T18:45:00.000",
            "ToDate": "2025-04-29T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T19:00:00.000",
            "ToDate": "2025-04-29T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T19:15:00.000",
            "ToDate": "2025-04-29T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T19:30:00.000",
            "ToDate": "2025-04-29T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T19:45:00.000",
            "ToDate": "2025-04-29T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T20:00:00.000",
            "ToDate": "2025-04-29T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T20:15:00.000",
            "ToDate": "2025-04-29T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T20:30:00.000",
            "ToDate": "2025-04-29T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T20:45:00.000",
            "ToDate": "2025-04-29T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T21:00:00.000",
            "ToDate": "2025-04-29T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T21:15:00.000",
            "ToDate": "2025-04-29T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T21:30:00.000",
            "ToDate": "2025-04-29T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T21:45:00.000",
            "ToDate": "2025-04-29T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T22:00:00.000",
            "ToDate": "2025-04-29T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T22:15:00.000",
            "ToDate": "2025-04-29T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T22:30:00.000",
            "ToDate": "2025-04-29T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T22:45:00.000",
            "ToDate": "2025-04-29T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T23:00:00.000",
            "ToDate": "2025-04-29T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-29T23:15:00.000",
            "ToDate": "2025-04-29T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T18:00:00.000",
            "ToDate": "2025-04-30T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T18:15:00.000",
            "ToDate": "2025-04-30T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T18:30:00.000",
            "ToDate": "2025-04-30T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T18:45:00.000",
            "ToDate": "2025-04-30T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T19:00:00.000",
            "ToDate": "2025-04-30T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T19:15:00.000",
            "ToDate": "2025-04-30T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T19:30:00.000",
            "ToDate": "2025-04-30T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T19:45:00.000",
            "ToDate": "2025-04-30T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T20:00:00.000",
            "ToDate": "2025-04-30T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T20:15:00.000",
            "ToDate": "2025-04-30T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T20:30:00.000",
            "ToDate": "2025-04-30T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T20:45:00.000",
            "ToDate": "2025-04-30T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T21:00:00.000",
            "ToDate": "2025-04-30T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T21:15:00.000",
            "ToDate": "2025-04-30T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T21:30:00.000",
            "ToDate": "2025-04-30T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T21:45:00.000",
            "ToDate": "2025-04-30T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T22:00:00.000",
            "ToDate": "2025-04-30T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T22:15:00.000",
            "ToDate": "2025-04-30T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T22:30:00.000",
            "ToDate": "2025-04-30T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T22:45:00.000",
            "ToDate": "2025-04-30T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T23:00:00.000",
            "ToDate": "2025-04-30T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-04-30T23:15:00.000",
            "ToDate": "2025-04-30T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T18:00:00.000",
            "ToDate": "2025-05-01T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T18:15:00.000",
            "ToDate": "2025-05-01T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T18:30:00.000",
            "ToDate": "2025-05-01T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T18:45:00.000",
            "ToDate": "2025-05-01T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T19:00:00.000",
            "ToDate": "2025-05-01T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T19:15:00.000",
            "ToDate": "2025-05-01T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T19:30:00.000",
            "ToDate": "2025-05-01T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T19:45:00.000",
            "ToDate": "2025-05-01T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T20:00:00.000",
            "ToDate": "2025-05-01T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T20:15:00.000",
            "ToDate": "2025-05-01T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T20:30:00.000",
            "ToDate": "2025-05-01T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T20:45:00.000",
            "ToDate": "2025-05-01T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T21:00:00.000",
            "ToDate": "2025-05-01T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T21:15:00.000",
            "ToDate": "2025-05-01T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T21:30:00.000",
            "ToDate": "2025-05-01T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T21:45:00.000",
            "ToDate": "2025-05-01T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T22:00:00.000",
            "ToDate": "2025-05-01T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T22:15:00.000",
            "ToDate": "2025-05-01T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T22:30:00.000",
            "ToDate": "2025-05-01T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T22:45:00.000",
            "ToDate": "2025-05-01T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T23:00:00.000",
            "ToDate": "2025-05-01T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-01T23:15:00.000",
            "ToDate": "2025-05-01T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T18:00:00.000",
            "ToDate": "2025-05-02T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T18:15:00.000",
            "ToDate": "2025-05-02T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T18:30:00.000",
            "ToDate": "2025-05-02T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T18:45:00.000",
            "ToDate": "2025-05-02T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T19:00:00.000",
            "ToDate": "2025-05-02T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T19:15:00.000",
            "ToDate": "2025-05-02T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T19:30:00.000",
            "ToDate": "2025-05-02T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T19:45:00.000",
            "ToDate": "2025-05-02T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T20:00:00.000",
            "ToDate": "2025-05-02T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T20:15:00.000",
            "ToDate": "2025-05-02T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T20:30:00.000",
            "ToDate": "2025-05-02T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T20:45:00.000",
            "ToDate": "2025-05-02T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T21:00:00.000",
            "ToDate": "2025-05-02T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T21:15:00.000",
            "ToDate": "2025-05-02T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T21:30:00.000",
            "ToDate": "2025-05-02T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T21:45:00.000",
            "ToDate": "2025-05-02T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T22:00:00.000",
            "ToDate": "2025-05-02T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T22:15:00.000",
            "ToDate": "2025-05-02T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T22:30:00.000",
            "ToDate": "2025-05-02T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T22:45:00.000",
            "ToDate": "2025-05-02T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T23:00:00.000",
            "ToDate": "2025-05-02T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-02T23:15:00.000",
            "ToDate": "2025-05-02T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T18:00:00.000",
            "ToDate": "2025-05-03T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T18:15:00.000",
            "ToDate": "2025-05-03T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T18:30:00.000",
            "ToDate": "2025-05-03T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T18:45:00.000",
            "ToDate": "2025-05-03T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T19:00:00.000",
            "ToDate": "2025-05-03T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T19:15:00.000",
            "ToDate": "2025-05-03T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T19:30:00.000",
            "ToDate": "2025-05-03T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T19:45:00.000",
            "ToDate": "2025-05-03T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T20:00:00.000",
            "ToDate": "2025-05-03T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T20:15:00.000",
            "ToDate": "2025-05-03T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T20:30:00.000",
            "ToDate": "2025-05-03T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T20:45:00.000",
            "ToDate": "2025-05-03T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T21:00:00.000",
            "ToDate": "2025-05-03T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T21:15:00.000",
            "ToDate": "2025-05-03T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T21:30:00.000",
            "ToDate": "2025-05-03T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T21:45:00.000",
            "ToDate": "2025-05-03T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T22:00:00.000",
            "ToDate": "2025-05-03T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T22:15:00.000",
            "ToDate": "2025-05-03T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T22:30:00.000",
            "ToDate": "2025-05-03T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T22:45:00.000",
            "ToDate": "2025-05-03T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T23:00:00.000",
            "ToDate": "2025-05-03T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-03T23:15:00.000",
            "ToDate": "2025-05-03T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T18:00:00.000",
            "ToDate": "2025-05-04T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T18:15:00.000",
            "ToDate": "2025-05-04T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T18:30:00.000",
            "ToDate": "2025-05-04T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T18:45:00.000",
            "ToDate": "2025-05-04T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T19:00:00.000",
            "ToDate": "2025-05-04T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T19:15:00.000",
            "ToDate": "2025-05-04T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T19:30:00.000",
            "ToDate": "2025-05-04T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T19:45:00.000",
            "ToDate": "2025-05-04T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T20:00:00.000",
            "ToDate": "2025-05-04T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T20:15:00.000",
            "ToDate": "2025-05-04T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T20:30:00.000",
            "ToDate": "2025-05-04T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T20:45:00.000",
            "ToDate": "2025-05-04T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T21:00:00.000",
            "ToDate": "2025-05-04T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T21:15:00.000",
            "ToDate": "2025-05-04T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T21:30:00.000",
            "ToDate": "2025-05-04T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T21:45:00.000",
            "ToDate": "2025-05-04T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T22:00:00.000",
            "ToDate": "2025-05-04T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T22:15:00.000",
            "ToDate": "2025-05-04T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T22:30:00.000",
            "ToDate": "2025-05-04T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T22:45:00.000",
            "ToDate": "2025-05-04T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T23:00:00.000",
            "ToDate": "2025-05-04T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-04T23:15:00.000",
            "ToDate": "2025-05-04T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T18:00:00.000",
            "ToDate": "2025-05-05T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T18:15:00.000",
            "ToDate": "2025-05-05T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T18:30:00.000",
            "ToDate": "2025-05-05T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T18:45:00.000",
            "ToDate": "2025-05-05T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T19:00:00.000",
            "ToDate": "2025-05-05T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T19:15:00.000",
            "ToDate": "2025-05-05T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T19:30:00.000",
            "ToDate": "2025-05-05T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T19:45:00.000",
            "ToDate": "2025-05-05T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T20:00:00.000",
            "ToDate": "2025-05-05T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T20:15:00.000",
            "ToDate": "2025-05-05T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T20:30:00.000",
            "ToDate": "2025-05-05T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T20:45:00.000",
            "ToDate": "2025-05-05T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T21:00:00.000",
            "ToDate": "2025-05-05T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T21:15:00.000",
            "ToDate": "2025-05-05T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T21:30:00.000",
            "ToDate": "2025-05-05T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T21:45:00.000",
            "ToDate": "2025-05-05T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T22:00:00.000",
            "ToDate": "2025-05-05T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T22:15:00.000",
            "ToDate": "2025-05-05T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T22:30:00.000",
            "ToDate": "2025-05-05T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T22:45:00.000",
            "ToDate": "2025-05-05T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T23:00:00.000",
            "ToDate": "2025-05-05T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-05T23:15:00.000",
            "ToDate": "2025-05-05T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T18:00:00.000",
            "ToDate": "2025-05-06T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T18:15:00.000",
            "ToDate": "2025-05-06T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T18:30:00.000",
            "ToDate": "2025-05-06T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T18:45:00.000",
            "ToDate": "2025-05-06T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T19:00:00.000",
            "ToDate": "2025-05-06T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T19:15:00.000",
            "ToDate": "2025-05-06T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T19:30:00.000",
            "ToDate": "2025-05-06T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T19:45:00.000",
            "ToDate": "2025-05-06T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T20:00:00.000",
            "ToDate": "2025-05-06T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T20:15:00.000",
            "ToDate": "2025-05-06T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T20:30:00.000",
            "ToDate": "2025-05-06T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T20:45:00.000",
            "ToDate": "2025-05-06T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T21:00:00.000",
            "ToDate": "2025-05-06T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T21:15:00.000",
            "ToDate": "2025-05-06T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T21:30:00.000",
            "ToDate": "2025-05-06T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T21:45:00.000",
            "ToDate": "2025-05-06T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T22:00:00.000",
            "ToDate": "2025-05-06T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T22:15:00.000",
            "ToDate": "2025-05-06T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T22:30:00.000",
            "ToDate": "2025-05-06T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T22:45:00.000",
            "ToDate": "2025-05-06T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T23:00:00.000",
            "ToDate": "2025-05-06T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-06T23:15:00.000",
            "ToDate": "2025-05-06T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T18:00:00.000",
            "ToDate": "2025-05-07T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T18:15:00.000",
            "ToDate": "2025-05-07T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T18:30:00.000",
            "ToDate": "2025-05-07T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T18:45:00.000",
            "ToDate": "2025-05-07T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T19:00:00.000",
            "ToDate": "2025-05-07T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T19:15:00.000",
            "ToDate": "2025-05-07T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T19:30:00.000",
            "ToDate": "2025-05-07T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T19:45:00.000",
            "ToDate": "2025-05-07T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T20:00:00.000",
            "ToDate": "2025-05-07T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T20:15:00.000",
            "ToDate": "2025-05-07T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T20:30:00.000",
            "ToDate": "2025-05-07T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T20:45:00.000",
            "ToDate": "2025-05-07T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T21:00:00.000",
            "ToDate": "2025-05-07T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T21:15:00.000",
            "ToDate": "2025-05-07T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T21:30:00.000",
            "ToDate": "2025-05-07T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T21:45:00.000",
            "ToDate": "2025-05-07T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T22:00:00.000",
            "ToDate": "2025-05-07T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T22:15:00.000",
            "ToDate": "2025-05-07T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T22:30:00.000",
            "ToDate": "2025-05-07T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T22:45:00.000",
            "ToDate": "2025-05-07T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T23:00:00.000",
            "ToDate": "2025-05-07T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-07T23:15:00.000",
            "ToDate": "2025-05-07T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T18:00:00.000",
            "ToDate": "2025-05-08T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T18:15:00.000",
            "ToDate": "2025-05-08T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T18:30:00.000",
            "ToDate": "2025-05-08T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T18:45:00.000",
            "ToDate": "2025-05-08T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T19:00:00.000",
            "ToDate": "2025-05-08T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T19:15:00.000",
            "ToDate": "2025-05-08T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T19:30:00.000",
            "ToDate": "2025-05-08T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T19:45:00.000",
            "ToDate": "2025-05-08T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T20:00:00.000",
            "ToDate": "2025-05-08T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T20:15:00.000",
            "ToDate": "2025-05-08T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T20:30:00.000",
            "ToDate": "2025-05-08T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T20:45:00.000",
            "ToDate": "2025-05-08T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T21:00:00.000",
            "ToDate": "2025-05-08T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T21:15:00.000",
            "ToDate": "2025-05-08T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T21:30:00.000",
            "ToDate": "2025-05-08T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T21:45:00.000",
            "ToDate": "2025-05-08T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T22:00:00.000",
            "ToDate": "2025-05-08T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T22:15:00.000",
            "ToDate": "2025-05-08T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T22:30:00.000",
            "ToDate": "2025-05-08T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T22:45:00.000",
            "ToDate": "2025-05-08T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T23:00:00.000",
            "ToDate": "2025-05-08T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-08T23:15:00.000",
            "ToDate": "2025-05-08T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T18:00:00.000",
            "ToDate": "2025-05-09T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T18:15:00.000",
            "ToDate": "2025-05-09T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T18:30:00.000",
            "ToDate": "2025-05-09T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T18:45:00.000",
            "ToDate": "2025-05-09T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T19:00:00.000",
            "ToDate": "2025-05-09T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T19:15:00.000",
            "ToDate": "2025-05-09T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T19:30:00.000",
            "ToDate": "2025-05-09T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T19:45:00.000",
            "ToDate": "2025-05-09T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T20:00:00.000",
            "ToDate": "2025-05-09T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T20:15:00.000",
            "ToDate": "2025-05-09T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T20:30:00.000",
            "ToDate": "2025-05-09T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T20:45:00.000",
            "ToDate": "2025-05-09T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T21:00:00.000",
            "ToDate": "2025-05-09T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T21:15:00.000",
            "ToDate": "2025-05-09T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T21:30:00.000",
            "ToDate": "2025-05-09T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T21:45:00.000",
            "ToDate": "2025-05-09T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T22:00:00.000",
            "ToDate": "2025-05-09T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T22:15:00.000",
            "ToDate": "2025-05-09T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T22:30:00.000",
            "ToDate": "2025-05-09T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T22:45:00.000",
            "ToDate": "2025-05-09T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T23:00:00.000",
            "ToDate": "2025-05-09T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-09T23:15:00.000",
            "ToDate": "2025-05-09T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T18:00:00.000",
            "ToDate": "2025-05-10T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T18:15:00.000",
            "ToDate": "2025-05-10T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T18:30:00.000",
            "ToDate": "2025-05-10T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T18:45:00.000",
            "ToDate": "2025-05-10T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T19:00:00.000",
            "ToDate": "2025-05-10T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T19:15:00.000",
            "ToDate": "2025-05-10T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T19:30:00.000",
            "ToDate": "2025-05-10T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T19:45:00.000",
            "ToDate": "2025-05-10T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T20:00:00.000",
            "ToDate": "2025-05-10T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T20:15:00.000",
            "ToDate": "2025-05-10T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T20:30:00.000",
            "ToDate": "2025-05-10T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T20:45:00.000",
            "ToDate": "2025-05-10T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T21:00:00.000",
            "ToDate": "2025-05-10T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T21:15:00.000",
            "ToDate": "2025-05-10T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T21:30:00.000",
            "ToDate": "2025-05-10T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T21:45:00.000",
            "ToDate": "2025-05-10T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T22:00:00.000",
            "ToDate": "2025-05-10T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T22:15:00.000",
            "ToDate": "2025-05-10T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T22:30:00.000",
            "ToDate": "2025-05-10T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T22:45:00.000",
            "ToDate": "2025-05-10T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T23:00:00.000",
            "ToDate": "2025-05-10T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-10T23:15:00.000",
            "ToDate": "2025-05-10T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T18:00:00.000",
            "ToDate": "2025-05-11T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T18:15:00.000",
            "ToDate": "2025-05-11T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T18:30:00.000",
            "ToDate": "2025-05-11T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T18:45:00.000",
            "ToDate": "2025-05-11T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T19:00:00.000",
            "ToDate": "2025-05-11T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T19:15:00.000",
            "ToDate": "2025-05-11T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T19:30:00.000",
            "ToDate": "2025-05-11T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T19:45:00.000",
            "ToDate": "2025-05-11T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T20:00:00.000",
            "ToDate": "2025-05-11T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T20:15:00.000",
            "ToDate": "2025-05-11T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T20:30:00.000",
            "ToDate": "2025-05-11T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T20:45:00.000",
            "ToDate": "2025-05-11T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T21:00:00.000",
            "ToDate": "2025-05-11T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T21:15:00.000",
            "ToDate": "2025-05-11T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T21:30:00.000",
            "ToDate": "2025-05-11T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T21:45:00.000",
            "ToDate": "2025-05-11T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T22:00:00.000",
            "ToDate": "2025-05-11T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T22:15:00.000",
            "ToDate": "2025-05-11T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T22:30:00.000",
            "ToDate": "2025-05-11T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T22:45:00.000",
            "ToDate": "2025-05-11T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T23:00:00.000",
            "ToDate": "2025-05-11T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-11T23:15:00.000",
            "ToDate": "2025-05-11T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T18:00:00.000",
            "ToDate": "2025-05-12T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T18:15:00.000",
            "ToDate": "2025-05-12T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T18:30:00.000",
            "ToDate": "2025-05-12T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T18:45:00.000",
            "ToDate": "2025-05-12T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T19:00:00.000",
            "ToDate": "2025-05-12T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T19:15:00.000",
            "ToDate": "2025-05-12T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T19:30:00.000",
            "ToDate": "2025-05-12T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T19:45:00.000",
            "ToDate": "2025-05-12T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T20:00:00.000",
            "ToDate": "2025-05-12T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T20:15:00.000",
            "ToDate": "2025-05-12T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T20:30:00.000",
            "ToDate": "2025-05-12T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T20:45:00.000",
            "ToDate": "2025-05-12T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T21:00:00.000",
            "ToDate": "2025-05-12T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T21:15:00.000",
            "ToDate": "2025-05-12T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T21:30:00.000",
            "ToDate": "2025-05-12T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T21:45:00.000",
            "ToDate": "2025-05-12T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T22:00:00.000",
            "ToDate": "2025-05-12T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T22:15:00.000",
            "ToDate": "2025-05-12T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T22:30:00.000",
            "ToDate": "2025-05-12T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T22:45:00.000",
            "ToDate": "2025-05-12T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T23:00:00.000",
            "ToDate": "2025-05-12T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-12T23:15:00.000",
            "ToDate": "2025-05-12T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T18:00:00.000",
            "ToDate": "2025-05-13T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T18:15:00.000",
            "ToDate": "2025-05-13T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T18:30:00.000",
            "ToDate": "2025-05-13T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T18:45:00.000",
            "ToDate": "2025-05-13T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T19:00:00.000",
            "ToDate": "2025-05-13T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T19:15:00.000",
            "ToDate": "2025-05-13T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T19:30:00.000",
            "ToDate": "2025-05-13T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T19:45:00.000",
            "ToDate": "2025-05-13T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T20:00:00.000",
            "ToDate": "2025-05-13T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T20:15:00.000",
            "ToDate": "2025-05-13T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T20:30:00.000",
            "ToDate": "2025-05-13T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T20:45:00.000",
            "ToDate": "2025-05-13T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T21:00:00.000",
            "ToDate": "2025-05-13T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T21:15:00.000",
            "ToDate": "2025-05-13T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T21:30:00.000",
            "ToDate": "2025-05-13T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T21:45:00.000",
            "ToDate": "2025-05-13T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T22:00:00.000",
            "ToDate": "2025-05-13T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T22:15:00.000",
            "ToDate": "2025-05-13T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T22:30:00.000",
            "ToDate": "2025-05-13T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T22:45:00.000",
            "ToDate": "2025-05-13T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T23:00:00.000",
            "ToDate": "2025-05-13T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-13T23:15:00.000",
            "ToDate": "2025-05-13T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T18:00:00.000",
            "ToDate": "2025-05-14T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T18:15:00.000",
            "ToDate": "2025-05-14T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T18:30:00.000",
            "ToDate": "2025-05-14T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T18:45:00.000",
            "ToDate": "2025-05-14T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T19:00:00.000",
            "ToDate": "2025-05-14T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T19:15:00.000",
            "ToDate": "2025-05-14T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T19:30:00.000",
            "ToDate": "2025-05-14T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T19:45:00.000",
            "ToDate": "2025-05-14T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T20:00:00.000",
            "ToDate": "2025-05-14T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T20:15:00.000",
            "ToDate": "2025-05-14T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T20:30:00.000",
            "ToDate": "2025-05-14T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T20:45:00.000",
            "ToDate": "2025-05-14T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T21:00:00.000",
            "ToDate": "2025-05-14T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T21:15:00.000",
            "ToDate": "2025-05-14T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T21:30:00.000",
            "ToDate": "2025-05-14T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T21:45:00.000",
            "ToDate": "2025-05-14T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T22:00:00.000",
            "ToDate": "2025-05-14T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T22:15:00.000",
            "ToDate": "2025-05-14T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T22:30:00.000",
            "ToDate": "2025-05-14T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T22:45:00.000",
            "ToDate": "2025-05-14T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T23:00:00.000",
            "ToDate": "2025-05-14T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-14T23:15:00.000",
            "ToDate": "2025-05-14T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T18:00:00.000",
            "ToDate": "2025-05-15T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T18:15:00.000",
            "ToDate": "2025-05-15T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T18:30:00.000",
            "ToDate": "2025-05-15T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T18:45:00.000",
            "ToDate": "2025-05-15T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T19:00:00.000",
            "ToDate": "2025-05-15T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T19:15:00.000",
            "ToDate": "2025-05-15T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T19:30:00.000",
            "ToDate": "2025-05-15T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T19:45:00.000",
            "ToDate": "2025-05-15T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T20:00:00.000",
            "ToDate": "2025-05-15T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T20:15:00.000",
            "ToDate": "2025-05-15T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T20:30:00.000",
            "ToDate": "2025-05-15T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T20:45:00.000",
            "ToDate": "2025-05-15T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T21:00:00.000",
            "ToDate": "2025-05-15T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T21:15:00.000",
            "ToDate": "2025-05-15T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T21:30:00.000",
            "ToDate": "2025-05-15T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T21:45:00.000",
            "ToDate": "2025-05-15T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T22:00:00.000",
            "ToDate": "2025-05-15T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T22:15:00.000",
            "ToDate": "2025-05-15T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T22:30:00.000",
            "ToDate": "2025-05-15T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T22:45:00.000",
            "ToDate": "2025-05-15T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T23:00:00.000",
            "ToDate": "2025-05-15T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-15T23:15:00.000",
            "ToDate": "2025-05-15T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T18:00:00.000",
            "ToDate": "2025-05-16T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T18:15:00.000",
            "ToDate": "2025-05-16T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T18:30:00.000",
            "ToDate": "2025-05-16T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T18:45:00.000",
            "ToDate": "2025-05-16T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T19:00:00.000",
            "ToDate": "2025-05-16T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T19:15:00.000",
            "ToDate": "2025-05-16T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T19:30:00.000",
            "ToDate": "2025-05-16T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T19:45:00.000",
            "ToDate": "2025-05-16T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T20:00:00.000",
            "ToDate": "2025-05-16T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T20:15:00.000",
            "ToDate": "2025-05-16T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T20:30:00.000",
            "ToDate": "2025-05-16T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T20:45:00.000",
            "ToDate": "2025-05-16T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T21:00:00.000",
            "ToDate": "2025-05-16T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T21:15:00.000",
            "ToDate": "2025-05-16T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T21:30:00.000",
            "ToDate": "2025-05-16T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T21:45:00.000",
            "ToDate": "2025-05-16T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T22:00:00.000",
            "ToDate": "2025-05-16T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T22:15:00.000",
            "ToDate": "2025-05-16T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T22:30:00.000",
            "ToDate": "2025-05-16T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T22:45:00.000",
            "ToDate": "2025-05-16T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T23:00:00.000",
            "ToDate": "2025-05-16T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-16T23:15:00.000",
            "ToDate": "2025-05-16T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T18:00:00.000",
            "ToDate": "2025-05-17T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T18:15:00.000",
            "ToDate": "2025-05-17T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T18:30:00.000",
            "ToDate": "2025-05-17T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T18:45:00.000",
            "ToDate": "2025-05-17T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T19:00:00.000",
            "ToDate": "2025-05-17T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T19:15:00.000",
            "ToDate": "2025-05-17T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T19:30:00.000",
            "ToDate": "2025-05-17T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T19:45:00.000",
            "ToDate": "2025-05-17T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T20:00:00.000",
            "ToDate": "2025-05-17T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T20:15:00.000",
            "ToDate": "2025-05-17T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T20:30:00.000",
            "ToDate": "2025-05-17T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T20:45:00.000",
            "ToDate": "2025-05-17T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T21:00:00.000",
            "ToDate": "2025-05-17T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T21:15:00.000",
            "ToDate": "2025-05-17T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T21:30:00.000",
            "ToDate": "2025-05-17T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T21:45:00.000",
            "ToDate": "2025-05-17T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T22:00:00.000",
            "ToDate": "2025-05-17T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T22:15:00.000",
            "ToDate": "2025-05-17T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T22:30:00.000",
            "ToDate": "2025-05-17T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T22:45:00.000",
            "ToDate": "2025-05-17T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T23:00:00.000",
            "ToDate": "2025-05-17T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-17T23:15:00.000",
            "ToDate": "2025-05-17T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T18:00:00.000",
            "ToDate": "2025-05-18T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T18:15:00.000",
            "ToDate": "2025-05-18T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T18:30:00.000",
            "ToDate": "2025-05-18T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T18:45:00.000",
            "ToDate": "2025-05-18T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T19:00:00.000",
            "ToDate": "2025-05-18T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T19:15:00.000",
            "ToDate": "2025-05-18T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T19:30:00.000",
            "ToDate": "2025-05-18T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T19:45:00.000",
            "ToDate": "2025-05-18T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T20:00:00.000",
            "ToDate": "2025-05-18T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T20:15:00.000",
            "ToDate": "2025-05-18T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T20:30:00.000",
            "ToDate": "2025-05-18T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T20:45:00.000",
            "ToDate": "2025-05-18T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T21:00:00.000",
            "ToDate": "2025-05-18T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T21:15:00.000",
            "ToDate": "2025-05-18T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T21:30:00.000",
            "ToDate": "2025-05-18T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T21:45:00.000",
            "ToDate": "2025-05-18T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T22:00:00.000",
            "ToDate": "2025-05-18T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T22:15:00.000",
            "ToDate": "2025-05-18T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T22:30:00.000",
            "ToDate": "2025-05-18T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T22:45:00.000",
            "ToDate": "2025-05-18T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T23:00:00.000",
            "ToDate": "2025-05-18T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-18T23:15:00.000",
            "ToDate": "2025-05-18T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T18:00:00.000",
            "ToDate": "2025-05-19T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T18:15:00.000",
            "ToDate": "2025-05-19T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T18:30:00.000",
            "ToDate": "2025-05-19T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T18:45:00.000",
            "ToDate": "2025-05-19T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T19:00:00.000",
            "ToDate": "2025-05-19T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T19:15:00.000",
            "ToDate": "2025-05-19T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T19:30:00.000",
            "ToDate": "2025-05-19T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T19:45:00.000",
            "ToDate": "2025-05-19T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T20:00:00.000",
            "ToDate": "2025-05-19T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T20:15:00.000",
            "ToDate": "2025-05-19T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T20:30:00.000",
            "ToDate": "2025-05-19T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T20:45:00.000",
            "ToDate": "2025-05-19T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T21:00:00.000",
            "ToDate": "2025-05-19T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T21:15:00.000",
            "ToDate": "2025-05-19T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T21:30:00.000",
            "ToDate": "2025-05-19T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T21:45:00.000",
            "ToDate": "2025-05-19T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T22:00:00.000",
            "ToDate": "2025-05-19T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T22:15:00.000",
            "ToDate": "2025-05-19T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T22:30:00.000",
            "ToDate": "2025-05-19T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T22:45:00.000",
            "ToDate": "2025-05-19T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T23:00:00.000",
            "ToDate": "2025-05-19T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-19T23:15:00.000",
            "ToDate": "2025-05-19T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T18:00:00.000",
            "ToDate": "2025-05-20T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T18:15:00.000",
            "ToDate": "2025-05-20T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T18:30:00.000",
            "ToDate": "2025-05-20T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T18:45:00.000",
            "ToDate": "2025-05-20T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T19:00:00.000",
            "ToDate": "2025-05-20T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T19:15:00.000",
            "ToDate": "2025-05-20T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T19:30:00.000",
            "ToDate": "2025-05-20T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T19:45:00.000",
            "ToDate": "2025-05-20T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T20:00:00.000",
            "ToDate": "2025-05-20T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T20:15:00.000",
            "ToDate": "2025-05-20T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T20:30:00.000",
            "ToDate": "2025-05-20T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T20:45:00.000",
            "ToDate": "2025-05-20T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T21:00:00.000",
            "ToDate": "2025-05-20T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T21:15:00.000",
            "ToDate": "2025-05-20T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T21:30:00.000",
            "ToDate": "2025-05-20T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T21:45:00.000",
            "ToDate": "2025-05-20T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T22:00:00.000",
            "ToDate": "2025-05-20T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T22:15:00.000",
            "ToDate": "2025-05-20T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T22:30:00.000",
            "ToDate": "2025-05-20T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T22:45:00.000",
            "ToDate": "2025-05-20T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T23:00:00.000",
            "ToDate": "2025-05-20T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-20T23:15:00.000",
            "ToDate": "2025-05-20T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T18:00:00.000",
            "ToDate": "2025-05-21T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T18:15:00.000",
            "ToDate": "2025-05-21T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T18:30:00.000",
            "ToDate": "2025-05-21T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T18:45:00.000",
            "ToDate": "2025-05-21T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T19:00:00.000",
            "ToDate": "2025-05-21T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T19:15:00.000",
            "ToDate": "2025-05-21T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T19:30:00.000",
            "ToDate": "2025-05-21T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T19:45:00.000",
            "ToDate": "2025-05-21T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T20:00:00.000",
            "ToDate": "2025-05-21T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T20:15:00.000",
            "ToDate": "2025-05-21T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T20:30:00.000",
            "ToDate": "2025-05-21T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T20:45:00.000",
            "ToDate": "2025-05-21T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T21:00:00.000",
            "ToDate": "2025-05-21T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T21:15:00.000",
            "ToDate": "2025-05-21T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T21:30:00.000",
            "ToDate": "2025-05-21T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T21:45:00.000",
            "ToDate": "2025-05-21T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T22:00:00.000",
            "ToDate": "2025-05-21T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T22:15:00.000",
            "ToDate": "2025-05-21T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T22:30:00.000",
            "ToDate": "2025-05-21T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T22:45:00.000",
            "ToDate": "2025-05-21T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T23:00:00.000",
            "ToDate": "2025-05-21T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-21T23:15:00.000",
            "ToDate": "2025-05-21T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T18:00:00.000",
            "ToDate": "2025-05-22T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T18:15:00.000",
            "ToDate": "2025-05-22T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T18:30:00.000",
            "ToDate": "2025-05-22T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T18:45:00.000",
            "ToDate": "2025-05-22T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T19:00:00.000",
            "ToDate": "2025-05-22T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T19:15:00.000",
            "ToDate": "2025-05-22T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T19:30:00.000",
            "ToDate": "2025-05-22T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T19:45:00.000",
            "ToDate": "2025-05-22T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T20:00:00.000",
            "ToDate": "2025-05-22T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T20:15:00.000",
            "ToDate": "2025-05-22T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T20:30:00.000",
            "ToDate": "2025-05-22T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T20:45:00.000",
            "ToDate": "2025-05-22T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T21:00:00.000",
            "ToDate": "2025-05-22T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T21:15:00.000",
            "ToDate": "2025-05-22T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T21:30:00.000",
            "ToDate": "2025-05-22T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T21:45:00.000",
            "ToDate": "2025-05-22T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T22:00:00.000",
            "ToDate": "2025-05-22T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T22:15:00.000",
            "ToDate": "2025-05-22T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T22:30:00.000",
            "ToDate": "2025-05-22T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T22:45:00.000",
            "ToDate": "2025-05-22T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T23:00:00.000",
            "ToDate": "2025-05-22T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-22T23:15:00.000",
            "ToDate": "2025-05-22T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T18:00:00.000",
            "ToDate": "2025-05-23T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T18:15:00.000",
            "ToDate": "2025-05-23T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T18:30:00.000",
            "ToDate": "2025-05-23T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T18:45:00.000",
            "ToDate": "2025-05-23T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T19:00:00.000",
            "ToDate": "2025-05-23T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T19:15:00.000",
            "ToDate": "2025-05-23T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T19:30:00.000",
            "ToDate": "2025-05-23T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T19:45:00.000",
            "ToDate": "2025-05-23T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T20:00:00.000",
            "ToDate": "2025-05-23T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T20:15:00.000",
            "ToDate": "2025-05-23T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T20:30:00.000",
            "ToDate": "2025-05-23T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T20:45:00.000",
            "ToDate": "2025-05-23T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T21:00:00.000",
            "ToDate": "2025-05-23T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T21:15:00.000",
            "ToDate": "2025-05-23T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T21:30:00.000",
            "ToDate": "2025-05-23T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T21:45:00.000",
            "ToDate": "2025-05-23T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T22:00:00.000",
            "ToDate": "2025-05-23T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T22:15:00.000",
            "ToDate": "2025-05-23T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T22:30:00.000",
            "ToDate": "2025-05-23T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T22:45:00.000",
            "ToDate": "2025-05-23T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T23:00:00.000",
            "ToDate": "2025-05-23T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-23T23:15:00.000",
            "ToDate": "2025-05-23T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T18:00:00.000",
            "ToDate": "2025-05-24T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T18:15:00.000",
            "ToDate": "2025-05-24T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T18:30:00.000",
            "ToDate": "2025-05-24T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T18:45:00.000",
            "ToDate": "2025-05-24T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T19:00:00.000",
            "ToDate": "2025-05-24T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T19:15:00.000",
            "ToDate": "2025-05-24T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T19:30:00.000",
            "ToDate": "2025-05-24T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T19:45:00.000",
            "ToDate": "2025-05-24T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T20:00:00.000",
            "ToDate": "2025-05-24T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T20:15:00.000",
            "ToDate": "2025-05-24T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T20:30:00.000",
            "ToDate": "2025-05-24T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T20:45:00.000",
            "ToDate": "2025-05-24T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T21:00:00.000",
            "ToDate": "2025-05-24T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T21:15:00.000",
            "ToDate": "2025-05-24T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T21:30:00.000",
            "ToDate": "2025-05-24T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T21:45:00.000",
            "ToDate": "2025-05-24T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T22:00:00.000",
            "ToDate": "2025-05-24T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T22:15:00.000",
            "ToDate": "2025-05-24T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T22:30:00.000",
            "ToDate": "2025-05-24T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T22:45:00.000",
            "ToDate": "2025-05-24T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T23:00:00.000",
            "ToDate": "2025-05-24T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-24T23:15:00.000",
            "ToDate": "2025-05-24T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T18:00:00.000",
            "ToDate": "2025-05-25T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T18:15:00.000",
            "ToDate": "2025-05-25T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T18:30:00.000",
            "ToDate": "2025-05-25T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T18:45:00.000",
            "ToDate": "2025-05-25T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T19:00:00.000",
            "ToDate": "2025-05-25T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T19:15:00.000",
            "ToDate": "2025-05-25T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T19:30:00.000",
            "ToDate": "2025-05-25T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T19:45:00.000",
            "ToDate": "2025-05-25T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T20:00:00.000",
            "ToDate": "2025-05-25T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T20:15:00.000",
            "ToDate": "2025-05-25T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T20:30:00.000",
            "ToDate": "2025-05-25T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T20:45:00.000",
            "ToDate": "2025-05-25T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T21:00:00.000",
            "ToDate": "2025-05-25T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T21:15:00.000",
            "ToDate": "2025-05-25T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T21:30:00.000",
            "ToDate": "2025-05-25T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T21:45:00.000",
            "ToDate": "2025-05-25T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T22:00:00.000",
            "ToDate": "2025-05-25T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T22:15:00.000",
            "ToDate": "2025-05-25T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T22:30:00.000",
            "ToDate": "2025-05-25T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T22:45:00.000",
            "ToDate": "2025-05-25T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T23:00:00.000",
            "ToDate": "2025-05-25T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-25T23:15:00.000",
            "ToDate": "2025-05-25T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T18:00:00.000",
            "ToDate": "2025-05-26T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T18:15:00.000",
            "ToDate": "2025-05-26T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T18:30:00.000",
            "ToDate": "2025-05-26T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T18:45:00.000",
            "ToDate": "2025-05-26T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T19:00:00.000",
            "ToDate": "2025-05-26T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T19:15:00.000",
            "ToDate": "2025-05-26T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T19:30:00.000",
            "ToDate": "2025-05-26T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T19:45:00.000",
            "ToDate": "2025-05-26T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T20:00:00.000",
            "ToDate": "2025-05-26T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T20:15:00.000",
            "ToDate": "2025-05-26T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T20:30:00.000",
            "ToDate": "2025-05-26T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T20:45:00.000",
            "ToDate": "2025-05-26T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T21:00:00.000",
            "ToDate": "2025-05-26T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T21:15:00.000",
            "ToDate": "2025-05-26T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T21:30:00.000",
            "ToDate": "2025-05-26T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T21:45:00.000",
            "ToDate": "2025-05-26T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T22:00:00.000",
            "ToDate": "2025-05-26T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T22:15:00.000",
            "ToDate": "2025-05-26T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T22:30:00.000",
            "ToDate": "2025-05-26T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T22:45:00.000",
            "ToDate": "2025-05-26T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T23:00:00.000",
            "ToDate": "2025-05-26T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-26T23:15:00.000",
            "ToDate": "2025-05-26T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T18:00:00.000",
            "ToDate": "2025-05-27T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T18:15:00.000",
            "ToDate": "2025-05-27T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T18:30:00.000",
            "ToDate": "2025-05-27T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T18:45:00.000",
            "ToDate": "2025-05-27T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T19:00:00.000",
            "ToDate": "2025-05-27T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T19:15:00.000",
            "ToDate": "2025-05-27T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T19:30:00.000",
            "ToDate": "2025-05-27T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T19:45:00.000",
            "ToDate": "2025-05-27T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T20:00:00.000",
            "ToDate": "2025-05-27T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T20:15:00.000",
            "ToDate": "2025-05-27T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T20:30:00.000",
            "ToDate": "2025-05-27T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T20:45:00.000",
            "ToDate": "2025-05-27T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T21:00:00.000",
            "ToDate": "2025-05-27T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T21:15:00.000",
            "ToDate": "2025-05-27T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T21:30:00.000",
            "ToDate": "2025-05-27T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T21:45:00.000",
            "ToDate": "2025-05-27T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T22:00:00.000",
            "ToDate": "2025-05-27T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T22:15:00.000",
            "ToDate": "2025-05-27T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T22:30:00.000",
            "ToDate": "2025-05-27T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T22:45:00.000",
            "ToDate": "2025-05-27T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T23:00:00.000",
            "ToDate": "2025-05-27T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-27T23:15:00.000",
            "ToDate": "2025-05-27T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T18:00:00.000",
            "ToDate": "2025-05-28T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T18:15:00.000",
            "ToDate": "2025-05-28T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T18:30:00.000",
            "ToDate": "2025-05-28T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T18:45:00.000",
            "ToDate": "2025-05-28T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T19:00:00.000",
            "ToDate": "2025-05-28T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T19:15:00.000",
            "ToDate": "2025-05-28T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T19:30:00.000",
            "ToDate": "2025-05-28T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T19:45:00.000",
            "ToDate": "2025-05-28T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T20:00:00.000",
            "ToDate": "2025-05-28T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T20:15:00.000",
            "ToDate": "2025-05-28T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T20:30:00.000",
            "ToDate": "2025-05-28T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T20:45:00.000",
            "ToDate": "2025-05-28T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T21:00:00.000",
            "ToDate": "2025-05-28T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T21:15:00.000",
            "ToDate": "2025-05-28T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T21:30:00.000",
            "ToDate": "2025-05-28T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T21:45:00.000",
            "ToDate": "2025-05-28T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T22:00:00.000",
            "ToDate": "2025-05-28T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T22:15:00.000",
            "ToDate": "2025-05-28T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T22:30:00.000",
            "ToDate": "2025-05-28T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T22:45:00.000",
            "ToDate": "2025-05-28T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T23:00:00.000",
            "ToDate": "2025-05-28T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-28T23:15:00.000",
            "ToDate": "2025-05-28T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T18:00:00.000",
            "ToDate": "2025-05-29T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T18:15:00.000",
            "ToDate": "2025-05-29T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T18:30:00.000",
            "ToDate": "2025-05-29T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T18:45:00.000",
            "ToDate": "2025-05-29T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T19:00:00.000",
            "ToDate": "2025-05-29T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T19:15:00.000",
            "ToDate": "2025-05-29T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T19:30:00.000",
            "ToDate": "2025-05-29T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T19:45:00.000",
            "ToDate": "2025-05-29T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T20:00:00.000",
            "ToDate": "2025-05-29T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T20:15:00.000",
            "ToDate": "2025-05-29T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T20:30:00.000",
            "ToDate": "2025-05-29T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T20:45:00.000",
            "ToDate": "2025-05-29T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T21:00:00.000",
            "ToDate": "2025-05-29T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T21:15:00.000",
            "ToDate": "2025-05-29T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T21:30:00.000",
            "ToDate": "2025-05-29T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T21:45:00.000",
            "ToDate": "2025-05-29T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T22:00:00.000",
            "ToDate": "2025-05-29T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T22:15:00.000",
            "ToDate": "2025-05-29T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T22:30:00.000",
            "ToDate": "2025-05-29T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T22:45:00.000",
            "ToDate": "2025-05-29T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T23:00:00.000",
            "ToDate": "2025-05-29T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-29T23:15:00.000",
            "ToDate": "2025-05-29T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T18:00:00.000",
            "ToDate": "2025-05-30T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T18:15:00.000",
            "ToDate": "2025-05-30T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T18:30:00.000",
            "ToDate": "2025-05-30T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T18:45:00.000",
            "ToDate": "2025-05-30T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T19:00:00.000",
            "ToDate": "2025-05-30T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T19:15:00.000",
            "ToDate": "2025-05-30T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T19:30:00.000",
            "ToDate": "2025-05-30T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T19:45:00.000",
            "ToDate": "2025-05-30T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T20:00:00.000",
            "ToDate": "2025-05-30T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T20:15:00.000",
            "ToDate": "2025-05-30T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T20:30:00.000",
            "ToDate": "2025-05-30T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T20:45:00.000",
            "ToDate": "2025-05-30T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T21:00:00.000",
            "ToDate": "2025-05-30T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T21:15:00.000",
            "ToDate": "2025-05-30T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T21:30:00.000",
            "ToDate": "2025-05-30T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T21:45:00.000",
            "ToDate": "2025-05-30T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T22:00:00.000",
            "ToDate": "2025-05-30T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T22:15:00.000",
            "ToDate": "2025-05-30T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T22:30:00.000",
            "ToDate": "2025-05-30T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T22:45:00.000",
            "ToDate": "2025-05-30T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T23:00:00.000",
            "ToDate": "2025-05-30T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-30T23:15:00.000",
            "ToDate": "2025-05-30T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T18:00:00.000",
            "ToDate": "2025-05-31T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T18:15:00.000",
            "ToDate": "2025-05-31T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T18:30:00.000",
            "ToDate": "2025-05-31T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T18:45:00.000",
            "ToDate": "2025-05-31T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T19:00:00.000",
            "ToDate": "2025-05-31T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T19:15:00.000",
            "ToDate": "2025-05-31T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T19:30:00.000",
            "ToDate": "2025-05-31T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T19:45:00.000",
            "ToDate": "2025-05-31T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T20:00:00.000",
            "ToDate": "2025-05-31T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T20:15:00.000",
            "ToDate": "2025-05-31T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T20:30:00.000",
            "ToDate": "2025-05-31T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T20:45:00.000",
            "ToDate": "2025-05-31T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T21:00:00.000",
            "ToDate": "2025-05-31T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T21:15:00.000",
            "ToDate": "2025-05-31T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T21:30:00.000",
            "ToDate": "2025-05-31T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T21:45:00.000",
            "ToDate": "2025-05-31T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T22:00:00.000",
            "ToDate": "2025-05-31T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T22:15:00.000",
            "ToDate": "2025-05-31T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T22:30:00.000",
            "ToDate": "2025-05-31T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T22:45:00.000",
            "ToDate": "2025-05-31T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T23:00:00.000",
            "ToDate": "2025-05-31T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-05-31T23:15:00.000",
            "ToDate": "2025-05-31T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T18:00:00.000",
            "ToDate": "2025-06-01T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T18:15:00.000",
            "ToDate": "2025-06-01T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T18:30:00.000",
            "ToDate": "2025-06-01T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T18:45:00.000",
            "ToDate": "2025-06-01T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T19:00:00.000",
            "ToDate": "2025-06-01T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T19:15:00.000",
            "ToDate": "2025-06-01T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T19:30:00.000",
            "ToDate": "2025-06-01T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T19:45:00.000",
            "ToDate": "2025-06-01T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T20:00:00.000",
            "ToDate": "2025-06-01T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T20:15:00.000",
            "ToDate": "2025-06-01T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T20:30:00.000",
            "ToDate": "2025-06-01T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T20:45:00.000",
            "ToDate": "2025-06-01T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T21:00:00.000",
            "ToDate": "2025-06-01T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T21:15:00.000",
            "ToDate": "2025-06-01T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T21:30:00.000",
            "ToDate": "2025-06-01T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T21:45:00.000",
            "ToDate": "2025-06-01T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T22:00:00.000",
            "ToDate": "2025-06-01T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T22:15:00.000",
            "ToDate": "2025-06-01T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T22:30:00.000",
            "ToDate": "2025-06-01T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T22:45:00.000",
            "ToDate": "2025-06-01T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T23:00:00.000",
            "ToDate": "2025-06-01T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-01T23:15:00.000",
            "ToDate": "2025-06-01T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T18:00:00.000",
            "ToDate": "2025-06-02T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T18:15:00.000",
            "ToDate": "2025-06-02T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T18:30:00.000",
            "ToDate": "2025-06-02T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T18:45:00.000",
            "ToDate": "2025-06-02T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T19:00:00.000",
            "ToDate": "2025-06-02T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T19:15:00.000",
            "ToDate": "2025-06-02T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T19:30:00.000",
            "ToDate": "2025-06-02T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T19:45:00.000",
            "ToDate": "2025-06-02T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T20:00:00.000",
            "ToDate": "2025-06-02T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T20:15:00.000",
            "ToDate": "2025-06-02T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T20:30:00.000",
            "ToDate": "2025-06-02T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T20:45:00.000",
            "ToDate": "2025-06-02T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T21:00:00.000",
            "ToDate": "2025-06-02T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T21:15:00.000",
            "ToDate": "2025-06-02T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T21:30:00.000",
            "ToDate": "2025-06-02T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T21:45:00.000",
            "ToDate": "2025-06-02T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T22:00:00.000",
            "ToDate": "2025-06-02T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T22:15:00.000",
            "ToDate": "2025-06-02T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T22:30:00.000",
            "ToDate": "2025-06-02T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T22:45:00.000",
            "ToDate": "2025-06-02T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T23:00:00.000",
            "ToDate": "2025-06-02T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-02T23:15:00.000",
            "ToDate": "2025-06-02T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T18:00:00.000",
            "ToDate": "2025-06-03T18:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T18:15:00.000",
            "ToDate": "2025-06-03T18:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T18:30:00.000",
            "ToDate": "2025-06-03T18:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T18:45:00.000",
            "ToDate": "2025-06-03T19:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T19:00:00.000",
            "ToDate": "2025-06-03T19:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T19:15:00.000",
            "ToDate": "2025-06-03T19:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T19:30:00.000",
            "ToDate": "2025-06-03T19:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T19:45:00.000",
            "ToDate": "2025-06-03T20:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T20:00:00.000",
            "ToDate": "2025-06-03T20:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T20:15:00.000",
            "ToDate": "2025-06-03T20:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T20:30:00.000",
            "ToDate": "2025-06-03T20:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T20:45:00.000",
            "ToDate": "2025-06-03T21:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T21:00:00.000",
            "ToDate": "2025-06-03T21:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T21:15:00.000",
            "ToDate": "2025-06-03T21:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T21:30:00.000",
            "ToDate": "2025-06-03T21:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T21:45:00.000",
            "ToDate": "2025-06-03T22:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T22:00:00.000",
            "ToDate": "2025-06-03T22:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T22:15:00.000",
            "ToDate": "2025-06-03T22:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T22:30:00.000",
            "ToDate": "2025-06-03T22:45:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T22:45:00.000",
            "ToDate": "2025-06-03T23:00:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T23:00:00.000",
            "ToDate": "2025-06-03T23:15:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        },
        {
            "FromDate": "2025-06-03T23:15:00.000",
            "ToDate": "2025-06-03T23:30:00.000",
            "TotalTables": 1,
            "TotalSeats": 5,
            "ReservedTables": 0,
            "ReservedSeats": 0
        }
    ]
}
