import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../../context/AppContext';
import { Check, Edit, Plus, Search, X } from 'lucide-react';
import { StyleSubCategoryModal } from '../Components/Modal/StyleSubCategoryModal';
import { useTranslation } from 'react-i18next';

export const StyleSubCategory = () => {
 const [isStyleSubCategoryModalOpen, setIsStyleSubCategoryModalOpen] = useState(false);
 const [modalTitle, setModalTitle] = useState("");
 const [state, dispatch] = useContext(AppContext);
 const [styleSubCategories, setStyleSubCategories] = useState([]);
 const [type, setType] = useState("");
 const [styleSubCategory, setStyleSubCategory] = useState(null);
 const [searchTerm, setSearchTerm] = useState('');
 const [t] = useTranslation("global");

 useEffect(() => {
   setStyleSubCategories(state.adminInitialData?.styleSubCategories)
 }, [state.adminInitialData])

 // Filtrar os styleSubCategories baseado no termo de busca
 const filteredStyleSubCategories = styleSubCategories?.filter(style =>
   style.description.toLowerCase().includes(searchTerm.toLowerCase()) ||
   style.styleSubCategoryCode.toLowerCase().includes(searchTerm.toLowerCase()) ||
   style.id.toString().includes(searchTerm)
 );

 return (
   <div className="p-6">
     <div className="flex justify-between items-center mb-4">
       <h2 className="host-title !text-xl">{`${t(`Admin.AdminPanel.categories`)}`}</h2>
       <div className="flex space-x-4">
         <div className="relative">
           <input
             type="text"
             value={searchTerm}
             onChange={(e) => setSearchTerm(e.target.value)}
             placeholder={`${t(`Admin.styleSubCategory.searchSubCategory`)}`}
             className="!pl-8 pr-4 py-2 host-search-input"
           />
           <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1.5" />
           {searchTerm && (
             <button
               onClick={() => setSearchTerm('')}
               className="absolute right-3 top-1.5"
             >
               <X className="w-4 h-4 text-gray-400 hover:text-gray-600" />
             </button>
           )}
         </div>
         <button
           className="flex items-center host-button-primary"
           onClick={() => {
             setModalTitle(`${t(`Admin.styleSubCategory.newSubCategory`)}`);
             setType("add");
             setIsStyleSubCategoryModalOpen(true)
           }}
         >
           <Plus className="w-5 h-5" />
         </button>
       </div>
     </div>

     <div className="overflow-x-auto">
       <table className="min-w-full divide-y divide-gray-200 host-grid border">
         <thead className="bg-gray-50">
           <tr>
             <th className="px-6 py-3 text-left uppercase tracking-wider">
               {`${t(`Admin.styleSubCategory.id`)}`}
             </th>
             <th className="px-6 py-3 text-left uppercase tracking-wider">
               {`${t(`Admin.styleSubCategory.code`)}`}
             </th>
             <th className="px-6 py-3 text-left uppercase tracking-wider">
               {`${t(`Admin.styleSubCategory.description`)}`}
             </th>
             <th className="px-6 py-3 text-center uppercase tracking-wider">
               {`${t(`Admin.styleSubCategory.active`)}`}
             </th>
             <th className="px-6 py-3 text-center uppercase tracking-wider">
               {`${t(`Admin.styleSubCategory.actions`)}`}
             </th>
           </tr>
         </thead>
         <tbody className="bg-white divide-y divide-gray-200">
           {filteredStyleSubCategories?.length > 0 ?
             filteredStyleSubCategories.map((style, index) => (
               <tr key={index} className="hover:bg-gray-50">
                 <td className="px-6 py-4 whitespace-nowrap">{style.id}</td>
                 <td className="px-6 py-4 whitespace-nowrap">{style.styleSubCategoryCode}</td>
                 <td className="px-6 py-4 whitespace-nowrap">{style.description}</td>
                 <td className="px-6 py-4 whitespace-nowrap">
                   <div className="w-8 h-8 rounded-md flex items-center mx-auto justify-center bg-white border-2 border-gray-200">
                     {style.isActive ? (
                       <Check className="w-5 h-5 text-green-500" />
                     ) : (
                       <X className="w-5 h-5 text-red-500" />
                     )}
                   </div>
                 </td>
                 <td className="align-middle h-full">
                   <div className="flex items-center justify-center">
                     <button
                       onClick={() => {
                         setModalTitle(`${t(`Admin.styleSubCategory.editSubCategory`)}`);
                         setIsStyleSubCategoryModalOpen(true);
                         setType("edit");
                         setStyleSubCategory(style)
                       }}
                       className="text-blue-600 hover:text-blue-900"
                     >
                       <Edit className="w-4 h-4" />
                     </button>
                   </div>
                 </td>
               </tr>
             ))
             :
             <tr>
               <td colSpan={5} className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-center">
                 {searchTerm ?
                   `${t(`Admin.styleSubCategory.notfound`)}` :
                   `${t(`Admin.styleSubCategory.notfound`)}`
                 }
               </td>
             </tr>
           }
         </tbody>
       </table>
     </div>

     {type && (
       <StyleSubCategoryModal
         isStyleSubCategoryModalOpen={isStyleSubCategoryModalOpen}
         onCloseStyleSubCategoryModal={() => setIsStyleSubCategoryModalOpen(false)}
         title={modalTitle}
         type={type}
         styleSubCategory={type === "add" ? null : styleSubCategory}
       />
     )}
   </div>
 );
};