import { getAdminInitialData } from "../../../services";

export const refreshData = async ({ setIsSending, dispatch, state, forceRefresh = false }) => {
  if(state){
  try {
    setIsSending(true);
    const response = await getAdminInitialData();

    // Obtém o estado atual
    const currentState = state.adminInitialData;

    // Se os dados forem iguais, não faz o dispatch
    if (JSON.stringify(currentState) === JSON.stringify(response) && !forceRefresh) {
      return;
    }

    dispatch({ type: "SET_ADMIN_INITIALDATA", payload: response });
  } catch (err) {
    console.error("Erro ao buscar dados:", err);
  } finally {
    setIsSending(false);
  }
}
};