// hooks/useValueOptions.js
import { useContext, useMemo } from 'react';
import { AppContext } from '../../../../context/AppContext';

export const useValueOptions = () => {
  const [state] = useContext(AppContext);

  const VALUE_OPTIONS = useMemo(() => ({
    'active_theme_id': state.hotelPanelInitialData.themeDescriptions.map(theme => ({
      value: theme.id.toString(),
      label: theme.description
    }))
    
  }), [state.themes]);

  return VALUE_OPTIONS;
};