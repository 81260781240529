import { useContext, useState, useEffect, useMemo } from "react";
import { AppContext } from "../../context/AppContext";
import { useCookies } from "react-cookie";
import { getOutlet } from "../../services";
import { toast } from "react-toastify";
import { useParams, Link, useLocation } from "react-router-dom";
import restauranteexample from "../../assets/images/restauranteexample.jpg";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useTranslation } from "react-i18next";
import { Loader } from "../../components/Layout/components/Loader/Loader";

/**
 * @module TableReservationMenu
 * @description This component displays a menu of available services for table reservations.
 * It retrieves the services based on the route parameters and manages the state for
 * storing service information. It also includes navigation components like Navbar,
 * Sidebar, and Footer for a complete user interface.
 *
 * @returns {JSX.Element} The table reservation menu component.
 *
 * @example
 * <TableReservationMenu />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const TableReservationMenu = () => {
  const [state, dispatch] = useContext(AppContext);
  const { keys, sUid, detailId } = useParams();
  const [services, setServices] = useState([]);
  const [cookie, setCookie] = useCookies([ "sUid", "currentHotel", "detailId", "platformUid", ]); // eslint-disable-line
  const [withoutReservation, setWithoutReservation] = useState(false);
  const location = useLocation();
  const [t] = useTranslation("global"); // Function for text translation
  const [isLoading, setIsLoading] = useState(true)
  const [serverTime, setServerTime] = useState();
  const { pathname } = useLocation();
  const isPreviewMode = pathname.includes("/previewmode/");
  const [showSchedules, setShowSchedules] = useState({});


  const handleMouseEnter = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hover",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'Hover on specific hoverable element'
      }, "*");
    }
  };

  const handleMouseLeave = (e, elementRef) => {

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hoverOut",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'HoverOut from specific hoverable element'
      }, "*");
    }
  };



    // Function to update server time
    const updateServerTime = (initialTime) => {
      const currentServerTime = new Date(initialTime);
      currentServerTime.setMinutes(currentServerTime.getMinutes() + 1);
      setServerTime(currentServerTime.toISOString());
    };

  const toggleSchedule = (serviceId) => {
    setShowSchedules((prev) => ({
      ...prev,
      [serviceId]: !prev[serviceId],
    }));
  };

  const getDayName = useMemo(() => {
    const currentDay = new Date().getDay();
    const days = [
      t(`sunday`),
      t(`monday`),
      t(`tuesday`),
      t(`wednesday`),
      t(`thursday`),
      t(`friday`),
      t(`saturday`),
    ];
    const reorderedDays = [
      ...days.slice(currentDay),
      ...days.slice(0, currentDay)
    ];

    return (day) => reorderedDays[day];
  }, [localStorage.getItem("userLanguage")])

  const formatTime = (timeString) => {
    return new Date(timeString).toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const isRestaurantOpen = (schedules) => {
    const now = new Date(serverTime);
    const currentDay = now.getDay();
    const currentTime = now.toLocaleTimeString(localStorage.getItem("userLanguage"), { hour12: false });

    return schedules.some((schedule) => {
      if (schedule.Day !== currentDay) return false;
      const fromTime = new Date(schedule.FromHour).toLocaleTimeString(localStorage.getItem("userLanguage"), { hour12: false, });
      const toTime = new Date(schedule.ToHour).toLocaleTimeString(localStorage.getItem("userLanguage"), { hour12: false, });
      return currentTime >= fromTime && currentTime <= toTime;
    });
  };

  const getCurrentDaySchedule = (schedules) => {
    const currentDay = new Date().getDay();
    return schedules
      .filter((schedule) => schedule.Day === currentDay)
      .map(
        (schedule) =>
          `${formatTime(schedule.FromHour)} - ${formatTime(schedule.ToHour)}`
      )
      .join(", ");
  };

  const groupSchedulesByDay = (schedules) => {
    const grouped = {};
    schedules.forEach((schedule) => {
      if (!grouped[schedule.Day]) {
        grouped[schedule.Day] = [];
      }
      grouped[schedule.Day].push(
        `${formatTime(schedule.FromHour)} - ${formatTime(schedule.ToHour)}`
      );
    });
    return grouped;
  };

  const sortDays = (groupedSchedules) => {
    const currentDay = new Date().getDay();
    const daysOrder = [0, 1, 2, 3, 4, 5, 6]; // Sunday to Saturday

    // Reorder days starting from the current day
    const reorderedDays = [
      ...daysOrder.slice(currentDay),
      ...daysOrder.slice(0, currentDay),
    ];

    // Create a new object with the reordered days
    const sortedSchedules = {};
    reorderedDays.forEach((day) => {
      const dayKey = day.toString(); // Convert to string to match groupedSchedules keys
      if (groupedSchedules.hasOwnProperty(dayKey)) {
        sortedSchedules[dayKey] = groupedSchedules[dayKey];
      }
    });

    return sortedSchedules;
  };

    // Effect to update server time every minute
    useEffect(() => {
      if (!serverTime) return;
      const interval = setInterval(() => {
        updateServerTime(serverTime);
      }, 60000); // Update every minute
      return () => clearInterval(interval);
    }, [serverTime]);

  /**
   * @function useEffectSetSUid
   * @description A React effect hook that runs when the component mounts or when the `sUid` value changes.
   * This hook checks if the `sUid` parameter is available and sets it as a cookie named "sUid".
   * The cookie is accessible throughout the entire site (indicated by `path: "/"`).
   *
   * @param {string} sUid - The unique identifier for the user session or specific user.
   *
   * @effect This effect will update the cookie whenever the `sUid` changes.
   * It ensures that the current session identifier is stored for reference in future requests.
   *
   * @dependencies [sUid] - The effect depends on `sUid`, meaning it will run whenever `sUid` changes.
   *
   * @example
   * // Example usage of sUid in useEffect
   * useEffect(() => {
   *   if (sUid) {
   *     setCookie("sUid", sUid, { path: "/" });
   *   }
   * }, [sUid]);
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  useEffect(() => {
    if (sUid) {
      setCookie("sUid", sUid, { path: "/" });
    }
  }, [sUid]);

  /**
   * @function useEffectSetService
   * @description This effect runs when the component mounts and retrieves outlet information
   * based on the `keys` parameter from the URL. It fetches the services available for table
   * reservations using the `getOutlet` service function and updates the local state with
   * the fetched service data. In case of an error, it logs the error to the console and
   * displays a toast notification to the user.
   *
   * @dependencies {string} keys - The keys extracted from the URL parameters that are
   * required to fetch the outlet information.
   * @dependencies {object} state.currentHotel - The current hotel identifier from cookies
   * used to specify the hotel context for the outlet fetch request.
   *
   * @returns {void}
   *
   * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
   * @version 1.0.0
   */
  useEffect(() => {
    const fetchOutletData = async () => {
      if (!keys || !state.currentHotel) return;

      try {
        setIsLoading(true)
        const data = await getOutlet(keys.split("-"), state.currentHotel);
        setServices(data.outletInfo);
        setServerTime(data.ServerTime);
      } catch (error) {
        console.error(
          "TableReservation: Error fetching service info:",
          error
        );
        toast.error("TableReservation: Unable to get service info!");
      } finally {
        setIsLoading(false)
      }
    };

    fetchOutletData();
  }, [keys, state.currentHotel]);

  useEffect(() => {
    // Verifica se o pathname começa com '/overview'
    if (location.pathname.includes("/treservationmenudirect")) {
      setWithoutReservation(true);
    }
  }, []);


  if (isLoading) {
    return <div className="flex justify-center h-screen dark:text-white dark:bg-gxp_dark_2"><Loader className={"w-20 h-20 mt-20"}/></div>;
  }

  if (!withoutReservation) {
    return (
      <>
        {services && cookie.platformUid && cookie.detailId && cookie.sUid && state.currentHotel.subscriptionKey && (
            <div className={`antialiased dark:bg-gxp_dark_2 flex w-full justify-center`}  >
                <section className="p-1 flex flex-col mx-auto sm:w-1/2 mb-2">
                  {services.map((service) => {
                    const isOpen = isRestaurantOpen(service.schedules);
                    const currentDaySchedule = getCurrentDaySchedule( service.schedules );
                    const groupedSchedules = groupSchedulesByDay( service.schedules );
                    const sortedGroupedSchedules = sortDays(groupedSchedules);

                    return (
                      <div key={service.Id} className="mt-3 bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-md" >
                        <Link to={`/hotel/${cookie.platformUid}/${detailId}/treservation/${cookie.sUid}/${service.Code}`} className="relative block" >
                          <div className="w-full h-48 bg-cover bg-center" style={{ backgroundImage: `url(${ service.logoUrl || restauranteexample })`, }} alt={service.Name} />
                          <div className="absolute bottom-0 left-0 w-full bg-white dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 p-3">
                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
                              {service.Name}
                            </h2>
                          </div>
                        </Link>

                        {service.schedules.length > 0 && (
                        <div className="p-3">
                          <div className="flex items-center justify-between">
                            <div className="flex justify-start">
                              <span className="font-medium text-gray-700 dark:text-gray-300">
                                {getDayName(0)}
                              </span>
                              <span className="text-gray-700 dark:text-gray-300 ml-3">
                                {currentDaySchedule}
                              </span>
                            </div>
                            <div className="flex items-center justify-end">
                              <div className={`w-3 h-3 rounded-full ${ isOpen ? "bg-green-500" : "bg-red-500" } mr-2`} ></div>
                              <span className={`text-sm ${ isOpen ? "text-green-500" : "text-red-500" }`} >
                              </span>
                              <div />
                              <button onClick={() => toggleSchedule(service.Id)} className="flex ml-2 items-center text-right text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-200" >
                                {showSchedules[service.Id] ? (
                                  <ChevronUp size={20} />
                                ) : (
                                  <ChevronDown size={20} />
                                )}
                              </button>
                            </div>
                          </div>

                          {service.schedules && service.schedules.length > 0 && (
                              <div className="mt-2">
                                <div className={`mt-2 overflow-hidden transition-all duration-300 ease-in-out ${ showSchedules[service.Id] ? "max-h-96" : "max-h-0" }`} >
                                {Object.entries(sortedGroupedSchedules).map(
                              ([day, times], index) => (
                                <div
                                  key={day}
                                  className={`py-1 text-sm text-gray-600 dark:text-gray-400 ${index === 0 ? "font-bold" : ""}`}
                                >
                                  <span className="font-medium">
                                    {getDayName(index)}:
                                  </span>{" "}
                                  {times.join(", ")}
                                </div>
                              )
                            )}
                                </div>
                              </div>
                            )}
                        </div>
                        )}
                      </div>
                    );
                  })}
                </section>
            </div>
          )}
      </>
    );
  }

  if (withoutReservation) {
    return (
      <>
       {services && cookie.platformUid && detailId && cookie.sUid && state.currentHotel.subscriptionKey && (
            <div className={`antialiased dark:bg-gxp_dark_2`} >
              <div className="h-auto flex flex-col min-h-screen sm:items-center">
                <section className="flex-grow p-1 sm:w-1/2">
                  {services.map((service) => {
                    const isOpen = isRestaurantOpen(service.schedules);
                    const currentDaySchedule = getCurrentDaySchedule( service.schedules );
                    const groupedSchedules = groupSchedulesByDay( service.schedules );
                    const sortedGroupedSchedules = sortDays(groupedSchedules);


                    return (
                      <div key={service.Id} className="mt-3 bg-white dark:bg-gray-800 rounded-lg overflow-hidden shadow-md" >
                        <Link to={`/hotel/${cookie.platformUid}/${cookie.detailId}/treservation/${cookie.sUid}/${service.Code}`} className="relative block" >
                          <div className="w-full h-48 bg-cover bg-center" style={{ backgroundImage: `url(${ service.logoUrl || restauranteexample })`, }} alt={service.Name} />
                          <div className="absolute bottom-0 left-0 w-full bg-white dark:bg-gray-900 bg-opacity-75 dark:bg-opacity-75 p-3">
                            <h2 className="text-2xl font-semibold text-gray-800 dark:text-white">
                              {service.Name}
                            </h2>
                          </div>
                        </Link>

                        {service.schedules.length > 0 && (
                          <div className="p-3">
                            <div className="flex items-center justify-between">
                              <div className="flex justify-start">
                                <span className="font-medium text-gray-700 dark:text-gray-300">
                                  {getDayName(0)}
                                </span>
                                <span className="text-gray-700 dark:text-gray-300 ml-3">
                                  {currentDaySchedule}
                                </span>
                              </div>
                              <div className="flex items-center justify-end">
                                <div className={`w-3 h-3 rounded-full ${ isOpen ? "bg-green-500" : "bg-red-500" } mr-2`} ></div>
                                <span className={`text-sm ${ isOpen ? "text-green-500" : "text-red-500" }`} >
                                  {isOpen ? "Open" : "Closed"}
                                </span>
                                <div />
                                <button onClick={() => toggleSchedule(service.Id)} className="flex ml-2 items-center text-right text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white transition-colors duration-200" >
                                  {showSchedules[service.Id] ? (
                                    <ChevronUp size={20} />
                                  ) : (
                                    <ChevronDown size={20} />
                                  )}
                                </button>
                              </div>
                            </div>

                          {service.schedules && service.schedules.length > 0 && (
                              <div className="mt-2">
                                <div className={`mt-2 overflow-hidden transition-all duration-300 ease-in-out ${ showSchedules[service.Id] ? "max-h-96" : "max-h-0" }`} >
                                {Object.entries(sortedGroupedSchedules).map(
                              ([day, times], index) => (
                                <div
                                  key={day}
                                  className={`py-1 text-sm text-gray-600 dark:text-gray-400 ${index === 0 ? "font-bold" : ""}`}
                                >
                                  <span className="font-medium">
                                    {getDayName(index)}:
                                  </span>{" "}
                                  {times.join(", ")}
                                </div>
                              )
                            )}
                                </div>
                              </div>
                            )}
                        </div>
                        )}
                      </div>
                    );
                  })}
                </section>
              </div>
            </div>
          )}
      </>
    );
  }
};
