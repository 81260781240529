import { useContext, useEffect, useState } from "react";
import { CalendarArrowUp, CalendarArrowDown, Clock, Users, Wifi, Tv, Coffee, Utensils, Snowflake, CreditCard, Euro, Wallet, BedDouble, Package, ListCollapse} from "lucide-react";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { getHostCheckoutInfo, getReservationByDetailId } from "../../services";
import { toast } from "react-toastify";
import { Slider } from "../../components/Layout/components/Slider/Slider";
import { Loader } from "../../components/Layout/components/Loader/Loader";
import { AppContext } from "../../context/AppContext";

import dayjs from "dayjs";

/**
* @module HotelReservationDetails
* @description Core module for displaying detailed hotel reservation information.
* Handles reservation data, checkout details, and amenity display.
*/

/**
* @namespace HotelReservationDetails
* @memberof module:HotelReservationDetails
* @description Component responsible for rendering comprehensive reservation details.
* Manages data fetching, balance calculations, and visual presentation.
*
* @component
* @returns {JSX.Element} HotelReservationDetails component
*
* @example
* <HotelReservationDetails />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const HotelReservationDetails = () => {

  const reservations = {
    hotelName: "Grand Luxe Hotel",
    roomType: "Deluxe Ocean View Suite",
    checkIn: "2023-07-15",
    checkOut: "2023-07-20",
    guests: 2,
    price: "$1,500",
    images: [
      {src:"https://images.unsplash.com/photo-1549638441-b787d2e11f14?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image1"},
      {src:"https://plus.unsplash.com/premium_photo-1661964402307-02267d1423f5?q=80&w=1973&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image2"},
      {src:"https://images.unsplash.com/photo-1621891334481-5c14b369d9d7?q=80&w=1771&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image3"},
      {src:"https://images.unsplash.com/photo-1559841771-599b6eeaca62?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image4"},
      {src:"https://plus.unsplash.com/premium_photo-1689609949898-5f7a10649fef?q=80&w=1770&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D", alt: "image5"},
    ],
    amenities: [
      { name: "Free Wi-Fi", icon: Wifi },
      { name: "Flat-screen TV", icon: Tv },
      { name: "Coffee maker", icon: Coffee },
      { name: "Mini-bar", icon: Utensils },
      { name: "Air conditioning", icon: Snowflake },
    ],
    description:
      "Experience luxury and comfort in our Deluxe Ocean View Suite. This spacious room offers breathtaking views of the ocean, a king-size bed, and a private balcony. Enjoy modern amenities and personalized service for an unforgettable stay.",
  };
  const {detailId } = useParams();
  const [t] = useTranslation("global");
  const [loadingReservationDetails, setLoadingReservationDetails]= useState(true)
  const [loadingReservationBalance, setLoadingReservationBalance]= useState(true)
  const [state, dispatch] = useContext(AppContext); // eslint-disable-line
  const [reservation, setReservation] = useState(state.reservation)
  const [cookie] = useCookies(['sUid','currentHotel']);
  const [checkoutData, setCheckoutData] = useState();
  const [prevision, setPrevision] = useState()
  const [charges, setCharges] = useState()
  const [paid, setPaid] = useState()

  /**
  * @function fetchReservations
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Fetches reservation details if not already present.
  * Updates both local and global state with fetched data.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchReservations()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchReservations = async () => {
    try {
      if(!reservation){
        setLoadingReservationDetails(true);
        const result = await getReservationByDetailId(detailId, state.currentHotel, cookie.sUid);
        setReservation(result[0]);
        dispatch({ type: "SET_USER_RESERVATION", payload:result[0] });
      }
    } catch (error) {
      console.log(error)
      toast.error("Unable to get reservations!");
    } finally {
      setLoadingReservationDetails(false);
    }
  }

  /**
  * @function fetchCheckoutInfo
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Fetches checkout information for active reservations.
  * Updates checkout data state and handles error cases.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchCheckoutInfo()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchCheckoutInfo = async () => {
    if(reservation?.GlobalResStatus===10){
      try {
        setLoadingReservationBalance(true);
        const result = await getHostCheckoutInfo(cookie.sUid, detailId, state.currentHotel);
        setCheckoutData(result.data);
      } catch (error) {
        console.log(error)
        toast.error("Unable to get Checkout Info!");
      } finally {
        setLoadingReservationBalance(false);
      }
    }
  }

  /**
  * @function useEffectFetchReservations
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Effect that triggers reservation data fetching.
  *
  * @effect Fetches reservation data when dependencies change
  * @dependencies [state.currentHotel, cookie.profileKey]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if(state.currentHotel && cookie.profileKey){
    fetchReservations();
    }
  }, [state.currentHotel, cookie.profileKey]); // eslint-disable-line

   /**
  * @function useEffectFetchCheckout
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Effect that manages checkout info fetching.
  *
  * @effect Fetches checkout information when dependencies change
  * @dependencies [detailId, reservation]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if(detailId && cookie.sUid && state.currentHotel){
      fetchCheckoutInfo();
    }
  }, [detailId, reservation]); // eslint-disable-line

   /**
  * @function useEffectCalculateBalances
  * @memberof module:HotelReservationDetails.HotelReservationDetails
  * @description Effect that calculates various balance totals.
  * Handles prevision, charges, and paid amounts calculations.
  *
  * @effect Updates balance states based on checkout data
  * @dependencies [checkoutData]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (checkoutData && checkoutData.Lines) {
      const total = checkoutData.Lines.reduce((total, line) => {
        if (line.RecordType === 2) {
          return total + (line.Price);
        }
        return total;
      }, 0);
      setPrevision(total);
    }

    if (checkoutData && checkoutData.Lines) {
      const total = checkoutData.Lines.reduce((total, line) => {
        if (line.RecordType === 0 && line.IsPayment===false && line.Price>0) {
          return total + (line.Price);
        }
        return total;
      }, 0);

      setCharges(total);
    }

    if (checkoutData && checkoutData.Lines) {
      const total = checkoutData.Lines.reduce((total, line) => {
        if (line.RecordType === 0 && line.Price<0) {
          return total + (line.Price);
        }
        return total;
      }, 0);

      setPaid(total);
    }
  }, [checkoutData]); // Recalcula sempre que checkoutData mudar

  return (
    (reservation && state.currentHotel &&
    <div className=" min-h-screen flex flex-col md:pt-4 md:px-4">
      <main className="flex-grow overflow-y-auto">
        <div className="max-w-4xl sm:mx-auto p-4 m-2 bg-white dark:bg-gxp_dark_3 dark:text-white dark:border-gxp_dark_1 border shadow-lg rounded-lg">
          <h1 className="text-3xl font-bold mb-2">{state.currentHotel.name2}</h1>
          <h2 className="text-xl font-bold mb-2"><span className="font-bold mr-2">{`${t("reservation")}`}</span>{reservation.resno}</h2>
          <div className="flex flex-wrap mb-6">
            {loadingReservationDetails ?
                 <div className="w-full flex flex-col items-center justify-center py-3">
                 <Loader className={`w-10 h-10`} />
               </div>
               :
            <>
            <div className="w-full md:w-1/2 mb-2 md:mb-0">
              <p className="flex items-center mb-2"> <CalendarArrowUp className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" /> <span className="font-bold mr-2">{`${t("HotelReservationDetails.checkin")}`}</span> {dayjs(reservation.checkin).format(t("dayjs_format")) } </p>
              <p className="flex items-center mb-2"> <CalendarArrowDown className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" /> <span className="font-bold mr-2">{`${t("HotelReservationDetails.checkout")}`}</span> {dayjs(reservation.checkout).format(t("dayjs_format")) } </p>
              <p className="flex items-center mb-2"> <Clock className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" /> {dayjs(reservation.checkout).diff(dayjs(reservation.checkin), 'days')} {`${t("HotelReservationDetails.nights")}`} </p>
            </div>
            <div className="w-full md:w-1/2 max-md:pt-4 mb-2 max-md:border-t">
              <p className="flex items-center mb-2"> <Users className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" /> <span className="font-bold mr-2">{`${t("HotelReservationDetails.guests")}`}</span> {reservation.pax + reservation.children + reservation.children2 + reservation.children3} ({reservation.pax} {`${t("HotelReservationDetails.adults")}`},{" "} {reservation.children + reservation.children2 + reservation.children3}{" "} {`${t("HotelReservationDetails.children")}`}) </p>
              <p className="flex items-center mb-2"> <BedDouble className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" /> <span className="font-bold mr-2">{`${t("Checkout.room")}`}</span> {reservation.room || 'Not assigned'} / {reservation.categoryDescription} </p>
              <p className="flex items-center mb-2"> <Package className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" /><span className="font-bold mr-2">{`${t("HotelReservationDetails.pack")}`}</span> {reservation.packDescription} </p>
            </div>
            </>}
            {reservation?.GlobalResStatus===10 ?
            loadingReservationBalance ?
              <div className="w-full flex flex-col items-center justify-center py-3">
                <Loader className={`w-10 h-10`} />
              </div>
            :
              <>
                <div className="w-full border-t pt-4">
                  <div className="flex justify-between items-center mb-2">
                    <p className="flex items-center"> <Euro className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" />{`${t("HotelReservationDetails.rates")}`}</p>
                    <div className="text-right">
                      <span>{(parseFloat(prevision)).toFixed(2)}</span><span className="ml-2">{checkoutData?.CurrencySymbol ?checkoutData?.CurrencySymbol : "€" }</span>
                    </div>
                  </div>


                  <div className="flex justify-between items-center mb-2">
                    <p className="flex items-center"> <Wallet className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" />{`${t("HotelReservationDetails.charges")}`}</p>
                    <div className="text-right">
                      <span>{(parseFloat(charges)).toFixed(2)}</span><span className="ml-2">{checkoutData?.CurrencySymbol ?checkoutData?.CurrencySymbol : "€" }</span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center mb-2">
                    <p className="flex items-center"> <CreditCard className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" />{`${t("HotelReservationDetails.paid")}`}</p>
                    <div className="text-right">
                      <span>{(parseFloat(paid)).toFixed(2)}</span><span className="ml-2">{checkoutData?.CurrencySymbol ?checkoutData?.CurrencySymbol : "€" }</span>
                    </div>
                  </div>

                  <div className="flex justify-between items-center font-bold border-t pt-2">
                    <p className="flex items-center"> <Euro className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" />{`${t("HotelReservationDetails.balance")}`}</p>
                    <div className="text-right">
                      <Link to={`/hotel/${cookie.platformUid}/${cookie.detailId}/checkout`} className="flex w-fit items-center bg-white dark:bg-gxp_dark_2  dark:text-white dark:border-gxp_dark_1 border rounded-lg shadow-md p-2 hover:bg-violet-100 hover:shadow-lg transition-shadow duration-300">
                        <ListCollapse className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" />
                        <span>{checkoutData?.Balance ? (parseFloat(checkoutData.Balance)).toFixed(2) : '0.00'}</span><span className="ml-2">{checkoutData?.CurrencySymbol ?checkoutData?.CurrencySymbol : "€" }</span>
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            :
              <div className="w-full border-t pt-4">
                <p className="dark:text-white">{t("HotelReservationDetails.checkinfirst")}</p>
              </div>
            }
          <div className="mb-6 overflow-x-hidden w-screen">
              <Slider data={reservations.images} />
          </div>
          <div className="flex flex-col w-full">
          <div className="mb-6">
            <h3 className="text-xl font-semibold mb-2">{`${t("HotelReservationDetails.roomdescription")}`}</h3>
            <p className="text-gray-700">{reservation.description}</p>
          </div>
          <div>
            <h3 className="text-xl font-semibold mb-2">{`${t("HotelReservationDetails.amenities")}`}</h3>
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4">
              {reservations.amenities.map((amenity, index) => (
                <div key={index} className="flex items-center">
                  <amenity.icon className="w-5 h-5 mr-2 text-gxp_violet dark:text-gxp_violet_dark" />
                  <span>{amenity.name}</span>
                </div>
              ))}
            </div>
          </div>
          </div>
        </div>
        </div>

      </main>
</div>
    )
  );
};