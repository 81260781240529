export const hostSelectCustomStyles = {
    // Estilos para o container principal do select
    control: (base) => ({
      ...base,
      minHeight: '30px',  // Reduz a altura mínima
      height: '30px',     // Define uma altura fixa menor
      color: "var(--text-color)",
      border: '1px solid var(--host-r24-border-color)',
      borderRadius: '0.375rem',
      cursor: 'pointer',
      boxShadow: 'none',
      transition: 'all 0.2s ease',
      '& .select__placeholder': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      '&:hover': {
        borderColor: '#3b82f6'
      }
    }),

    // Estilos para o menu dropdown
    menu: (base, state) => ({
        ...base,
        color: "var(--text-color)",
        animation: state.selectProps.menuIsOpen
          ? 'slideDown 0.3s ease-in-out'
          : 'slideUp 0.3s ease-in-out',
        borderRadius: '0.375rem',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
        // Animação de entrada do menu dropdown
        '@keyframes slideDown': {
          from: {
            opacity: 0,
            transform: 'translateY(-10px)'
          },
          to: {
            opacity: 1,
            transform: 'translateY(0)'
          }
        },
        // Animação de saída do menu dropdown
        '@keyframes slideUp': {
          from: {
            opacity: 1,
            transform: 'translateY(0)'
          },
          to: {
            opacity: 0,
            transform: 'translateY(-10px)'
          }
        }
      }),

    // Estilos para cada opção no dropdown
    option: (base, state) => ({
      ...base,
      // Cores de fundo baseadas no estado (selecionado, hover, padrão)
      backgroundColor: state.isSelected
        ? '#DDEBF6'
        : state.isFocused
          ? '#f3f4f6'
          : 'white',
      color: "var(--text-color)",
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
      padding: '0.5rem',
      transition: 'all 0.2s ease',
      cursor: 'pointer',
      borderRadius: '0.375rem',
      fontWeight: state.isSelected ? '600' : '400', // Negrito para item selecionado
      // Efeito hover
      '&:hover': {
        backgroundColor: state.isSelected ? '' : 'var(--host-r24-hover-color)'
      }
    }),

    // Estilos para a lista que contém as opções
    menuList: (base) => ({
      ...base,
      padding: '4px',
    }),

    // Estilos para o valor selecionado que aparece no select
    singleValue: (base) => ({
      ...base,
      color: "var(--text-color)",
      fontSize: '12px',
      fontFamily: 'Poppins, sans-serif',
    }),

    // Estilos para o indicador de dropdown (seta)
    dropdownIndicator: (base, state) => ({
      ...base,
      color: "var(--host-r24-border-color-3)", // Cor da seta
      transition: 'transform 0.2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      margin: '0px 6px',
      padding: '0px',
      display: 'flex',
      alignItems: 'center' // Alinha verticalmente // Rotação da seta quando aberto
    }),

    // Estilos para o container de indicadores (inclui a seta de dropdown)
    indicatorsContainer: (base) => ({
      ...base,
      color: "var(--host-r24-text-color)"

    }),

    // Estilos para o container do valor
    valueContainer: (base) => ({
      ...base,

    }),

    // Estilos para quando o select está com foco
    input: (base) => ({
      ...base,
      color: "var(--host-r24-text-color)",
    }),

    // Estilos para o placeholder
    placeholder: (base) => ({
      ...base,
      color: "var(--host-r24-text-color-secondary)",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }),
  };