export const usersRoles = [
    {
        id: 1,
        name: "Admin"
    },
    {
        id: 2,
        name: "Developer"
    },
    {
        id: 3,
        name: "Comercial"
    },
    {
        id: 4,
        name: "Pending"
    }
]

export const usersStatus = [
    {
        id: 1,
        name: "Pending"
    },
    {
        id: 2,
        name: "Active"
    },
    {
        id: 3,
        name: "Inactive"
    }
]
