import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import global_en_gb from "../../../translations/en_gb/global.json";
import global_pt_pt from "../../../translations/pt_pt/global.json";

export const formatLanguage = (language) => {
  if (!language) return 'en_US'; // Fallback explícito

  try {
    // Remove espaços e converte para maiúsculo
    let formatted = typeof language === 'string' ? language : JSON.parse(language);

    // Garante formato com underscore
    return formatted.replace('-', '_');
  } catch (error) {
    console.error('Error formatting language:', error);
    return 'en_US'; // Fallback em caso de erro
  }
};

const i18nextConfig = {
  interpolation: { escapeValue: true },
  fallbackLng: 'en_US',
  resources: {
    en_US: {
      global: global_en_gb,
    },
    en_GB: {
      global: global_en_gb,
    },
    pt_PT: {
      global: global_pt_pt,
    },
  },
  debug: false, // Adicione isso para ver logs
  detection: {
    order: ['localStorage', 'cookie'],
    lookupLocalStorage: 'userLanguage',
  }
};

i18next .use(initReactI18next) .init(i18nextConfig)

export const updateI18nLanguage = (newLanguage) => {
  const formattedLanguage = formatLanguage(newLanguage);

  if (i18next.options.resources[formattedLanguage]) {
    i18next.changeLanguage(formattedLanguage);
  } else {
    console.warn(`Language ${formattedLanguage} not found in resources`);
  }
};

export const configureI18n = () => i18next;