import { useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AppContext } from "../context/AppContext";
import { Loader } from "../components/Layout/components/Loader/Loader";
import { useTranslation } from "react-i18next";
import { getAdminInitialData, getHotelById, getHotelPanelInitialData } from "../services";

// Main Admin Panel Component
export const HotelPanel = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState("dashboard");
  const [expandedCategories, setExpandedCategories] = useState([""]);
  const [state, dispatch] = useContext(AppContext);
  const [t] = useTranslation("global");
  const { hotelId } = useParams();

  const fetchHotelPanelData = async ({ hotelId }) => {
    try {
      setLoading(true);
      const response = await getHotelPanelInitialData(hotelId);
      dispatch({ type: "SET_HOTELPANEL_INITIALDATA", payload: response });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const result = await response.json();
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchHotelPanelData({ hotelId });
  }, [state.globalRefresh, hotelId]);

  //escuta mensagens do pms para atualizar o state.pmsUserLoggedIn
  useEffect(() => {
    // console.log('Setting up message listener in iframe');

    const handleMessage = (event) => {
      // Ignorar mensagens do webpack
      if (event.data.type === "webpackWarnings") {
        return;
      }

      // console.log('Message received in iframe:', event.data);

      if (event.data.type === "UPDATE_USER") {
        const { user } = event.data.data;
        // console.log("Received user data:", user);
        dispatch({ type: "SET_PMS_USER_LOGGEDIN", payload: user });
      }
    };

    // Adicionar listener para mensagens
    window.addEventListener("message", handleMessage);

    // Informar ao componente pai que o iframe está pronto
    // console.log('Sending ready message to parent');
    window.parent.postMessage(
      {
        type: "IFRAME_READY",
        status: "ready",
      },
      "*"
    );

    return () => {
      // console.log('Cleaning up message listener in iframe');
      window.removeEventListener("message", handleMessage);
    };
  }, []);

    useEffect(() => {
    const fetchHotelData = async () => {
        try {
          setLoading(true);
          const hotelData = await getHotelById(hotelId);
          dispatch({ type: "SET_CURRENT_HOTEL", payload: hotelData });

        } catch (error) {
          console.error("App: Error fetching hotel info:", error);
        } finally {
          setLoading(false);
        }
    }
    fetchHotelData();
  }, [hotelId]); // eslint-disable-line

  return (
    <div className="flex h-screen flex-1 bg-host_one_bg overflow-clip">
      <div className="flex flex-col flex-1 overflow-hidden">
        {loading ? (
          <div className="flex justify-center items-center h-screen">
            <Loader />
          </div>
        ) : (
          <main className="flex-1 overflow-auto">
            <Outlet />
          </main>
        )}
      </div>
    </div>
  );
};
