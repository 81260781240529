import { Check, X, ChevronDown, ChevronRight, Edit3 } from "lucide-react";
import React, { useState, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { EditableCell } from "./EditableCell";
import { useParams } from "react-router-dom";
import { AppContext } from "../../../../context/AppContext";

export const ParametersTable = ({paramsToSave, setParamsToSave, parameterDescriptions, setParameterDescriptions, setType, setParameterDescription, showSections = true, }) => {
  const [t] = useTranslation("global");
  const [expandedSections, setExpandedSections] = useState({});
  const { hotelId } = useParams();
  const [state, dispatch] = useContext(AppContext);

  const handleChangeParameter = ({parameter, newValue}) => {
    const newParamObject = {
        id: parameter.id,
        groupId: "",
        hotelId: hotelId,
        paramId: parameter.paramDescriptionId,
        value: newValue,
        user: state?.pmsUserLoggedIn
    };

    setParamsToSave((prevParams) => {
        // Se id !== 0, procura pelo id
        if (parameter.id !== 0) {
            const paramIndex = prevParams.findIndex(p => p.id === parameter.id);
            if (paramIndex !== -1) {
                // Substitui o parâmetro existente
                const newParams = [...prevParams];
                newParams[paramIndex] = newParamObject;
                return newParams;
            }
        } else {
            // Se id === 0, procura pela combinação hotelId+paramId ou groupId+paramId
            const paramIndex = prevParams.findIndex(p => {
                if (hotelId) {
                    return p.hotelId === hotelId && p.paramId === parameter.paramDescriptionId;
                }
                if (parameter.groupId) {
                    return p.groupId === parameter.groupId && p.paramId === parameter.paramDescriptionId;
                }
                return false;
            });

            if (paramIndex !== -1) {
                // Substitui o parâmetro existente
                const newParams = [...prevParams];
                newParams[paramIndex] = newParamObject;
                return newParams;
            }
        }

        // Se não encontrou nenhum parâmetro para substituir, adiciona o novo
        return [...prevParams, newParamObject];
    });
 };

  const groupedParameters = useMemo(() => {
    if (!showSections) {
      return { Results: parameterDescriptions };
    }

    const groups = {
      General: [],
      "Online Check-In": [],
      "Table Reservation": [],
    };

    const sortedParameters = [...(parameterDescriptions || [])].sort(
      (a, b) => a.paramDescriptionId - b.paramDescriptionId
    );

    sortedParameters.forEach((parameter) => {
      if (parameter.paramCode.startsWith("oc_")) {
        groups["Online Check-In"].push(parameter);
      } else if (parameter.paramCode.startsWith("tr_")) {
        groups["Table Reservation"].push(parameter);
      } else {
        groups["General"].push(parameter);
      }
    });

    return Object.fromEntries(
      Object.entries(groups).filter(([_, parameters]) => parameters.length > 0)
    );
  }, [parameterDescriptions, showSections]);

  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  const sectionOrder = ["General", "Online Check-In", "Table Reservation"];
  const orderedSections = sectionOrder.filter(
    (section) => groupedParameters[section]?.length > 0
  );

  const renderParameterRow = (parameter, index) => (
    <tr key={index} className="hover:bg-gray-50">
      <td className="text-center whitespace-nowrap w-20">{parameter.paramDescriptionId}</td>
      <td className="whitespace-nowrap w-64">{parameter.paramCode}</td>
      <td className="whitespace-nowrap w-[500px]">
        {parameter.paramDescription}
      </td>
      <td className="text-center whitespace-nowrap w-60">
        <EditableCell
          parameter={parameter}
          onChange={handleChangeParameter}
        /></td>
      <td className="items-center whitespace-nowrap w-20">
        <div className="mx-auto w-8 h-8 rounded-md flex items-center justify-center bg-white border-2 border-gray-200">
          {parameter.isActive ? (
            <Check className="w-5 h-5 text-green-500" />
          ) : (
            <X className="w-5 h-5 text-red-500" />
          )}
        </div>
      </td>

    </tr>
  );

  return (
    <table className="min-w-full host-grid">
      <thead>
        <tr>
          <th className="first-letter:uppercase lowercase text-center tracking-wider w-20">
            {`${t(`Admin.parameters.id`)}`}
          </th>
          <th className="text-left tracking-wider w-64">
            {`${t(`Admin.parameters.code`)}`}
          </th>
          <th className="text-left tracking-wider w-[500px]">
            {`${t(`Admin.parameters.description`)}`}
          </th>
          <th className="text-center tracking-wider w-60">
            {`${t(`Admin.parameters.value`)}`}
          </th>
          <th className="text-center tracking-wider w-20">
            {`${t(`Admin.parameters.active`)}`}
          </th>
        </tr>
      </thead>
      <tbody>
        {!showSections
          ? // Renderiza os resultados da busca sem seções
            parameterDescriptions.map((parameter, index) =>
              renderParameterRow(parameter, index)
            )
          : // Renderiza com seções quando não está pesquisando

            orderedSections.map((category) => (
              <React.Fragment key={category}>
                <tr className="bg-gray-100">
                  <td colSpan={6} className="py-2 px-4">
                    <button
                      onClick={() => toggleSection(category)}
                      className="flex items-center gap-2 w-full text-left font-medium"
                    >
                      <div
                        className={` transform transition-transform duration-300 ${
                          expandedSections[category] ?  "rotate-0" :"-rotate-90"
                        }`}
                      >
                        <ChevronDown className=" w-4 h-4" />
                      </div>
                      {category}
                    </button>
                  </td>
                </tr>
                {expandedSections[category] &&
                  groupedParameters[category].map((parameter, index) =>
                    renderParameterRow(parameter, `${category}-${index}`)
                  )}
              </React.Fragment>
            ))}
        {!parameterDescriptions?.length && (
          <tr>
            <td colSpan={6} className="whitespace-nowrap text-center">
              {`${t(`Admin.parameters.notfound`)}`}
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
};
