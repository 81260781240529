const transformThemeData = (selectedTheme, adminInitialData) => {
  const { styleCategories, styleSubCategories, themeStyles, themes } = adminInitialData;

  // Filtra apenas os themeStyles ativos
  const activeThemeStyles = themeStyles.filter(style => style.isActive);

  // Filtra as linhas do tema selecionado
  const selectedThemeLines = themes.filter(
    line => line.themeDescriptionId === selectedTheme.id
  );

  // Organiza as categorias com as suas subcategorias
  const categories = styleCategories.map(category => {
    // Encontra todos os estilos para esta categoria
    const categoryStyles = activeThemeStyles.filter(
      style => style.styleCategoryId === category.id
    );

    // Organiza as subcategorias para esta categoria
    const subCategories = categoryStyles.map(style => {
      // Encontra a descrição da subcategoria
      const subCategoryInfo = styleSubCategories.find(
        sub => sub.id === style.styleSubCategoryId
      );

      const selectedThemeStyle = activeThemeStyles.find(
        ts => ts.styleCategoryId === category.id &&
             ts.styleSubCategoryId === subCategoryInfo.id
      );

      // Encontra se existe uma linha customizada para este estilo
      const customLine = selectedThemeLines.find(
        line => line.themeStyleId === selectedThemeStyle.id
      );

      // Define o valor baseado na lógica de comparação
      let finalValue = selectedThemeStyle.valueDefault;
      if (customLine && customLine.value !== selectedThemeStyle.valueDefault) {
        finalValue = customLine.value;
      }

      return {
        id: style.id,
        description: subCategoryInfo?.description || '',
        valueType: style.valueType,
        value: finalValue,
        valueList: style.valueList || [],
        styleSubCategoryCode: subCategoryInfo?.styleSubCategoryCode,
        themeStyleId: selectedThemeStyle.id,
        styleCategoryId: category.id,
      };
    });

    return {
      id: category.id,
      description: category.description,
      styleCategoryCode: category.styleCategoryCode,
      subCategories: subCategories
    };
  });

  return categories;
};

export const formatCategories = (selectedTheme, adminInitialData) => {
  const categories = transformThemeData(selectedTheme, adminInitialData);
  return categories.filter(category => category.subCategories.length > 0);
};