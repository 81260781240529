// EditableCell.jsx
import React, { useState, useRef, useEffect } from 'react';
import { Check, X } from 'lucide-react';
import { VALUE_OPTIONS } from './ValueOptions';
import Select from 'react-select';
import { useValueOptions } from './useValueOptions';
import { hostSelectCustomStyles } from '../../../components/utils/hostSelectStyles';
import { PasswordInput } from '../../../../00_AdminPanel/Components/utils/PasswordInput';

const getBooleanValue = (value) => {
  if (typeof value === 'boolean') return value;
  if (typeof value === 'string') return value.toLowerCase() === 'true';
  return false;
};

export const EditableCell = ({ parameter, onChange }) => {
  const [currentValue, setCurrentValue] = useState(parameter.value);
  const [showDropdown, setShowDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const valueOptions = useValueOptions();

  useEffect(() => {
    setCurrentValue(parameter.value);
  }, [parameter.value]);

  const handleChange = (newValue) => {
    onChange({ parameter, newValue });
    setCurrentValue(newValue);
  };

  // Componente Toggle para boolean
  if (parameter.valueType === 'boolean') {
    return (
      <div className="flex items-center justify-center p-2">
        <button
          onClick={() => {
            const newValue = (!getBooleanValue(currentValue)).toString();
            handleChange(newValue);
          }}
          className={`
            relative w-11 h-6 rounded-full transition-colors duration-200 ease-in-out
            ${getBooleanValue(currentValue) ? 'bg-[var(--host-r24-primary-color)]' : 'bg-gray-200'}
          `}
        >
          <span
            className={`
              absolute left-1 top-1 w-4 h-4 rounded-full bg-white transition-transform duration-200 ease-in-out
              ${getBooleanValue(currentValue) ? 'translate-x-5' : 'translate-x-0'}
            `}
          />
        </button>
      </div>
    );
  }

  // POR CORRIGIR!!!!!!
  if (parameter.valueType === 'integer' && valueOptions[parameter.paramCode]?.length > 0) {
    const options = valueOptions[parameter.paramCode] || [];

    return (
      <div className="flex items-center justify-between gap-2 p-1 w-full">
        <Select
            className="host-select cursor-pointer w-full"
            classNamePrefix="select"
            styles={hostSelectCustomStyles}
            isSearchable={false}
            name="color"
            placeholder=""
            value={options.find(option => option.value === currentValue)}
            options={options}
            onChange={(option) => handleChange(option.value)}
        />
        <button
            onClick={() => {
              setCurrentValue(parameter.value);
            }}
            className="p-1 hover:bg-red-100 rounded transition-colors"
          >
            <X className="w-4 h-4 text-red-500" />
          </button>

      </div>
    );
  }

  if (parameter.valueType === 'list' && parameter.valueList?.length > 0) {
    const options = parameter.valueList.map(value => ({
      label: value,
      value: value
    }));

    return (
      <div className="flex items-center justify-between gap-2 p-1 w-full">
        <Select
            className="host-select cursor-pointer w-full"
            classNamePrefix="select"
            styles={hostSelectCustomStyles}
            isSearchable={false}
            name="color"
            placeholder=""
            value={options.find(opt => opt.value === currentValue)}
            options={options}
            onChange={(option) => handleChange(option.value)}
        />

          <button
            onClick={() => {
              setCurrentValue(parameter.value);
            }}
            className="p-1 hover:bg-red-100 rounded transition-colors"
          >
            <X className="w-4 h-4 text-red-500" />
          </button>

      </div>
    );
  }

    // Usando o novo Select para integer com opções
    if (parameter.valueType === 'password' ) {

      return (
        <div className="flex items-center gap-2 p-1">
          <PasswordInput
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            onBlur={() => handleChange(currentValue)}
            className="w-full p-1 host-search-input"
          />
          <button
            onClick={() => {
              setCurrentValue(parameter.value);
            }}
            className="p-1 hover:bg-red-100 rounded transition-colors"
          >
            <X className="w-4 h-4 text-red-500" />
          </button>

        </div>
      );
    }



  // Componente Input para string
  return (
    <div className="relative">
        <div className="flex items-center gap-2 p-1">
          <input
            type="text"
            value={currentValue}
            onChange={(e) => setCurrentValue(e.target.value)}
            onBlur={() => handleChange(currentValue)}
            className="w-full p-1 host-search-input"
          />
          <button
            onClick={() => {
              setCurrentValue(parameter.value);
            }}
            className="p-1 hover:bg-red-100 rounded transition-colors"
          >
            <X className="w-4 h-4 text-red-500" />
          </button>
        </div>
    </div>
  );
};