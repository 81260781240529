import React from "react";

export const SelectSection = () => {
  return (
    <div className="flex-1 flex items-center justify-center h-full min-h-[50vh]">
      <p className="text-gray-500 text-2xl font-medium px-4 py-2">
        Seleccione uma secção
      </p>
    </div>
  );
};
