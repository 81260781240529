import { useState, useEffect, useMemo, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useCookies } from "react-cookie";
import { Select } from "antd";
import { ChevronDown } from "lucide-react";
import { AppContext } from "../../../../context/AppContext";

export const Phone = ({mainDialCodes, formData, setFormData, initialData }) => {
  const [selectedDialCode, setSelectedDialCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDialCodeSelected, setIsDialCodeSelected] = useState(false);
  const [t] = useTranslation("global");
  const [cookie, setCookie] = useCookies(["currentHotel"]); // eslint-disable-line
  const [isOpen, setIsOpen] = useState(false);
  const [state, dispatch] = useContext(AppContext);
  const [labelIsActive, setLabelIsActive] = useState(null);
  const [placeholderIsActive, setPlaceholderIsActive] = useState(null);

  useEffect(() => {
    setLabelIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-label_is_active" && style.isActive ).value );
    setPlaceholderIsActive( state.activeTheme.find( (style) => style.themeStyleCode === "OC-FormField-placeholder_is_active" && style.isActive ).value );
  }, [state.activeTheme]);

  const countries = useMemo(() => {
    return initialData?.CountryList?.map(country => ({
      ...country,
      dialCode: initialData.DialCodes[country.IsoCode] || ''
    }));
  }, [initialData?.CountryList, initialData?.DialCodes]);

  const handleDialCodeChange = (selected) => {
    const dialCode = selected.value;
    setSelectedDialCode(dialCode);
    setIsDialCodeSelected(true);
  };

  const dialCodeIndex = mainDialCodes?.reduce((acc, code, index) => {
    acc[code] = index;
    return acc;
  }, {});

  const sortedCountries = useMemo(() => {
    return initialData?.CountryList?.sort((a, b) => {
      const aDialCode = a.IsoCode3LetterDocumentReader;
      const bDialCode = b.IsoCode3LetterDocumentReader;
      const aIndex = dialCodeIndex[aDialCode] !== undefined ? dialCodeIndex[aDialCode] : Infinity;
      const bIndex = dialCodeIndex[bDialCode] !== undefined ? dialCodeIndex[bDialCode] : Infinity;
      return aIndex - bIndex;
    });
  }, [initialData?.CountryList, dialCodeIndex]);

  useEffect(() => {
    setFormData({ ...formData, GuestPhone1: `${selectedDialCode} ${phoneNumber}` });
  }, [phoneNumber, selectedDialCode]);

  return (
    <>
      {initialData && mainDialCodes && (
        <>

<div>
      {(labelIsActive === "true" || labelIsActive === null) && (
        <label
          htmlFor="telefone"
          className={`TR-FormField-label_location_phone TR-FormField-label_color TR-FormField-label_size TR-FormField-label_font_family TR-FormField-label_variant TR-Form-bg_color pl-2  rounded-xl inline-block text-center justify-center dark:bg-gxp_dark_3 dark:text-white mb-1 z-10`}
        >

          {`${t(`Phone.phonenumber`)}`}
          <span className="dark:text-white ml-2">
            {`( * )\u00A0`}
          </span>{" "}
        </label>
      )}
      <div className="flex h-[43.62px] w-full">
        <div className="flex h-full w-full items-stretch dark:border-gray-600">
          <Select
            suffixIcon={
              <ChevronDown
                className={`TR-FormField-icon_color dark:text-white transition-transform duration-300 ease-in-out ${
                  isOpen ? "rotate-180" : ""
                }`}
              />
            }
            onDropdownVisibleChange={(open) => setIsOpen(open)}
            className={`TR-FormField-text_color TR-FormField-text_size TR-FormField-text_font_family TR-FormField-text_variant TR-FormField-bg_color TR-FormField-border_rounded_phone_select TR-FormField-border_position TR-FormField-border_color TR-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-2 py-2 h-[42px] focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out`}
            style={{ width: "150px" }}


            onChange={handleDialCodeChange}
            placeholder={
              <span className="dark:text-gray-400">
                {`${t(`Phone.select`)}`}
              </span>
            }
            optionLabelProp="label"
            popupClassName={`dark:dark`}
            showSearch
            dropdownStyle={{ minWidth: "260px" }}
            labelInValue
            value={
              selectedDialCode
                ? { value: selectedDialCode, label: selectedDialCode }
                : undefined
            }
            filterOption={(input, option) => {
              const country = countries.find(
                (country) => country.dialCode === option.value
              );
              return (
                country?.TranslatedDescription?.toLowerCase().includes(
                  input.toLowerCase()
                ) ||
                country?.dialCode?.toLowerCase().includes(input.toLowerCase())
              );
            }}
            virtual={false}
          >
            {countries.map((country) => (
              <Select.Option
                key={country.IsoCode}
                className={`dark:dark`}
                value={country.dialCode}
                label={
                  <div className="flex items-center">
                    {" "}
                    <img
                      src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                      alt={`${country.TranslatedDescription} flag`}
                      className="w-6 h-4 mr-2"
                    />{" "}
                    <span className="text-l">{country.dialCode}</span>{" "}
                  </div>
                }
              >
                <div className="flex items-center">
                  <img
                    src={`https://flagcdn.com/w20/${country.IsoCode.toLowerCase()}.png`}
                    alt={`${country.TranslatedDescription} flag`}
                    className="w-6 h-4 mr-2 "
                  />
                  <span className="text-l dark:text-white">
                    {country.TranslatedDescription}
                  </span>
                  <span className="ml-2 text-l text-gray-500 dark:text-white">
                    {country.dialCode}
                  </span>
                </div>
              </Select.Option>
            ))}
          </Select>

          <div className="justify-center w-full h-full">
            <input
              name="Mobile"
              onChange={(e) => setPhoneNumber(e.target.value)}
              value={phoneNumber || ""}
              type="tel"
              id="telefone"
              className={`TR-FormField-text_color TR-FormField-text_size TR-FormField-text_font_family TR-FormField-text_variant TR-FormField-bg_color TR-FormField-border_color TR-FormField-border_rounded_phone TR-FormField-border_position TR-FormField-border_width dark:bg-gxp_dark_3 dark:text-white w-full px-4 py-2 h-[42px] focus:ring-2 focus:border-blue-500 transition duration-150 ease-in-out
              TR-FormField-placeholder_color TR-FormField-placeholder_font_family TR-FormField-placeholder_variant`}

              placeholder={ placeholderIsActive === "true" || placeholderIsActive === null ? `${t(`Phone.phonenumber`)} ${`( * )\u00A0`}` : "" }
            />
          </div>
        </div>
      </div>
      </div>
      </>
      )}
    </>
  );
};