import React, { useContext, useEffect, useRef, useState } from 'react'
import { MobileHeader } from '../../components/Layout/MobileHeader'
import { MobileFooter } from '../../components'
import { useLocation, useParams } from 'react-router-dom';
import { getDefaultPreviewInitialData } from '../../services';
import { AppContext } from '../../context/AppContext';


export const HeaderFooterPreview = () => {
    const { pathname } = useLocation();
    const isPreviewMode = pathname.includes("/previewmode/");
    const [isLoading, setIsLoading] = useState(true);
    const {defaultThemeId} = useParams()
    const [state,dispatch] = useContext(AppContext);


    useEffect(() => {
      const fetchDefaultPreviewInitialData = async () => {
        try {
          const InitialData = await getDefaultPreviewInitialData(defaultThemeId);
          dispatch({ type: "SET_ACTIVE_THEME", payload: InitialData.activeTheme });
        } catch (error) {
          console.error("Error loading initialData-AllRoutes:", error);
        }
      }

      if (isPreviewMode) {
        fetchDefaultPreviewInitialData()
      }
    }, [defaultThemeId]); // eslint-disable-line

  return (

    <div className="min-h-screen flex flex-col">
      {/* Header Section */}
          <div className="">
            <MobileHeader />
          </div>

      {/* Main Content Section */}
      <div className={`flex-1 flex pb-20 bg-gray-200`}>
        {/* Main Content Area */}
        <main className="flex-1 flex flex-col w-full">
          <div className="flex-1 w-full z-0 h-full">
          </div>

        </main>
      </div>

      {/* Mobile Footer */}
        <div className="z-100">
          <MobileFooter />
        </div>
    </div>

  )
}
