import dayjs from "dayjs";
import { BedDouble, Calendar, ChevronRight, Package, Users, } from "lucide-react";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/AppContext";
import { getGXPInitialData } from "../../../services";
import { Loader } from "../../../components/Layout/components/Loader/Loader";

/**
* @namespace ReservationCard
* @memberof module:Home
* @description Component responsible for rendering individual reservation cards.
* Displays reservation details including dates, room info, guests, and package details.
* Supports preview mode with theme customization and image loading.
*
* @component
* @param {Object} props - Component props
* @param {Object} props.reservations - Reservation data object
* @param {boolean} props.isLoading - Loading state flag
* @param {Function} props.setIsLoading - Function to update loading state
* @param {boolean} props.isPreviewMode - Flag indicating if component is in preview mode
* @param {React.RefObject} props.HMCardRef - Reference for hotel card element
* @param {Function} props.handleElementEnter - Handler for mouse enter events
* @param {Function} props.handleElementLeave - Handler for mouse leave events
* @param {string|null} props.isHovered - ID of currently hovered element
*
* @returns {JSX.Element} ReservationCard component
*
* @example
* <ReservationCard
*   reservations={reservationData}
*   isLoading={isLoading}
*   setIsLoading={setIsLoading}
*   isPreviewMode={isPreviewMode}
*   HMCardRef={HMCardRef}
*   handleElementEnter={handleElementEnter}
*   handleElementLeave={handleElementLeave}
*   isHovered={isHovered}
* />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const ReservationCard = ({ reservations, isLoading, setIsLoading, isPreviewMode,HMCardRef, handleElementEnter, handleElementLeave, isHovered }) => {
  const [t] = useTranslation("global");
  const [state, dispatch] = useContext(AppContext);
  const [hotelImage, setHotelImage] = useState();
  const [hotelActiveTheme, setHotelActiveTheme] = useState();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isLoadingHotelInitialData, setIsLoadingHotelInitialData] = useState(true);
  const fetchedRef = useRef(false);

   /**
  * @function fetchGxpInitialData
  * @memberof module:Home.ReservationCard
  * @description Fetches initial theme data for the hotel.
  * Manages loading state and updates hotel theme information.
  * Loads mock data in preview mode or fetches real data otherwise.
  * 
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchGxpInitialData()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchGxpInitialData = async () => {
    if (reservations && !fetchedRef.current) {
      try {
        setIsLoadingHotelInitialData(true);
        // Mantém referência de que já foi feito o fetch
        fetchedRef.current = true;

        const gxpInitialData = await getGXPInitialData( state.currentHotel.hotelId );
        setHotelActiveTheme(gxpInitialData.activeTheme);
      } catch (error) {
        console.error("Error loading initialData-AllRoutes:", error);
      } finally {
        setIsLoadingHotelInitialData(false);
      }
    }
  };

  /**
  * @function useEffectFetchData
  * @memberof module:Home.ReservationCard
  * @description Effect that manages initial data fetching and preview mode.
  * Sets hotel theme and loading states based on mode.
  *
  * @effect Updates hotel theme and loading states
  * @dependencies [reservations, state]
  *
  * @example
  * useEffect(() => {
  *   if (isPreviewMode) {
  *     setHotelActiveTheme(state.activeTheme);
  *     setIsLoadingHotelInitialData(false);
  *     return;
  *   }
  *   fetchGxpInitialData();
  * }, [reservations, state]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (isPreviewMode) {
      setHotelActiveTheme(state.activeTheme);
      setIsLoadingHotelInitialData(false);
      return;
    }
    fetchGxpInitialData();
  }, [reservations, state]);

  /**
  * @function useMemoHotelImage
  * @memberof module:Home.ReservationCard
  * @description Memo that finds and sets the hotel background image.
  * Updates image state when hotel theme changes.
  *
  * @effect Updates hotelImage state
  * @dependencies [hotelActiveTheme]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useMemo(() => {
    if (hotelActiveTheme) {
      const newImage = hotelActiveTheme.find(
        (category) => category.themeStyleCode === "HM-Card-bg_image"
      );
      setHotelImage(newImage);
    }
  }, [hotelActiveTheme]);

/**
  * @function preloadImage
  * @memberof module:Home.ReservationCard
  * @description Preloads an image and updates loading state.
  * Returns a promise that resolves when image is loaded.
  *
  * @param {string} url - URL of the image to preload
  * @returns {Promise} Promise that resolves when image loads
  *
  * @example
  * await preloadImage('https://example.com/image.jpg')
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 const preloadImage = (url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        setImageLoaded(true);
        resolve();
      };
      img.onerror = reject;
    });
  };

 /**
  * @function useEffectImageLoader
  * @memberof module:Home.ReservationCard
  * @description Effect that manages image preloading.
  * Updates image loading state when hotel image changes.
  *
  * @effect Updates imageLoaded state
  * @dependencies [hotelImage?.value]
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
 useEffect(() => {
    if (hotelImage?.value) {
      setImageLoaded(false); // Reset do estado
      preloadImage(hotelImage.value);
    } else {
      setImageLoaded(true); // Se não houver imagem, considerar como carregado
    }
  }, [hotelImage?.value]);

  return (
    <>
    {reservations.reservations.map((reservation, index) => (
      <div key={index} className="h-[200px] mb-4">
        {isLoadingHotelInitialData || !imageLoaded ? (
          <div className="h-full w-full flex justify-center items-center HM-Card-bg_color HM-Card-border_color HM-Card-border_rounded HM-Card-border_position HM-Card-border_width dark:bg-gxp_dark_3 dark:border-gray-800 border">
            <Loader className={`h-20 w-20 dark:text-white`} />
          </div>
        ) : (
          <Link
            to={isPreviewMode ? `` : `/hotel/${reservations.hotel.hotelId.substring(0, 8)}/${reservation.detailid}`}
            className={`${isPreviewMode && isHovered === "HM-Card" ? "diagonal-stripes" : ""} h-full relative flex flex-col HM-Card-bg_color HM-Card-border_color HM-Card-border_rounded HM-Card-border_position HM-Card-border_width dark:bg-gxp_dark_3 dark:border-gray-800 dark:text-white border shadow-md p-4 hover:shadow-lg transition-shadow duration-300`}
            style={{
              backgroundImage: hotelImage?.value ? `url(${hotelImage?.value})` : ``,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
            id="HM-Card" ref={HMCardRef} onMouseOver={(e) => handleElementEnter(e, HMCardRef, "HM-Card")} onMouseLeave={(e) => handleElementLeave(e, HMCardRef, "HM-Card")}
          >
              {/* Semi-transparent overlay */}
              <div className="absolute inset-0 bg-gradient-to-r from-black/70 to-transparent HM-Card-border_rounded" />
              {/* Content container with higher z-index */}
              <div className="relative">
                <div className="flex justify-between items-center mb-4">
                  <span className="HM-Card-title_color HM-Card-title_size HM-Card-title_font_family HM-Card-title_variant">
                    {`${t("reservation")}`} {reservation.resno}
                  </span>
                  <div className="flex justify-center items-center space-x-3">
                    <div className=" dark:text-white transition-colors duration-200 HM-Card-icon_color">
                      <ChevronRight className="w-5 h-5 " />
                    </div>
                  </div>
                </div>

                <div className="flex items-center mb-2">
                  <Calendar className="w-5 h-5 mr-2 HM-Card-icon_color dark:text-violet-400" />
                  <span className="HM-Card-text_color HM-Card-text_size HM-Card-text_font_family HM-Card-text_variant">
                    {dayjs(reservation.checkin).format(t("dayjs_format"))} -{" "}
                    {dayjs(reservation.checkout).format(t("dayjs_format"))}
                  </span>
                </div>

                <div className="flex items-center mb-2">
                  <BedDouble className="w-5 h-5 mr-2 HM-Card-icon_color dark:text-violet-400" />
                  <p className="HM-Card-text_color HM-Card-text_size HM-Card-text_font_family HM-Card-text_variant">
                    {`${t("Checkout.room")}`} {reservation.room} -{" "}
                    {reservation.categoryDescription}
                  </p>
                </div>

                <div className="flex items-center mb-2">
                  <Users className="w-5 h-5 mr-2 HM-Card-icon_color dark:text-violet-400" />
                  <p className="HM-Card-text_color HM-Card-text_size HM-Card-text_font_family HM-Card-text_variant">
                    {reservation.pax +
                      reservation.children +
                      reservation.children2 +
                      reservation.children3}{" "}
                    {reservation.pax +
                      reservation.children +
                      reservation.children2 +
                      reservation.children3 <
                    2
                      ? `${t("HotelReservationDetails.guest")} `
                      : `${t("HotelReservationDetails.guests")} `}
                    ( {reservation.pax}{" "}
                    {reservation.pax < 2
                      ? `${t("HotelReservationDetails.adult")}`
                      : `${t("HotelReservationDetails.adults")}`}
                    {reservation.children +
                      reservation.children2 +
                      reservation.children3 >
                    0
                      ? ","
                      : ""}{" "}
                    {reservation.children +
                      reservation.children2 +
                      reservation.children3 >
                    0
                      ? reservation.children +
                        reservation.children2 +
                        reservation.children3
                      : ""}{" "}
                    {reservation.children +
                      reservation.children2 +
                      reservation.children3 >=
                    2
                      ? `${t("HotelReservationDetails.childrens")}`
                      : reservation.children +
                          reservation.children2 +
                          reservation.children3 ===
                        1
                      ? `${t("HotelReservationDetails.children")}`
                      : ``}
                    )
                  </p>
                </div>

                <div className="flex items-center">
                  <Package className="w-5 h-5 mr-2 HM-Card-icon_color dark:text-violet-400" />
                  <p className="HM-Card-text_color HM-Card-text_size HM-Card-text_font_family HM-Card-text_variant">
                    {reservation.packDescription}
                  </p>
                </div>
              </div>
              </Link>
        )}
      </div>
    ))}
  </>
);
};
