import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import { configureI18n, formatLanguage } from './ConfigureI18n';

export const LanguageProvider = ({ children }) => {
  const [cookies, setCookie] = useCookies(['cultureCode']);
  const { i18n } = useTranslation();

  useEffect(() => {
    const savedLanguage = localStorage.getItem('userLanguage');

    if (savedLanguage) {
      const formattedLanguage = formatLanguage(savedLanguage);

      if (i18n.language !== formattedLanguage) {
        i18n.changeLanguage(formattedLanguage);
      }
    }
  }, [cookies.language, i18n]);

  return children;
};