import { useEffect, useState } from 'react';
import { MoreVertical, Save, Download, Trash2 } from 'lucide-react';
import { Tooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';

export const DropdownMenu = ({ onSave, onLoad, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [t] = useTranslation("global");

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-container')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
   }, []);

  return (
    <div className="relative dropdown-container">
      <div
        className="group"
        onClick={() => setIsOpen(!isOpen)}
      >
        <button
          data-tooltip-id={`menu`}
          data-tooltip-content={`Menu`}
          data-tooltip-place="top"
          className="p-2 rounded-md hover:bg-gray-200 relative" >
          <MoreVertical className="w-4 h-4" />
          <Tooltip
            id="menu"
            style={{padding: "4px 8px", backgroundColor: "var(--host-r24-tooltip-background)", borderRadius: "6px",    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out"}}
            offset={5}
            />
        </button>

        <div
          className={` absolute -right-2 mt-2 bg-white rounded-md shadow-lg p-1 transition-all duration-200 transform origin-top-right ${
            isOpen
              ? 'opacity-100 scale-100'
              : 'opacity-0 scale-95 pointer-events-none'
          }`}
          onMouseLeave={() => setIsOpen(false)}
        >
          <div className="relative group/save">
            <button
              data-tooltip-id={`save`}
              data-tooltip-content={`${t(`Admin.ThemeDefault.save`)}`}
              data-tooltip-place="right"
              onClick={onSave}
              className="p-2 rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-10 h-10 flex items-center justify-center mb-1"
            >
              <Save className="w-4 h-4" />
            </button>
            <Tooltip
                  id={`save`}
                  style={{padding: "4px 8px", backgroundColor: "var(--host-r24-tooltip-background)", borderRadius: "6px",    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out"}}
                  offset={5}
                  />
          </div>

          <div className="relative group/load">
            <button
              data-tooltip-id={`load`}
              data-tooltip-content={`${t(`Admin.ThemeDefault.load`)}`}
              data-tooltip-place="right"
              onClick={onLoad}
              className="p-2 rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 w-10 h-10 flex items-center justify-center mb-1"
            >
              <Download className="w-4 h-4" />
            </button>
            <Tooltip
                  id={`load`}
                  style={{padding: "4px 8px", backgroundColor: "var(--host-r24-tooltip-background)", borderRadius: "6px",    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out"}}
                  offset={5}
                  />
          </div>

          <div className="relative group/delete">
            <button
              data-tooltip-id={`delete`}
              data-tooltip-content={`${t(`Admin.ThemeDefault.delete`)}`}
              data-tooltip-place="right"
              onClick={onDelete}
              className="p-2 rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 w-10 h-10 flex items-center justify-center"
            >
              <Trash2 className="w-4 h-4" />
            </button>
            <Tooltip
                  id={`delete`}
                  style={{padding: "4px 8px", backgroundColor: "var(--host-r24-tooltip-background)", borderRadius: "6px",    transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out"}}
                  offset={5}
                  />
          </div>
        </div>
      </div>
    </div>
  );
};