// DefaultValueInput.jsx
import React, { useEffect, useState } from "react";
import { PasswordInput } from "./PasswordInput";

export const DefaultValueInput = React.memo(({ formData, setFormData }) => {
  const [localValue, setLocalValue] = useState(formData.defaultValue || "");

  useEffect(() => {
    setLocalValue(formData.defaultValue || "");
  }, [formData.defaultValue, formData.valueType]);

  const handleLocalChange = (e) => {
    e.stopPropagation();
    const value = e.target.value;
    setLocalValue(value);
    setFormData((prev) => ({
      ...prev,
      defaultValue: value,
    }));
  };

  switch (formData.valueType) {
    case "string":
      return (
        <input
          type="text"
          name="defaultValue"
          value={localValue}
          onChange={handleLocalChange}
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
          required
        />
      );

    case "boolean":
      return (
        <select
          value={localValue}
          name="defaultValue"
          onChange={handleLocalChange}
          className="w-full px-2 py-2 border border-gray-300 rounded bg-white text-sm"
        >
          <option disabled value="">
            Selecione uma opção
          </option>
          {['true', 'false'].map((option, index) => (
            <option key={index} value={option}>
              {option}
            </option>
          ))}
        </select>
      );

    case "password":
      return (
        <PasswordInput
          value={formData.defaultValue || ""}
          onChange={handleLocalChange}
        />
      );

      case "integer":
        return (
          <input
            type="text"  // Mantemos como text para melhor controle
            name="defaultValue"
            value={localValue}
            onChange={(e) => {
              e.stopPropagation();
              const value = e.target.value;
              // Permite apenas números e vazio (para poder apagar)
              if (value === '' || /^[0-9]+$/.test(value)) {
                handleLocalChange(e);
              }
            }}
            onKeyDown={(e) => {
              // Previne caracteres não numéricos
              if (!/[0-9]/.test(e.key) &&
                  e.key !== 'Backspace' &&
                  e.key !== 'Delete' &&
                  e.key !== 'ArrowLeft' &&
                  e.key !== 'ArrowRight' &&
                  e.key !== 'Tab') {
                e.preventDefault();
              }
            }}
            className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
            required
          />
        );

        case "list":
            return formData.valueList?.length ? (
                <select
                  value={localValue}
                  name="defaultValue"
                  onChange={handleLocalChange}
                  className="w-full px-2 py-2 border border-gray-300 rounded bg-white text-sm"
                >
                  <option disabled value="">
                    Selecione uma opção
                  </option>
                  {formData.valueList?.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <div className="flex items-center text-sm text-amber-600 bg-amber-50 px-3 py-2 rounded-md border border-amber-200">
                  <p>Por favor, preencha primeiro a lista de valores abaixo</p>
                </div>
              );


    default:
      return (
        <input
          type="text"
          name="defaultValue"
          value={localValue}
          onChange={handleLocalChange}
          className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:ring-blue-500"
          required
        />
      );
  }
});
