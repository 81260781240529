import { Plus, Search, X } from "lucide-react";
import React from "react";
import { useTranslation } from "react-i18next";

export const SubCategoriesColumn = ({ handleRemoveRelationship, handleAddRelationship, filteredSubcategories, themeStyles, selectedCategory, setSelectedCategory, searchTerm, setSearchTerm}) => {
  const [t] = useTranslation("global");

  return (
    <div className="bg-white rounded-lg shadow-md border p-4 flex flex-col">
      <div className="mb-4">
        <h3 className="host-title mb-2">{`${t(`Admin.AdminPanel.subcategories`)}`}</h3>
        <div className="relative">
          <input type="text" value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} placeholder={`${t(`Admin.styleSubCategory.searchSubCategory`)}`} className="w-full !pl-8 pr-4 py-2 host-search-input" />
          <Search className="w-4 h-4 text-gray-400 absolute left-3 top-1.5" />
        </div>
      </div>
      <div className="overflow-y-auto h-96 space-y-2">
        {filteredSubcategories.map((subcategory) => {
          const relationship = themeStyles.find(
            (style) =>
              style.styleSubCategoryId === subcategory.id &&
              (!selectedCategory || style.styleCategoryId === selectedCategory.id)
          );

          return (
            <div key={subcategory.id} className={`p-3 rounded-lg border ${ relationship?.isActive ? "border-green-200 bg-green-50" : "border-gray-200" }`} >
              <div className="flex justify-between items-start">
                <div>
                  <div className="host-title !text-sm"> {subcategory.styleSubCategoryCode} </div>
                  <div className="host-text !text-gray-500"> {subcategory.description} </div>
                </div>
                {selectedCategory && (
                  <button onClick={() => { if (relationship?.isActive) { handleRemoveRelationship( selectedCategory.id, subcategory.id ); } else { handleAddRelationship(subcategory.id); } }} className={`p-1 rounded-full ${ relationship?.isActive ? "text-red-600 hover:bg-red-100" : "text-green-600 hover:bg-green-100" }`} >
                    {relationship?.isActive ? ( <X className="w-5 h-5" /> ) : ( <Plus className="w-5 h-5" /> )}
                  </button>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
