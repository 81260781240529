import { UserRoundCog } from "lucide-react";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/logos/Host_GXP-Dashboard-Black.png";
import Logo_dark from "../../assets/logos/Host_GXP-Dashboard.png";
import { useState, useContext, useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import { AppContext } from "../../context/AppContext";
import { SystemClock } from "./components/SystemClock";
import { useTranslation } from "react-i18next";
import { updateI18nLanguage } from "../../pages/Settings/Language/ConfigureI18n";
import { HeaderUserMenu } from "./components/HeaderUserMenu";

export const MobileHeader = () => {
  const [darkMode, setDarkMode] = useState( JSON.parse(localStorage.getItem(`darkMode`)) || false );
  const [selectedLanguage, setSelectedLanguage] = useState(); // Portuguese by default
  const [isLanguageOpen, setIsLanguageOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [avaliableLanguages, setAvaliableLanguages] = useState();

  const [cookies, setCookie] = useCookies([ "sUid", "profileKey", "GUid", "guest", "initialLink", ]);
  const [state, dispatch] = useContext(AppContext);
  const dropdownRef = useRef(null);
  const languageSelectRef = useRef(null);
  const { pathname } = useLocation();
  const [t] = useTranslation("global");
  const isPreviewMode = pathname.includes("/previewmode/");
  const showMenus = !pathname.includes("/checkindirect");

  const gxpLanguages = [
    { code: "pt", name: "Português", cultureCode:"pt-PT" },
    { code: "gb", name: "English", cultureCode:"en-GB" },
    { code: "us", name: "English", cultureCode:"en-US" },
  ];

  const [isHovered, setIsHovered] = useState();
  const [currentRef, setCurrentRef] = useState(null);
  const [inspectMode, setInspectMode] = useState(JSON.parse(localStorage.getItem("inspectMode"))==="true" ? true : false);
  const MHGeneralRef = useRef(null);

  ///////////////////////// UTILIZADO PARA O PREVIEW MODE /////////////////////////

  const handleMouseEnter = (e, elementRef) => {
    e.stopPropagation();

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hover",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'Hover on specific hoverable element'
      }, "*");
    }
  };

  const handleMouseLeave = (e, elementRef) => {

    if (isPreviewMode && elementRef.current) {
      const current = elementRef.current;
      window.parent.postMessage({
        action: "hoverOut",
        elementId: current.id,
        elementClass: current.className || '',
        elementTag: current.tagName || '',
        debugInfo: 'HoverOut from specific hoverable element'
      }, "*");
    }
  };

  const handleElementEnter = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Se houver um hover ativo em outro elemento
    if (isHovered && isHovered !== id && currentRef) {
        handleMouseLeave(e, currentRef);
    }

    // Atualiza o estado para o novo elemento
    if(inspectMode){
      console.log("handleElementEnter", id);
    setCurrentRef(ref);
    handleMouseEnter(e, ref);
    setIsHovered(id);
    }
};


const handleElementLeave = (e, ref, id) => {
    e.preventDefault();
    e.stopPropagation();

    // Apenas limpa o estado se for o elemento atual com hover
    if (isHovered === id) {
        handleMouseLeave(e, ref);
        setIsHovered(null);
        setCurrentRef(null);
    }
};

useEffect(() => {
  const handleMessage = (event) => {
    // Log para debug
    // console.log("Mensagem recebida no iframe:", event.data);

    if (event.data.action === "toggleInspectMode") {
      const newMode = event.data.value;
      setInspectMode(newMode);
      localStorage.setItem("inspectMode", newMode.toString());
    }
  };

  window.addEventListener("message", handleMessage);
  return () => window.removeEventListener("message", handleMessage);
}, []);

   // Utilizado para o previewMode
   useEffect(() => {
    // Registrar a função de atualização globalmente
    window.__THEME_UPDATE__ = (themeStyleCode, value) => {
      console.log('Theme update function called:', { themeStyleCode, value });
      dispatch({
        type: 'UPDATE_ACTIVE_THEME_STYLE',
        payload: { themeStyleCode, value }
      });
    };

    return () => {
      delete window.__THEME_UPDATE__;
    };
  }, [dispatch]);

//////////////////////////////////////////////////////////////////////////////////////



  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && languageSelectRef.current && !languageSelectRef.current.contains(event.target) ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        setIsLanguageOpen(false);
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    // Efeito imediato para definir o idioma do localStorage
    const storedLanguage = localStorage.getItem("userLanguage");
    if (storedLanguage) {
      const initialLanguage = gxpLanguages.find(l => l.cultureCode === storedLanguage);
      if (initialLanguage) {
        setSelectedLanguage(initialLanguage);
        dispatch({ type: "SET_SYSTEM_LANGUAGE", payload: initialLanguage.cultureCode });
        dispatch({ type: "SET_USER_LANGUAGE", payload: initialLanguage.cultureCode });

        updateI18nLanguage(storedLanguage);
      }
    }
  }, [state.hotelInitialData, state.userDetails]); // Executa apenas na montagem

   // Efeito para processar as línguas disponíveis inicialmente
   useEffect(() => {
    if (state.hotelInitialData?.Hotel?.LanguagesList) {
      const matchedLanguages = gxpLanguages
        .filter(
          (gxpLang) =>
            state.hotelInitialData.Hotel.LanguagesList[gxpLang.cultureCode]
        )
        .map((gxpLang) => ({
          ...gxpLang,
          ...state.hotelInitialData.Hotel.LanguagesList[gxpLang.cultureCode],
        }));

      if (matchedLanguages.length > 0) {
        setAvaliableLanguages(matchedLanguages);

        // Só define uma nova língua se não tivermos uma selecionada
        if (!selectedLanguage) {
          const storedLanguage = localStorage.getItem("userLanguage");

          if (storedLanguage) {
            const language = matchedLanguages.find( (l) => l.cultureCode === storedLanguage );

            if (language) {
              setSelectedLanguage(language);
              updateI18nLanguage(language.cultureCode);
              return
            }
          } else if (state.userDetails?.CultureCode) {
            const language = matchedLanguages.find( (l) => l.cultureCode === state.userDetails.CultureCode );

            if (language) {
              setSelectedLanguage(language);
              localStorage.setItem("userLanguage", language.cultureCode);
              updateI18nLanguage(language.cultureCode);
              return;
            }
          } else if(state.hotelInitialData.Hotel.DefaultLanguage){
              // Fallback para a língua padrão do hotel ou en-US
              const defaultLang = matchedLanguages.find( (l) => l.cultureCode === state.hotelInitialData.Hotel.DefaultLanguage ) || matchedLanguages.find((l) => l.cultureCode === "en-US");
              if (defaultLang) {
                setSelectedLanguage(defaultLang);
                localStorage.setItem("userLanguage", defaultLang.cultureCode);
                updateI18nLanguage(defaultLang.cultureCode);
                return;
              }
          } else {
            // Fallback final
            setSelectedLanguage(matchedLanguages[0]);
            localStorage.setItem("userLanguage", matchedLanguages[0].cultureCode);
            updateI18nLanguage(matchedLanguages[0].cultureCode);
          }
        }
      }
    }
  }, [state.hotelInitialData, state.userDetails, selectedLanguage]);

  useEffect(() => {
    setDarkMode(state.isDarkMode)
  }, [state.isDarkMode])



  return (
    <header id="MH-General" ref={MHGeneralRef} onMouseOver={(e) => handleElementEnter(e, MHGeneralRef, "MH-General")} onMouseLeave={(e) => handleElementLeave(e, MHGeneralRef, "MH-General")} className={`${isPreviewMode && isHovered === "MH-General" ? "diagonal-stripes" : ""} MH-bg_color MH-border_color MH-border_rounded MH-border_position MH-border_width border dark:bg-gxp_dark_1 dark:border-white p-4 flex justify-between items-center sticky top-0 z-30 shadow-md ${ showMenus ? "" : "" }`} >
      <Link to={cookies.initialLink} className="flex items-center justify-between mr-4" >
        <img src={darkMode ? Logo_dark : Logo} className="mr-3 h-8 max-sm:h-6 " alt="HHS Logo" />
      </Link>{" "}
      <div className=" flex items-center space-x-4">
        <SystemClock />
        <div className="relative" ref={dropdownRef}>
          <button onClick={() => setIsOpen(!isOpen)} className="p-2 MH-icon_color dark:!text-white hover:bg-gray-100 dark:hover:!text-gray-800 rounded-full transition-colors" >
            <UserRoundCog className="h-5 w-5" />
          </button>
          {isOpen && (
            <HeaderUserMenu isOpen={isOpen} setIsOpen={setIsOpen} />
          )}
        </div>
      </div>
    </header>
  );
};
