
export async function getAdminInitialData(){
    const requestOptions = {
        method:"GET",
        headers:{"Content-Type":"application/json"}
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/admin/AdminInitialData`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function getDefaultPreviewInitialData(themeId){
    const requestOptions = {
        method:"GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"}
    }

    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/getdefaultpreviewinitialdata?defaultThemeId=${themeId}`, requestOptions)
    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()
    return data
}

export async function saveStyleCategory(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/stylecategory/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}

export async function saveStyleSubCategory(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/stylesubcategory/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}

export async function saveThemeLines(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/themelines/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}

export async function saveThemeDescription(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/themedescription/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}

export async function copyThemeDescription(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/themedescription/copy`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}

export async function saveThemeStyle(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/themestyle/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}

export async function saveParameterDescription(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/parameterdescription/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}

export async function saveGroupHotel(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/grouphotel/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}

export async function saveHotel(request){
    const requestOptions = {
        method:"POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers:{"Content-Type":"application/json"},
        body: JSON.stringify(request)
    }
    const response = await fetch(`${process.env.REACT_APP_BACKEND_IP}/hotel/save`, requestOptions)

    if(!response.ok){
        throw {message: response.statusText, status: response.status}//eslint-disable-line
    }
    const data = await response.json()

    return data
}