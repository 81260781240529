import React, { useState, useEffect, useRef } from 'react';

export const RangeSlider = ({value, onChange, valueList}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [inputValue, setInputValue] = useState(value?.toString() || '');
  const [min, setMin] = useState(null);
  const [max, setMax] = useState(null);
  const sliderRef = useRef(null);
  const thumbRef = useRef(null);
  const progressRef = useRef(null);
  const tooltipRef = useRef(null);
  const inputRef = useRef(null);

  const handleSliderChange = (e) => {
    const newValue = Number(e.target.value);
    onChange(newValue);
    setInputValue(newValue.toString());
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputBlur = () => {
    let newValue = parseInt(inputValue);

    if (isNaN(newValue)) {
      newValue = value || min;
    } else if (newValue < min) {
      newValue = min;
    } else if (newValue > max) {
      newValue = max;
    }

    onChange(newValue);
    setInputValue(newValue.toString());
    setIsEditing(false);
  };

  const handleInputKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleInputBlur();
    } else if (e.key === 'Escape') {
      setInputValue(value?.toString() || min?.toString());
      setIsEditing(false);
    }
  };

  const handleTooltipClick = (e) => {
    e.stopPropagation();
    setIsEditing(true);
    setTimeout(() => {
      inputRef.current?.focus();
      inputRef.current?.select();
    }, 0);
  };

  const calculateBackgroundGradient = () => {
    if (min === null || max === null || value === null) return '';
    const percentage = ((value - min) / (max - min)) * 100;
    return `linear-gradient(to right, #3b82f6 ${percentage}%, #e5e7eb ${percentage}%)`;
  };

  useEffect(() => {
    if (progressRef.current) {
      progressRef.current.style.background = calculateBackgroundGradient();
    }
  }, [value, min, max]);

  useEffect(() => {
    setInputValue(value?.toString() || min?.toString() || '');
  }, [value, min]);

  useEffect(() => {

    if (valueList.length === 0) {
      setMin(null);
      setMax(null);
      return;
    }

      if (valueList.length > 0) {

          const minValue = Math.min(Number(valueList[0]), Number(valueList[1]));
          const maxValue = Math.max(Number(valueList[0]), Number(valueList[1]));
          setMin(minValue);
          setMax(maxValue);

          // Se não houver valor definido, use o mínimo
          if (value === undefined || value === null || value === 0 || value<minValue) {
            onChange(minValue);
            setInputValue(minValue);
          }

          // Se não houver valor definido, use o mínimo
          if (valueList.length > 1 && value>maxValue) {
            onChange(maxValue);
            setInputValue(maxValue);
          }
      }

  }, [valueList, min, max]);

  useEffect(() => {

    if(inputValue === undefined || inputValue === null || inputValue === 0) {
      setInputValue(min?.toString());
    }
  }, [min, max])

  if (!valueList || valueList === '') {
    return (
      <div className="mb-2 text-sm text-red-500">
        Por favor indique os valores mínimos e máximos separados por ponto e vírgula (;)
      </div>
    );
  }

  if (min === null || max === null) {
    return (
      <div className="text-sm text-blue-600">
        Defina os valores min e max...
      </div>
    );
  }

  return (
    <div className="w-full max-w-md mx-auto pr-2">
      <div className="relative">
        {/* Custom Slider Container */}
        <div className="relative h-12 flex items-center">
          {/* Tooltip */}
          <div
            ref={tooltipRef}
            className="absolute -top-0.5 px-2 bg-blue-600 text-white text-xs rounded transform -translate-x-1/2 transition-all duration-200 cursor-text"
            style={{ left: `${((value - min) / (max - min)) * 100}%` }}
            onClick={handleTooltipClick}
          >
            {isEditing ? (
              <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleInputKeyPress}
                className="w-8 h-4 bg-white text-[10px] text-blue-600 rounded px-1 text-center outline-none"
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <span className='py-0.5'>{value}</span>
            )}
          </div>

          {/* Track and Progress */}
          <div
            ref={progressRef}
            className="relative w-full h-2 bg-gray-200 rounded-full"
          >
            {/* Thumb */}
            <div
              ref={thumbRef}
              className={`absolute top-1/2 w-4 h-4 bg-blue-600 rounded-full shadow-lg transform -translate-y-1/2 transition-transform duration-150 ${isDragging ? 'scale-125' : 'scale-100'} hover:scale-125`}
              style={{ left: `${((value - min) / (max - min)) * 100}%`, transform: `translateX(-50%) translateY(-50%)` }}
            />

            {/* Native Range Input (hidden but functional) */}
            <input
              type="range"
              min={min}
              max={max}
              value={value || min}
              onChange={handleSliderChange}
              onMouseDown={() => setIsDragging(true)}
              onMouseUp={() => setIsDragging(false)}
              onTouchStart={() => setIsDragging(true)}
              onTouchEnd={() => setIsDragging(false)}
              className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            />
          </div>
        </div>

        {/* Tick Marks */}
        <div className="flex justify-between -translate-y-4">
          <div className="flex flex-col items-center">
            <div className="w-0.5 h-1 bg-gray-300"/>
            <span className="text-xs text-gray-500 mt-1">{min}</span>
          </div>
          <div className="flex flex-col items-center">
            <div className="w-0.5 h-1 bg-gray-300"/>
            <span className="text-xs text-gray-500 mt-1">{max}</span>
          </div>
        </div>
      </div>
    </div>
  );
};