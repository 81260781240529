import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { MobileHeader } from "../components/Layout/MobileHeader";
import { MobileFooter } from "../components";

/**
* @module RootLayout
* @description Core module for application layout structure.
* Manages the main layout template including header, content area, and footer.
*/

/**
* @namespace RootLayout
* @memberof module:RootLayout
* @description Component responsible for the main application layout structure.
* Handles conditional rendering of header and footer based on route path.
* Provides consistent layout structure for nested routes through Outlet.
*
* @component
* @returns {JSX.Element} RootLayout component
*
* @example
* <RootLayout />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const RootLayout = () => {
  const { pathname } = useLocation();
  const isDirect = pathname.includes("direct");

  return (
    <div className="min-h-screen flex flex-col bg-gxp_violet_violet_bg dark:bg-gxp_dark_2">
      {/* Header Section */}
      {!isDirect && (
          <div className="">
            <MobileHeader />
          </div>
      )}

      {/* Main Content Section */}
      <div className={`flex-1 flex ${isDirect ? "" : "pb-20"}`}>
        {/* Main Content Area */}
        <main className="flex-1 flex flex-col w-full">
          <div className="flex-1 w-full z-0 h-full">
            <Outlet />
          </div>

        </main>
      </div>

      {/* Mobile Footer */}
      {!isDirect && (
        <div className="z-100">
          <MobileFooter />
        </div>
      )}
    </div>
  );
};