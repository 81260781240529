import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

/**
 * @module TimeStep
 * @description Renders a selection step for available reservation times.
 * It allows users to choose a time slot from the provided list of available times.
 * The selected time is highlighted, and clicking a time updates the state and advances
 * to the next step in the reservation process.
 *
 * @param {Object} props - The component properties.
 * @param {function} props.handleTimeChange - Function to handle the change of the selected time.
 * @param {Object} props.fadeVariants - Variants for Framer Motion animations.
 * @param {function} props.setStep - Function to update the current step in the reservation process.
 * @param {string} props.time - Currently selected time for the reservation.
 * @param {Array<string>} props.availableTimes - Array of strings representing available time slots.
 *
 * @returns {JSX.Element} The TimeStep component for selecting reservation times.
 *
 * @example
 * <TimeStep
 *   handleTimeChange={changeTimeHandler}
 *   fadeVariants={fadeAnimationVariants}
 *   setStep={updateStep}
 *   time={selectedTime}
 *   availableTimes={['12:00', '13:00', '14:00']}
 * />
 *
 * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
 * @version 1.0.0
 */
export const TimeStep = ({ handleTimeChange, fadeVariants, setStep, time, availableTimes, isPreviewMode, isHovered, TRCardRef, handleElementEnter, handleElementLeave }) => {
  const [t] = useTranslation("global");

  const handleNext = (t, event) => {

    handleTimeChange(t)
    setStep(4);


    if (isPreviewMode) {
      // Verificamos se temos o evento antes de usar
      if (event) {
        handleElementLeave(event, TRCardRef, "TR-Card");
      }

      window.parent.postMessage({
        action: "TR-FormPage",
        debugInfo: 'Change to Form Page'
      }, "*");
    }
  };

  return (
    <motion.div variants={fadeVariants} initial="hidden" animate="visible" exit="exit">
      <h2 className="TR-Form-title_color TR-Form-title_size TR-Form-title_font_family TR-Form-title_variant mb-4 mt-4 dark:text-white">{`${t(`TimeStep.whattime`)}`}</h2>
      <div className="grid grid-cols-3 gap-4">
        {availableTimes.map((t) => (
          <button type='button' key={t} id={`TR-Card`} ref={TRCardRef} onMouseOver={(e) => handleElementEnter(e, TRCardRef, "TR-Card")} onMouseLeave={(e) => handleElementLeave(e, TRCardRef, "TR-Card")} onClick={(e) => handleNext(t, e)} className={` ${isPreviewMode && isHovered === "TR-Card" ? "diagonal-stripes" : ""} p-2 border rounded transition-transform duration-300 ease-in-out transform hover:scale-105 TR-Card-border_rounded TR-Card-border_position TR-Card-border_width TR-Card-text_size TR-Card-text_font_family TR-Card-text_variant TR-Card-border_color TR-Card-hover_bg_color TR-Card-hover_border_color TR-Card-text_color dark:bg-white  dark:hover:text-white dark:hover:bg-gxp_violet_dark dark:hover:border-gxp_violet ${ time === t ? 'TR-Card-active_color TR-Card-active_bg_color dark:bg-gxp_violet_dark dark:border-gxp_violet dark:text-white' : 'dark:text-gxp_dark_3' }`} >
            {t}
          </button>
        ))}
      </div>
    </motion.div>
  );
};