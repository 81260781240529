import { ChevronDown, Menu, Shield, } from "lucide-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import { useState, useEffect, useContext } from "react";
import { logoutUser } from "../../../services";
import { AppContext } from "../../../context/AppContext";
import { Loader } from "../../../components/Layout/components/Loader/Loader";
import { Tooltip } from "react-tooltip";
import { useTranslation } from "react-i18next";

export const Sidebar = ({ isOpen, categories, activeSection, expandedCategories, onToggle, onCategoryClick, onToggleCategory, }) => {
  const [showContent, setShowContent] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useContext(AppContext);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRedirect, setShouldRedirect] = useState(false);
  const [usersPending, setUsersPending] = useState(null);
  const [t] = useTranslation("global");

  const logout = async () => {
    setIsLoading(true);

    try {
      const response = await logoutUser();

      if (response.message === "success") {
        console.log("Logout bem sucedido");
        dispatch({ type: "SET_ADMIN_LOGGEDIN", payload: null });
        navigate("/admin/login", { replace: true });
        return; // garante que o código pare aqui
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  // Função para encontrar a categoria ativa baseada na URL atual
  const findActiveCategory = (pathname) => {
    // Procura primeiro nas subcategorias
    for (const category of categories) {
      if (category.subCategories) {
        const subCategory = category.subCategories.find(
          (sub) => sub.linkTo === pathname
        );
        if (subCategory) {
          onCategoryClick(`${category.name}.${subCategory.name}`);
          if (!expandedCategories.includes(category.name)) {
            onToggleCategory(category.name);
          }
          return;
        }
      }
      // Verifica a categoria principal
      if (category.linkTo === pathname) {
        onCategoryClick(category.name);
        return;
      }
    }
  };

  // Primeiro, vamos criar uma função auxiliar para filtrar as categorias
const getVisibleCategories = () => {
  if (state?.adminLoggedIn?.status !== "Active") {
    // Se o usuário não estiver ativo, retorna apenas o botão de logout
    return categories.filter(category => category.name === "logout");
  }

  // Filtrar categorias e subcategorias que contêm o role
  return categories.filter(category => {
    const categoryHasRole = category.roles.includes(state?.adminLoggedIn?.role);

    // Se a categoria tem subcategorias
    if (category.subCategories) {
      // Filtra as subcategorias
      category.subCategories = category.subCategories.filter(subCategory =>
        subCategory.roles.includes(state?.adminLoggedIn?.role)
      );
    }
    return categoryHasRole;
  });
};

  // Atualiza a categoria ativa quando a rota muda
  useEffect(() => {
    findActiveCategory(location.pathname);
  }, [location.pathname]);

  // Handler para quando a animação da sidebar completar
  const handleAnimationComplete = () => {
    if (isOpen) {
      setShowContent(true);
    } else {
      setShowContent(false);
    }
  };

  useEffect(() => {

    const usersP = state?.adminInitialData?.users?.filter(user => user.status === "Pending").length

    setUsersPending(usersP)

  }, [state?.adminInitialData?.users])

  return (
    <div className="relative h-full">
      <motion.div
        className={`${
          isOpen ? "w-64" : "w-20"
        } bg-white shadow-lg relative overflow-y-auto overflow-x-hidden h-full`}
        initial={false}
        animate={{ width: isOpen ? 256 : 80 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        onAnimationComplete={handleAnimationComplete}
      >
        <div className="p-4">
          <div className="flex items-center space-x-2 mb-8">
            <Shield className="w-8 h-8 text-blue-600" />
            {showContent && isOpen && (
              <span className="text-xl font-bold">Admin Panel</span>
            )}
          </div>

          <nav>
            {getVisibleCategories().map((category) => (
              <div key={category.name} className="mb-2">
                <Link
                  to={category.linkTo}
                  onClick={(e) => {
                    if (category.subCategories) {
                      e.preventDefault();
                      onToggleCategory(category.name);
                      if (!isOpen) {
                        onToggle();
                      }
                    } else {
                      onCategoryClick(category.name);
                      if (!isOpen) {
                        onToggle();
                      }
                    }
                    if (category.name === "logout") {
                      logout();
                    }
                  }}
                  className={`w-full flex items-center p-2 rounded-lg ${
                    activeSection === category.name
                      ? "bg-blue-50 text-blue-600"
                      : "hover:bg-gray-50"
                  }`}
                  data-tooltip-id={category.name}
                  data-tooltip-content={ category.label.charAt(0).toUpperCase() + category.label.slice(1) }
                  data-tooltip-place="right"
                >
                  {category.name === "logout" && isLoading ? (
                    <Loader />
                  ) : (
                    <>
                      <div className="shrink-0 host-title">{category.icon}</div>
                      {isOpen && (
                        <motion.div
                          className="flex flex-1 items-center whitespace-nowrap host-title"
                          initial={{ width: 0, opacity: 0 }}
                          animate={{ width: "auto", opacity: 1 }}
                          exit={{ width: 0, opacity: 0 }}
                          transition={{ duration: 0.3 }}
                        >
                          <span
                            className={`ml-3 flex-1 flex items-center `}
                          >
                            {category.label} {category.name === "users" && usersPending > 0 && <div className="ml-2 flex text-center justify-center w-5 h-5 bg-red-500 text-white rounded-full" data-tooltip-id={"Pending"} data-tooltip-content={ t("Admin.users.pending") } data-tooltip-place="right"><p>{usersPending}</p></div>}
                          </span>
                          {category.subCategories && (
                            <motion.div
                              animate={{ rotate: expandedCategories.includes( category.name ) ? 180 : 0, }}
                              transition={{ duration: 0.2 }}
                            >
                              <ChevronDown className="w-4 h-4" />
                            </motion.div>
                          )}
                        </motion.div>
                      )}
                    </>
                  )}
                </Link>

                <AnimatePresence>
                  {isOpen &&
                    category.subCategories &&
                    expandedCategories.includes(category.name) && (
                      <motion.div
                        className="ml-6 mt-2 space-y-2"
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: "auto" }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.2 }}
                      >
                        {category.subCategories.map((sub) => (
                          <motion.div
                            key={sub.name}
                            initial={{ opacity: 0, y: -10 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -10 }}
                            transition={{ duration: 0.2 }}
                          >
                            <Link
                              to={sub.linkTo}
                              onClick={() => onCategoryClick(`${category.name}.${sub.name}`) }
                              className={`host-subtitle w-full flex items-center p-2 rounded-lg ${ activeSection === `${category.name}.${sub.name}` ? "bg-blue-50 text-blue-600" : "hover:bg-gray-50" }`}
                            >
                              {sub.icon}
                              <span className="ml-2">{sub.label}</span>
                            </Link>
                          </motion.div>
                        ))}
                      </motion.div>
                    )}
                </AnimatePresence>
              </div>
            ))}
          </nav>
        </div>
      </motion.div>

      {/* Tooltips movidos para fora do motion.div */}
      {!isOpen &&
        categories.map((category) => (
          <Tooltip
            key={category.name}
            id={category.name}
            style={{
              padding: "4px 8px",
              backgroundColor: "var(--host-r24-tooltip-background)",
              borderRadius: "6px",
              transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
            }}
            offset={5}
            className="z-[100]"
          />
        ))}
      {isOpen &&
          <Tooltip
          key={"Pending"}
          id={"Pending"}
          style={{
            padding: "4px 8px",
            backgroundColor: "var(--host-r24-tooltip-background)",
            borderRadius: "6px",
            transition: "opacity 0.3s ease-in-out, transform 0.3s ease-in-out",
          }}
          offset={15}
          className="z-[100]"
        />}

      <button
        onClick={onToggle}
        className="absolute right-0 top-4 transform translate-x-1/2 bg-white rounded-full p-2 shadow-md z-[9999]"
      >
        <motion.div
          animate={{ rotate: isOpen ? 180 : 0 }}
          transition={{ duration: 0.3 }}
        >
          <Menu className="w-4 h-4" />
        </motion.div>
      </button>
    </div>
  );
};
