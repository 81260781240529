import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useCookies } from "react-cookie";
import { getReservationByDetailId } from "../../services";
import { toast } from "react-toastify";
import { MainSection } from "./components/MainSection";
import { AppContext } from "../../context/AppContext";
import { AnimatePresence, motion } from "framer-motion";
import { SliderSection } from "./components/SliderSection";
import { NotificationsBanner } from "./components/NotificationsBanner";
import { Modal } from "./components/Modal";

/**
* @module HotelHome
* @description Core module for rendering individual hotel reservation details page.
* Manages reservation data, animations, and modal interactions.
*/

/**
* @namespace HotelHome
* @memberof module:HotelHome
* @description Main component responsible for displaying hotel reservation menu.
* Handles reservation data fetching, fetching menu elements, section animations and others.
*
* @component
* @returns {JSX.Element} HotelHome page component
*
* @example
* <HotelHome />
*
* @author Tiago Ferreira <tiago.ferreira@hhs.pt>
* @since 1.0.0
* @version 1.0.0
*/
export const HotelHome = () => {
  const { platformUid, detailId } = useParams();
  const [state, dispatch] = useContext(AppContext);
  const [reservation, setReservation] = useState(state.reservation);
  const [cookie, setCookie] = useCookies(["platformUid", "currentHotel", "GUid", "reservation", "detailId"]); // eslint-disable-line
  const [isSubmenuActive, setIsSubmenuActive] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [isAnimating, setIsAnimating] = useState(false); // Novo estado para controlar a animação
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [notificationToShow, setNotificationToShow] = useState(null);

   /**
  * @function handleExitAnimation
  * @memberof module:HotelHome.HotelHome
  * @description Manages the exit animation for sections.
  * Controls animation states and timing for smooth transitions.
  *
  * @example
  * handleExitAnimation()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const handleExitAnimation = () => {
    setIsAnimating(true);
    setActiveSection(null); // Garante que ambas as seções estão saindo juntas
    setTimeout(() => {
      setIsAnimating(false); // Permite novas animações após a saída
    }, 300); // Ajuste a duração conforme necessário
  };

   /**
  * @function fetchReservationByDetailId
  * @memberof module:HotelHome.HotelHome
  * @description Fetches reservation details using detail ID.
  * Updates reservation state and dispatches to global context.
  *
  * @async
  * @returns {Promise<void>}
  *
  * @example
  * await fetchReservationByDetailId()
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  const fetchReservationByDetailId = async () => {
    if (state.currentHotel?.subscriptionKey && detailId) {
      try {
        const results = await getReservationByDetailId(detailId, state.currentHotel, cookie.sUid);
        setReservation(results[0]);
        dispatch({ type: "SET_USER_RESERVATION", payload: results[0] });
      } catch (error) {
        console.log(error);
        toast.error("Unable to get reservations!");
      }
    }
  };

   /**
  * @function useEffectDetailId
  * @memberof module:HotelHome.HotelHome
  * @description Effect that updates detail ID cookie.
  *
  * @effect Sets detailId cookie
  * @dependencies [detailId]
  *
  * @example
  * useEffect(() => {
  *   setCookie("detailId", detailId, { path: "/" });
  * }, [detailId]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
   useEffect(() => {
    setCookie("detailId", detailId, { path: "/" });
  }, [detailId]); // eslint-disable-line

   /**
  * @function useEffectFetchReservation
  * @memberof module:HotelHome.HotelHome
  * @description Effect that triggers reservation fetching.
  * Fetches data when hotel subscription key and detail ID are available.
  *
  * @effect Fetches reservation data
  * @dependencies [detailId, state.currentHotel?.subscriptionKey]
  *
  * @example
  * useEffect(() => {
  *   if (state.currentHotel?.subscriptionKey && detailId) {
  *     fetchReservationByDetailId();
  *   }
  * }, [detailId, state.currentHotel?.subscriptionKey]);
  *
  * @author Tiago Ferreira <tiago.ferreira@hhs.pt>
  * @since 1.0.0
  * @version 1.0.0
  */
  useEffect(() => {
    if (state.currentHotel?.subscriptionKey && detailId) {
      fetchReservationByDetailId();
    }
  }, [detailId,state.currentHotel?.subscriptionKey]); // eslint-disable-line

  return (
    <AnimatePresence mode="wait">
      {state.currentHotel && detailId && state.reservation && (
        <motion.div key="main-content" className="w-full" initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 100 }} transition={{ duration: 0.3 }} >
          <main className=" max-w-4xl w-full h-full items-center mx-auto ">
            <NotificationsBanner setNotificationToShow={setNotificationToShow} setIsModalOpen={setIsModalOpen} />
            <motion.div key="sections-container" className="w-full items-center" initial={{ opacity: 0, x: -100 }} animate={{ opacity: 1, x: 0 }} exit={{ opacity: 0, x: 100 }} transition={{ duration: 0.3 }} >
              {!isAnimating && (activeSection === "MAIN" || activeSection === null) && (
                <MainSection detailId={detailId} onMenuStateChange={setIsSubmenuActive} posSection="MAIN" setActiveSection={setActiveSection} setIsExiting={handleExitAnimation} />
              )}
               {!isAnimating && (activeSection === "SLIDER" || activeSection === null) && (
                <SliderSection  posSection="SLIDER" className={``} />
              )}
              {!isAnimating && (activeSection === "MAIN-2" || activeSection === null) && (
                <MainSection detailId={detailId} onMenuStateChange={setIsSubmenuActive} posSection="MAIN-2" setActiveSection={setActiveSection} setIsExiting={handleExitAnimation} />
              )}
            </motion.div>
            <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} initialText={notificationToShow?.Description}  />
          </main>
        </motion.div>
      )}
    </AnimatePresence>
  );
};