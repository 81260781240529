import React, { useEffect, useRef, useState } from 'react';
import SignaturePad from 'react-signature-pad-wrapper';
import { SquarePen } from 'lucide-react';
import { useTranslation } from 'react-i18next';

const IndividualSignaturePad = ({ guest, setGuests, militaryMode, titles }) => {
  const padRef = useRef(null);
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [hasSignature, setHasSignature] = useState(false);
  const [t] = useTranslation("global");
  const [post, setPost] = useState("");

  useEffect(() => {
    if (militaryMode && titles && guest) {
      const newTitle = titles?.result?.title.find((element) => element.ID === guest.data.Title_Id);
      setPost(newTitle);
    }
  }, [guest, titles, militaryMode]);

  // Efeito para atualizar o estado hasSignature
  useEffect(() => {
    setHasSignature(!!guest.data?.GuestSignatureBase64);
    if (guest.data?.GuestSignatureBase64) {
      setIsConfirmed(true);
    }
  }, [guest.data?.GuestSignatureBase64]);

  // Efeito para carregar a assinatura no pad
  useEffect(() => {
    if (padRef.current && guest.data?.GuestSignatureBase64) {
      try {
        const signatureDataUrl = guest.data.GuestSignatureBase64.startsWith('data:')
          ? guest.data.GuestSignatureBase64
          : `data:image/png;base64,${guest.data.GuestSignatureBase64}`;
        padRef.current.fromDataURL(signatureDataUrl);
      } catch (error) {
        console.error('Error loading signature:', error);
      }
    }
  }, [guest.data?.GuestSignatureBase64]);

  const clearSignature = (e) => {
    e.preventDefault();

    // Limpa o pad
    if (padRef.current) {
      padRef.current.clear();
    }

    // Reseta os estados locais
    setIsConfirmed(false);
    setHasSignature(false);

    // Atualiza o estado global removendo a assinatura
    setGuests(prevGuests =>
      prevGuests.map(g =>
        g.id === guest.id
          ? { ...g, data: { ...g.data, GuestSignatureBase64: null }}
          : g
      )
    );
  };

  const handleConfirm = (e) => {
    e.preventDefault();
    if (!padRef.current || padRef.current.isEmpty()) {
      alert(t('SignaturePadComp.alersign'));
      return;
    }

    const dataURL = padRef.current.toDataURL('image/png');
    // Remove o prefixo 'data:image/png;base64,' da string
    const base64Data = dataURL.split(',')[1];

    setGuests(prevGuests =>
      prevGuests.map(g =>
        g.id === guest.id
          ? { ...g, data: { ...g.data, GuestSignatureBase64: base64Data }}
          : g
      )
    );
    setIsConfirmed(true);
    setHasSignature(true);
  };

  if (isConfirmed && hasSignature) {
    return (
      <div className="text-center">
        <div className="flex justify-between items-center">
          <h2 className="OC-Consent-title_color OC-Consent-title_size OC-Consent-title_font_family OC-Consent-title_variant mb-4 dark:text-white">
            {t('SignaturePadComp.signature')}
          </h2>
          <SquarePen
            onClick={clearSignature}
            className="cursor-pointer dark:text-white"
          />
        </div>
        <div className="bg-green-100 text-green-700 p-4 rounded-lg mb-4">
          {militaryMode && post ? (
            <p>
              {t('HostFormComplete.thankyou')} {post.Description} {guest?.data?.LastName || t('HostFormComplete.guest')}
              {t('SignaturePadComp.signaturesaved')}
            </p>
          ) : (
            <p>
              {t('HostFormComplete.thankyou')} {guest?.data?.FirstName || t('HostFormComplete.guest')}
              {t('SignaturePadComp.signaturesaved')}
            </p>
          )}
        </div>
      </div>
    );
  }

  return (
    <div>
      <h2 className="OC-Consent-title_color OC-Consent-title_size OC-Consent-title_font_family OC-Consent-title_variant mb-4 dark:text-white">
        {t('SignaturePadComp.guest')}
      </h2>
      <div className="border border-gray-300 dark:border-white mb-4">
        <SignaturePad
          ref={padRef}
          options={{
            minWidth: 1,
            maxWidth: 3,
            penColor: "black",
            backgroundColor: "rgb(255, 255, 255)"
          }}
          canvasProps={{
            className: "w-full h-40",
            style: { touchAction: "none" }
          }}
        />
      </div>
      <div className="flex space-x-4 mb-6">
        <button
          type="button"
          onClick={clearSignature}
          className="flex-1 py-2 px-4 border border-gray-300 rounded dark:bg-white"
        >
          {t('SignaturePadComp.clean')}
        </button>
        <button
          type="button"
          onClick={handleConfirm}
          className="flex-1 py-2 px-4 bg-blue-600 text-white rounded"
        >
          {t('SignaturePadComp.confirm')}
        </button>
      </div>
    </div>
  );
};

export const SignaturePadComp = ({ guest, setGuests, activeTab, militaryMode, titles }) => {
  // Só renderiza o pad se for o guest ativo
  if (activeTab !== guest.id) {
    return null;
  }

  return (
    <IndividualSignaturePad
      guest={guest}
      setGuests={setGuests}
      militaryMode={militaryMode}
      titles={titles}
    />
  );
};

