import React from 'react';

export const applyTheme = (themeStyles) => {
  if (!themeStyles || !Array.isArray(themeStyles)) {
    return;
  }

  const root = document.documentElement;

  const applyStyleProperty = (styleCode) => {
    const style = themeStyles.find(s => s.themeStyleCode === styleCode);
    if (style) {
      const propertyName = `--${styleCode}`;
      let propertyValue;

      switch (true) {
        case style.themeStyleCode.includes('_width'):
          propertyValue = `${style.value}px`;
          break;

        case style.themeStyleCode.includes('_size'):
          propertyValue = `${style.value}px`;
          break;

        case style.themeStyleCode.includes('_color'):
          propertyValue = style.value;
          break;

        case style.themeStyleCode.includes('border_position'):
          // Mapeamento dos valores para as propriedades de borda
          const borderMap = {
            'Bottom': 'none none solid none',
            'X-Axis': 'none solid none solid',
            'Y-Axis': 'solid none solid none',
            'Top': 'solid none none none',
            'Left': 'none none none solid',
            'Right': 'none solid none none',
            'All': 'solid'
          };
          propertyValue = borderMap[style.value] || 'none';
          break;

          case style.themeStyleCode.includes('border_rounded'):
            // Mapeamento dos valores para as propriedades de borda
            const borderRoundedMap = {
              'None': '0px',
              'Small': '4px',
              'Medium': '8px',
              'Large': '12px',
              'Extra Large': '16px',
              'Round': '24px',
              'Full': '9999px'
            };
            propertyValue = borderRoundedMap[style.value] || 'none';
            break;

            case style.themeStyleCode.includes('FormField-label_location'):
              // Mapeamento dos valores para as propriedades de borda
              const locationMap = {
                'Normal': '0px',
                'On Top': '14px',
              };
              propertyValue = locationMap[style.value] || 'none';
              break;

          case style.themeStyleCode.includes('_variant'):
            const variantMap = {
              'None': { style: 'normal', weight: 'normal' },
              'Italic': { style: 'italic', weight: 'normal' },
              'Bold': { style: 'normal', weight: 'bold' },
              'Bold & Italic': { style: 'italic', weight: 'bold' }
            };

            // Definir as propriedades separadamente
            const variant = variantMap[style.value] || variantMap['None'];
            root.style.setProperty(`${propertyName}-style`, variant.style);
            root.style.setProperty(`${propertyName}-weight`, variant.weight);
            return; // Retorna aqui pois já definimos as propriedades

        default:
          propertyValue = style.value;
      }

      root.style.setProperty(propertyName, propertyValue);
    }
  };

  const styleCategories = {
    onlineCheckin: [
      'OC-bg_color',
      'OC-title_color',
      'OC-title_size',
      'OC-title_font_family',
      'OC-title_variant',

      'OC-Header-image',
      'OC-Header-logo_image',
      'OC-Header-title_color',
      'OC-Header-title_size',
      'OC-Header-title_font_family',
      'OC-Header-title_variant',
      'OC-Header-text_color',
      'OC-Header-text_size',
      'OC-Header-text_font_family',
      'OC-Header-text_variant',
      'OC-Header-bar_bg_color',
      'OC-Header-bar_border_color',
      'OC-Header-bar_border_width',
      'OC-Header-bar_border_position',
      'OC-Header-bar_border_rounded',

      'OC-Stepper-border_color',
      'OC-Stepper-active_color',
      'OC-Stepper-text_color',
      'OC-Stepper-text_font_family',
      'OC-Stepper-text_variant',
      'OC-Stepper-label_color',
      'OC-Stepper-label_font_family',
      'OC-Stepper-label_variant',
      'OC-Stepper-label_size',

      'OC-Form-title_color',
      'OC-Form-title_size',
      'OC-Form-title_font_family',
      'OC-Form-title_variant',
      'OC-Form-text_color',
      'OC-Form-text_size',
      'OC-Form-text_font_family',
      'OC-Form-text_variant',
      'OC-Form-bg_color',
      'OC-Form-border_color',
      'OC-Form-border_rounded',
      'OC-Form-border_position',
      'OC-Form-border_width',

      'OC-FormField-label_color',
      'OC-FormField-label_size',
      'OC-FormField-label_font_family',
      'OC-FormField-label_variant',
      'OC-FormField-label_location',
      'OC-FormField-label_is_active',
      'OC-FormField-text_color',
      'OC-FormField-text_size',
      'OC-FormField-text_font_family',
      'OC-FormField-text_variant',
      'OC-FormField-bg_color',
      'OC-FormField-icon_color',
      'OC-FormField-border_color',
      'OC-FormField-border_rounded',
      'OC-FormField-border_position',
      'OC-FormField-border_width',
      'OC-FormField-placeholder_color',
      'OC-FormField-placeholder_font_family',
      'OC-FormField-placeholder_variant',
      'OC-FormField-placeholder_is_active',


      'OC-Button1-bg_color',
      'OC-Button1-icon_color',
      'OC-Button1-border_color',

      'OC-Button2-bg_color',
      'OC-Button2-icon_color',
      'OC-Button2-border_color',

      'OC-Consent-bg_color',
      'OC-Consent-border_color',
      'OC-Consent-border_rounded',
      'OC-Consent-border_position',
      'OC-Consent-border_width',
      'OC-Consent-text_color',
      'OC-Consent-text_size',
      'OC-Consent-text_font_family',
      'OC-Consent-text_variant',
      'OC-Consent-title_color',
      'OC-Consent-title_size',
      'OC-Consent-title_font_family',
      'OC-Consent-title_variant',
      'OC-Consent-icon_color',

      'OC-GeneralTerm-bg_color',
      'OC-GeneralTerm-border_color',
      'OC-GeneralTerm-border_rounded',
      'OC-GeneralTerm-border_position',
      'OC-GeneralTerm-border_width',
      'OC-GeneralTerm-text_color',
      'OC-GeneralTerm-text_size',
      'OC-GeneralTerm-text_font_family',
      'OC-GeneralTerm-text_variant',
      'OC-GeneralTerm-title_color',
      'OC-GeneralTerm-title_size',
      'OC-GeneralTerm-title_font_family',
      'OC-GeneralTerm-title_variant',
      'OC-GeneralTerm-icon_color',

    ],
    tableReservation: [
      'TR-bg_color',

      'TR-Header-title_color',
      'TR-Header-title_size',
      'TR-Header-title_font_family',
      'TR-Header-title_variant',
      'TR-Header-text_color',
      'TR-Header-text_size',
      'TR-Header-text_font_family',
      'TR-Header-text_variant',
      'TR-Header-bar_bg_color',
      'TR-Header-bar_border_color',
      'TR-Header-bar_border_width',
      'TR-Header-bar_border_position',
      'TR-Header-bar_border_rounded',

      'TR-Stepper-border_color',
      'TR-Stepper-active_color',
      'TR-Stepper-text_color',
      'TR-Stepper-text_font_family',
      'TR-Stepper-text_variant',
      'TR-Stepper-icon_color',
      'TR-Stepper-hover_bg_color',
      'TR-Stepper-bg_color',

      'TR-Form-title_color',
      'TR-Form-title_size',
      'TR-Form-title_font_family',
      'TR-Form-title_variant',
      'TR-Form-text_color',
      'TR-Form-text_size',
      'TR-Form-text_font_family',
      'TR-Form-text_variant',
      'TR-Form-bg_color',
      'TR-Form-border_color',
      'TR-Form-border_rounded',
      'TR-Form-border_position',
      'TR-Form-border_width',


      'TR-Card-bg_color',
      'TR-Card-border_color',
      'TR-Card-border_rounded',
      'TR-Card-border_position',
      'TR-Card-border_width',
      'TR-Card-text_color',
      'TR-Card-text_size',
      'TR-Card-text_font_family',
      'TR-Card-text_variant',
      'TR-Card-hover_bg_color',
      'TR-Card-hover_border_color',
      'TR-Card-active_bg_color',
      'TR-Card-active_color',
      'TR-Card-disabled_bg_color',
      'TR-Card-disabled_text_color',
      'TR-Card-disabled_border_color',

      'TR-Button1-bg_color',
      'TR-Button1-icon_color',
      'TR-Button1-border_color',


      'TR-Button2-bg_color',
      'TR-Button2-icon_color',
      'TR-Button2-border_color',

      'TR-FormField-label_color',
      'TR-FormField-label_size',
      'TR-FormField-label_font_family',
      'TR-FormField-label_variant',
      'TR-FormField-label_location',
      'TR-FormField-label_is_active',
      'TR-FormField-text_color',
      'TR-FormField-text_size',
      'TR-FormField-text_font_family',
      'TR-FormField-text_variant',
      'TR-FormField-bg_color',
      'TR-FormField-icon_color',
      'TR-FormField-border_color',
      'TR-FormField-border_rounded',
      'TR-FormField-border_position',
      'TR-FormField-border_width',
      'TR-FormField-placeholder_color',
      'TR-FormField-placeholder_font_family',
      'TR-FormField-placeholder_variant',
      'TR-FormField-placeholder_is_active',

    ],

    menuHeader: [
      'MH-bg_color',
      'MH-title_color',
      'MH-title_size',
      'MH-title_font_family',
      'MH-title_variant',
      'MH-border_color',
      'MH-border_rounded',
      'MH-border_position',
      'MH-border_width',
      'MH-icon_color',
      'MH-text_color',
      'MH-text_size',
      'MH-text_font_family',
      'MH-text_variant',
    ],
    menuFooter: [
      'MF-bg_color',
      'MF-title_color',
      'MF-title_size',
      'MF-title_font_family',
      'MF-title_variant',
      'MF-border_color',
      'MF-border_rounded',
      'MF-border_position',
      'MF-border_width',
      'MF-icon_color',
    ],
    home: [
      'HM-bg_color',
      'HM-title_color',
      'HM-title_size',
      'HM-title_font_family',
      'HM-title_variant',
      'HM-text_color',
      'HM-text_size',
      'HM-text_font_family',
      'HM-text_variant',
      'HM-icon_color',

      'HM-Card-bg_color',
      'HM-Card-title_color',
      'HM-Card-title_size',
      'HM-Card-title_font_family',
      'HM-Card-title_variant',
      'HM-Card-text_color',
      'HM-Card-text_size',
      'HM-Card-text_font_family',
      'HM-Card-text_variant',
      'HM-Card-border_color',
      'HM-Card-border_rounded',
      'HM-Card-border_position',
      'HM-Card-border_width',
      'HM-Card-icon_color',
    ]
  };

  // Aplicar todas as propriedades de estilo organizadas por categoria
  Object.values(styleCategories).flat().forEach(applyStyleProperty);
};